

















import { Component, Vue } from 'vue-property-decorator'

import NavbarNotLoggedIn from '@/components/navbar/navbar-not-logged-in.vue'

@Component({
  components: {
    NavbarNotLoggedIn
  }
})
export default class Logout extends Vue {}
