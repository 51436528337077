



























































































































































import { Component, Vue } from 'vue-property-decorator'

import { getSeurantajaksot } from '@/api/kouluttaja'
import Pagination from '@/components/pagination/pagination.vue'
import SearchInput from '@/components/search-input/search-input.vue'
import { Seurantajakso } from '@/types'
import { SeurantajaksoTila, TaskStatus } from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    SearchInput,
    Pagination
  }
})
export default class SeurantakeskustelutViewKouluttajaVastuuhenkilo extends Vue {
  seurantajaksot: Seurantajakso[] = []

  loading = true

  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('seurantakeskustelut'),
      active: true
    }
  ]

  fields = [
    {
      key: 'erikoistuvanNimi',
      label: this.$t('erikoistuja'),
      class: 'nimi',
      sortable: true
    },
    {
      key: 'seurantajakso',
      label: this.$t('seurantajakso'),
      class: 'seurantajakso',
      sortable: true
    },
    {
      key: 'tila',
      label: this.$t('tila'),
      class: 'tila',
      sortable: true
    },
    {
      key: 'pvm',
      label: this.$t('pvm'),
      class: 'pvm',
      sortable: true
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      class: 'actions'
    }
  ]
  perPage = 20
  currentPage = 1
  hakutermi = ''

  async mounted() {
    this.loading = true
    try {
      this.seurantajaksot = (await getSeurantajaksot()).data
    } catch {
      toastFail(this, this.$t('seurantakeskustelujen-hakeminen-epaonnistui'))
      this.seurantajaksot = []
    }
    this.loading = false
  }

  get rows() {
    return this.tulokset?.length ?? 0
  }

  linkComponent(tila: SeurantajaksoTila) {
    if (
      tila === SeurantajaksoTila.ODOTTAA_ARVIOINTIA ||
      tila === SeurantajaksoTila.ODOTTAA_ARVIOINTIA_JA_YHTEISIA_MERKINTOJA ||
      tila === SeurantajaksoTila.ODOTTAA_HYVAKSYNTAA
    ) {
      return 'muokkaa-seurantajaksoa'
    }
    return 'seurantajakso'
  }

  taskIcon(tila: SeurantajaksoTila) {
    switch (tila) {
      case SeurantajaksoTila.HYVAKSYTTY:
        return ['fas', 'check-circle']
      case SeurantajaksoTila.PALAUTETTU_KORJATTAVAKSI:
        return ['fas', 'undo-alt']
      case SeurantajaksoTila.ODOTTAA_YHTEISIA_MERKINTOJA:
        return ['far', 'check-circle']
      default:
        return ['far', 'clock']
    }
  }

  taskClass(tila: SeurantajaksoTila) {
    switch (tila) {
      case SeurantajaksoTila.HYVAKSYTTY:
        return 'text-success'
      case SeurantajaksoTila.PALAUTETTU_KORJATTAVAKSI:
        return ''
      case SeurantajaksoTila.ODOTTAA_YHTEISIA_MERKINTOJA:
        return 'text-success'
      default:
        return 'text-warning'
    }
  }

  taskStatus(tila: SeurantajaksoTila) {
    switch (tila) {
      case SeurantajaksoTila.HYVAKSYTTY:
        return this.$t('lomake-tila-' + TaskStatus.HYVAKSYTTY)
      case SeurantajaksoTila.PALAUTETTU_KORJATTAVAKSI:
        return this.$t('lomake-tila-' + TaskStatus.PALAUTETTU)
      case SeurantajaksoTila.ODOTTAA_YHTEISIA_MERKINTOJA:
        return this.$t('lomake-tila-' + TaskStatus.ODOTTAA_YHTEISIA_MERKINTOJA)
      default:
        return this.$t('lomake-tila-' + TaskStatus.AVOIN)
    }
  }

  get tulokset() {
    if (this.hakutermi) {
      this.currentPage = 1
      return this.seurantajaksot?.filter((item: Seurantajakso) =>
        item.erikoistuvanNimi?.toLowerCase().includes(this.hakutermi.toLowerCase())
      )
    }

    return this.seurantajaksot
  }
}
