var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.poissaolo !== null && _vm.childDataReceived)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('poissaolo')))]),_c('elsa-form-group',{attrs:{"label":_vm.$t('poissaolon-syy'),"required":true},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',{attrs:{"title":_vm.$t('poissaolon-syy')}},[_c('elsa-poissaolon-syyt')],1)]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.poissaolonSyytSorted,"state":_vm.validateState('poissaolonSyy'),"label":"nimi","track-by":"id"},on:{"input":function($event){return _vm.$emit('input', _vm.poissaolo)}},model:{value:(_vm.poissaolo.poissaolonSyy),callback:function ($$v) {_vm.$set(_vm.poissaolo, "poissaolonSyy", $$v)},expression:"poissaolo.poissaolonSyy"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.poissaolo.poissaolonSyy.id !== 0}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,219744407)}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pr-sm-3",attrs:{"label":_vm.$t('alkamispaiva'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"alkamispaiva",attrs:{"id":uid,"min":_vm.minAlkamispaiva,"max":_vm.maxAlkamispaiva},on:{"input":function($event){return _vm.$emit('input', _vm.poissaolo)}},model:{value:(_vm.poissaolo.alkamispaiva),callback:function ($$v) {_vm.$set(_vm.poissaolo, "alkamispaiva", $$v)},expression:"poissaolo.alkamispaiva"}}):_vm._e()]}}],null,false,3784904412)}),_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pl-sm-3",attrs:{"label":_vm.$t('paattymispaiva'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"paattymispaiva",staticClass:"datepicker-range",attrs:{"id":uid,"min":_vm.minPaattymispaiva,"max":_vm.maxPaattymispaiva},on:{"input":function($event){return _vm.$emit('input', _vm.poissaolo)}},model:{value:(_vm.poissaolo.paattymispaiva),callback:function ($$v) {_vm.$set(_vm.poissaolo, "paattymispaiva", $$v)},expression:"poissaolo.paattymispaiva"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,890054170)})],1),_c('b-form-checkbox',{class:{ 'mb-3': !_vm.poissaolo.kokoTyoajanPoissaolo },on:{"change":function($event){return _vm.$emit('input', _vm.poissaolo)}},model:{value:(_vm.poissaolo.kokoTyoajanPoissaolo),callback:function ($$v) {_vm.$set(_vm.poissaolo, "kokoTyoajanPoissaolo", $$v)},expression:"poissaolo.kokoTyoajanPoissaolo"}},[_vm._v(" "+_vm._s(_vm.$t('koko-tyoajan-poissaolo'))+" ")]),_c('b-form-row',[(!_vm.poissaolo.kokoTyoajanPoissaolo)?_c('elsa-form-group',{staticClass:"col-sm-5",attrs:{"label":((_vm.$t('poissaolo-nykyisesta-taydesta-tyoajasta')) + " (0-100 %)"),"required":true},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',[_vm._v(" "+_vm._s(_vm.$t('poissaoloprosentti-tooltip'))+" ")])]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('poissaoloprosentti'),"type":"number","step":"any"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.poissaolo.poissaoloprosentti),callback:function ($$v) {_vm.$set(_vm.poissaolo, "poissaoloprosentti", _vm._n($$v))},expression:"poissaolo.poissaoloprosentti"}}),_c('span',{staticClass:"mx-3"},[_vm._v("%")])],1),_c('b-form-invalid-feedback',{style:({
            display: _vm.validateState('poissaoloprosentti') === false ? 'block' : 'none'
          }),attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(((_vm.$t('poissaoloprosentti-validointivirhe')) + " 0-100 %"))+" ")])]}}],null,false,525287487)}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }