













import Vue from 'vue'
import Component from 'vue-class-component'

import MuokkaaSeurantajaksoaErikoistuja from '@/views/seurantakeskustelut/erikoistuva/muokkaa-seurantajaksoa-erikoistuja.vue'
import MuokkaaSeurantajaksoaKouluttajaVastuuhenkilo from '@/views/seurantakeskustelut/kouluttaja-vastuuhenkilo/muokkaa-seurantajaksoa-kouluttaja-vastuuhenkilo.vue'

@Component({
  components: {
    MuokkaaSeurantajaksoaErikoistuja,
    MuokkaaSeurantajaksoaKouluttajaVastuuhenkilo
  }
})
export default class SeurantajaksoContainer extends Vue {
  get notFound() {
    return !(this.$isErikoistuva() || this.$isKouluttaja())
  }

  skipRouteExitConfirm() {
    this.$emit('skipRouteExitConfirm', true)
  }
}
