import { render, staticRenderFns } from "./koulutussuunnitelma-form.vue?vue&type=template&id=4c697399&scoped=true&"
import script from "./koulutussuunnitelma-form.vue?vue&type=script&lang=ts&"
export * from "./koulutussuunnitelma-form.vue?vue&type=script&lang=ts&"
import style0 from "./koulutussuunnitelma-form.vue?vue&type=style&index=0&id=4c697399&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c697399",
  null
  
)

export default component.exports