import { render, staticRenderFns } from "./paivittaiset-merkinnat-card.vue?vue&type=template&id=056175fc&"
import script from "./paivittaiset-merkinnat-card.vue?vue&type=script&lang=ts&"
export * from "./paivittaiset-merkinnat-card.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports