





































































































































































































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'

import { getKoulutusjaksot } from '@/api/erikoistuva'
import ElsaAccordian from '@/components/accordian/accordian.vue'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import ElsaButton from '@/components/button/button.vue'
import store from '@/store'
import { Koulutusjakso, Koulutussuunnitelma } from '@/types'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaAccordian,
    ElsaButton,
    AsiakirjatContent
  }
})
export default class KoulutussuunnitelmaView extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koulutussuunnitelma'),
      active: true
    }
  ]

  koulutussuunnitelma: Koulutussuunnitelma | null = null
  koulutusjaksot: Koulutusjakso[] | null = null
  loading = true

  async mounted() {
    await Promise.all([this.fetchKoulutusjaksot(), this.fetchKoulutussuunnitelma()])
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  async fetchKoulutussuunnitelma() {
    try {
      this.koulutussuunnitelma = (await axios.get(`erikoistuva-laakari/koulutussuunnitelma`)).data
    } catch {
      toastFail(this, this.$t('koulutussuunnitelman-hakeminen-epaonnistui'))
    }
  }

  async fetchKoulutusjaksot() {
    try {
      this.koulutusjaksot = (await getKoulutusjaksot()).data
    } catch {
      toastFail(this, this.$t('koulutusjaksojen-hakeminen-epaonnistui'))
    }
  }

  onPrintSuunnitelma() {
    ;(this.$refs.motivaatiokirjeAccordian as ElsaAccordian).open()
    ;(this.$refs.opiskeluJaTyohistoriaYksityinenAccordian as ElsaAccordian).open()
    ;(this.$refs.vahvuudetYksityinenAccordian as ElsaAccordian).open()
    ;(this.$refs.tulevaisuudenVisiointiYksityinenAccordian as ElsaAccordian).open()
    ;(this.$refs.osaamisenKartuttaminenYksityinenAccordian as ElsaAccordian).open()
    ;(this.$refs.elamankenttaYksityinenAccordian as ElsaAccordian).open()
    setTimeout(() => window.print(), 500) // Varmistetaan, että sisältöjen avaamisanimaatiot ovat suoritettu.
  }

  get linkki() {
    return `<a
              href="https://www.laaketieteelliset.fi/ammatillinen-jatkokoulutus/opinto-oppaat/"
              target="_blank"
              rel="noopener noreferrer"
            >${this.$t('henkilokohtainen-koulutussuunnitelma-linkki')}</a>`
  }

  get koulutussuunnitelmaAsiakirjatTableItems() {
    return this.koulutussuunnitelma?.koulutussuunnitelmaAsiakirja
      ? [this.koulutussuunnitelma.koulutussuunnitelmaAsiakirja]
      : []
  }

  get motivaatiokirjeAsiakirjatTableItems() {
    return this.koulutussuunnitelma?.motivaatiokirjeAsiakirja
      ? [this.koulutussuunnitelma.motivaatiokirjeAsiakirja]
      : []
  }
}
