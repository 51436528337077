


























































































































































import axios from 'axios'
import { Vue, Component } from 'vue-property-decorator'

import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import TyoskentelyjaksoForm from '@/forms/tyoskentelyjakso-form.vue'
import store from '@/store'
import { Tyoskentelyjakso } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { KaytannonKoulutusTyyppi } from '@/utils/constants'
import { ELSA_ROLE } from '@/utils/roles'
import { toastFail, toastSuccess } from '@/utils/toast'
import { tyoskentelypaikkaTyyppiLabel } from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    TyoskentelyjaksoForm,
    ElsaFormGroup,
    ElsaButton,
    AsiakirjatContent
  }
})
export default class YekTyoskentelyjakso extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('tyoskentelyjaksot'),
      to: { name: 'yektyoskentelyjaksot' }
    },
    {
      text: this.$t('yektyoskentelyjakso'),
      active: true
    }
  ]
  tyoskentelyjakso: Tyoskentelyjakso | null = null
  loading = false
  deleting = false

  async mounted() {
    const tyoskentelyjaksoId = this.$route?.params?.tyoskentelyjaksoId
    if (tyoskentelyjaksoId) {
      this.loading = true
      try {
        this.tyoskentelyjakso = (
          await axios.get(`yek-koulutettava/tyoskentelyjaksot/${tyoskentelyjaksoId}`)
        ).data
      } catch {
        toastFail(this, this.$t('tyoskentelyjakson-hakeminen-epaonnistui'))
        this.$router.replace({ name: 'yektyoskentelyjaksot' })
      }
      this.loading = false
    }
  }

  get account() {
    return store.getters['auth/account']
  }

  async onTyoskentelyjaksoDelete() {
    if (!this.tyoskentelyjakso?.id) return
    if (
      await confirmDelete(
        this,
        this.$t('poista-tyoskentelyjakso') as string,
        (this.$t('tyoskentelyjakson') as string).toLowerCase()
      )
    ) {
      this.deleting = true
      try {
        await axios.delete(`yek-koulutettava/tyoskentelyjaksot/${this.tyoskentelyjakso.id}`)
        toastSuccess(this, this.$t('tyoskentelyjakso-poistettu-onnistuneesti'))
        this.$router.push({
          name: 'yektyoskentelyjaksot'
        })
      } catch {
        toastFail(this, this.$t('tyoskentelyjakson-poistaminen-epaonnistui'))
      }
      this.deleting = false
    }
  }

  get omaaErikoisalaaTukeva() {
    return KaytannonKoulutusTyyppi.OMAA_ERIKOISALAA_TUKEVA_KOULUTUS
  }

  get tyyppiLabel() {
    if (this.tyoskentelyjakso?.tyoskentelypaikka?.tyyppi) {
      return tyoskentelypaikkaTyyppiLabel(this, this.tyoskentelyjakso?.tyoskentelypaikka?.tyyppi)
    }
    return undefined
  }

  get muokkausoikeudet() {
    if (!this.account.impersonated) {
      return true
    }

    if (
      this.account.originalUser.authorities.includes(ELSA_ROLE.OpintohallinnonVirkailija) &&
      this.account.erikoistuvaLaakari.muokkausoikeudetVirkailijoilla
    ) {
      return true
    }

    return false
  }
}
