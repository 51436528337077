


































































import Vue from 'vue'
import Component from 'vue-class-component'

import TietosuojaselosteModal from '@/components/tietosuojaseloste/tietosuojaseloste-modal.vue'
import PalauteFormModal from '@/forms/palaute-form-modal.vue'
import store from '@/store'
import { Meta } from '@/utils/decorators'

@Component({
  components: {
    PalauteFormModal,
    TietosuojaselosteModal
  }
})
export default class App extends Vue {
  showTietosuojaselosteFormModal = false
  showPalauteFormModal = false

  openTietosuojaselosteModal() {
    this.showTietosuojaselosteFormModal = true
  }

  openPalauteFormModal() {
    this.showPalauteFormModal = true
  }

  hidePalauteFormModal() {
    this.showPalauteFormModal = false
  }

  @Meta
  getMetaInfo() {
    if (this.$route && this.$route.name) {
      return {
        title: this.$t(this.$route.name),
        titleTemplate: `%s - ${this.$t('elsa-palvelu')}`
      }
    }
    return {
      title: this.$t('elsa-palvelu'),
      titleTemplate: null
    }
  }

  get isLoggedIn() {
    return store.getters['auth/isLoggedIn']
  }
}
