import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowsAlt,
  faArrowUp,
  faAward,
  faBars,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faDumbbell,
  faEdit,
  faEnvelope,
  faEnvelopeOpenText,
  faExclamationCircle,
  faFile,
  faFileAlt,
  faFileDownload,
  faHome,
  faInfo,
  faInfoCircle,
  faPaperPlane,
  faPlus,
  faPortrait,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faSearch,
  faShare,
  faTheaterMasks,
  faTrophy,
  faTimes,
  faToolbox,
  faUndoAlt,
  faUniversity,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons'

library.add(faArrowsAlt)
library.add(faArrowUp)
library.add(faAward)
library.add(faBars)
library.add(faCaretDown)
library.add(faCaretUp)
library.add(faChartLine)
library.add(faCheckCircle)
library.add(faChevronDown)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faChevronUp)
library.add(faCircle)
library.add(faClipboard)
library.add(faClipboardCheck)
library.add(faClipboardList)
library.add(faDumbbell)
library.add(faEdit)
library.add(faEnvelope)
library.add(faEnvelopeOpenText)
library.add(faExclamationCircle)
library.add(faFile)
library.add(faFileAlt)
library.add(faFileDownload)
library.add(faHome)
library.add(faInfo)
library.add(faInfoCircle)
library.add(faPaperPlane)
library.add(faPlus)
library.add(faPortrait)
library.add(faPrint)
library.add(faQuestion)
library.add(faQuestionCircle)
library.add(faSearch)
library.add(faShare)
library.add(faTheaterMasks)
library.add(faTrophy)
library.add(faTimes)
library.add(faToolbox)
library.add(faUndoAlt)
library.add(faUniversity)
library.add(faUserFriends)
