




























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class BCardSkeleton extends Vue {
  @Prop({ required: false, default: false })
  loading!: boolean

  @Prop({ required: false, default: '', type: String })
  header!: string

  @Prop({ required: false, default: '', type: String })
  headerRoute!: string
}
