



























































































































































import { AxiosError } from 'axios'
import { Component, Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'

import { postVirkailija, getYliopistot } from '@/api/kayttajahallinta'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import { KayttajahallintaNewKayttaja, ElsaError, Yliopisto } from '@/types'
import { toastFail, toastSuccess } from '@/utils/toast'
import VastuuhenkilonTehtavat from '@/views/kayttajahallinta/vastuuhenkilon-tehtavat.vue'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    VastuuhenkilonTehtavat
  },
  validations: {
    form: {
      etunimi: { required },
      sukunimi: { required },
      sahkoposti: {
        required,
        email
      },
      sahkopostiUudelleen: {
        required,
        email,
        sameAsSahkoposti: sameAs('sahkoposti')
      },
      eppn: {
        required
      },
      yliopisto: {
        required
      }
    }
  }
})
export default class VirkailijaForm extends Mixins(validationMixin) {
  form: KayttajahallintaNewKayttaja = {
    etunimi: null,
    sukunimi: null,
    sahkoposti: null,
    sahkopostiUudelleen: null,
    eppn: null,
    yliopisto: null
  }
  yliopistot: Yliopisto[] = []
  loading = true
  saving = false

  async mounted() {
    this.yliopistot = (await getYliopistot()).data
    if (this.yliopistot.length === 1) {
      this.form.yliopisto = this.yliopistot[0]
    }
    this.loading = false
  }

  async onSubmit() {
    if (!this.validateForm()) {
      return
    }
    this.saving = true

    try {
      const kayttajaId = (
        await postVirkailija({
          ...this.form
        })
      ).data.kayttaja?.id
      toastSuccess(this, this.$t('virkailija-lisatty'))
      this.$emit('skipRouteExitConfirm', true)
      this.saving = false
      this.$router.push({
        name: 'virkailija',
        params: { kayttajaId: `${kayttajaId}` }
      })
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('tietojen-tallennus-epaonnistui')}: ${this.$t(message)}`
          : this.$t('tietojen-tallennus-epaonnistui')
      )
      this.saving = false
    }
  }

  onCancel() {
    this.$router.push({
      name: 'kayttajahallinta'
    })
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form?.[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  yliopistoLabel(value: Yliopisto) {
    return this.$t(`yliopisto-nimi.${value.nimi}`)
  }
}
