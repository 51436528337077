











































































import { Component, Prop, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import { ValmistumispyyntoListItem, Page } from '@/types'
import { ValmistumispyynnonTila } from '@/utils/constants'
import { ValmistumispyynnonHyvaksyjaRole } from '@/utils/roles'

@Component({
  components: {
    ElsaButton,
    ElsaPagination
  }
})
export default class YekValmistumispyynnotList extends Vue {
  @Prop({ required: true, default: undefined })
  valmistumispyynnot!: Page<ValmistumispyyntoListItem>

  @Prop({ required: true, type: Boolean, default: false })
  hakutermiExists!: boolean

  @Prop({ required: true, type: Number })
  currentPage!: number

  @Prop({ required: true, type: Number })
  perPage!: number

  @Prop({ required: true, type: Boolean, default: false })
  loading!: boolean

  fields = [
    {
      key: 'erikoistujanNimi',
      label: this.$t('yek.koulutettava'),
      class: 'nimi',
      sortable: false
    },
    {
      key: 'tila',
      label: this.$t('tila'),
      class: 'tila',
      sortable: false
    },
    {
      key: 'tapahtumanAjankohta',
      label: this.$t('pvm'),
      class: 'pvm',
      sortable: false
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      class: 'actions'
    }
  ]

  odottaaHyvaksyntaa(valmistumispyynto: ValmistumispyyntoListItem) {
    return valmistumispyynto.tila === ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
  }

  linkComponent(valmistumispyynto: ValmistumispyyntoListItem) {
    switch (valmistumispyynto.rooli) {
      case ValmistumispyynnonHyvaksyjaRole.VIRKAILIJA:
        return 'valmistumispyynnon-tarkistus-yek'
      case ValmistumispyynnonHyvaksyjaRole.VASTUUHENKILO_HYVAKSYJA:
        return 'valmistumispyynnon-hyvaksynta-yek'
    }
  }

  getActionButtonText(valmistumispyynto: ValmistumispyyntoListItem) {
    if (valmistumispyynto.tila === ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA) {
      return this.$t('hyvaksy')
    } else {
      return this.$t('tarkista')
    }
  }

  taskIcon(status: string, isAvoin: boolean) {
    if (isAvoin) {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
          return ['far', 'clock']
      }
    } else {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
          return ['far', 'check-circle']
        case ValmistumispyynnonTila.VASTUUHENKILON_HYVAKSYNTA_PALAUTETTU:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_PALAUTETTU:
          return ['fas', 'undo-alt']
        case ValmistumispyynnonTila.HYVAKSYTTY:
          return ['fas', 'check-circle']
      }
    }
  }

  taskClass(status: string, isAvoin: boolean) {
    if (isAvoin) {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
          return 'text-warning'
      }
    } else {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
        case ValmistumispyynnonTila.HYVAKSYTTY:
          return 'text-success'
      }
    }
    return ''
  }

  taskStatus(status: string, isAvoin: boolean) {
    if (isAvoin) {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
          return this.$t('valmistumispyynnon-tila.avoin-odottaa-hyvaksyntaa')
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
          return this.$t('avoin')
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
          return this.$t('valmistumispyynnon-tila.avoin-virkailijan-tarkastus-kesken')
      }
    } else {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
          return this.$t('valmistumispyynnon-tila.valmis-odottaa-virkailijan-tarkastusta')
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
          return this.$t('valmistumispyynnon-tila.valmis-odottaa-hyvaksyntaa')
        case ValmistumispyynnonTila.VASTUUHENKILON_HYVAKSYNTA_PALAUTETTU:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_PALAUTETTU:
          return this.$t('valmistumispyynnon-tila.palautettu-koulutettavalle')
        case ValmistumispyynnonTila.HYVAKSYTTY:
          return this.$t('valmistumispyynnon-tila.hyvaksytty')
      }
    }
  }

  get content() {
    return this.valmistumispyynnot?.content
  }

  get rows() {
    return this.valmistumispyynnot?.totalElements ?? 0
  }

  onPageInput(value: number) {
    this.$emit('update:currentPage', value)
  }
}
