





























import { Component, Vue } from 'vue-property-decorator'

import { getPaivittaisetMerkinnat } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import store from '@/store'
import { Paivakirjamerkinta } from '@/types'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton
  }
})
export default class PaivittaisetMerkinnatCard extends Vue {
  merkinnat: Paivakirjamerkinta[] | null = null
  loading = true

  async mounted() {
    try {
      const merkinnat = (
        await getPaivittaisetMerkinnat({
          page: 0,
          size: 5,
          sort: 'paivamaara,id,desc'
        })
      ).data
      this.merkinnat = merkinnat.content
    } catch {
      toastFail(this, this.$t('paivittaisten-merkintojen-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  get rows() {
    return this.merkinnat?.length ?? 0
  }
}
