






































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KommenttiCard from '@/components/kommentti-card/kommentti-card.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import ArviointiForm from '@/forms/arviointi-form.vue'
import store from '@/store'
import { Suoritusarviointi } from '@/types'
import { resolveRolePath } from '@/utils/apiRolePathResolver'
import { sortByDateAsc } from '@/utils/date'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ArviointiForm,
    UserAvatar,
    ElsaFormGroup,
    KommenttiCard,
    ElsaButton
  }
})
export default class Arviointi extends Vue {
  value: Suoritusarviointi | null = null
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('arvioinnit'),
      to: { name: 'arvioinnit' }
    },
    {
      text: this.$t('arviointi'),
      active: true
    }
  ]
  kommentti = {
    teksti: null
  }
  saving = false

  async mounted() {
    const arviointiId = this.$route?.params?.arviointiId
    if (arviointiId) {
      try {
        this.value = (
          await axios.get(`${resolveRolePath()}/suoritusarvioinnit/${arviointiId}`)
        ).data
      } catch {
        toastFail(this, this.$t('arvioinnin-hakeminen-epaonnistui'))
        this.$router.replace({ name: 'arvioinnit' })
      }
    }
  }

  onKommenttiUpdated(kommentti: any) {
    const updatedKommentti = this.value?.kommentit.find((k: any) => k.id === kommentti.id)
    if (updatedKommentti) {
      updatedKommentti.teksti = kommentti.teksti
      updatedKommentti.muokkausaika = kommentti.muokkausaika
    }
  }

  async onKommenttiSubmit() {
    this.saving = true
    try {
      const kommentti = (
        await axios.post(`suoritusarvioinnit/${this.value?.id}/kommentti`, this.kommentti)
      ).data
      kommentti.kommentoija.nimi = `${this.account.firstName} ${this.account.lastName}`
      this.value?.kommentit.push(kommentti)
      this.kommentti = {
        teksti: null
      }
    } catch {
      toastFail(this, this.$t('uuden-kommentin-lisaaminen-epaonnistui'))
    }
    this.saving = false
  }

  get kommentit() {
    if (this.value) {
      return this.value.kommentit
        .sort((a: any, b: any) => sortByDateAsc(a.luontiaika, b.luontiaika))
        .map((k: any) => {
          return {
            kommentti: k,
            self: k.kommentoija?.userId === this.account?.id
          }
        })
    } else {
      return []
    }
  }

  get account() {
    return store.getters['auth/account']
  }
}
