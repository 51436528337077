var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[(!_vm.laillistamisTiedotForm.laillistamistiedotAdded)?_c('div',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-4 pl-0",attrs:{"label":_vm.$t('yek.valviran-laillistamispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-datepicker',{ref:"laillistamispaiva",attrs:{"id":uid,"value":_vm.laillistamisTiedotForm.laillistamispaiva},on:{"update:value":function($event){return _vm.$set(_vm.laillistamisTiedotForm, "laillistamispaiva", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}})]}}],null,false,2825000095)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('laillistamispaivan-liitetiedosto'),"required":true}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('lisaa-liite-joka-todistaa-laillistamispaivan'))+" ")]),_c('asiakirjat-upload',{staticClass:"mt-3",attrs:{"is-primary-button":false,"allow-multiples-files":false,"button-text":_vm.$t('lisaa-liitetiedosto'),"disabled":_vm.laillistamispaivaAsiakirjat.length > 0},on:{"selectedFiles":_vm.onLaillistamispaivaFilesAdded}}),(_vm.laillistamispaivaAsiakirjat.length > 0)?_c('div',[_c('asiakirjat-content',{attrs:{"asiakirjat":_vm.laillistamispaivaAsiakirjat,"sorting-enabled":false,"pagination-enabled":false,"enable-search":false,"enable-delete":true,"enable-lisatty":false,"no-results-info-text":_vm.$t('ei-liitetiedostoja')},on:{"deleteAsiakirja":_vm.onDeleteLaillistamispaivanLiite}})],1):_c('div',[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"dark","show":""}},[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-asiakirjoja'))+" ")])],1)],1)],1)],1):_vm._e(),(_vm.isEnsimmainenTyoskentelyjakso)?_c('div',[_c('elsa-form-group',{attrs:{"label":_vm.$t('yek.aiempi-laakarikoulutus'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('div',[_c('b-form-radio',{key:"laakarikoulutus-suomi-belgia",attrs:{"name":"laakarikoulutus","value":true},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)},"change":function($event){return _vm.aiempiLaakarikoulutusChange('suomibelgia')}},model:{value:(_vm.laillistamisTiedotForm.laakarikoulutusSuoritettuSuomiTaiBelgia),callback:function ($$v) {_vm.$set(_vm.laillistamisTiedotForm, "laakarikoulutusSuoritettuSuomiTaiBelgia", $$v)},expression:"laillistamisTiedotForm.laakarikoulutusSuoritettuSuomiTaiBelgia"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('yek.aiempi-laakarikoulutus-olen-suorittanut-suomi-belgia'))+" ")])]),_c('b-form-radio',{key:"laakarikoulutus-muu",attrs:{"name":"laakarikoulutus","value":true},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)},"change":function($event){return _vm.aiempiLaakarikoulutusChange('muu')}},model:{value:(_vm.laillistamisTiedotForm.laakarikoulutusSuoritettuMuuKuinSuomiTaiBelgia),callback:function ($$v) {_vm.$set(_vm.laillistamisTiedotForm, "laakarikoulutusSuoritettuMuuKuinSuomiTaiBelgia", $$v)},expression:"laillistamisTiedotForm.laakarikoulutusSuoritettuMuuKuinSuomiTaiBelgia"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('yek.aiempi-laakarikoulutus-olen-suorittanut-muu-kuin-suomi-belgia'))+" ")])]),_c('b-form-invalid-feedback',{style:({
              display:
                !_vm.laillistamisTiedotForm.laakarikoulutusSuoritettuSuomiTaiBelgia &&
                !_vm.laillistamisTiedotForm.laakarikoulutusSuoritettuMuuKuinSuomiTaiBelgia
                  ? 'block'
                  : 'none'
            }),attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])],1)]}}],null,false,4141319438)})],1):_vm._e(),(!_vm.laillistamisTiedotForm.laillistamistiedotAdded || _vm.isEnsimmainenTyoskentelyjakso)?_c('hr'):_vm._e(),_c('elsa-form-group',{attrs:{"label":_vm.$t('tyyppi'),"required":!_vm.value.tapahtumia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var uid = ref.uid;
return [_c('div',[_vm._l((_vm.tyypit),function(tyyppi,index){return _c('b-form-radio',{key:index,attrs:{"state":_vm.validateState('tyoskentelypaikka.tyyppi'),"name":"tyoskentelyjakso-tyyppi","value":tyyppi.value},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.tyoskentelypaikka.tyyppi),callback:function ($$v) {_vm.$set(_vm.form.tyoskentelypaikka, "tyyppi", $$v)},expression:"form.tyoskentelypaikka.tyyppi"}},[_c('span',[_vm._v(" "+_vm._s(tyyppi.text)+" ")])])}),_c('b-form-radio',{attrs:{"state":_vm.validateState('tyoskentelypaikka.tyyppi'),"name":"tyoskentelyjakso-tyyppi","value":"MUU"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.tyoskentelypaikka.tyyppi),callback:function ($$v) {_vm.$set(_vm.form.tyoskentelypaikka, "tyyppi", $$v)},expression:"form.tyoskentelypaikka.tyyppi"}},[(_vm.form.tyoskentelypaikka.tyyppi === 'MUU')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('muu'))+", "),_c('span',{staticClass:"text-lowercase"},[_vm._v(_vm._s(_vm.$t('kerro-mika')))]),_c('span',{staticClass:"text-primary"},[_vm._v("*")])]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('muu'))+" ")])]),(_vm.form.tyoskentelypaikka.tyyppi === 'MUU')?_c('div',{staticClass:"pl-4"},[_c('b-form-input',{attrs:{"state":_vm.validateState('tyoskentelypaikka.muuTyyppi')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.tyoskentelypaikka.muuTyyppi),callback:function ($$v) {_vm.$set(_vm.form.tyoskentelypaikka, "muuTyyppi", $$v)},expression:"form.tyoskentelypaikka.muuTyyppi"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t('pakollinen-tieto')))])],1):_vm._e(),(_vm.form.tyoskentelypaikka.tyyppi === 'MUU')?_c('span',{staticClass:"text-muted small"},[_vm._v(" "+_vm._s(_vm.$t('yek.työskentelyjakson-lisays-muu-ohje'))+" ")]):_vm._e(),_c('b-form-invalid-feedback',{style:({
            display: _vm.validateState('tyoskentelypaikka.tyyppi') === false ? 'block' : 'none'
          }),attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])],2)]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelypaikka'),"required":!_vm.value.tapahtumia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var uid = ref.uid;
return [_c('div',[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('tyoskentelypaikka.nimi')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.tyoskentelypaikka.nimi),callback:function ($$v) {_vm.$set(_vm.form.tyoskentelypaikka, "nimi", $$v)},expression:"form.tyoskentelypaikka.nimi"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])],1)]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('kunta'),"required":!_vm.value.tapahtumia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var uid = ref.uid;
return [_c('div',[_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.kunnatFormatted,"state":_vm.validateState('tyoskentelypaikka.kunta'),"label":"abbreviation","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.tyoskentelypaikka.kunta),callback:function ($$v) {_vm.$set(_vm.form.tyoskentelypaikka, "kunta", $$v)},expression:"form.tyoskentelypaikka.kunta"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])],1)]}}])}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pr-sm-3",attrs:{"label":_vm.$t('alkamispaiva'),"required":!_vm.value.tapahtumia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var uid = ref.uid;
return [_c('div',[(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"alkamispaiva",attrs:{"id":uid,"value":_vm.form.alkamispaiva,"max":_vm.maxAlkamispaiva,"max-error-text":_vm.value.tapahtumia
                ? _vm.$t('tyoskentelyjakso-datepicker-help')
                : _vm.$t('alkamispaiva-ei-voi-olla-paattymispaivan-jalkeen')},on:{"update:value":function($event){return _vm.$set(_vm.form, "alkamispaiva", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}}):_vm._e(),(_vm.value.tapahtumia)?_c('small',{staticClass:"form-text text-muted"},[_vm._v(" "+_vm._s(_vm.$t('tyoskentelyjakso-paattymispaiva-help'))+" ")]):_vm._e()],1)]}}])}),_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pl-sm-3",attrs:{"label":_vm.$t('paattymispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var uid = ref.uid;
return [(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"paattymispaiva",staticClass:"datepicker-range",attrs:{"id":uid,"value":_vm.form.paattymispaiva,"min":_vm.minPaattymispaiva,"min-error-text":_vm.value.tapahtumia
              ? _vm.$t('tyoskentelyjakso-datepicker-help')
              : _vm.$t('paattymispaiva-ei-voi-olla-ennen-alkamispaivaa'),"required":false,"aria-describedby":(uid + "-help")},on:{"update:value":function($event){return _vm.$set(_vm.form, "paattymispaiva", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}}):_vm._e(),(_vm.value.tapahtumia)?_c('small',{staticClass:"form-text text-muted"},[_vm._v(" "+_vm._s(_vm.$t('tyoskentelyjakso-paattymispaiva-help'))+" ")]):_c('small',{staticClass:"form-text text-muted"},[_vm._v(" "+_vm._s(_vm.$t('jata-tyhjaksi-jos-ei-tiedossa'))+" ")])]}}])})],1),_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoaika-taydesta-tyopaivasta') + ' (50–100 %)',"required":!_vm.value.tapahtumia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var uid = ref.uid;
return [_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"col-sm-3",attrs:{"id":uid,"value":_vm.form.osaaikaprosentti,"state":_vm.validateState('osaaikaprosentti'),"type":"number","step":"any"},on:{"input":_vm.onOsaaikaprosenttiInput}}),_c('span',{staticClass:"mx-3"},[_vm._v("%")])],1),_c('small',{staticClass:"d-flex form-text text-muted"},[_vm._v(" "+_vm._s(_vm.$t('alle-50-osaaikaisuus-ei-kerryta'))+" ")]),_c('b-form-invalid-feedback',{style:({
            display: _vm.validateState('osaaikaprosentti') === false ? 'block' : 'none'
          }),attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('osaaikaprosentti-validointivirhe'))+" 50–100 % ")])],1)]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('liitetiedostot')},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',[_vm._v(" "+_vm._s(_vm.$t('sallitut-tiedostoformaatit-default'))+" ")])]},proxy:true}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tyoskentelyjakson-liitetiedostot-kuvaus'))+" ")]),_c('asiakirjat-upload',{staticClass:"mt-3",attrs:{"is-primary-button":false,"button-text":_vm.$t('lisaa-liitetiedosto'),"existing-file-names-in-current-view":_vm.existingFileNamesInCurrentView,"existing-file-names-in-other-views":_vm.existingFileNamesInOtherViews,"disabled":_vm.reservedAsiakirjaNimetMutable === undefined},on:{"selectedFiles":_vm.onFilesAdded}}),_c('asiakirjat-content',{attrs:{"asiakirjat":_vm.asiakirjatTableItems,"sorting-enabled":false,"pagination-enabled":false,"enable-search":false,"show-info-if-empty":false},on:{"deleteAsiakirja":_vm.onDeleteLiitetiedosto}})],1),(_vm.asiakirjatTableItems.length === 0)?_c('hr'):_vm._e(),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap",class:{ 'mt-4': _vm.asiakirjatTableItems.length > 0 }},[_c('elsa-button',{staticClass:"ml-2 mb-2",attrs:{"loading":_vm.params.saving,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.editing ? _vm.$t('tallenna') : _vm.$t('lisaa'))+" ")]),_c('elsa-button',{staticClass:"mb-2",attrs:{"variant":"back"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCancel($event)}}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }