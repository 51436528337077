


















































































































































































import Avatar from 'vue-avatar'
import { Component, Mixins } from 'vue-property-decorator'

import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import NavbarMixin from '@/mixins/navbar'

@Component({
  components: {
    Avatar,
    UserAvatar
  }
})
export default class Navbar extends Mixins(NavbarMixin) {}
