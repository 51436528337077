


























































































































































































































































import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'

import { getKoulutussopimus as getKoulutussopimusKouluttaja } from '@/api/kouluttaja'
import { getKoulutussopimus as getKoulutussopimusVastuuhenkilo } from '@/api/vastuuhenkilo'
import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaReturnToSenderModal from '@/components/modal/return-to-sender-modal.vue'
import store from '@/store'
import {
  KoejaksonVaiheAllekirjoitus,
  KoulutussopimusLomake,
  Kouluttaja,
  KoejaksonVaiheButtonStates,
  Vastuuhenkilo,
  KoejaksonVaihe
} from '@/types'
import { resolveRolePath } from '@/utils/apiRolePathResolver'
import { defaultKoulutuspaikka, LomakeTilat, LomakeTyypit } from '@/utils/constants'
import { checkCurrentRouteAndRedirect } from '@/utils/functions'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'
import { toastFail, toastSuccess } from '@/utils/toast'
import KoulutussopimusReadonly from '@/views/koejakso/kouluttaja-vastuuhenkilo/koulutussopimus/koulutussopimus-readonly.vue'
import KouluttajaKoulutussopimusForm from '@/views/koejakso/kouluttaja/kouluttaja-koulutussopimus-form.vue'
import VastuuhenkiloKoulutussopimusForm from '@/views/koejakso/vastuuhenkilo/vastuuhenkilo-koulutussopimus-form.vue'

@Component({
  components: {
    KouluttajaKoulutussopimusForm,
    VastuuhenkiloKoulutussopimusForm,
    ElsaFormGroup,
    ElsaFormError,
    ElsaButton,
    KoulutussopimusReadonly,
    ErikoistuvaDetails,
    KoejaksonVaiheAllekirjoitukset,
    ElsaConfirmationModal,
    ElsaReturnToSenderModal
  }
})
export default class Koulutussopimus extends Mixins(validationMixin) {
  skipRouteExitConfirm!: boolean
  $refs!: {
    kouluttajaKoulutussopimusForm: Array<KouluttajaKoulutussopimusForm>
    vastuuhenkiloKoulutussopimusForm: VastuuhenkiloKoulutussopimusForm
  }
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('koulutussopimus-kouluttaja'),
      active: true
    }
  ]

  form: KoulutussopimusLomake = {
    id: null,
    erikoistuvanNimi: '',
    erikoistuvanErikoisala: '',
    erikoistuvanOpiskelijatunnus: '',
    erikoistuvanPuhelinnumero: '',
    erikoistuvanSahkoposti: '',
    erikoistuvanSyntymaaika: '',
    erikoistuvanYliopisto: '',
    koejaksonAlkamispaiva: '',
    korjausehdotus: '',
    vastuuhenkilonKorjausehdotus: '',
    kouluttajat: [],
    koulutuspaikat: [defaultKoulutuspaikka],
    lahetetty: false,
    muokkauspaiva: '',
    opintooikeudenMyontamispaiva: '',
    opintooikeudenPaattymispaiva: '',
    vastuuhenkilo: null,
    yliopistot: []
  }

  loading = true
  childKouluttajaFormValid = false
  childVastuuhenkiloFormValid = false
  showVastuuhenkiloSubmitButton = false
  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }

  onChildKouluttajaFormValid(index: number, form: Kouluttaja) {
    this.form.kouluttajat[index] = form
    this.childKouluttajaFormValid = true
  }

  onChildVastuuhenkiloFormValid(form: Vastuuhenkilo) {
    this.form.vastuuhenkilo = form
    this.childVastuuhenkiloFormValid = true
  }

  get koulutussopimusData() {
    return store.getters[`${resolveRolePath()}/koejaksot`].find(
      (k: KoejaksonVaihe) =>
        k.id === this.koulutussopimusId && k.tyyppi === LomakeTyypit.KOULUTUSSOPIMUS
    )
  }

  get koulutussopimusId() {
    return Number(this.$route.params.id)
  }

  get account() {
    return store.getters['auth/account']
  }

  get editable() {
    return !this.loading && this.koulutussopimusData.tila === LomakeTilat.ODOTTAA_HYVAKSYNTAA
  }

  get signed() {
    let signed = false
    this.form.kouluttajat.forEach((k: Kouluttaja) => {
      if (this.isCurrentKouluttaja(k)) {
        signed = k.sopimusHyvaksytty
      }
    })
    return signed
  }

  get allKouluttajatSigned() {
    return this.form.kouluttajat.every((k: Kouluttaja) => k.sopimusHyvaksytty)
  }

  get returned() {
    return !this.loading && this.koulutussopimusData.tila === LomakeTilat.PALAUTETTU_KORJATTAVAKSI
  }

  get showWaitingForVastuuhenkilo() {
    return this.koulutussopimusData.tila === LomakeTilat.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
  }

  get showWaitingForAnotherKouluttaja() {
    return this.koulutussopimusData.tila === LomakeTilat.ODOTTAA_TOISEN_KOULUTTAJAN_HYVAKSYNTAA
  }

  get showSigned() {
    return this.koulutussopimusData.tila === LomakeTilat.HYVAKSYTTY
  }

  get erikoistuvanAvatar() {
    return this.form.erikoistuvanAvatar
  }

  get erikoistuvanNimi() {
    return this.form.erikoistuvanNimi
  }

  get erikoistuvanErikoisala() {
    return this.form.erikoistuvanErikoisala ?? ''
  }

  get sendToVastuuhenkilo() {
    return (
      this.form.kouluttajat.length === 1 ||
      this.form.kouluttajat.filter((k) => k.sopimusHyvaksytty).length === 1
    )
  }

  isCurrentKouluttaja(kouluttaja: any) {
    return this.account.id === kouluttaja.kayttajaUserId
  }

  async returnToSender(korjausehdotus: string) {
    const form = {
      ...this.form,
      korjausehdotus: korjausehdotus,
      lahetetty: false
    }
    try {
      this.buttonStates.secondaryButtonLoading = true
      await store.dispatch(`${resolveRolePath()}/putKoulutussopimus`, form)
      this.buttonStates.secondaryButtonLoading = false
      this.$emit('skipRouteExitConfirm', true)
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('koulutussopimus-palautettu-onnistuneesti'))
    } catch {
      toastFail(this, this.$t('koulutussopimus-palautus-epaonnistui'))
    }
  }

  async updateSentForm() {
    this.$emit('skipRouteExitConfirm', true)
    try {
      this.buttonStates.primaryButtonLoading = true
      await store.dispatch(`${resolveRolePath()}/putKoulutussopimus`, this.form)
      this.buttonStates.primaryButtonLoading = false
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('koulutussopimus-lisatty-onnistuneesti'))
    } catch {
      toastFail(this, this.$t('koulutussopimuksen-lisaaminen-epaonnistui'))
    }
  }

  onSubmit() {
    if (this.$isVastuuhenkilo()) {
      this.handleSubmitVastuuhenkilo()
    } else {
      this.handleSubmitKouluttaja()
    }
  }

  private handleSubmitKouluttaja() {
    if (this.$refs.kouluttajaKoulutussopimusForm.length === 2) {
      this.$refs.kouluttajaKoulutussopimusForm[0].validateForm()
      this.$refs.kouluttajaKoulutussopimusForm[1].validateForm()
    } else {
      this.$refs.kouluttajaKoulutussopimusForm[0].validateForm()
    }

    if (this.childKouluttajaFormValid) {
      this.$bvModal.show('confirm-send-kouluttaja')
    }
  }

  private handleSubmitVastuuhenkilo() {
    this.$refs.vastuuhenkiloKoulutussopimusForm.validateForm()

    if (this.childVastuuhenkiloFormValid) {
      this.$bvModal.show('confirm-send-vastuuhenkilo')
    }
  }

  async mounted() {
    this.loading = true
    await store.dispatch(`${resolveRolePath()}/getKoejaksot`)

    try {
      const { data } = await (this.$isVastuuhenkilo()
        ? getKoulutussopimusVastuuhenkilo(this.koulutussopimusId)
        : getKoulutussopimusKouluttaja(this.koulutussopimusId))
      this.form = data
      if (this.form.vastuuhenkilo) {
        this.form.vastuuhenkilo.puhelin = this.account.phoneNumber
        this.form.vastuuhenkilo.sahkoposti = this.account.email
      }
      this.loading = false

      if (!this.editable || this.returned) {
        this.$emit('skipRouteExitConfirm', true)
      }
    } catch {
      toastFail(this, this.$t('koulutussopimuksen-hakeminen-epaonnistui'))
      this.$emit('skipRouteExitConfirm', true)
      this.$router.replace({ name: 'koejakso' })
    }
  }

  get allekirjoitukset(): KoejaksonVaiheAllekirjoitus[] {
    const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
      this,
      this.form.erikoistuvanNimi,
      this.form.erikoistuvanAllekirjoitusaika
    ) as KoejaksonVaiheAllekirjoitus
    const allekirjoituksetKouluttajat =
      allekirjoituksetHelper.mapAllekirjoituksetSopimuksenKouluttajat(
        this.form.kouluttajat
      ) as KoejaksonVaiheAllekirjoitus[]
    const allekirjoitusVastuuhenkilo = allekirjoituksetHelper.mapAllekirjoitusVastuuhenkilo(
      this.form.vastuuhenkilo ?? null
    ) as KoejaksonVaiheAllekirjoitus

    return [
      allekirjoitusErikoistuva,
      ...allekirjoituksetKouluttajat,
      allekirjoitusVastuuhenkilo
    ].filter((a): a is KoejaksonVaiheAllekirjoitus => a !== null)
  }

  onSkipRouteExitConfirm(value: boolean) {
    this.$emit('skipRouteExitConfirm', value)
  }
}
