var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"px-0 py-lg-0",attrs:{"id":"navbar-top","toggleable":"lg","type":"dark","variant":_vm.$isYekKoulutettava() ? 'yek' : 'primary',"sticky":""}},[_c('router-link',{attrs:{"to":"/etusivu"}},[_c('b-navbar-brand',{staticClass:"col mr-0 text-nowrap user-select-none"},[_c('span',{staticClass:"brand-logo d-inline-block font-weight-bold text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('elsa'))+" ")]),_c('span',{staticClass:"brand-text d-inline-block align-text-top"},[_vm._v(" - "),_c('span',{staticClass:"text-lowercase"},[_vm._v(_vm._s(_vm.$t('palvelu')))])])])],1),(!_vm.$screen.lg)?_c('b-navbar-toggle',{staticClass:"border-0",attrs:{"target":"sidebar-right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'times'],"size":"lg"}}):_c('font-awesome-icon',{attrs:{"icon":['fas', 'bars'],"size":"lg"}})]}}],null,false,900627316)}):_vm._e(),_c('b-navbar-nav',{staticClass:"ml-auto pr-3 font-weight-500 d-none d-lg-flex"},[_c('b-nav-item-dropdown',{staticClass:"user-dropdown align-self-center px-3",attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('user-avatar',{attrs:{"src-base64":_vm.avatar,"src-content-type":"image/jpeg","title":_vm.title,"display-name":_vm.displayName}})]},proxy:true}])},[_c('div',{staticClass:"user-dropdown-content"},[(!_vm.account.impersonated)?_c('b-dropdown-item',{attrs:{"to":{ name: 'profiili' }}},[_vm._v(" "+_vm._s(_vm.$t('oma-profiilini'))+" ")]):_vm._e(),_c('hr',{staticClass:"p-0 m-0"}),_c('b-dropdown-item',{on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t('kirjaudu-ulos'))+" "),_c('b-form',{ref:"logoutForm",attrs:{"action":_vm.logoutUrl,"method":"POST"}})],1),(!_vm.account.impersonated && (_vm.$isErikoistuva() || _vm.$isYekKoulutettava()))?_c('div',[_c('hr',{staticClass:"p-0 m-0"}),_c('div',{staticClass:"dropdown-item dropdown-item__header mt-1 pb-1"},[_c('span',{staticClass:"font-weight-500"},[_vm._v(_vm._s(_vm.$t('valitse-opinto-oikeus')))])]),_vm._l((_vm.opintooikeudet),function(opintooikeus){return _c('b-dropdown-item',{key:opintooikeus.id,on:{"click":function($event){return _vm.changeOpintooikeus(opintooikeus)}}},[_c('div',{staticClass:"d-flex",class:{
                'dropdown-item__disabled': _vm.opintooikeusKaytossa
                  ? opintooikeus.id === _vm.opintooikeusKaytossa.id
                  : false
              }},[_c('div',{staticClass:"flex-column icon-col-min-width"},[(_vm.opintooikeusKaytossa && opintooikeus.id === _vm.opintooikeusKaytossa.id)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['far', 'check-circle'],"fixed-width":"","size":"lg"}}):_vm._e()],1),_c('div',{staticClass:"flex-column"},[_vm._v(" "+_vm._s(opintooikeus.erikoisalaNimi)+" "),_c('div',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t(("yliopisto-nimi." + (opintooikeus.yliopistoNimi))))+" ")])])])])})],2):_vm._e(),(!_vm.account.impersonated && _vm.authorities && _vm.authorities.length > 1)?_c('div',[_c('hr',{staticClass:"p-0 m-0"}),_c('div',{staticClass:"dropdown-item dropdown-item__header mt-1 pb-1"},[_c('span',{staticClass:"font-weight-500"},[_vm._v(_vm._s(_vm.$t('valitse-rooli')))])]),(_vm.$hasErikoistujaRole())?_c('b-dropdown-item',{on:{"click":_vm.changeToErikoistuja}},[_c('div',{staticClass:"d-flex",class:{
                'dropdown-item__disabled': _vm.$isErikoistuva()
              }},[_c('div',{staticClass:"flex-column icon-col-min-width"},[(_vm.$isErikoistuva())?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['far', 'check-circle'],"fixed-width":"","size":"lg"}}):_vm._e()],1),_c('div',{staticClass:"flex-column"},[_vm._v(" "+_vm._s(_vm.$t('erikoistuva-laakari'))+" ")])])]):_vm._e(),(_vm.$hasYekRole())?_c('b-dropdown-item',{on:{"click":_vm.changeToYekKoulutettava}},[_c('div',{staticClass:"d-flex",class:{
                'dropdown-item__disabled': _vm.$isYekKoulutettava()
              }},[_c('div',{staticClass:"flex-column icon-col-min-width"},[(_vm.$isYekKoulutettava())?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['far', 'check-circle'],"fixed-width":"","size":"lg"}}):_vm._e()],1),_c('div',{staticClass:"flex-column"},[_vm._v(" "+_vm._s(_vm.$t('yek.yek-koulutettava'))+" ")])])]):_vm._e(),(_vm.$hasKouluttajaRole())?_c('b-dropdown-item',{on:{"click":_vm.changeToKouluttaja}},[_c('div',{staticClass:"d-flex",class:{
                'dropdown-item__disabled': _vm.$isKouluttaja()
              }},[_c('div',{staticClass:"flex-column icon-col-min-width"},[(_vm.$isKouluttaja())?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['far', 'check-circle'],"fixed-width":"","size":"lg"}}):_vm._e()],1),_c('div',{staticClass:"flex-column"},[_vm._v(" "+_vm._s(_vm.$t('kouluttaja'))+" ")])])]):_vm._e()],1):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }