























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import KoulutuspaikanArvioijat from '@/components/koejakson-vaiheet/koulutuspaikan-arvioijat.vue'
import { AloituskeskusteluLomake, KoejaksonVaiheAllekirjoitus } from '@/types'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'

@Component({
  components: {
    KoulutuspaikanArvioijat,
    KoejaksonVaiheAllekirjoitukset
  }
})
export default class ArviointilomakeAloituskeskusteluReadonly extends Vue {
  @Prop({ required: true, default: {} })
  data!: AloituskeskusteluLomake

  get tyotunnitViikossa() {
    return this.data?.tyotunnitViikossa?.toString().replace('.', ',')
  }

  get allekirjoitukset(): KoejaksonVaiheAllekirjoitus[] {
    const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
      this,
      this.data.erikoistuvanNimi,
      this.data.erikoistuvanKuittausaika
    ) as KoejaksonVaiheAllekirjoitus
    const allekirjoitusLahikouluttaja = allekirjoituksetHelper.mapAllekirjoitusLahikouluttaja(
      this,
      this.data.lahikouluttaja
    )
    const allekirjoitusLahiesimies = allekirjoituksetHelper.mapAllekirjoitusLahiesimies(
      this,
      this.data.lahiesimies
    )

    return [
      allekirjoitusErikoistuva,
      allekirjoitusLahikouluttaja,
      allekirjoitusLahiesimies
    ].filter((a): a is KoejaksonVaiheAllekirjoitus => a !== null)
  }
}
