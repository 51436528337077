










































































































import { BModal } from 'bootstrap-vue'
import Component from 'vue-class-component'
import { Mixins, Prop, Vue } from 'vue-property-decorator'

import { postKoulutusjakso } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import KoulutusjaksoForm from '@/forms/koulutusjakso-form.vue'
import TyoskentelyjaksoMixin from '@/mixins/tyoskentelyjakso'
import {
  ArvioitavanKokonaisuudenKategoria,
  Erikoisala,
  Koulutusjakso,
  Kunta,
  Seurantajakso,
  SeurantajaksoHakuForm
} from '@/types'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormDatepicker,
    ElsaFormGroup,
    ElsaFormMultiselect,
    KoulutusjaksoForm
  }
})
export default class SeurantajaksoHakuFormClass extends Mixins(TyoskentelyjaksoMixin) {
  $refs!: {
    alkamispaiva: ElsaFormDatepicker
    paattymispaiva: ElsaFormDatepicker
  }

  @Prop({ required: false, default: () => [] })
  arvioitavanKokonaisuudenKategoriat!: ArvioitavanKokonaisuudenKategoria[]

  @Prop({ required: false, default: () => [] })
  kunnat!: Kunta[]

  @Prop({ required: false, default: () => [] })
  erikoisalat!: Erikoisala[]

  @Prop({ required: false, default: () => [] })
  koulutusjaksot!: Koulutusjakso[]

  @Prop({
    required: false,
    default: () => null
  })
  seurantajakso!: Seurantajakso | null

  form: SeurantajaksoHakuForm = {
    alkamispaiva: null,
    paattymispaiva: null,
    koulutusjaksot: [{}]
  }

  params = {
    saving: false
  }
  childDataReceived = false

  mounted() {
    if (this.seurantajakso) {
      this.form = {
        ...this.seurantajakso,
        koulutusjaksot:
          this.seurantajakso.koulutusjaksot.length > 0 ? this.seurantajakso.koulutusjaksot : [{}]
      }
    }
    this.childDataReceived = true
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  get maxAlkamispaiva() {
    return this.form.paattymispaiva
  }

  get minPaattymispaiva() {
    return this.form.alkamispaiva
  }

  get koulutusjaksotFiltered() {
    return this.koulutusjaksot?.filter(
      (koulutusjakso) => !this.form.koulutusjaksot?.find((t) => t?.id === koulutusjakso.id)
    )
  }

  koulutusjaksoLabel(koulutusjakso: Koulutusjakso): string {
    return koulutusjakso.nimi || ''
  }

  addKoulutusjakso() {
    this.form.koulutusjaksot?.push({})
  }

  deleteKoulutusjakso(index: number) {
    if (this.form.koulutusjaksot) {
      Vue.delete(this.form.koulutusjaksot, index)
    }
  }

  async onKoulutusjaksoSubmit(data: Koulutusjakso, params: { saving: boolean }, modal: BModal) {
    params.saving = true
    try {
      const koulutusjakso = (await postKoulutusjakso(data)).data
      this.koulutusjaksot.push(koulutusjakso)
      this.form.koulutusjaksot[this.form.koulutusjaksot.length - 1] = koulutusjakso
      modal.hide('confirm')
      toastSuccess(this, this.$t('koulutusjakso-lisatty'))
    } catch (err) {
      toastFail(this, this.$t('uuden-koulutusjakson-lisaaminen-epaonnistui'))
    }
    params.saving = false
  }

  onSubmit() {
    const validations = [
      this.$refs.alkamispaiva.validateForm(),
      this.$refs.paattymispaiva.validateForm()
    ]

    if (validations.includes(false)) {
      return
    }

    this.$emit(
      'submit',
      {
        ...this.form,
        koulutusjaksot: this.form.koulutusjaksot?.filter((koulutusjakso) => koulutusjakso?.id)
      },
      this.params
    )
  }

  onCancel() {
    this.$emit('cancel')
  }
}
