

































































































































import _get from 'lodash/get'
import Component from 'vue-class-component'
import { Prop, Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

import ElsaFormGroup from '@/components/form-group/form-group.vue'
import store from '@/store'
import { Kouluttaja } from '@/types'
import { defaultKouluttaja, phoneNumber } from '@/utils/constants'

@Component({
  components: {
    ElsaFormGroup
  },
  validations: {
    form: {
      nimike: {
        required
      },
      toimipaikka: {
        required
      },
      lahiosoite: {
        required
      },
      postitoimipaikka: {
        required
      },
      sahkoposti: {
        required,
        email
      },
      puhelin: {
        required,
        phoneNumber
      }
    }
  }
})
export default class KouluttajaKoulutussopimusForm extends Mixins(validationMixin) {
  @Prop({ required: true, default: {} })
  kouluttaja!: Kouluttaja

  @Prop({ required: true })
  index!: number

  form: Kouluttaja = defaultKouluttaja

  get kouluttajaIndex() {
    return this.index
  }

  get account() {
    return store.getters['auth/account']
  }

  validateState(value: string) {
    const form = this.$v.form
    const { $dirty, $error } = _get(form, value) as any
    return $dirty ? ($error ? false : null) : null
  }

  validateForm() {
    this.$v.$touch()
    if (this.$v.$anyError) {
      return
    }

    this.$emit('ready', this.kouluttajaIndex, this.form)
  }

  mounted() {
    this.form = this.kouluttaja
  }
}
