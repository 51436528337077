import { render, staticRenderFns } from "./opintosuoritus-tab.vue?vue&type=template&id=1ed24845&scoped=true&"
import script from "./opintosuoritus-tab.vue?vue&type=script&lang=ts&"
export * from "./opintosuoritus-tab.vue?vue&type=script&lang=ts&"
import style0 from "./opintosuoritus-tab.vue?vue&type=style&index=0&id=1ed24845&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed24845",
  null
  
)

export default component.exports