














import { Component, Vue } from 'vue-property-decorator'

import { ELSA_API_LOCATION } from '@/api'
import HakaYliopistoForm from '@/forms/haka-yliopisto-form.vue'

@Component({
  components: {
    HakaYliopistoForm
  }
})
export default class HakaYliopisto extends Vue {
  loading = false

  async onSubmit(value: string) {
    return (window.location.href = `${ELSA_API_LOCATION}/saml2/authenticate/${value}`)
  }
}
