



























import Vue from 'vue'
import Avatar from 'vue-avatar'
import Component from 'vue-class-component'

import { ELSA_API_LOCATION } from '@/api'
import ElsaButton from '@/components/button/button.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import store from '@/store'

@Component({
  components: {
    Avatar,
    ElsaButton,
    UserAvatar
  }
})
export default class NavbarImpersonated extends Vue {
  get account() {
    return store.getters['auth/account']
  }

  vaihdaRooli() {
    window.location.href = `${ELSA_API_LOCATION}/api/logout/impersonate`
  }
}
