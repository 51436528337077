


















































































































































































































































































import { AxiosError } from 'axios'
import { Component, Mixins } from 'vue-property-decorator'
import { Validation } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'

import {
  deleteKayttaja,
  getKayttaja,
  getKorvaavatKouluttajat,
  passivateKayttaja,
  patchKouluttaja,
  putKouluttajaInvitation
} from '@/api/kayttajahallinta'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import KayttajahallintaKayttajaMixin from '@/mixins/kayttajahallinta-kayttaja'
import { ElsaError, Kayttaja, KayttajahallintaUpdateKayttaja } from '@/types'
import { confirmExit } from '@/utils/confirm'
import { KayttajatiliTila } from '@/utils/constants'
import { formatList } from '@/utils/kouluttajaAndVastuuhenkiloListFormatter'
import { sortByAsc } from '@/utils/sort'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaConfirmationModal,
    ElsaFormGroup,
    ElsaFormMultiselect
  },
  validations: {
    form: {
      sahkoposti: {
        required,
        email
      },
      sahkopostiUudelleen: {
        required,
        email,
        sameAsSahkoposti: sameAs('sahkoposti')
      },
      etunimi: {
        required
      },
      sukunimi: {
        required
      }
    },
    reassignedKouluttaja: {
      required
    }
  }
})
export default class KouluttajaView extends Mixins(KayttajahallintaKayttajaMixin) {
  items = [
    {
      text: this.$t('kayttajahallinta'),
      to: { name: 'kayttajahallinta' }
    },
    {
      text: this.$t('kayttaja'),
      active: true
    }
  ]

  form: KayttajahallintaUpdateKayttaja = {
    etunimi: null,
    sukunimi: null,
    sahkoposti: null,
    sahkopostiUudelleen: null
  }

  deleting = false
  reassignedKouluttaja: Kayttaja | null = null
  kouluttajat: Kayttaja[] = []

  async mounted() {
    await this.fetchKayttaja()
    await this.fetchKouluttajat()
  }

  async fetchKayttaja() {
    try {
      this.kayttajaWrapper = (await getKayttaja(this.$route?.params?.kayttajaId)).data
      this.initForm()
    } catch (err) {
      toastFail(this, this.$t('kayttajan-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'kayttajahallinta' })

      this.loading = false
    }
  }

  async fetchKouluttajat() {
    try {
      this.kouluttajat = (await getKorvaavatKouluttajat(this.$route?.params?.kayttajaId)).data
        .filter((k) => k.id !== this.kayttajaWrapper?.kayttaja?.id)
        .sort((a, b) => sortByAsc(a.sukunimi, b.sukunimi))
    } catch (err) {
      toastFail(this, this.$t('kayttajan-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'kayttajahallinta' })

      this.loading = false
    }
  }

  initForm() {
    const sahkoposti = this.sahkoposti
    this.form.sahkoposti = sahkoposti
    this.form.sahkopostiUudelleen = sahkoposti
    this.form.etunimi = this.etunimi
    this.form.sukunimi = this.sukunimi
  }

  async onSave() {
    if (!this.validateForm() || !this.kayttajaWrapper?.kayttaja?.id) {
      return
    }
    this.updatingKayttaja = true

    try {
      await patchKouluttaja(this.kayttajaWrapper.kayttaja.id, {
        etunimi: this.form.etunimi,
        sukunimi: this.form.sukunimi,
        sahkoposti: this.form.sahkoposti
      })
      toastSuccess(this, this.$t('kayttajan-tiedot-paivitetty'))
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('tietojen-tallennus-epaonnistui')}: ${this.$t(message)}`
          : this.$t('tietojen-tallennus-epaonnistui')
      )
    }

    await this.fetchKayttaja()
    this.editing = false
    this.updatingKayttaja = false
    this.skipRouteExitConfirm = true
    this.$emit('skipRouteExitConfirm', true)
  }

  async onCancel() {
    if (this.skipRouteExitConfirm || (await confirmExit(this))) {
      this.initForm()
      this.$v.form.$reset()
      this.skipRouteExitConfirm = true
      this.editing = false
      this.$emit('skipRouteExitConfirm', true)
    }
  }

  async onInvitationResend() {
    if (
      this.kayttajaWrapper?.kayttaja?.id &&
      (await this.$bvModal.msgBoxConfirm(this.$t('laheta-kutsu-viesti-kouluttaja') as string, {
        title: this.$t('laheta-kutsu-uudelleen') as string,
        okVariant: 'primary',
        okTitle: this.$t('laheta-kutsu') as string,
        cancelTitle: this.$t('peruuta') as string,
        cancelVariant: 'back',
        hideHeaderClose: false,
        centered: true
      }))
    ) {
      try {
        await putKouluttajaInvitation(this.kayttajaWrapper?.kayttaja?.id)
        toastSuccess(this, this.$t('kutsulinkki-lahetetty-uudestaan'))
      } catch (err) {
        toastFail(this, this.$t('kutsulinkin-lahettaminen-epaonnistui'))
      }
    }
  }

  showDeleteConfirm() {
    this.$bvModal.show('confirm-dialog')
  }

  async onPassivateDeleteKayttaja() {
    this.$v.reassignedKouluttaja.$touch()
    if (
      (this.kayttajaWrapper?.avoimiaTehtavia && this.reassignedKouluttaja == null) ||
      !this.kayttajaWrapper?.kayttaja?.id
    ) {
      return
    }
    this.$bvModal.hide('confirm-dialog')
    this.updatingTila = true
    if (this.isAktiivinen) {
      try {
        await passivateKayttaja(this.kayttajaWrapper.kayttaja.id, this.reassignedKouluttaja?.id)
        this.kayttajaWrapper.kayttaja.tila = KayttajatiliTila.PASSIIVINEN
        this.kayttajaWrapper.avoimiaTehtavia = false
        toastSuccess(this, this.$t('kayttajan-passivointi-onnistui'))
      } catch (err) {
        toastFail(this, this.$t('kayttajan-passivointi-epaonnistui'))
      }
    } else {
      try {
        await deleteKayttaja(this.kayttajaWrapper.kayttaja.id, this.reassignedKouluttaja?.id)
        toastSuccess(this, this.$t('kayttajan-poisto-onnistui'))
        this.$emit('skipRouteExitConfirm', true)
        this.$router.replace({ name: 'kayttajahallinta' })
      } catch (err) {
        toastFail(this, this.$t('kayttajan-poisto-epaonnistui'))
      }
    }
    this.updatingTila = false
    this.$emit('skipRouteExitConfirm', true)
  }

  onCancelConfirm() {
    this.$emit('skipRouteExitConfirm', true)
  }

  get formattedKouluttajat() {
    return formatList(this, this.kouluttajat)
  }

  validateConfirm() {
    const { $dirty, $error } = this.$v.reassignedKouluttaja as Validation
    return $dirty ? ($error ? false : null) : null
  }
}
