
























































































































































































































import { format } from 'date-fns'
import _get from 'lodash/get'
import Component from 'vue-class-component'
import { Vue, Prop, Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import {
  KoejaksonVaiheButtonStates,
  Kouluttaja,
  KoulutussopimusLomake,
  UserAccount,
  Opintooikeus,
  Vastuuhenkilo
} from '@/types'
import { defaultKouluttaja, defaultKoulutuspaikka, phoneNumber } from '@/utils/constants'
import { formatList } from '@/utils/kouluttajaAndVastuuhenkiloListFormatter'
import { resolveOpintooikeusKaytossa } from '@/utils/opintooikeus'
import KouluttajaDetails from '@/views/koejakso/erikoistuva/koulutussopimus/kouluttaja-details.vue'
import KoulutuspaikkaDetails from '@/views/koejakso/erikoistuva/koulutussopimus/koulutuspaikka-details.vue'

@Component({
  components: {
    KoulutuspaikkaDetails,
    KouluttajaDetails,
    ElsaFormGroup,
    ElsaFormDatepicker,
    ElsaButton,
    ElsaPopover,
    ElsaConfirmationModal
  },
  validations: {
    form: {
      erikoistuvanSahkoposti: {
        required,
        email
      },
      erikoistuvanPuhelinnumero: {
        required,
        phoneNumber
      }
    }
  }
})
export default class KoulutussopimusForm extends Mixins(validationMixin) {
  $refs!: {
    kouluttajaDetails: any
    koulutuspaikkaDetails: any
    koejaksonAlkamispaiva: ElsaFormDatepicker
  }

  @Prop({ required: true, default: {} })
  data!: KoulutussopimusLomake

  @Prop({ required: true, default: {} })
  account!: UserAccount

  @Prop({ required: true, default: () => [] })
  kouluttajat!: Kouluttaja[]

  @Prop({ required: true, default: () => [] })
  yliopistot!: []

  @Prop({ required: true, default: () => [] })
  vastuuhenkilo!: Vastuuhenkilo

  form: KoulutussopimusLomake = {
    id: null,
    erikoistuvanNimi: '',
    erikoistuvanErikoisala: '',
    erikoistuvanOpiskelijatunnus: '',
    erikoistuvanPuhelinnumero: '',
    erikoistuvanSahkoposti: '',
    erikoistuvanSyntymaaika: '',
    koejaksonAlkamispaiva: '',
    korjausehdotus: '',
    vastuuhenkilonKorjausehdotus: '',
    kouluttajat: [],
    koulutuspaikat: [],
    lahetetty: false,
    muokkauspaiva: '',
    opintooikeudenMyontamispaiva: '',
    opintooikeudenPaattymispaiva: '',
    vastuuhenkilo: null,
    yliopistot: []
  }
  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }
  childDataReceived = false

  validateState(value: string) {
    const form = this.$v.form
    const { $dirty, $error } = _get(form, value) as any
    return $dirty ? ($error ? false : null) : null
  }

  get koejaksonPaattymispaiva() {
    return ''
  }

  addKoulutuspaikka() {
    this.form.koulutuspaikat.push(Object.assign({}, defaultKoulutuspaikka))
    this.$emit('skipRouteExitConfirm', false)
  }

  deleteKoulutuspaikka() {
    this.form.koulutuspaikat.pop()
    this.$emit('skipRouteExitConfirm', false)
  }

  selectKouluttaja(kouluttaja: Kouluttaja, index: number) {
    Vue.set(this.form.kouluttajat, index, kouluttaja)
    this.$emit('skipRouteExitConfirm', false)
  }

  addKouluttaja() {
    this.form.kouluttajat.push(defaultKouluttaja)
    this.$emit('skipRouteExitConfirm', false)
  }

  deleteKouluttaja() {
    this.form.kouluttajat.pop()
    this.$emit('skipRouteExitConfirm', false)
  }

  clearKouluttaja(value: Kouluttaja) {
    const kouluttajat = this.form.kouluttajat.map((k: any) => {
      if (k.kayttajaId && k.kayttajaId !== value.id) {
        return {
          ...k,
          $isDisabled: false
        }
      }
    })
    this.form.kouluttajat = kouluttajat
  }

  get kouluttajatList() {
    const selectedKouluttajat =
      this.form.kouluttajat[0]?.kayttajaId || this.form.kouluttajat[1]?.kayttajaId
        ? this.form.kouluttajat.map((k) => {
            return k ? k.kayttajaId : null
          })
        : null

    const kouluttajat = this.kouluttajat.map((k: any) => {
      if (selectedKouluttajat?.includes(k.id)) {
        return {
          ...k,
          $isDisabled: true
        }
      } else {
        return {
          ...k,
          $isDisabled: false
        }
      }
    })
    return formatList(this, kouluttajat)
  }

  get opintooikeusKaytossa(): Opintooikeus | undefined {
    return resolveOpintooikeusKaytossa(this.account.erikoistuvaLaakari)
  }

  get maxKoejaksonAlkamispaiva() {
    const dateFormat = 'yyyy-MM-dd'
    if (!this.opintooikeusKaytossa?.opintooikeudenPaattymispaiva) {
      return null
    }

    const d = new Date(this.opintooikeusKaytossa.opintooikeudenPaattymispaiva)
    // Koejakson voi aloittaa viimeistään 6kk ennen määrä-aikaisen
    // opinto-oikeuden päättymispäivää, koska koejakson kesto on 6kk.
    d.setMonth(d.getMonth() - 6)
    return format(d, dateFormat)
  }

  nimikeLabel(nimike: string) {
    if (nimike) {
      return ', ' + nimike
    }
    return ''
  }

  saveAndExit() {
    this.$emit('saveAndExit', this.form, this.buttonStates)
  }

  resetValidationsAndConfirm() {
    this.$v.$reset()
    this.$refs.koejaksonAlkamispaiva.$v.$reset()
    this.$refs.kouluttajaDetails.forEach((k: any) => {
      k.$v.$reset()
    })
    this.$refs.koulutuspaikkaDetails.forEach((k: any) => {
      k.$v.$reset()
    })
    return this.$bvModal.show('confirm-save')
  }

  validateAndConfirmSend() {
    let childFormsValid = true
    this.$v.form.$touch()
    this.$refs.kouluttajaDetails.forEach((k: any) => {
      if (!k.validateForm()) {
        childFormsValid = false
      }
    })

    this.$refs.koulutuspaikkaDetails.forEach((k: any) => {
      if (!k.validateForm()) {
        childFormsValid = false
      }
    })
    childFormsValid = !this.$refs.koejaksonAlkamispaiva.validateForm() ? false : childFormsValid

    if (this.$v.form.$anyError || !childFormsValid) {
      return
    }

    return this.$bvModal.show('confirm-send')
  }

  onSubmit() {
    this.$emit('submit', this.form, this.buttonStates)
  }

  mounted(): void {
    if (this.data !== null) {
      this.form = this.data
    } else {
      this.form.kouluttajat.push(defaultKouluttaja)
      // Luo kopio defaultKoulutuspaikasta, koska muutoin mahdollinen toinen koulutuspaikka mäppäytyy samaan objektiin.
      this.form.koulutuspaikat.push(Object.assign({}, defaultKoulutuspaikka))
    }

    // Asetetaan ei-muokattavien kenttien arvot
    this.form.erikoistuvanNimi = `${this.account.firstName} ${this.account.lastName}`
    this.form.erikoistuvanOpiskelijatunnus = this.opintooikeusKaytossa?.opiskelijatunnus
    this.form.erikoistuvanErikoisala = this.opintooikeusKaytossa?.erikoisalaNimi
    this.form.erikoistuvanSyntymaaika = this.account.erikoistuvaLaakari.syntymaaika
    this.form.opintooikeudenMyontamispaiva =
      this.opintooikeusKaytossa?.opintooikeudenMyontamispaiva

    // Asetetaan arvot kentille, jotka saatavissa erikoistuvan lääkärin tiedoista, mutta jotka
    // käyttäjä on saattanut yliajaa lomakkeen välitallennuksen yhteydessä. Kuitenkaan opinto-oikeuden
    // alkamispäivää käyttäjä ei voi yliajaa, mikäli se on saatu opintotietojärjestelmästä.
    if (!this.form.erikoistuvanPuhelinnumero) {
      this.form.erikoistuvanPuhelinnumero =
        this.account.erikoistuvaLaakari.puhelinnumero ?? this.account.phoneNumber
    }
    if (!this.form.erikoistuvanSahkoposti) {
      this.form.erikoistuvanSahkoposti = this.account.email
    }
    this.form.vastuuhenkilo = this.vastuuhenkilo
    this.childDataReceived = true
  }
}
