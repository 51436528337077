






















































import { BvModalEvent } from 'bootstrap-vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { confirmExit } from '@/utils/confirm'

@Component({})
export default class ElsaFormGroup extends Vue {
  @Prop({ required: true, type: String })
  label!: string

  @Prop({ required: false, type: String })
  addNewLabel?: string

  @Prop({ required: false, default: false })
  addNewEnabled!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  required!: boolean

  @Prop({ required: false, type: String })
  help?: string

  skipConfirm = true

  async onShow() {
    this.skipConfirm = true
  }

  async onHide(event: BvModalEvent) {
    // Tarkista, onko poistuminen jo vahvistettu
    if (!this.skipConfirm) {
      event.preventDefault()
    } else {
      return
    }

    // Pyydä poistumisen vahvistus
    if (await confirmExit(this)) {
      this.skipConfirm = true
      this.$bvModal.hide(this.modalRef)
    }
  }

  // Välitä tapahtuma vanhemmalle
  onSubmit(value: any, params: any) {
    this.skipConfirm = true
    this.$emit('submit', value, params, this.$refs[this.modalRef])
  }

  async onSkipRouteExitConfirm(value: boolean) {
    this.skipConfirm = value
  }

  async onCancel() {
    if (this.skipConfirm) {
      this.$bvModal.hide(this.modalRef)
    } else {
      if (await confirmExit(this)) {
        this.skipConfirm = true
        this.$bvModal.hide(this.modalRef)
      }
    }
  }

  get addNewLabelText() {
    return this.addNewLabel ? this.addNewLabel : this.$t('lisaa-uusi')
  }

  get modalRef() {
    return `elsa-form-group-modal-${(this as any)._uid}`
  }

  get uid() {
    return `elsa-form-group-${(this as any)._uid}`
  }

  showModal() {
    this.$bvModal.show(this.modalRef)
  }
}
