


































































































import axios from 'axios'
import { Vue, Component } from 'vue-property-decorator'

import { getTeoriakoulutus } from '@/api/erikoistuva'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import store from '@/store'
import { Teoriakoulutus } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { ELSA_ROLE } from '@/utils/roles'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaFormGroup,
    ElsaButton,
    AsiakirjatContent
  }
})
export default class TeoriakoulutusView extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('teoriakoulutukset'),
      to: { name: 'teoriakoulutukset' }
    },
    {
      text: this.$t('teoriakoulutus'),
      active: true
    }
  ]
  teoriakoulutus: Teoriakoulutus | null = null
  loading = false
  deleting = false

  async mounted() {
    try {
      this.loading = true
      this.teoriakoulutus = (await getTeoriakoulutus(this.$route?.params?.teoriakoulutusId)).data
    } catch (err) {
      toastFail(this, this.$t('teoriakoulutuksen-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'teoriakoulutukset' })
    }
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  async onTeoriakoulutusDelete() {
    if (
      await confirmDelete(
        this,
        this.$t('poista-teoriakoulutus') as string,
        (this.$t('teoriakoulutuksen') as string).toLowerCase(),
        this.$t('poista-teoriakoulutus-liitetiedostot-huomio') as string
      )
    ) {
      this.deleting = true
      try {
        await axios.delete(`erikoistuva-laakari/teoriakoulutukset/${this.teoriakoulutus?.id}`)
        toastSuccess(this, this.$t('teoriakoulutus-poistettu'))
        this.$router.push({
          name: 'teoriakoulutukset'
        })
      } catch (err) {
        toastFail(this, this.$t('teoriakoulutuksen-poistaminen-epaonnistui'))
      }
      this.deleting = false
    }
  }

  get muokkausoikeudet() {
    if (!this.account.impersonated) {
      return true
    }

    if (
      this.account.originalUser.authorities.includes(ELSA_ROLE.OpintohallinnonVirkailija) &&
      this.account.erikoistuvaLaakari.muokkausoikeudetVirkailijoilla
    ) {
      return true
    }

    return false
  }
}
