
























































































































import { Component, Vue } from 'vue-property-decorator'

import { getKoulutusjaksot, getKoulutussuunnitelma } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import store from '@/store'
import { Koulutusjakso, Koulutussuunnitelma } from '@/types'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton
  }
})
export default class KoulutussuunnitelmaCard extends Vue {
  koulutusjaksot: Koulutusjakso[] = []
  koulutussuunnitelma: Koulutussuunnitelma | null = null
  naytaKuvaus = false
  loading = true

  async mounted() {
    this.loading = true
    try {
      this.koulutusjaksot = (await getKoulutusjaksot()).data
    } catch {
      toastFail(this, this.$t('koulutusjaksojen-hakeminen-epaonnistui'))
      this.koulutusjaksot = []
    }
    try {
      this.koulutussuunnitelma = (await getKoulutussuunnitelma()).data
    } catch {
      toastFail(this, this.$t('koulutussuunnitelman-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }
}
