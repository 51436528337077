































import _get from 'lodash/get'
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup
  },
  validations: {
    korjausehdotus: {
      required
    }
  }
})
export default class ElsaReturnToSenderModal extends Mixins(validationMixin) {
  @Prop({ required: true, type: String })
  id!: string

  @Prop({ required: true, type: String })
  title!: string

  korjausehdotus: string | null = null

  validateState(value: string) {
    const form = this.$v
    const { $dirty, $error } = _get(form, value) as any
    return $dirty ? ($error ? false : null) : null
  }

  hideModal() {
    return this.$bvModal.hide(this.id)
  }

  // Välitä tapahtuma vanhemmalle
  onSubmit() {
    this.$v.$touch()
    if (this.$v.$anyError) {
      return
    }
    this.hideModal()
    this.$emit('submit', this.korjausehdotus)
  }
}
