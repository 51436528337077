






































































































































































































































































































import _get from 'lodash/get'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'

import { getValiarviointi as getValiarviointiKouluttaja } from '@/api/kouluttaja'
import { getValiarviointi as getValiarviointiVastuuhenkilo } from '@/api/vastuuhenkilo'
import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import KoulutuspaikanArvioijat from '@/components/koejakson-vaiheet/koulutuspaikan-arvioijat.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaReturnToSenderModal from '@/components/modal/return-to-sender-modal.vue'
import store from '@/store'
import {
  ValiarviointiLomake,
  KoejaksonVaiheButtonStates,
  KoejaksonVaiheAllekirjoitus,
  KoejaksonVaihe
} from '@/types'
import { resolveRolePath } from '@/utils/apiRolePathResolver'
import { KehittamistoimenpideKategoria, LomakeTilat, LomakeTyypit } from '@/utils/constants'
import { checkCurrentRouteAndRedirect } from '@/utils/functions'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ErikoistuvaDetails,
    ElsaFormGroup,
    ElsaFormError,
    ElsaButton,
    ElsaConfirmationModal,
    ElsaReturnToSenderModal,
    KoulutuspaikanArvioijat,
    KoejaksonVaiheAllekirjoitukset
  },
  validations: {
    valiarviointi: {
      edistyminenTavoitteidenMukaista: {
        required
      },
      kehittamistoimenpideKategoriat: {
        required: requiredIf((value) => {
          return value.edistyminenTavoitteidenMukaista === false
        })
      },
      muuKategoria: {
        required: requiredIf((value) => {
          return value.kehittamistoimenpideKategoriat?.includes(KehittamistoimenpideKategoria.MUU)
        })
      }
    }
  }
})
export default class ArviointilomakeValiarviointi extends Mixins(validationMixin) {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('valiarviointi-kouluttaja'),
      active: true
    }
  ]

  edistyminenVaihtoehdot = [
    {
      text: this.$t('kylla'),
      value: true
    },
    {
      text: this.$t('ei-huolenaiheita-on'),
      value: false
    }
  ]

  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }

  loading = true

  kategoriaOrder = [
    KehittamistoimenpideKategoria.TYOSSASUORIUTUMINEN,
    KehittamistoimenpideKategoria.TYOKAYTTAYTYMINEN,
    KehittamistoimenpideKategoria.POTILASPALAUTE,
    KehittamistoimenpideKategoria.MUU
  ]

  valiarviointi: ValiarviointiLomake | null = null

  validateState(value: string) {
    const form = this.$v.valiarviointi
    const { $dirty, $error } = _get(form, value) as any
    return $dirty ? ($error ? false : null) : null
  }

  get valiarvioinninTila() {
    return store.getters[`${resolveRolePath()}/koejaksot`].find(
      (k: KoejaksonVaihe) =>
        k.id === this.valiarviointi?.id && k.tyyppi === LomakeTyypit.VALIARVIOINTI
    )?.tila
  }

  get valiarviointiId() {
    return Number(this.$route.params.id)
  }

  get editable() {
    if (
      this.valiarviointi?.lahiesimies.id == this.valiarviointi?.lahikouluttaja.id &&
      !this.valiarviointi?.lahiesimies.sopimusHyvaksytty &&
      !this.valiarviointi?.lahikouluttaja.sopimusHyvaksytty
    ) {
      return true
    }
    return (
      (this.$isKouluttaja() || this.$isVastuuhenkilo()) &&
      !this.isCurrentUserLahiesimies &&
      !this.valiarviointi?.lahikouluttaja.sopimusHyvaksytty
    )
  }

  get editableForEsimies() {
    return (
      this.isCurrentUserLahiesimies &&
      this.valiarviointi?.lahikouluttaja.sopimusHyvaksytty &&
      !this.valiarviointi?.lahiesimies.sopimusHyvaksytty
    )
  }

  get isCurrentUserLahiesimies() {
    const currentUser = store.getters['auth/account']
    return this.valiarviointi?.lahiesimies.kayttajaUserId === currentUser.id
  }

  get erikoistuvanAvatar() {
    return this.valiarviointi?.erikoistuvanAvatar
  }

  get erikoistuvanNimi() {
    return this.valiarviointi?.erikoistuvanNimi
  }

  get kehittamistoimenpideKategoriat() {
    return Object.keys(KehittamistoimenpideKategoria).map((k) => ({
      text: this.$t('kehittamistoimenpidekategoria-' + k),
      value: k
    }))
  }

  naytaKehittamistoimenpideKategoria(kategoria: string) {
    if (kategoria === KehittamistoimenpideKategoria.MUU) return this.valiarviointi?.muuKategoria
    return this.$t('kehittamistoimenpidekategoria-' + kategoria)
  }

  get muuValittu() {
    return this.valiarviointi?.kehittamistoimenpideKategoriat?.includes(
      KehittamistoimenpideKategoria.MUU
    )
  }

  get sortedKategoriat() {
    return this.valiarviointi?.kehittamistoimenpideKategoriat?.sort(
      (a, b) => this.kategoriaOrder.indexOf(a) - this.kategoriaOrder.indexOf(b)
    )
  }

  get showWaitingForLahiesimiesOrErikoistuva() {
    return (
      !this.isCurrentUserLahiesimies &&
      this.valiarviointi?.lahikouluttaja.sopimusHyvaksytty &&
      !this.valiarviointi?.lahiesimies.sopimusHyvaksytty
    )
  }

  get acceptedByEveryone() {
    return this.valiarviointi?.lahiesimies.sopimusHyvaksytty
  }

  get returned() {
    return this.valiarvioinninTila === LomakeTilat.PALAUTETTU_KORJATTAVAKSI
  }

  get allekirjoitukset(): KoejaksonVaiheAllekirjoitus[] {
    const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
      this,
      this.valiarviointi?.erikoistuvanNimi,
      this.valiarviointi?.erikoistuvanKuittausaika
    )
    const allekirjoitusLahikouluttaja = allekirjoituksetHelper.mapAllekirjoitusLahikouluttaja(
      this,
      this.valiarviointi?.lahikouluttaja
    )
    const allekirjoitusLahiesimies = allekirjoituksetHelper.mapAllekirjoitusLahiesimies(
      this,
      this.valiarviointi?.lahiesimies
    )

    return [
      allekirjoitusLahikouluttaja,
      allekirjoitusLahiesimies,
      allekirjoitusErikoistuva
    ].filter((a): a is KoejaksonVaiheAllekirjoitus => a !== null)
  }

  async onReturnToSender(korjausehdotus: string) {
    const form = {
      ...this.valiarviointi,
      korjausehdotus: korjausehdotus,
      lahetetty: false
    }

    try {
      this.buttonStates.secondaryButtonLoading = true
      await store.dispatch('kouluttaja/putValiarviointi', form)
      this.buttonStates.secondaryButtonLoading = false
      this.$emit('skipRouteExitConfirm', true)
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('valiarviointi-palautettu-muokattavaksi'))
    } catch {
      toastFail(this, this.$t('valiarviointi-palautus-epaonnistui'))
    }
  }

  async onSign() {
    if (!this.valiarviointi) return
    if (this.valiarviointi.edistyminenTavoitteidenMukaista === true) {
      this.valiarviointi.kehittamistoimenpideKategoriat = null
    }
    if (
      !this.valiarviointi.kehittamistoimenpideKategoriat?.includes(
        KehittamistoimenpideKategoria.MUU
      )
    ) {
      this.valiarviointi.muuKategoria = null
    }
    try {
      this.buttonStates.primaryButtonLoading = true
      await store.dispatch('kouluttaja/putValiarviointi', this.valiarviointi)
      this.buttonStates.primaryButtonLoading = false
      this.$emit('skipRouteExitConfirm', true)
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('valiarviointi-allekirjoitettu-ja-lahetetty-onnistuneesti'))
    } catch {
      toastFail(this, this.$t('valiarvioinnin-tallennus-epaonnistui'))
    }
  }

  onValidateAndConfirm() {
    if (!this.isCurrentUserLahiesimies) {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
    }
    return this.$bvModal.show('confirm-send')
  }

  async mounted() {
    this.loading = true
    await store.dispatch(`${resolveRolePath()}/getKoejaksot`)

    try {
      const { data } = await (this.$isVastuuhenkilo()
        ? getValiarviointiVastuuhenkilo(this.valiarviointiId)
        : getValiarviointiKouluttaja(this.valiarviointiId))
      this.valiarviointi = data
      this.loading = false
    } catch {
      toastFail(this, this.$t('valiarvioinnin-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'koejakso' })
    }
  }
}
