import { render, staticRenderFns } from "./yek-valmistumispyynnot-card.vue?vue&type=template&id=73846c0b&scoped=true&"
import script from "./yek-valmistumispyynnot-card.vue?vue&type=script&lang=ts&"
export * from "./yek-valmistumispyynnot-card.vue?vue&type=script&lang=ts&"
import style0 from "./yek-valmistumispyynnot-card.vue?vue&type=style&index=0&id=73846c0b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73846c0b",
  null
  
)

export default component.exports