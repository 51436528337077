






















































































































import axios from 'axios'
import { Vue, Component } from 'vue-property-decorator'

import { getKoulutusjakso } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import store from '@/store'
import { Koulutusjakso } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup
  }
})
export default class KoulutusjaksoView extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koulutussuunnitelma'),
      to: { name: 'koulutussuunnitelma' }
    },
    {
      text: this.$t('koulutusjakso'),
      active: true
    }
  ]
  koulutusjakso: Koulutusjakso | null = null
  deleting = false

  async mounted() {
    try {
      this.koulutusjakso = (await getKoulutusjakso(this.$route?.params?.koulutusjaksoId)).data
    } catch {
      toastFail(this, this.$t('koulutusjakson-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'koulutussuunnitelma' })
    }
  }

  get account() {
    return store.getters['auth/account']
  }

  async onKoulutusjaksoDelete() {
    if (
      await confirmDelete(
        this,
        this.$t('poista-koulutusjakso') as string,
        (this.$t('koulutusjakson') as string).toLowerCase()
      )
    ) {
      this.deleting = true
      try {
        await axios.delete(
          `erikoistuva-laakari/koulutussuunnitelma/koulutusjaksot/${this.koulutusjakso?.id}`
        )
        toastSuccess(this, this.$t('koulutusjakso-poistettu'))
        this.$router.push({
          name: 'koulutussuunnitelma'
        })
      } catch {
        toastFail(this, this.$t('koulutusjakson-poistaminen-epaonnistui'))
      }
      this.deleting = false
    }
  }
}
