import { render, staticRenderFns } from "./korvaa-arvioitava-kokonaisuus.vue?vue&type=template&id=dac01b3c&scoped=true&"
import script from "./korvaa-arvioitava-kokonaisuus.vue?vue&type=script&lang=ts&"
export * from "./korvaa-arvioitava-kokonaisuus.vue?vue&type=script&lang=ts&"
import style0 from "./korvaa-arvioitava-kokonaisuus.vue?vue&type=style&index=0&id=dac01b3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dac01b3c",
  null
  
)

export default component.exports