var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelyjakso'),"add-new-enabled":true,"add-new-label":_vm.$t('lisaa-tyoskentelyjakso'),"required":true},on:{"submit":_vm.onTyoskentelyjaksoSubmit},scopedSlots:_vm._u([{key:"modal-content",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
return [_c('tyoskentelyjakso-form',{attrs:{"kunnat":_vm.kunnat,"erikoisalat":_vm.erikoisalat},on:{"submit":submit,"cancel":cancel}})]}},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.tyoskentelyjaksotFormatted,"state":_vm.validateState('tyoskentelyjakso'),"label":"label","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)},"select":_vm.onTyoskentelyjaksoSelect},model:{value:(_vm.form.tyoskentelyjakso),callback:function ($$v) {_vm.$set(_vm.form, "tyoskentelyjakso", $$v)},expression:"form.tyoskentelyjakso"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('hr'),(_vm.value.id == null)?_c('div',[_vm._l((_vm.uudetSuoritteet),function(uusiSuorite,index){return _c('div',{key:index},[_c('elsa-form-group',{attrs:{"label":_vm.$t('suorite'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.suoritteenKategoriat,"state":_vm.validateSuoriteState(index),"group-values":"suoritteet","group-label":"nimi","group-select":false,"label":"nimi","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.$isLabel)?_c('span',[_vm._v(_vm._s(props.option.$groupLabel))]):_c('span',{staticClass:"d-inline-block ml-3"},[_vm._v(_vm._s(props.option.nimi))])]}}],null,true),model:{value:(uusiSuorite.suorite),callback:function ($$v) {_vm.$set(uusiSuorite, "suorite", $$v)},expression:"uusiSuorite.suorite"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,true)}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-md-6 mb-2",attrs:{"label":_vm.arviointiAsteikonNimi},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',{attrs:{"title":_vm.arviointiAsteikonNimi}},[_c('elsa-arviointiasteikon-taso-tooltip-content',{attrs:{"arviointiasteikon-tasot":_vm.arviointiasteikko.tasot}})],1)]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.arviointiasteikko.tasot,"custom-label":_vm.arviointiasteikonTasoLabel,"track-by":"taso"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"font-weight-700"},[_vm._v(_vm._s(props.option.taso))]),_vm._v(" "+_vm._s(_vm.$t('arviointiasteikon-taso-' + props.option.nimi))+" ")]}},{key:"option",fn:function(props){return [_c('span',{staticClass:"font-weight-700"},[_vm._v(_vm._s(props.option.taso))]),_vm._v(" "+_vm._s(_vm.$t('arviointiasteikon-taso-' + props.option.nimi))+" ")]}}],null,true),model:{value:(uusiSuorite.arviointiasteikonTaso),callback:function ($$v) {_vm.$set(uusiSuorite, "arviointiasteikonTaso", $$v)},expression:"uusiSuorite.arviointiasteikonTaso"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,true)}),_c('elsa-form-group',{staticClass:"col-md-6 mb-2",attrs:{"label":_vm.$t('vaativuustaso')},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',{attrs:{"title":_vm.$t('vaativuustaso')}},[_c('elsa-vaativuustaso-tooltip-content')],1)]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.vaativuustasot,"custom-label":_vm.vaativuustasoLabel,"track-by":"arvo"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"font-weight-700"},[_vm._v(_vm._s(props.option.arvo))]),_vm._v(" "+_vm._s(_vm.$t(props.option.nimi))+" ")]}},{key:"option",fn:function(props){return [_c('span',{staticClass:"font-weight-700"},[_vm._v(_vm._s(props.option.arvo))]),_vm._v(" "+_vm._s(_vm.$t(props.option.nimi))+" ")]}}],null,true),model:{value:(uusiSuorite.vaativuustaso),callback:function ($$v) {_vm.$set(uusiSuorite, "vaativuustaso", $$v)},expression:"uusiSuorite.vaativuustaso"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,true)})],1),(index !== 0)?_c('elsa-button',{staticClass:"text-decoration-none shadow-none p-0",attrs:{"variant":"link"},on:{"click":function($event){return _vm.deleteSuorite(index)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trash-alt'],"fixed-width":"","size":"sm"}}),_vm._v(" "+_vm._s(_vm.$t('poista-suorite'))+" ")],1):_vm._e(),(_vm.uudetSuoritteet.length > 1)?_c('hr'):_vm._e()],1)}),_c('elsa-button',{staticClass:"lisaa-suorite",class:_vm.uudetSuoritteet.length > 1 ? '' : 'mt-3',attrs:{"variant":"outline-primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onLisaaSuorite($event)}}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-suorite'))+" ")])],2):_c('div',[_c('elsa-form-group',{attrs:{"label":_vm.$t('suorite'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.suoritteenKategoriat,"state":_vm.validateState('suorite'),"group-values":"suoritteet","group-label":"nimi","group-select":false,"label":"nimi","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.$isLabel)?_c('span',[_vm._v(_vm._s(props.option.$groupLabel))]):_c('span',{staticClass:"d-inline-block ml-3"},[_vm._v(_vm._s(props.option.nimi))])]}}],null,true),model:{value:(_vm.form.suorite),callback:function ($$v) {_vm.$set(_vm.form, "suorite", $$v)},expression:"form.suorite"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-md-6",attrs:{"label":_vm.arviointiAsteikonNimi},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',{attrs:{"title":_vm.arviointiAsteikonNimi}},[_c('elsa-arviointiasteikon-taso-tooltip-content',{attrs:{"arviointiasteikon-tasot":_vm.arviointiasteikko.tasot}})],1)]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.arviointiasteikko.tasot,"custom-label":_vm.arviointiasteikonTasoLabel,"track-by":"taso"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"font-weight-700"},[_vm._v(_vm._s(props.option.taso))]),_vm._v(" "+_vm._s(_vm.$t('arviointiasteikon-taso-' + props.option.nimi))+" ")]}},{key:"option",fn:function(props){return [_c('span',{staticClass:"font-weight-700"},[_vm._v(_vm._s(props.option.taso))]),_vm._v(" "+_vm._s(_vm.$t('arviointiasteikon-taso-' + props.option.nimi))+" ")]}}],null,true),model:{value:(_vm.form.arviointiasteikonTaso),callback:function ($$v) {_vm.$set(_vm.form, "arviointiasteikonTaso", $$v)},expression:"form.arviointiasteikonTaso"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('elsa-form-group',{staticClass:"col-md-6",attrs:{"label":_vm.$t('vaativuustaso')},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',{attrs:{"title":_vm.$t('vaativuustaso')}},[_c('elsa-vaativuustaso-tooltip-content')],1)]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.vaativuustasot,"custom-label":_vm.vaativuustasoLabel,"track-by":"arvo"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"font-weight-700"},[_vm._v(_vm._s(props.option.arvo))]),_vm._v(" "+_vm._s(_vm.$t(props.option.nimi))+" ")]}},{key:"option",fn:function(props){return [_c('span',{staticClass:"font-weight-700"},[_vm._v(_vm._s(props.option.arvo))]),_vm._v(" "+_vm._s(_vm.$t(props.option.nimi))+" ")]}}],null,true),model:{value:(_vm.form.vaativuustaso),callback:function ($$v) {_vm.$set(_vm.form, "vaativuustaso", $$v)},expression:"form.vaativuustaso"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])})],1)],1),_c('hr'),_c('elsa-form-group',{attrs:{"label":_vm.$t('suorituspaiva'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"suorituspaiva",staticClass:"col-md-4 pl-0",attrs:{"id":uid,"value":_vm.form.suorituspaiva,"min":_vm.tyoskentelyjaksonAlkamispaiva,"min-error-text":_vm.$t('suorituspaiva-ei-voi-olla-ennen-tyoskentelyjakson-alkamista'),"max":_vm.tyoskentelyjaksonPaattymispaiva,"max-error-text":_vm.$t('suorituspaiva-ei-voi-olla-tyoskentelyjakson-paattymisen-jalkeen')},on:{"update:value":function($event){return _vm.$set(_vm.form, "suorituspaiva", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}}):_vm._e()]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('lisatiedot')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-textarea',{attrs:{"id":uid,"rows":"5"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.lisatiedot),callback:function ($$v) {_vm.$set(_vm.form, "lisatiedot", $$v)},expression:"form.lisatiedot"}})]}}])}),_c('div',{staticClass:"text-right"},[_c('elsa-button',{attrs:{"variant":"back","to":{ name: 'suoritemerkinnat' }}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")]),(_vm.value.id)?_c('elsa-button',{attrs:{"loading":_vm.params.deleting,"variant":"outline-danger"},on:{"click":_vm.onSuoritemerkintaDelete}},[_vm._v(" "+_vm._s(_vm.$t('poista-merkinta'))+" ")]):_vm._e(),_c('elsa-button',{staticClass:"ml-2",attrs:{"loading":_vm.params.saving,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tallenna'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }