












import Vue from 'vue'
import Component from 'vue-class-component'

import MobileNav from '@/components/mobile-nav/mobile-nav.vue'
import NavbarImpersonated from '@/components/navbar/navbar-impersonate.vue'
import Navbar from '@/components/navbar/navbar.vue'
import SidebarMenu from '@/components/sidebar-menu/sidebar-menu.vue'

@Component({
  components: {
    Navbar,
    NavbarImpersonated,
    SidebarMenu,
    MobileNav
  }
})
export default class Root extends Vue {
  get hasGrayBackdrop() {
    return this.$route.meta.grayBackdrop
  }
}
