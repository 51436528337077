

















































import { Component, Prop, Vue } from 'vue-property-decorator'

import { getEtusivuKoejaksot as getEtusivuKoejaksotKouluttaja } from '@/api/kouluttaja'
import { getEtusivuKoejaksot as getEtusivuKoejaksotVastuuhenkilo } from '@/api/vastuuhenkilo'
import { getEtusivuKoejaksot as getEtusivuKoejaksotVirkailija } from '@/api/virkailija'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import { KoejaksonVaihe } from '@/types'
import { LomakeTyypit } from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton
  }
})
export default class KoejaksotCard extends Vue {
  vaiheet: KoejaksonVaihe[] | null = null

  @Prop({ required: false, default: true })
  showVaihe!: boolean
  get fields() {
    return [
      {
        key: 'erikoistuvanNimi',
        label: this.$t('erikoistuja'),
        sortable: true
      },
      {
        key: 'pvm',
        label: this.$t('pvm'),
        sortable: true
      },
      {
        key: 'tyyppi',
        label: this.$t('vaihe'),
        sortable: true,
        thClass: this.showVaihe ? '' : 'd-none',
        tdClass: this.showVaihe ? '' : 'd-none'
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        class: 'actions'
      }
    ]
  }

  loading = true

  private componentLinks = new Map([
    [LomakeTyypit.KOULUTUSSOPIMUS, 'koulutussopimus'],
    [LomakeTyypit.ALOITUSKESKUSTELU, 'aloituskeskustelu-kouluttaja'],
    [LomakeTyypit.VALIARVIOINTI, 'valiarviointi-kouluttaja'],
    [LomakeTyypit.KEHITTAMISTOIMENPITEET, 'kehittamistoimenpiteet-kouluttaja'],
    [LomakeTyypit.LOPPUKESKUSTELU, 'loppukeskustelu-kouluttaja'],
    [LomakeTyypit.VASTUUHENKILON_ARVIO, 'vastuuhenkilon-arvio-vastuuhenkilo']
  ])

  async mounted() {
    try {
      if (this.$isVastuuhenkilo()) {
        this.vaiheet = (await getEtusivuKoejaksotVastuuhenkilo()).data
      } else if (this.$isVirkailija()) {
        this.vaiheet = (await getEtusivuKoejaksotVirkailija()).data
      } else {
        this.vaiheet = (await getEtusivuKoejaksotKouluttaja()).data
      }
    } catch (err) {
      toastFail(this, this.$t('koejaksojen-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  get rows() {
    return this.vaiheet?.length ?? 0
  }

  linkComponent(type: LomakeTyypit) {
    if (this.$isVirkailija() && type === LomakeTyypit.VASTUUHENKILON_ARVIO) {
      return 'virkailijan-tarkistus'
    }
    return this.componentLinks.get(type)
  }

  buttonText(type: LomakeTyypit) {
    switch (type) {
      case LomakeTyypit.KOULUTUSSOPIMUS:
        return 'hyvaksy'
      case LomakeTyypit.ALOITUSKESKUSTELU:
        return 'hyvaksy'
      case LomakeTyypit.VALIARVIOINTI:
        return 'tee-arviointi'
      case LomakeTyypit.KEHITTAMISTOIMENPITEET:
        return 'tee-arviointi'
      case LomakeTyypit.LOPPUKESKUSTELU:
        return 'tee-arviointi'
      case LomakeTyypit.VASTUUHENKILON_ARVIO:
        return 'tee-arviointi'
    }
  }
}
