












































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import ElsaProgressBar from '@/components/progress-bar/progress-bar.vue'
import { BarChartRow } from '@/types'

@Component({
  components: {
    ElsaProgressBar
  }
})
export default class ElsaBarChart extends Vue {
  @Prop({ required: true, default: [] })
  value!: BarChartRow[]
}
