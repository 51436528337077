





















import { Component, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'

@Component({
  components: {
    ElsaButton
  }
})
export default class PageNotFoundContent extends Vue {}
