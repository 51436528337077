




















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Validation } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import { Kurssikoodi, OpintosuoritusTyyppi } from '@/types'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaFormError,
    ElsaPopover
  }
})
export default class KurssikoodiForm extends Vue {
  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({ required: true, type: Object })
  kurssikoodi!: Kurssikoodi

  @Prop({ required: false, default: () => [] })
  tyypit?: OpintosuoritusTyyppi[]

  params = {
    saving: false
  }

  validations() {
    return {
      kurssikoodi: {
        tunniste: {
          required
        },
        tyyppi: {
          required
        }
      }
    }
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.kurssikoodi[name] as Validation
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.kurssikoodi.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    if (!this.validateForm()) {
      return
    }
    this.$emit(
      'submit',
      {
        ...this.kurssikoodi
      },
      this.params
    )
  }
}
