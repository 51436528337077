
























































































































































import axios from 'axios'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaAloituskeskusteluCard from '@/components/koejakso-cards/aloituskeskustelu-card.vue'
import ElsaKehittamistoimenpiteetCard from '@/components/koejakso-cards/kehittamistoimenpiteet-card.vue'
import ElsaKoulutussopimusCard from '@/components/koejakso-cards/koulutussopimus-card.vue'
import ElsaLoppukeskusteluCard from '@/components/koejakso-cards/loppukeskustelu-card.vue'
import ElsaValiarviointiCard from '@/components/koejakso-cards/valiarviointi-card.vue'
import ElsaVastuuhenkilonArvioCard from '@/components/koejakso-cards/vastuuhenkilon-arvio-card.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import TyoskentelyjaksoForm from '@/forms/tyoskentelyjakso-form.vue'
import TyoskentelyjaksoMixin from '@/mixins/tyoskentelyjakso'
import store from '@/store'
import { KoejaksonTyoskentelyjakso, Tyoskentelyjakso } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { sortByDateDesc } from '@/utils/date'
import { toastSuccess, toastFail } from '@/utils/toast'
import { tyoskentelyjaksoLabel } from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    ElsaButton,
    ElsaPopover,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaKoulutussopimusCard,
    ElsaAloituskeskusteluCard,
    ElsaValiarviointiCard,
    ElsaKehittamistoimenpiteetCard,
    ElsaLoppukeskusteluCard,
    ElsaVastuuhenkilonArvioCard,
    TyoskentelyjaksoForm
  }
})
export default class KoejaksoViewErikoistuva extends Mixins(TyoskentelyjaksoMixin) {
  tyoskentelyjaksotMutable: Tyoskentelyjakso[] = []
  tyoskentelyjaksotKoejakso: KoejaksonTyoskentelyjakso[] = []
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      active: true
    }
  ]
  loading = true
  tyoskentelyjaksoUpdating = false
  sortBy = 'paattymispaiva'
  sortDesc = true

  fields = [
    {
      key: 'formattedNimi',
      label: '',
      class: 'btn-tyoskentelyjakso'
    },
    {
      key: 'delete',
      label: '',
      width: 5
    },
    {
      key: 'paattymispaiva',
      thClass: 'd-none',
      tdClass: 'd-none'
    }
  ]

  async mounted() {
    this.loading = true
    await store.dispatch('erikoistuva/getKoejakso')
    this.tyoskentelyjaksotMutable = store.getters['erikoistuva/koejakso'].tyoskentelyjaksot
    this.tyoskentelyjaksotKoejakso = this.mapTyoskentelyjaksotKoejakso(
      this.tyoskentelyjaksotMutable?.filter((t: any) => t.liitettyKoejaksoon) ?? []
    )
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  mapTyoskentelyjaksotKoejakso(tyoskentelyjaksot: any[]): KoejaksonTyoskentelyjakso[] {
    return tyoskentelyjaksot.map((t) => this.mapTyoskentelyjaksoKoejakso(t))
  }

  mapTyoskentelyjaksoKoejakso(tyoskentelyjakso: any): KoejaksonTyoskentelyjakso {
    return {
      id: tyoskentelyjakso.id,
      formattedNimi: tyoskentelyjaksoLabel(this, tyoskentelyjakso),
      paattymispaiva: tyoskentelyjakso.paattymispaiva
    } as KoejaksonTyoskentelyjakso
  }

  async onTyoskentelyjaksoAttached() {
    this.tyoskentelyjaksoUpdating = true
    try {
      const tyoskentelyjakso = (
        await axios.patch('erikoistuva-laakari/tyoskentelyjaksot/koejakso', {
          id: this.form.tyoskentelyjakso.id,
          liitettyKoejaksoon: true
        })
      ).data

      this.tyoskentelyjaksotKoejakso = [
        this.mapTyoskentelyjaksoKoejakso(tyoskentelyjakso),
        ...this.tyoskentelyjaksotKoejakso
      ] as any
      this.form.tyoskentelyjakso = null

      toastSuccess(this, this.$t('tyoskentelyjakson-lisaaminen-koejaksolle-onnistui'))
    } catch {
      toastFail(this, this.$t('tyoskentelyjakson-lisaaminen-koejaksolle-epaonnistui'))
    }
    this.tyoskentelyjaksoUpdating = false
  }

  async onTyoskentelyjaksoDetached(tyoskentelyjakso: KoejaksonTyoskentelyjakso) {
    if (
      await confirmDelete(
        this,
        this.$t('poista-tyoskentelyjakso-koejaksolta') as string,
        (this.$t('tyoskentelyjakson-koejaksolta') as string).toLowerCase()
      )
    ) {
      Vue.set(tyoskentelyjakso, 'disableDelete', true)
      try {
        await axios.patch('erikoistuva-laakari/tyoskentelyjaksot/koejakso', {
          id: tyoskentelyjakso.id,
          liitettyKoejaksoon: false
        })
        this.tyoskentelyjaksotKoejakso = this.tyoskentelyjaksotKoejakso.filter(
          (t: any) => t.id !== tyoskentelyjakso.id
        )

        toastSuccess(this, this.$t('tyoskentelyjakson-poistaminen-koejaksolta-onnistui'))
      } catch {
        toastFail(this, this.$t('tyoskentelyjakson-poistaminen-koejaksolta-epaonnistui'))
      }
      Vue.set(tyoskentelyjakso, 'disableDelete', false)
    }
  }

  get tyoskentelyjaksotFormatted() {
    return this.tyoskentelyjaksotMutable
      .concat(this.tyoskentelyjaksot)
      .filter((t: any) => !this.tyoskentelyjaksotKoejakso.map((tk) => tk.id).includes(t.id))
      .map((t: any) => ({
        ...t,
        label: tyoskentelyjaksoLabel(this, t)
      }))
      .sort((t1: Tyoskentelyjakso, t2: Tyoskentelyjakso) => {
        return sortByDateDesc(t1.paattymispaiva, t2.paattymispaiva)
      })
  }

  get kunnat() {
    return store.getters['erikoistuva/koejakso'].kunnat
  }

  get erikoisalat() {
    return store.getters['erikoistuva/koejakso'].erikoisalat
  }
}
