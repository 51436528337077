

















import { Component, Vue } from 'vue-property-decorator'

import BCardSkeleton from '@/components/card/card.vue'

@Component({
  components: {
    BCardSkeleton
  }
})
export default class ArvioitavatKokonaisuudetCard extends Vue {}
