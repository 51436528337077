

























































































































































import { Vue, Component } from 'vue-property-decorator'

import { getPaivakirjamerkinnatRajaimet, getPaivittaisetMerkinnat } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import store from '@/store'
import {
  Page,
  PaivakirjaAihekategoria,
  Paivakirjamerkinta,
  PaivakirjamerkintaRajaimet
} from '@/types'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaFormDatepicker,
    ElsaPagination
  }
})
export default class PaivittaisetMerkinnat extends Vue {
  $refs!: {
    ajankohtaAlkaa: ElsaFormDatepicker
    ajankohtaPaattyy: ElsaFormDatepicker
  }

  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('paivittaiset-merkinnat'),
      active: true
    }
  ]
  loading = true
  selected: {
    aihekategoriaId: number | null
    ajankohtaAlkaa: string | null
    ajankohtaPaattyy: string | null
    aihe: PaivakirjaAihekategoria | null
  } = {
    aihekategoriaId: null,
    ajankohtaAlkaa: null,
    ajankohtaPaattyy: null,
    aihe: null
  }
  currentPage = 1
  perPage = 20
  rajaimet: PaivakirjamerkintaRajaimet | null = null
  merkinnat: Page<Paivakirjamerkinta> | null = null

  async mounted() {
    await Promise.all([this.fetchRajaimet(), this.fetch()])
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  async fetchRajaimet() {
    try {
      this.rajaimet = (await getPaivakirjamerkinnatRajaimet()).data
    } catch {
      toastFail(this, this.$t('paivittaisten-merkintojen-hakeminen-epaonnistui'))
    }
  }

  async fetch() {
    try {
      const merkinnat = (
        await getPaivittaisetMerkinnat({
          page: this.currentPage - 1,
          size: this.perPage,
          sort: 'paivamaara,id,desc',
          ...(this.selected.aihekategoriaId
            ? { 'aihekategoriaId.equals': this.selected.aihekategoriaId }
            : {}),
          ...(this.selected.ajankohtaAlkaa
            ? { 'paivamaara.greaterThanOrEqual': this.selected.ajankohtaAlkaa }
            : {}),
          ...(this.selected.ajankohtaPaattyy
            ? { 'paivamaara.lessThanOrEqual': this.selected.ajankohtaPaattyy }
            : {})
        })
      ).data
      this.merkinnat = {
        ...merkinnat,
        content: merkinnat.content.map((merkinta) => ({
          ...merkinta,
          aihekategoriat: merkinta.aihekategoriat.sort(
            (a, b) => (a.jarjestysnumero ?? 0) - (b.jarjestysnumero ?? 0)
          )
        }))
      }
    } catch {
      toastFail(this, this.$t('paivittaisten-merkintojen-hakeminen-epaonnistui'))
    }
  }

  onPageInput(value: number) {
    this.currentPage = value
    this.fetch()
  }

  onAihekategoriaSelect(selected: PaivakirjaAihekategoria) {
    this.currentPage = 1
    this.selected.aihekategoriaId = selected.id ?? null
    this.fetch()
  }

  onAjankohtaAlkaaSelect(value: string) {
    this.selected.ajankohtaAlkaa = value

    if (!this.$refs.ajankohtaAlkaa.validateForm()) {
      return
    }
    this.currentPage = 1
    this.fetch()
  }

  onAjankohtaPaattyySelect(value: string) {
    this.selected.ajankohtaPaattyy = value

    if (!this.$refs.ajankohtaPaattyy.validateForm()) {
      return
    }
    this.currentPage = 1
    this.fetch()
  }

  resetFilters() {
    this.currentPage = 1
    this.selected = {
      aihekategoriaId: null,
      ajankohtaAlkaa: null,
      ajankohtaPaattyy: null,
      aihe: null
    }
    this.$refs.ajankohtaAlkaa.resetValue()
    this.$refs.ajankohtaPaattyy.resetValue()
    this.fetch()
  }

  get maxAjankohtaAlkaa() {
    return this.selected.ajankohtaPaattyy
  }

  get minAjankohtaPaattyy() {
    return this.selected.ajankohtaAlkaa
  }

  get rows() {
    return this.merkinnat?.totalElements ?? 0
  }

  get aihekategoriat() {
    return this.rajaimet?.aihekategoriat ?? []
  }

  get anyFilterSelected() {
    return (
      this.selected.aihekategoriaId ||
      this.selected.ajankohtaAlkaa ||
      this.selected.ajankohtaPaattyy
    )
  }
}
