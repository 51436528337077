













































































































import Component from 'vue-class-component'
import { Vue, Prop } from 'vue-property-decorator'
import { between, integer, required, requiredIf } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPoissaolonSyyt from '@/components/poissaolon-syyt/poissaolon-syyt.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import { TyokertymaLaskuriPoissaolo } from '@/types'

@Component({
  components: {
    ElsaPoissaolonSyyt,
    ElsaButton,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    ElsaFormDatepicker,
    ElsaPopover
  },
  validations: {
    poissaolo: {
      poissaolonSyy: {
        nimi: {
          required
        }
      },
      kokoTyoajanPoissaolo: {
        required
      },
      poissaoloprosentti: {
        required: requiredIf((poissaolo) => {
          return poissaolo.kokoTyoajanPoissaolo === false
        }),
        between: between(0, 100),
        integer
      }
    }
  }
})
export default class TyokertymalaskuriTyoskentelyjaksoPoissaoloForm extends Vue {
  $refs!: {
    alkamispaiva: ElsaFormDatepicker
    paattymispaiva: ElsaFormDatepicker
  }
  @Prop({ type: Object, required: true })
  poissaolo!: TyokertymaLaskuriPoissaolo | any

  @Prop({ type: Array, required: true })
  poissaolonSyytSorted!: any[]

  @Prop({ type: Boolean, default: false })
  childDataReceived!: boolean

  @Prop({ type: String, required: true })
  tyojaksoAlkamispaiva!: string

  @Prop({ type: String, required: true })
  tyojaksoPaattymispaiva!: string

  validateState(name: string) {
    const { $dirty, $error } = this.poissaolo[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  mounted() {
    this.$parent.$on('validate-all-poissaolot', this.validateForm)
  }

  beforeDestroy() {
    this.$parent.$off('validate-all-poissaolot', this.validateForm)
  }

  validateForm(): boolean {
    this.$refs.alkamispaiva.validateForm()
    this.$refs.paattymispaiva.validateForm()
    this.$v.poissaolo.$touch()
    return !this.$v.$anyError
  }

  get minAlkamispaiva() {
    return this.tyojaksoAlkamispaiva
  }

  get maxAlkamispaiva() {
    return this.tyojaksoPaattymispaiva
  }

  get minPaattymispaiva() {
    if (this.poissaolo.alkamispaiva !== null) {
      return this.poissaolo.alkamispaiva
    }
    return this.tyojaksoAlkamispaiva
  }

  get maxPaattymispaiva() {
    return this.tyojaksoPaattymispaiva
  }

  get isParentDatesSelected() {
    return this.tyojaksoAlkamispaiva && this.tyojaksoPaattymispaiva
  }

  getISODateNow() {
    const date = new Date()
    return date.toISOString().split('T')[0]
  }
}
