var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[(!_vm.isReadonly)?_c('b-col',{attrs:{"lg":"10"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('koulutuspaikan-arvioijat')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('valitse-arvioijat-help'))+" "),_c('b-link',{attrs:{"to":{ name: 'profiili' }}},[_vm._v(_vm._s(_vm.$t('profiilissasi')))])],1),(!_vm.loading)?_c('div',[_c('elsa-form-group',{attrs:{"label":_vm.$t('lahikouluttaja'),"add-new-enabled":true,"add-new-label":_vm.$t('lisaa-kouluttaja'),"required":true},on:{"submit":_vm.onLahikouluttajaSubmit},scopedSlots:_vm._u([{key:"modal-content",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
return [_c('kouluttaja-form',{attrs:{"kouluttajat":_vm.kouluttajat},on:{"submit":submit,"cancel":cancel}})]}},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.lahikouluttajatList,"state":_vm.validateState('lahikouluttaja'),"label":"nimi","track-by":"nimi"},on:{"select":_vm.onLahikouluttajaSelect},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [(option.nimi)?_c('div',[_vm._v(_vm._s(_vm.optionDisplayName(option)))]):_vm._e()]}}],null,true),model:{value:(_vm.form.lahikouluttaja),callback:function ($$v) {_vm.$set(_vm.form, "lahikouluttaja", $$v)},expression:"form.lahikouluttaja"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,1379925717)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('lahiesimies-tai-muu'),"add-new-enabled":true,"add-new-label":_vm.$t('lisaa-henkilo'),"required":true},on:{"submit":_vm.onLahiesimiesSubmit},scopedSlots:_vm._u([{key:"modal-content",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
return [_c('kouluttaja-form',{attrs:{"kouluttajat":_vm.kouluttajat},on:{"submit":submit,"cancel":cancel}})]}},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.lahiesimiesList,"state":_vm.validateState('lahiesimies'),"label":"nimi","track-by":"nimi"},on:{"select":_vm.onLahiesimiesSelect},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [(option.nimi)?_c('div',[_vm._v(_vm._s(_vm.optionDisplayName(option)))]):_vm._e()]}}],null,true),model:{value:(_vm.form.lahiesimies),callback:function ($$v) {_vm.$set(_vm.form, "lahiesimies", $$v)},expression:"form.lahiesimies"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,691083937)})],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)]):_c('b-col',{attrs:{"lg":"10"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('koulutuspaikan-arvioijat')))]),_c('h5',[_vm._v(_vm._s(_vm.$t('lahikouluttaja')))]),_c('p',[_vm._v(" "+_vm._s(_vm.lahikouluttaja.nimike ? _vm.lahikouluttaja.nimi + ', ' + _vm.lahikouluttaja.nimike : _vm.lahikouluttaja.nimi)+" ")]),_c('h5',[_vm._v(_vm._s(_vm.$t('lahiesimies-tai-muu')))]),_c('p',[_vm._v(" "+_vm._s(_vm.lahiesimies.nimike ? _vm.lahiesimies.nimi + ', ' + _vm.lahiesimies.nimike : _vm.lahiesimies.nimi)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }