
























import { Component, Prop, Vue } from 'vue-property-decorator'

import { KoejaksonVaiheAllekirjoitus } from '@/types'

@Component({})
export default class KoejaksonVaiheAllekirjoitukset extends Vue {
  @Prop({ required: true, default: [] })
  allekirjoitukset!: KoejaksonVaiheAllekirjoitus[] | null

  @Prop({ required: false, default: 'muokkauspaivamaarat' })
  title!: string

  get showAllekirjoitukset() {
    return this.allekirjoitukset ? this.allekirjoitukset.length > 0 : false
  }
}
