


























































import Component from 'vue-class-component'
import { Mixins, Prop, Watch } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import { YhdistaKayttajatilejaForm } from '@/types'

@Component({
  components: {
    ElsaFormGroup,
    ElsaFormMultiselect,
    UserAvatar,
    ElsaButton
  }
})
export default class YhteinenSahkoposti extends Mixins(validationMixin) {
  @Prop({ required: true })
  form!: YhdistaKayttajatilejaForm

  validations() {
    return {
      form: {
        yhteinenSahkoposti: {
          required,
          email
        },
        yhteinenSahkopostiUudelleen: {
          required,
          email,
          sameAsSahkoposti: sameAs('yhteinenSahkoposti')
        }
      }
    }
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  yhteinenSahkopostiTouch() {
    this.$v.form.yhteinenSahkoposti?.$touch()
  }

  yhteinenSahkopostiUudelleenTouch() {
    this.$v.form?.yhteinenSahkopostiUudelleen?.$touch()
  }

  get yhteinenSahkopostiRequired() {
    return this.$v.form.yhteinenSahkoposti?.$error && !this.$v.form.yhteinenSahkoposti.required
  }

  get yhteinenSahkopostiUudelleenRequired() {
    return (
      this.$v.form?.yhteinenSahkopostiUudelleen?.$error &&
      !this.$v.form?.yhteinenSahkopostiUudelleen.required
    )
  }

  get yhteinenSahkopostiInvalid() {
    return this.$v.form.yhteinenSahkoposti?.$error && !this.$v.form.yhteinenSahkoposti.email
  }

  get yhteinenSahkopostiUudelleenInvalid() {
    return (
      this.$v.form?.yhteinenSahkopostiUudelleen?.$error &&
      !this.$v.form?.yhteinenSahkopostiUudelleen.email
    )
  }

  get yhteinenSahkopostiUudelleenNoMatch() {
    return (
      this.$v.form?.yhteinenSahkopostiUudelleen?.$error &&
      this.$v.form?.yhteinenSahkopostiUudelleen?.required &&
      this.$v.form?.yhteinenSahkopostiUudelleen?.email &&
      !this.$v.form.yhteinenSahkopostiUudelleen.sameAsSahkoposti
    )
  }

  get isFormValid() {
    const isYhteinenSahkopostiValid =
      this.$v.form?.yhteinenSahkoposti?.$pending === false &&
      !this.$v.form?.yhteinenSahkoposti.$invalid
    const isYhteinenSahkopostiUudelleenValid =
      this.$v.form?.yhteinenSahkopostiUudelleen?.$pending === false &&
      !this.$v.form?.yhteinenSahkopostiUudelleen.$invalid
    return isYhteinenSahkopostiValid && isYhteinenSahkopostiUudelleenValid
  }

  @Watch('isFormValid')
  onFormValidityChange(newVal: boolean) {
    this.form.formValid = newVal
  }
}
