














































































































































































import _get from 'lodash/get'
import { Component, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import KoulutuspaikanArvioijat from '@/components/koejakson-vaiheet/koulutuspaikan-arvioijat.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import store from '@/store'
import {
  ValiarviointiLomake,
  Koejakso,
  KoejaksonVaiheAllekirjoitus,
  KoejaksonVaiheHyvaksyja,
  KoejaksonVaiheButtonStates
} from '@/types'
import { KehittamistoimenpideKategoria, LomakeTilat } from '@/utils/constants'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ErikoistuvaDetails,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaButton,
    ElsaConfirmationModal,
    KoulutuspaikanArvioijat,
    KoejaksonVaiheAllekirjoitukset
  }
})
export default class ErikoistuvaArviointilomakeValiarviointi extends Vue {
  $refs!: {
    koulutuspaikanArvioijat: KoulutuspaikanArvioijat
  }
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('koejakson-valiarviointi'),
      active: true
    }
  ]

  loading = true

  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }

  koejaksonVaihe = this.$t('valiarviointi')

  valiarviointiLomake: Partial<ValiarviointiLomake> = {
    edistyminenTavoitteidenMukaista: null,
    erikoistuvaAllekirjoittanut: false,
    erikoistuvanErikoisala: this.account.erikoistuvaLaakari.erikoisalaNimi,
    erikoistuvanNimi: `${this.account.firstName} ${this.account.lastName}`,
    erikoistuvanOpiskelijatunnus: this.account.erikoistuvaLaakari.opiskelijatunnus,
    erikoistuvanYliopisto: this.account.erikoistuvaLaakari.yliopisto,
    id: null,
    kehittamistoimenpideKategoriat: [],
    kehittamistoimenpiteet: '',
    korjausehdotus: '',
    lahiesimies: {
      id: null,
      kayttajaUserId: null,
      kuittausaika: '',
      nimi: '',
      nimike: null,
      sopimusHyvaksytty: false
    },
    lahikouluttaja: {
      id: 0,
      kayttajaUserId: null,
      kuittausaika: '',
      nimi: '',
      nimike: null,
      sopimusHyvaksytty: false
    },
    muokkauspaiva: '',
    muuKategoria: '',
    vahvuudet: ''
  }

  kategoriaOrder = [
    KehittamistoimenpideKategoria.TYOSSASUORIUTUMINEN,
    KehittamistoimenpideKategoria.TYOKAYTTAYTYMINEN,
    KehittamistoimenpideKategoria.POTILASPALAUTE,
    KehittamistoimenpideKategoria.MUU
  ]

  validateState(value: string) {
    const form = this.$v.valiarviointiLomake
    const { $dirty, $error } = _get(form, value) as any
    return $dirty ? ($error ? false : null) : null
  }

  get account() {
    return store.getters['auth/account']
  }

  get kouluttajat() {
    return store.getters['erikoistuva/kouluttajatJaVastuuhenkilot'] || []
  }

  get editable() {
    if (this.account.impersonated) {
      return false
    }
    return this.koejaksoData.valiarvioinninTila === LomakeTilat.UUSI
  }

  get deletable() {
    return this.koejaksoData.valiarvioinninTila === LomakeTilat.ODOTTAA_HYVAKSYNTAA
  }

  get showWaitingForAcceptance() {
    return this.koejaksoData.valiarvioinninTila === LomakeTilat.ODOTTAA_HYVAKSYNTAA
  }

  get showAcceptedByEveryone() {
    return this.koejaksoData.valiarvioinninTila === LomakeTilat.HYVAKSYTTY
  }

  get koejaksoData(): Koejakso {
    return store.getters['erikoistuva/koejakso']
  }

  setKoejaksoData() {
    if (this.koejaksoData.valiarviointi) {
      this.valiarviointiLomake = this.koejaksoData.valiarviointi
    }
  }

  naytaKehittamistoimenpideKategoria(kategoria: string) {
    if (kategoria === KehittamistoimenpideKategoria.MUU)
      return this.valiarviointiLomake?.muuKategoria
    return this.$t('kehittamistoimenpidekategoria-' + kategoria)
  }

  get sortedKategoriat() {
    return this.valiarviointiLomake?.kehittamistoimenpideKategoriat?.sort(
      (a, b) => this.kategoriaOrder.indexOf(a) - this.kategoriaOrder.indexOf(b)
    )
  }

  get allekirjoitukset(): KoejaksonVaiheAllekirjoitus[] {
    const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
      this,
      this.valiarviointiLomake?.erikoistuvanNimi,
      this.valiarviointiLomake?.erikoistuvanKuittausaika
    )
    const allekirjoitusLahikouluttaja = allekirjoituksetHelper.mapAllekirjoitusLahikouluttaja(
      this,
      this.valiarviointiLomake?.lahikouluttaja
    )
    const allekirjoitusLahiesimies = allekirjoituksetHelper.mapAllekirjoitusLahiesimies(
      this,
      this.valiarviointiLomake?.lahiesimies
    )

    return [
      allekirjoitusLahikouluttaja,
      allekirjoitusLahiesimies,
      allekirjoitusErikoistuva
    ].filter((a): a is KoejaksonVaiheAllekirjoitus => a !== null)
  }

  optionDisplayName(option: any) {
    return option.nimike ? option.nimi + ', ' + option.nimike : option.nimi
  }

  hideModal(id: string) {
    return this.$bvModal.hide(id)
  }

  onLahikouluttajaSelect(lahikouluttaja: KoejaksonVaiheHyvaksyja) {
    this.valiarviointiLomake.lahikouluttaja = lahikouluttaja
  }

  onLahiesimiesSelect(lahiesimies: KoejaksonVaiheHyvaksyja) {
    this.valiarviointiLomake.lahiesimies = lahiesimies
  }

  onValidateAndConfirm(modalId: string) {
    if (
      this.$refs.koulutuspaikanArvioijat &&
      !this.$refs.koulutuspaikanArvioijat.validateForm()
    ) {
      return
    }
    return this.$bvModal.show(modalId)
  }

  async onSend() {
    try {
      this.buttonStates.primaryButtonLoading = true
      await store.dispatch('erikoistuva/postValiarviointi', this.valiarviointiLomake)
      this.buttonStates.primaryButtonLoading = false
      toastSuccess(this, this.$t('valiarviointi-lahetetty-onnistuneesti'))
      this.setKoejaksoData()
    } catch {
      toastFail(this, this.$t('valiarviointi-tallennus-epaonnistui'))
    }
  }

  async onFormDelete() {
    try {
      this.buttonStates.primaryButtonLoading = true
      await store.dispatch('erikoistuva/deleteValiarviointi', this.valiarviointiLomake)
      this.buttonStates.primaryButtonLoading = false
      toastSuccess(this, this.$t('lomake-tyhjennetty-onnistuneesti'))
      this.valiarviointiLomake.id = null
    } catch {
      toastFail(this, this.$t('lomakkeen-tyhjennys-epaonnistui'))
    }
  }

  async mounted() {
    this.loading = true
    if (!this.koejaksoData) {
      await store.dispatch('erikoistuva/getKoejakso')
    }
    await store.dispatch('erikoistuva/getKouluttajatJaVastuuhenkilot')
    this.setKoejaksoData()
    this.loading = false
  }
}
