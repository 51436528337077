











































































































import Vue from 'vue'
import Component from 'vue-class-component'

import KoejaksoCardContent from './koejakso-card-content.vue'

import ElsaButton from '@/components/button/button.vue'
import store from '@/store'
import { LomakeTilat } from '@/utils/constants'

@Component({
  components: {
    KoejaksoCardContent,
    ElsaButton
  }
})
export default class AloituskeskusteluCard extends Vue {
  get account() {
    return store.getters['auth/account']
  }

  get koejakso() {
    return store.getters['erikoistuva/koejakso']
  }

  get tila() {
    return this.koejakso.aloituskeskustelunTila
  }

  get korjausehdotus() {
    if (this.koejakso.aloituskeskustelu) {
      return this.koejakso.aloituskeskustelu.korjausehdotus
    }
    return null
  }

  get lomaketilat() {
    return LomakeTilat
  }

  get url() {
    return 'koejakson-aloituskeskustelu'
  }
}
