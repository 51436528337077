var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('p',[_vm._v(_vm._s(_vm.$t('kayton-aloitus-ingressi')))]),_c('elsa-form-group',{attrs:{"label":_vm.$t('sahkopostiosoite'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sahkoposti')},model:{value:(_vm.form.sahkoposti),callback:function ($$v) {_vm.$set(_vm.form, "sahkoposti", $$v)},expression:"form.sahkoposti"}}),(_vm.$v.form.sahkoposti && !_vm.$v.form.sahkoposti.required)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")]):_vm._e(),(_vm.$v.form.sahkoposti && !_vm.$v.form.sahkoposti.email)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('sahkopostiosoite-ei-kelvollinen'))+" ")]):_vm._e()]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('vahvista-sahkopostiosoite'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sahkopostiUudelleen')},model:{value:(_vm.form.sahkopostiUudelleen),callback:function ($$v) {_vm.$set(_vm.form, "sahkopostiUudelleen", $$v)},expression:"form.sahkopostiUudelleen"}}),(_vm.$v.form.sahkopostiUudelleen && !_vm.$v.form.sahkopostiUudelleen.required)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")]):_vm._e(),(_vm.$v.form.sahkopostiUudelleen && !_vm.$v.form.sahkopostiUudelleen.email)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('sahkopostiosoite-ei-kelvollinen'))+" ")]):_vm._e(),(
          _vm.$v.form.sahkopostiUudelleen &&
          _vm.$v.form.sahkopostiUudelleen.required &&
          _vm.$v.form.sahkopostiUudelleen.email &&
          !_vm.$v.form.sahkopostiUudelleen.sameAsSahkoposti
        )?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('sahkopostiosoitteet-eivat-tasmaa'))+" ")]):_vm._e()]}}])}),(_vm.hasMultipleOpintooikeus)?_c('elsa-form-group',{attrs:{"label":_vm.$t('useampi-opinto-oikeus'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var uid = ref.uid;
return [_c('p',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t('useampi-opinto-oikeus-ingressi')))]),_c('b-form-radio-group',{attrs:{"id":uid,"options":_vm.opintooikeudetOptions,"state":_vm.validateState('opintooikeusId'),"stacked":""},model:{value:(_vm.form.opintooikeusId),callback:function ($$v) {_vm.$set(_vm.form, "opintooikeusId", $$v)},expression:"form.opintooikeusId"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('opintooikeusId')}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,3768367130)}):_vm._e(),_c('div',{staticClass:"text-right"},[_c('b-form',{ref:"logoutForm",attrs:{"action":_vm.logoutUrl,"method":"POST"}}),_c('elsa-button',{attrs:{"variant":"back"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" "+_vm._s(_vm.$t('keskeyta-ja-kirjaudu-ulos'))+" ")]),_c('elsa-button',{staticClass:"ml-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('aloita-palvelun-kaytto'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }