























import { Component, Vue } from 'vue-property-decorator'

import {
  getArvioitavanKokonaisuudenKategoria,
  putArvioitavanKokonaisuudenKategoria
} from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import ArvioitavanKokonaisuudenKategoriaForm from '@/forms/arvioitavan-kokonaisuuden-kategoria-form.vue'
import { ArvioitavanKokonaisuudenKategoriaWithErikoisala } from '@/types'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ArvioitavanKokonaisuudenKategoriaForm,
    ElsaButton
  }
})
export default class MuokkaaSuoritteenKategoriaa extends Vue {
  loading = true

  kategoria: ArvioitavanKokonaisuudenKategoriaWithErikoisala | null = null

  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('opetussuunnitelmat'),
        to: { name: 'opetussuunnitelmat' }
      },
      {
        text: this.kategoria?.erikoisala.nimi,
        to: { name: 'erikoisala' }
      },
      {
        text: this.$t('muokkaa-kategoriaa'),
        active: true
      }
    ]
  }

  async mounted() {
    await this.fetchKategoria()
    this.loading = false
  }

  async fetchKategoria() {
    try {
      this.kategoria = (
        await getArvioitavanKokonaisuudenKategoria(this.$route.params.kategoriaId)
      ).data
    } catch (err) {
      toastFail(this, this.$t('arvioitavan-kokonaisuuden-kategorian-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'arvioitavan-kokonaisuuden-kategoria' })
    }
  }

  async onSubmit(
    value: ArvioitavanKokonaisuudenKategoriaWithErikoisala,
    params: { saving: boolean }
  ) {
    params.saving = true
    try {
      await putArvioitavanKokonaisuudenKategoria(value)
      toastSuccess(this, this.$t('kategorian-tallentaminen-onnistui'))
      this.$emit('skipRouteExitConfirm', true)
      this.$router.push({ name: 'arvioitavan-kokonaisuuden-kategoria' })
    } catch (err) {
      toastFail(this, this.$t('kategorian-tallentaminen-epaonnistui'))
    }
    params.saving = false
  }
}
