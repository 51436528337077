















































































































































































































































































































































































































































import { AxiosError } from 'axios'
import { Component, Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { requiredIf, required, email } from 'vuelidate/lib/validators'

import {
  getValmistumispyynto,
  getValmistumispyyntoSuoritustenTila,
  postValmistumispyynto,
  putValmistumispyynto
} from '@/api/erikoistuva'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import AsiakirjatUpload from '@/components/asiakirjat/asiakirjat-upload.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import ValmistumispyyntoMixin from '@/mixins/valmistumispyynto'
import store from '@/store'
import {
  ValmistumispyyntoVaatimuksetLomake,
  ValmistumispyyntoLomakeErikoistuja,
  ValmistumispyyntoSuoritustenTila,
  ElsaError,
  Asiakirja
} from '@/types'
import { confirmExit } from '@/utils/confirm'
import { ErikoisalaTyyppi, ValmistumispyynnonTila, phoneNumber } from '@/utils/constants'
import { mapFile, mapFiles } from '@/utils/fileMapper'
import { toastFail, toastSuccess } from '@/utils/toast'
import ValmistumispyynnonTilaErikoistuja from '@/views/valmistumispyynnot/erikoistuja/valmistumispyynnon-tila-erikoistuja.vue'

@Component({
  components: {
    AsiakirjatContent,
    AsiakirjatUpload,
    ElsaButton,
    ErikoistuvaDetails,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormDatepicker,
    ElsaPopover,
    ElsaConfirmationModal,
    ValmistumispyynnonTilaErikoistuja
  }
})
export default class ValmistumispyyntoErikoistuja extends Mixins<ValmistumispyyntoMixin>(
  validationMixin,
  ValmistumispyyntoMixin
) {
  $refs!: {
    laillistamispaiva: ElsaFormDatepicker
  }

  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('valmistumispyynto'),
      active: true
    }
  ]

  valmistumispyyntoSuoritustenTila: Partial<ValmistumispyyntoSuoritustenTila> = {}
  valmistumispyyntoVaatimuksetLomake: Partial<ValmistumispyyntoVaatimuksetLomake> = {}
  valmistumispyyntoLomake: ValmistumispyyntoLomakeErikoistuja = {
    selvitysVanhentuneistaSuorituksista: null,
    laillistamispaiva: null,
    laillistamistodistus: null,
    erikoistujanPuhelinnumero: null,
    erikoistujanSahkoposti: null
  }
  vaatimuksetHyvaksytty = false
  loading = true
  sending = false
  laillistamispaivaAsiakirjat: Asiakirja[] = []
  laillistaminenMuokattavissa = false

  validations() {
    return {
      valmistumispyyntoVaatimuksetLomake: {
        tyoskentelyjaksot: { checked: (value: boolean) => value === true },
        tyotodistukset: { checked: (value: boolean) => value === true },
        tyoskentelyjaksotpaatetty: { checked: (value: boolean) => value === true },
        kuulusteluJaJohtamisopinnot: { checked: (value: boolean) => value === true },
        teoriakoulutus: { checked: (value: boolean) => value === true },
        osaamisenArvioinnit: { checked: (value: boolean) => value === true },
        tkjakso: { checked: (value: boolean) => value === true },
        palautekysely: { checked: (value: boolean) => value === true }
      },
      valmistumispyyntoLomake: {
        erikoistujanSahkoposti: {
          required,
          email
        },
        erikoistujanPuhelinnumero: {
          required,
          phoneNumber
        },
        selvitysVanhentuneistaSuorituksista: {
          required: requiredIf(() => this.hasVanhentuneitaSuorituksia)
        },
        laillistamispaiva: {
          required
        },
        laillistamistodistus: {
          required: requiredIf(() => {
            return this.laillistamispaivaAsiakirjat.length === 0
          })
        }
      }
    }
  }

  async mounted() {
    try {
      this.valmistumispyynto = (await getValmistumispyynto()).data
      if (this.showValmistumispyyntoForm) {
        this.valmistumispyyntoSuoritustenTila = (await getValmistumispyyntoSuoritustenTila()).data
        this.initLaillistamispaivaAndTodistus()
        this.valmistumispyyntoLomake.erikoistujanSahkoposti =
          this.account.erikoistuvaLaakari.sahkoposti
        this.valmistumispyyntoLomake.erikoistujanPuhelinnumero =
          this.account.erikoistuvaLaakari.puhelinnumero
        this.valmistumispyyntoLomake.selvitysVanhentuneistaSuorituksista =
          this.valmistumispyynto.selvitysVanhentuneistaSuorituksista || ''
      }
      this.loading = false
    } catch (err) {
      toastFail(this, this.$t('valmistumispyynnon-hakeminen-epaonnistui'))
    }
  }

  get account() {
    return store.getters['auth/account']
  }

  get vanhentunutSuoritusVuotta() {
    return this.valmistumispyyntoSuoritustenTila.erikoisalaTyyppi === ErikoisalaTyyppi.LAAKETIEDE
      ? 10
      : 6
  }

  get hasVanhentuneitaSuorituksia() {
    return (
      this.valmistumispyyntoSuoritustenTila.vanhojaTyoskentelyjaksojaOrSuorituksiaExists ||
      this.valmistumispyyntoSuoritustenTila.kuulusteluVanhentunut
    )
  }

  get opiskelijatunnus() {
    return this.account.erikoistuvaLaakari.opiskelijatunnus ?? ''
  }

  get showValmistumispyyntoForm() {
    return (
      this.valmistumispyynto?.tila === ValmistumispyynnonTila.UUSI ||
      this.valmistumispyynto?.tila ===
        ValmistumispyynnonTila.VASTUUHENKILON_TARKASTUS_PALAUTETTU ||
      this.valmistumispyynto?.tila === ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_PALAUTETTU ||
      this.valmistumispyynto?.tila === ValmistumispyynnonTila.VASTUUHENKILON_HYVAKSYNTA_PALAUTETTU
    )
  }

  validateVaatimuksetState(name: string) {
    const { $dirty, $error } = this.$v.valmistumispyyntoVaatimuksetLomake[name] as any
    return $dirty ? !$error : null
  }

  validateValmistumispyyntoState(name: string) {
    const { $dirty, $error } = this.$v.valmistumispyyntoLomake[name] as any
    return $dirty ? !$error : null
  }

  confirmVaatimukset() {
    this.$v.valmistumispyyntoVaatimuksetLomake.$touch()
    this.vaatimuksetHyvaksytty = !this.$v.$anyError
  }

  onValidateAndConfirmSend(modalId: string) {
    const validations = [
      this.validateForm(),
      this.$refs.laillistamispaiva ? this.$refs.laillistamispaiva.validateForm() : true
    ]
    if (validations.includes(false)) return

    return this.$bvModal.show(modalId)
  }

  validateForm() {
    this.$v.$touch()
    return !this.$v.$anyError
  }

  initLaillistamispaivaAndTodistus() {
    if (this.valmistumispyynto?.erikoistujanLaillistamistodistus) {
      const data = Uint8Array.from(
        atob(this.valmistumispyynto?.erikoistujanLaillistamistodistus),
        (c) => c.charCodeAt(0)
      )
      this.laillistamispaivaAsiakirjat.push(
        mapFile(
          new File([data], this.valmistumispyynto?.erikoistujanLaillistamistodistusNimi || '', {
            type: this.valmistumispyynto?.erikoistujanLaillistamistodistusTyyppi
          })
        )
      )
    }
    if (this.valmistumispyynto?.erikoistujanLaillistamispaiva) {
      this.valmistumispyyntoLomake.laillistamispaiva =
        this.valmistumispyynto.erikoistujanLaillistamispaiva
    }
  }

  async onSend() {
    try {
      this.sending = true
      this.valmistumispyynto = this.valmistumispyyntoPalautettu
        ? (await putValmistumispyynto(this.valmistumispyyntoLomake)).data
        : (await postValmistumispyynto(this.valmistumispyyntoLomake)).data
      const account = store.getters['auth/account']
      account.erikoistuvaLaakari.sahkoposti = this.valmistumispyyntoLomake.erikoistujanSahkoposti
      account.email = this.valmistumispyyntoLomake.erikoistujanSahkoposti
      account.erikoistuvaLaakari.puhelinnumero =
        this.valmistumispyyntoLomake.erikoistujanPuhelinnumero
      account.phoneNumber = this.valmistumispyyntoLomake.erikoistujanPuhelinnumero
      this.$emit('skipRouteExitConfirm', true)
      toastSuccess(this, this.$t('valmistumispyynto-lahetetty-onnistuneesti'))
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('valmistumispyynnon-tallentaminen-epaonnistui')}: ${this.$t(message)}`
          : this.$t('valmistumispyynnon-tallentaminen-epaonnistui')
      )
    }
    this.sending = false
  }

  async onCancel() {
    if (await confirmExit(this)) {
      this.initForm()
      this.$v.valmistumispyyntoVaatimuksetLomake.$reset()
      this.$v.valmistumispyyntoLomake.$reset()
      this.$nextTick(() => {
        this.$emit('skipRouteExitConfirm', true)
      })
    }
  }

  onLaillistamistodistusFilesAdded(files: File[]) {
    this.valmistumispyyntoLomake.laillistamistodistus = files[0]
    this.laillistamispaivaAsiakirjat.push(...mapFiles(files))
  }

  async onDeletelaillistamistodistus() {
    this.valmistumispyyntoLomake.laillistamistodistus = null
    this.laillistamispaivaAsiakirjat = []
    this.$emit('skipRouteExitConfirm', false)
  }

  initForm() {
    this.valmistumispyyntoVaatimuksetLomake.tyoskentelyjaksot = false
    this.valmistumispyyntoVaatimuksetLomake.tyotodistukset = false
    this.valmistumispyyntoVaatimuksetLomake.kuulusteluJaJohtamisopinnot = false
    this.valmistumispyyntoVaatimuksetLomake.teoriakoulutus = false
    this.valmistumispyyntoVaatimuksetLomake.osaamisenArvioinnit = false
    this.valmistumispyyntoLomake.selvitysVanhentuneistaSuorituksista = null
    this.valmistumispyyntoLomake.erikoistujanSahkoposti =
      this.account.erikoistuvaLaakari.sahkoposti
    this.valmistumispyyntoLomake.erikoistujanPuhelinnumero =
      this.account.erikoistuvaLaakari.puhelinnumero
    this.vaatimuksetHyvaksytty = false
    this.initLaillistamispaivaAndTodistus()
  }

  muokkaaLaillistamista() {
    this.laillistaminenMuokattavissa = true
  }
}
