var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"poissaolo"},[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('poissaolo')))]),_c('hr'),(_vm.poissaoloWrapper && _vm.poissaoloWrapper.poissaolonSyy)?_c('div',[_c('elsa-form-group',{attrs:{"label":_vm.$t('poissaolon-syy')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.poissaoloWrapper.poissaolonSyy.nimi))])]}}],null,false,1441002086)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelyjakso')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.poissaoloWrapper.tyoskentelyjakso.label))])]}}],null,false,2505304428)}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"label":_vm.$t('alkamispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.poissaoloWrapper.alkamispaiva ? _vm.$date(_vm.poissaoloWrapper.alkamispaiva) : '')+" ")])]}}],null,false,1605354303)}),(_vm.poissaoloWrapper.paattymispaiva)?_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pl-md-3",attrs:{"label":_vm.$t('paattymispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{staticClass:"datepicker-range",attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.$date(_vm.poissaoloWrapper.paattymispaiva))+" ")])]}}],null,false,634783213)}):_vm._e()],1),_c('elsa-form-group',{attrs:{"label":_vm.$t('poissaolo-nykyisesta-taydesta-tyoajasta') + ' (%)'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.poissaoloWrapper.poissaoloprosentti)+" %")])]}}],null,false,1297254692)}),_c('hr'),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[(_vm.muokkausoikeudet)?_c('elsa-button',{staticClass:"ml-2 mb-3",attrs:{"to":{ name: 'muokkaa-yek-poissaoloa' },"disabled":_vm.poissaoloWrapper.tyoskentelyjakso.liitettyTerveyskeskuskoulutusjaksoon,"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('muokkaa-poissaoloa'))+" ")]):_vm._e(),(_vm.muokkausoikeudet)?_c('elsa-button',{staticClass:"mb-3",attrs:{"loading":_vm.deleting,"disabled":_vm.poissaoloWrapper.tyoskentelyjakso.liitettyTerveyskeskuskoulutusjaksoon,"variant":"outline-danger"},on:{"click":_vm.onPoissaoloDelete}},[_vm._v(" "+_vm._s(_vm.$t('poista-poissaolo'))+" ")]):_vm._e(),_c('elsa-button',{staticClass:"mb-3 mr-auto font-weight-500 tyoskentelyjaksot-link",attrs:{"to":{ name: 'yektyoskentelyjaksot' },"variant":"link"}},[_vm._v(" "+_vm._s(_vm.$t('palaa-tyoskentelyjaksoihin'))+" ")])],1),(
              _vm.poissaoloWrapper.tyoskentelyjakso.liitettyTerveyskeskuskoulutusjaksoon &&
              !_vm.account.impersonated
            )?_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex flex-row"},[_c('em',{staticClass:"align-middle"},[_c('font-awesome-icon',{staticClass:"text-muted mr-1",attrs:{"icon":"info-circle","fixed-width":""}})],1),_c('div',[_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('poissaoloa-ei-voi-muokata-tai-poistaa-tooltip'))+" ")])])])])],1):_vm._e()],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }