



























































































































































































































































import { AxiosError } from 'axios'
import { Component, Mixins } from 'vue-property-decorator'
import { Validation } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'

import {
  getKayttaja,
  getKorvaavatKouluttajat,
  passivateKayttaja,
  putVastuuhenkilo
} from '@/api/kayttajahallinta'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import KayttajahallintaKayttajaMixin from '@/mixins/kayttajahallinta-kayttaja'
import {
  KayttajahallintaUpdateKayttaja,
  ErikoisalaForVastuuhenkilonTehtavat,
  ElsaError,
  KayttajaYliopistoErikoisala,
  VastuuhenkilonTehtava,
  ReassignedVastuuhenkilonTehtava,
  Kayttaja
} from '@/types'
import { confirmExit } from '@/utils/confirm'
import { KayttajatiliTila } from '@/utils/constants'
import { formatList } from '@/utils/kouluttajaAndVastuuhenkiloListFormatter'
import { sortByAsc } from '@/utils/sort'
import { toastFail, toastSuccess } from '@/utils/toast'
import VastuuhenkilonTehtavat from '@/views/kayttajahallinta/vastuuhenkilon-tehtavat.vue'

@Component({
  components: {
    ElsaButton,
    ElsaConfirmationModal,
    ElsaFormError,
    ElsaFormGroup,
    ElsaFormMultiselect,
    VastuuhenkilonTehtavat
  },
  validations: {
    form: {
      sahkoposti: {
        required,
        email
      },
      sahkopostiUudelleen: {
        required,
        email,
        sameAsSahkoposti: sameAs('sahkoposti')
      },
      eppn: {
        required
      }
    },
    reassignedKouluttaja: {
      required
    }
  }
})
export default class VastuuhenkiloView extends Mixins(KayttajahallintaKayttajaMixin) {
  $refs!: {
    vastuuhenkilonTehtavat: any
  }

  items = [
    {
      text: this.$t('kayttajahallinta'),
      to: { name: 'kayttajahallinta' }
    },
    {
      text: this.$t('kayttaja'),
      active: true
    }
  ]

  form: KayttajahallintaUpdateKayttaja = {
    sahkoposti: null,
    sahkopostiUudelleen: null,
    eppn: null,
    yliopistotAndErikoisalat: [],
    reassignedTehtavat: []
  }

  reassignedKouluttaja: Kayttaja | null = null
  kouluttajat: Kayttaja[] = []

  async mounted() {
    await this.fetchKayttaja()
    await this.fetchKouluttajat()
    this.loading = false
  }

  async fetchKayttaja() {
    try {
      this.kayttajaWrapper = (await getKayttaja(this.$route?.params?.kayttajaId)).data
      this.initForm()
    } catch (err) {
      toastFail(this, this.$t('kayttajan-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'kayttajahallinta' })
    }
  }

  async fetchKouluttajat() {
    try {
      this.kouluttajat = (await getKorvaavatKouluttajat(this.$route?.params?.kayttajaId)).data
        .filter((k) => k.id !== this.kayttajaWrapper?.kayttaja?.id)
        .sort((a, b) => sortByAsc(a.sukunimi, b.sukunimi))
    } catch (err) {
      toastFail(this, this.$t('kayttajan-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'kayttajahallinta' })

      this.loading = false
    }
  }

  initForm() {
    const sahkoposti = this.sahkoposti
    this.form.sahkoposti = sahkoposti
    this.form.sahkopostiUudelleen = sahkoposti
    this.form.eppn = this.eppn
    this.form.yliopistotAndErikoisalat = []
    this.form.reassignedTehtavat = []
  }

  onEditUser() {
    this.editing = true
    this.$refs.vastuuhenkilonTehtavat.initForm()
  }

  async onCancel() {
    if (this.skipRouteExitConfirm || (await confirmExit(this))) {
      this.initForm()
      this.$v.form.$reset()
      this.$refs.vastuuhenkilonTehtavat.initForm()
      this.skipRouteExitConfirm = true
      this.editing = false
      this.$emit('skipRouteExitConfirm', true)
    }
  }

  async onSave() {
    const vastuuhenkilonTehtavatForm = this.$refs.vastuuhenkilonTehtavat.getFormIfValid()
    if (
      !this.kayttajaWrapper?.kayttaja?.userId ||
      !this.validateForm() ||
      !vastuuhenkilonTehtavatForm
    ) {
      return
    }
    this.updatingKayttaja = true
    this.form.yliopistotAndErikoisalat = vastuuhenkilonTehtavatForm.yliopistotAndErikoisalat.map(
      (ye: KayttajaYliopistoErikoisala) => {
        return {
          ...ye,
          vastuuhenkilonTehtavat: ye.vastuuhenkilonTehtavat.filter(
            (vt: VastuuhenkilonTehtava | boolean) => vt !== false
          )
        }
      }
    )
    this.form.reassignedTehtavat = vastuuhenkilonTehtavatForm.erikoisalatForTehtavat
      .map((e: ErikoisalaForVastuuhenkilonTehtavat) => e.reassignedTehtavat)
      .flat()
      .filter((r: ReassignedVastuuhenkilonTehtava) => r !== undefined)

    try {
      if (!this.kayttajaWrapper.kayttaja?.id) return
      this.kayttajaWrapper = (
        await putVastuuhenkilo(this.kayttajaWrapper.kayttaja?.id, {
          ...this.form,
          yliopistotAndErikoisalat: this.form.yliopistotAndErikoisalat,
          reassignedTehtavat: this.form.reassignedTehtavat
        })
      ).data

      toastSuccess(this, this.$t('kayttajan-tiedot-paivitetty'))
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('tietojen-tallennus-epaonnistui')}: ${this.$t(message)}`
          : this.$t('tietojen-tallennus-epaonnistui')
      )
    }

    this.editing = false
    this.updatingKayttaja = false
    this.skipRouteExitConfirm = true
    this.$emit('skipRouteExitConfirm', true)
  }

  showPassivateConfirm() {
    this.$bvModal.show('confirm-dialog')
  }

  async onPassivateKayttaja() {
    this.$v.reassignedKouluttaja.$touch()
    if (
      (this.kayttajaWrapper?.avoimiaTehtavia && this.reassignedKouluttaja == null) ||
      !this.kayttajaWrapper?.kayttaja?.id
    ) {
      return
    }
    this.$bvModal.hide('confirm-dialog')
    this.updatingTila = true
    try {
      await passivateKayttaja(this.kayttajaWrapper.kayttaja.id, this.reassignedKouluttaja?.id)
      this.kayttajaWrapper.kayttaja.tila = KayttajatiliTila.PASSIIVINEN
      this.kayttajaWrapper.avoimiaTehtavia = false
      toastSuccess(this, this.$t('kayttajan-passivointi-onnistui'))
    } catch (err) {
      toastFail(this, this.$t('kayttajan-passivointi-epaonnistui'))
    }
    this.updatingTila = false
    this.$emit('skipRouteExitConfirm', true)
  }

  onCancelConfirm() {
    this.$emit('skipRouteExitConfirm', true)
  }

  get formattedKouluttajat() {
    return formatList(this, this.kouluttajat)
  }

  validateConfirm() {
    const { $dirty, $error } = this.$v.reassignedKouluttaja as Validation
    return $dirty ? ($error ? false : null) : null
  }

  onSkipRouteExitConfirm(value: boolean) {
    this.skipRouteExitConfirm = value
  }

  get hasVastuualueita() {
    return (
      (
        this.kayttajaWrapper?.kayttaja?.yliopistotAndErikoisalat.filter(
          (ye) => ye.vastuuhenkilonTehtavat.length > 0
        ) || []
      ).length > 0
    )
  }
}
