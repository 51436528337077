import { render, staticRenderFns } from "./haka-yliopisto-form.vue?vue&type=template&id=e09639ea&"
import script from "./haka-yliopisto-form.vue?vue&type=script&lang=ts&"
export * from "./haka-yliopisto-form.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports