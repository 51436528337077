


























import { Component, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import MobileNav from '@/components/mobile-nav/mobile-nav.vue'
import NavbarNotLoggedIn from '@/components/navbar/navbar-not-logged-in.vue'
import Navbar from '@/components/navbar/navbar.vue'
import SidebarMenu from '@/components/sidebar-menu/sidebar-menu.vue'
import store from '@/store'
import PageNotFoundContent from '@/views/404/page-not-found-content.vue'

@Component({
  components: {
    ElsaButton,
    Navbar,
    NavbarNotLoggedIn,
    SidebarMenu,
    MobileNav,
    PageNotFoundContent
  }
})
export default class PageNotFound extends Vue {
  loading = true

  get isLoggedIn() {
    return store.getters['auth/isLoggedIn']
  }

  changeLocale(lang: string) {
    this.$i18n.locale = lang
  }

  get currentLocale() {
    return this.$i18n.locale
  }

  get locales() {
    return Object.keys(this.$i18n.messages)
  }

  async mounted() {
    await store.dispatch('auth/authorize')
    this.loading = false
  }
}
