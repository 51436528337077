





























































































































import axios from 'axios'
import Component from 'vue-class-component'
import { Mixins, Prop, Watch } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import { Palaute } from '@/types'

@Component({
  components: {
    ElsaFormError,
    ElsaFormGroup,
    ElsaButton
  },
  validations: {
    form: {
      palautteenAihe: {
        required
      },
      palaute: {
        required
      },
      anonyymiPalaute: {
        required
      }
    }
  }
})
export default class PalauteFormModal extends Mixins(validationMixin) {
  @Prop({ required: true, type: Boolean, default: false })
  show!: boolean

  saving = false
  showFormSent = false
  showError = false

  form: Palaute = {
    palautteenAihe: null,
    palaute: null,
    anonyymiPalaute: false
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  @Watch('show')
  onPropertyChanged(value: boolean) {
    if (value) {
      this.$bvModal.show('palauteFormModal')
    } else {
      this.$bvModal.hide('palauteFormModal')
    }
  }

  hideAndReset() {
    this.$emit('hide')
    this.$v.form.$reset()
    this.form = {
      palautteenAihe: null,
      palaute: null,
      anonyymiPalaute: false
    }
    this.showFormSent = false
    this.showError = false
  }

  async onSubmit() {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) {
      return
    }

    this.saving = true
    try {
      await axios.post('/palaute', this.form)
      this.showFormSent = true
    } catch {
      this.showError = true
    }
    this.saving = false
  }
}
