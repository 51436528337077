









































import { Component, Vue } from 'vue-property-decorator'

import { getOpinoopas } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import OpintoopasForm from '@/forms/opintoopas-form.vue'
import YekOpintoopasForm from '@/forms/yek-opintoopas-form.vue'
import { Opintoopas } from '@/types'
import { ERIKOISALA_YEK_ID } from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    OpintoopasForm,
    YekOpintoopasForm
  }
})
export default class OpintoopasView extends Vue {
  opas: Opintoopas | null = null

  loading = true

  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('opetussuunnitelmat'),
        to: { name: 'opetussuunnitelmat' }
      },
      {
        text: this.opas?.erikoisala.nimi,
        to: { name: 'erikoisala' }
      },
      {
        text: this.$t('opintoopas'),
        active: true
      }
    ]
  }

  async mounted() {
    await this.fetchOpas()
    this.loading = false
  }

  async fetchOpas() {
    try {
      this.opas = (await getOpinoopas(this.$route?.params?.opintoopasId)).data
    } catch (err) {
      toastFail(this, this.$t('opintooppaan-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'opetussuunnitelmat' })
    }
  }

  get onkoYEK() {
    return this.$route.params.erikoisalaId == ERIKOISALA_YEK_ID.toString()
  }
}
