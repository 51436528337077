
















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class ElsaAccordian extends Vue {
  @Prop({ required: false, default: true, type: Boolean })
  visible!: boolean

  @Prop({ required: false, default: false, type: Boolean })
  alwaysOpen!: boolean

  @Prop({ required: false, type: String })
  accordion?: string

  @Prop({ required: false })
  icon?: string | string[]

  @Prop({ required: false })
  title?: string

  toggle() {
    this.visible = this.alwaysOpen ? true : !this.visible
  }

  open() {
    this.visible = true
  }
}
