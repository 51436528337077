























































































































































































































































































































































































































































































































































































import { intervalToDuration, formatDuration, isBefore, isAfter } from 'date-fns'
import { fi, sv, enUS } from 'date-fns/locale'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'

import { getVastuuhenkilonArvio, putVastuuhenkilonArvio } from '@/api/virkailija'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaReturnToSenderModal from '@/components/modal/return-to-sender-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPoissaolotDisplay from '@/components/poissaolot-display/poissaolot-display.vue'
import {
  KoejaksonVaiheAllekirjoitus,
  KoejaksonVaiheButtonStates,
  VastuuhenkilonArvioLomake
} from '@/types'
import { KaytannonKoulutusTyyppi, LomakeTilat, TyoskentelyjaksoTyyppi } from '@/utils/constants'
import { daysBetweenDates } from '@/utils/date'
import { checkCurrentRouteAndRedirect } from '@/utils/functions'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'
import { toastFail, toastSuccess } from '@/utils/toast'
import {
  tyoskentelypaikkaTyyppiLabel,
  tyoskentelyjaksoKaytannonKoulutusLabel
} from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    ErikoistuvaDetails,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaButton,
    ElsaPoissaolotDisplay,
    ElsaConfirmationModal,
    ElsaReturnToSenderModal,
    KoejaksonVaiheAllekirjoitukset,
    AsiakirjatContent
  }
})
export default class VirkailijanTarkistus extends Mixins(validationMixin) {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('virkailijan-tarkistus'),
      active: true
    }
  ]

  koejaksoHyvaksyttyVaihtoehdot = [
    {
      text: this.$t('hyvaksytty'),
      value: true
    },
    {
      text: this.$t('hylatty'),
      value: false
    }
  ]

  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }
  vastuuhenkilonArvio: VastuuhenkilonArvioLomake | null = null
  loading = true

  get editable() {
    return this.vastuuhenkilonArvio?.tila === LomakeTilat.ODOTTAA_HYVAKSYNTAA
  }

  get waitingForVastuuhenkilo() {
    return this.vastuuhenkilonArvio?.tila === LomakeTilat.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
  }

  get returned() {
    return this.vastuuhenkilonArvio?.tila === LomakeTilat.PALAUTETTU_KORJATTAVAKSI
  }

  get waitingForSignatures() {
    return this.vastuuhenkilonArvio?.tila === LomakeTilat.ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA
  }

  get acceptedByEveryone() {
    return this.vastuuhenkilonArvio?.tila === LomakeTilat.ALLEKIRJOITETTU
  }

  get vastuuhenkilonArvioId() {
    return Number(this.$route.params.id)
  }

  get erikoistuvanAvatar() {
    return this.vastuuhenkilonArvio?.erikoistuvanAvatar
  }

  get erikoistuvanNimi() {
    return this.vastuuhenkilonArvio?.erikoistuvanNimi
  }

  get allekirjoitukset(): KoejaksonVaiheAllekirjoitus[] {
    if (this.vastuuhenkilonArvio?.erikoistuvanKuittausaika) {
      const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
        this,
        this.vastuuhenkilonArvio?.erikoistuvanNimi,
        this.vastuuhenkilonArvio?.erikoistuvanKuittausaika
      )
      const allekirjoitusVastuuhenkilo = allekirjoituksetHelper.mapAllekirjoitusVastuuhenkilo(
        this.vastuuhenkilonArvio?.vastuuhenkilo ?? null
      ) as KoejaksonVaiheAllekirjoitus

      return [allekirjoitusVastuuhenkilo, allekirjoitusErikoistuva].filter(
        (a): a is KoejaksonVaiheAllekirjoitus => a !== null
      )
    }
    return []
  }

  get asiakirjaDataEndpointUrlTyoskentelyjakso() {
    return '/virkailija/koejakso/tyoskentelyjakso-liite'
  }

  get asiakirjaDataEndpointUrl() {
    return `/virkailija/koejakso/vastuuhenkilon-arvio/${this.vastuuhenkilonArvio?.id}/liite`
  }

  onValidateAndConfirm(modalId: string) {
    return this.$bvModal.show(modalId)
  }

  displayTyoskentelypaikkaTyyppiLabel(muu: string | null, tyyppi: TyoskentelyjaksoTyyppi) {
    return muu ? muu : tyoskentelypaikkaTyyppiLabel(this, tyyppi)
  }

  displayKaytannonKoulutus(value: KaytannonKoulutusTyyppi) {
    return tyoskentelyjaksoKaytannonKoulutusLabel(this, value)
  }

  yhdistaPoissaolot(data: VastuuhenkilonArvioLomake) {
    let obj = data

    obj.koejaksonSuorituspaikat?.tyoskentelyjaksot.forEach((tj) => {
      tj.poissaolot = obj.koejaksonSuorituspaikat?.keskeytykset?.filter(
        (keskeytys) => keskeytys.tyoskentelyjaksoId == tj.id
      )
    })
    return obj
  }

  koejaksonKesto() {
    const koejaksonAlkamispaiva =
      this.vastuuhenkilonArvio?.aloituskeskustelu?.koejaksonAlkamispaiva
    const koejaksonLoppumispaiva = this.vastuuhenkilonArvio?.loppukeskustelu
      ?.koejaksonPaattymispaiva
      ? this.vastuuhenkilonArvio?.loppukeskustelu?.koejaksonPaattymispaiva
      : this.vastuuhenkilonArvio?.aloituskeskustelu?.koejaksonPaattymispaiva

    if (!koejaksonAlkamispaiva || !koejaksonLoppumispaiva) {
      return null
    }

    const koejaksoAlku = new Date(koejaksonAlkamispaiva)
    const koejaksoLoppu = new Date(koejaksonLoppumispaiva)
    const keskeytykset = this.vastuuhenkilonArvio?.koejaksonSuorituspaikat?.keskeytykset
    const tyoskentelyjaksot = this.vastuuhenkilonArvio?.koejaksonSuorituspaikat?.tyoskentelyjaksot
    let keskeytyksetDays = 0
    let tyojaksoDays = 0
    for (const keskeytys of keskeytykset !== undefined ? keskeytykset : []) {
      if (
        isBefore(new Date(keskeytys.alkamispaiva), koejaksoLoppu) &&
        isAfter(new Date(keskeytys.paattymispaiva), koejaksoAlku)
      ) {
        keskeytyksetDays += daysBetweenDates(
          new Date(keskeytys.alkamispaiva) < koejaksoAlku
            ? koejaksoAlku
            : new Date(keskeytys.alkamispaiva),
          new Date(keskeytys.paattymispaiva) > koejaksoLoppu
            ? koejaksoLoppu
            : new Date(keskeytys.paattymispaiva)
        )
      }
    }

    const tyoskentelyjaksojenOsaAjat = []
    for (const tyoskentelyjakso of tyoskentelyjaksot !== undefined ? tyoskentelyjaksot : []) {
      const osaaika =
        (tyoskentelyjakso.osaaikaprosentti != null ? tyoskentelyjakso.osaaikaprosentti : 100) /
        100
      const alku = new Date(tyoskentelyjakso.alkamispaiva)
      const loppu = new Date(
        tyoskentelyjakso.paattymispaiva != null ? tyoskentelyjakso.paattymispaiva : new Date()
      )
      if (isBefore(alku, koejaksoLoppu) && isAfter(loppu, koejaksoAlku)) {
        tyoskentelyjaksojenOsaAjat.push(osaaika)
      }
    }

    const osaAikaProsentti =
      tyoskentelyjaksojenOsaAjat.length > 0
        ? tyoskentelyjaksojenOsaAjat.reduce((pv, cv) => pv + cv, 0) /
          tyoskentelyjaksojenOsaAjat.length
        : 1.0
    tyojaksoDays = daysBetweenDates(koejaksoAlku, koejaksoLoppu) * osaAikaProsentti

    return formatDuration(
      intervalToDuration({
        start: new Date(),
        end: new Date().setDate(new Date().getDate() + (tyojaksoDays - keskeytyksetDays))
      }),
      {
        format: ['years', 'months', 'days'],
        locale: this.getLocaleObj()
      }
    )
  }

  getLocaleObj() {
    switch (this.currentLocale) {
      case 'fi':
        return fi
      case 'sv':
        return sv
      case 'en':
        return enUS
    }
  }

  get currentLocale() {
    if (this.$i18n) {
      return this.$i18n.locale
    } else {
      return 'fi'
    }
  }

  async onSend() {
    try {
      this.buttonStates.primaryButtonLoading = true
      if (this.vastuuhenkilonArvio != null) {
        this.vastuuhenkilonArvio.virkailijanKorjausehdotus = null
        await putVastuuhenkilonArvio(this.vastuuhenkilonArvio)
        this.buttonStates.primaryButtonLoading = false
        checkCurrentRouteAndRedirect(this.$router, '/koejakso')
        toastSuccess(this, this.$t('virkailijan-tarkistus-lahetetty-onnistuneesti'))
      }
    } catch {
      toastFail(this, this.$t('virkailijan-tarkistus-lahetys-epaonnistui'))
    }
  }

  async onReturnToSender(korjausehdotus: string) {
    const form: VastuuhenkilonArvioLomake = {
      ...(this.vastuuhenkilonArvio as VastuuhenkilonArvioLomake),
      virkailijanKorjausehdotus: korjausehdotus
    }

    try {
      this.buttonStates.secondaryButtonLoading = true
      await putVastuuhenkilonArvio(form)
      this.buttonStates.primaryButtonLoading = false
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('koejakso-palautettu-muokattavaksi'))
    } catch {
      toastFail(this, this.$t('koejakso-palautus-epaonnistui'))
    }
  }

  async mounted() {
    this.loading = true

    try {
      const { data } = await getVastuuhenkilonArvio(this.vastuuhenkilonArvioId)
      this.vastuuhenkilonArvio = data
      this.vastuuhenkilonArvio = this.yhdistaPoissaolot(this.vastuuhenkilonArvio)
      this.loading = false
    } catch {
      toastFail(this, this.$t('vastuuhenkilon-arvion-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'koejakso' })
    }
  }

  get korjausehdotus() {
    return this.vastuuhenkilonArvio?.virkailijanKorjausehdotus != null
      ? this.vastuuhenkilonArvio?.virkailijanKorjausehdotus
      : this.vastuuhenkilonArvio?.vastuuhenkilonKorjausehdotus
  }
}
