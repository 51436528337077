
















import { Component, Vue } from 'vue-property-decorator'

import ValmistumispyynnotVirkailijaYek from '../../valmistumispyynnot-yek/virkailija/valmistumispyynnot-virkailija-yek.vue'

import ValmistumispyynnotVirkailijaErikoistuvaLaakari from './valmistumispyynnot-virkailija-erikoistuva-laakari.vue'

import { getErikoisalat } from '@/api/virkailija'
import { Erikoisala } from '@/types'

@Component({
  components: {
    ValmistumispyynnotVirkailijaErikoistuvaLaakari,
    ValmistumispyynnotVirkailijaYek
  }
})
export default class ValmistumispyynnotVirkailija extends Vue {
  initializing = true
  erikoisalat: Erikoisala[] | null = null

  tabIndex = 0
  tabs = ['#erikoislaakarikoulutus', '#yek']

  beforeMount() {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  }

  async mounted() {
    this.erikoisalat = this.$isVirkailija() ? (await getErikoisalat()).data : []
    this.initializing = false
  }
}
