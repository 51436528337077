


















import { Component, Vue, Prop } from 'vue-property-decorator'

import ElsaAccordian from '@/components/accordian/accordian.vue'
import { ArvioitavaKokonaisuus } from '@/types'

@Component({
  components: {
    ElsaAccordian
  }
})
export default class ArvioitavatKokonaisuudetLista extends Vue {
  @Prop({ required: true })
  arvioitavatKokonaisuudet!: ArvioitavaKokonaisuus[]

  @Prop({ required: true, type: String })
  locale!: string
}
