






































































































import axios, { AxiosError } from 'axios'
import { Vue, Component } from 'vue-property-decorator'

import ElsaBadge from '@/components/badge/badge.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import store from '@/store'
import { Poissaolo, ElsaError } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { ELSA_ROLE } from '@/utils/roles'
import { toastFail, toastSuccess } from '@/utils/toast'
import { tyoskentelyjaksoLabel } from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    ElsaFormGroup,
    ElsaPopover,
    ElsaBadge,
    ElsaButton
  }
})
export default class PoissaoloView extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('tyoskentelyjaksot'),
      to: { name: 'tyoskentelyjaksot' }
    },
    {
      text: this.$t('poissaolo'),
      active: true
    }
  ]
  poissaolo: null | Poissaolo = null
  deleting = false

  async mounted() {
    const poissaoloId = this.$route?.params?.poissaoloId
    if (poissaoloId) {
      try {
        this.poissaolo = (
          await axios.get(`erikoistuva-laakari/tyoskentelyjaksot/poissaolot/${poissaoloId}`)
        ).data
      } catch {
        toastFail(this, this.$t('poissaolon-hakeminen-epaonnistui'))
        this.$router.replace({ name: 'tyoskentelyjaksot' })
      }
    }
  }

  get account() {
    return store.getters['auth/account']
  }

  async onPoissaoloDelete() {
    if (
      await confirmDelete(
        this,
        this.$t('poista-poissaolo') as string,
        (this.$t('poissaolon') as string).toLowerCase()
      )
    ) {
      this.deleting = true
      try {
        await axios.delete(
          `erikoistuva-laakari/tyoskentelyjaksot/poissaolot/${this.poissaolo?.id}`
        )
        toastSuccess(this, this.$t('poissaolo-poistettu-onnistuneesti'))
        this.$router.push({
          name: 'tyoskentelyjaksot'
        })
      } catch (err) {
        const axiosError = err as AxiosError<ElsaError>
        const message = axiosError?.response?.data?.message
        toastFail(
          this,
          message
            ? `${this.$t('poissaolon-poistaminen-epaonnistui')}: ${this.$t(message)}`
            : this.$t('poissaolon-poistaminen-epaonnistui')
        )
      }
      this.deleting = false
    }
  }

  get poissaoloWrapper() {
    if (this.poissaolo) {
      return {
        ...this.poissaolo,
        tyoskentelyjakso: {
          ...this.poissaolo.tyoskentelyjakso,
          label: tyoskentelyjaksoLabel(this, this.poissaolo.tyoskentelyjakso)
        }
      }
    } else {
      return undefined
    }
  }

  get muokkausoikeudet() {
    if (!this.account.impersonated) {
      return true
    }

    if (
      this.account.originalUser.authorities.includes(ELSA_ROLE.OpintohallinnonVirkailija) &&
      this.account.erikoistuvaLaakari.muokkausoikeudetVirkailijoilla
    ) {
      return true
    }

    return false
  }
}
