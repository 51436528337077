var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[(_vm.editing)?_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('elsa-form-group',{staticClass:"col-12 pr-md-3 pl-0",attrs:{"label":_vm.$t('kategorian-nimi'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-input-group',{staticClass:"mb-2"},[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"input-group-fi"},[_vm._v(_vm._s('FI'))])],1),_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('nimi')},model:{value:(_vm.kategoria.nimi),callback:function ($$v) {_vm.$set(_vm.kategoria, "nimi", $$v)},expression:"kategoria.nimi"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('nimi')}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-input-group',{attrs:{"prepend":"SV"}},[_c('b-form-input',{attrs:{"id":uid},model:{value:(_vm.kategoria.nimiSv),callback:function ($$v) {_vm.$set(_vm.kategoria, "nimiSv", $$v)},expression:"kategoria.nimiSv"}})],1)]}}],null,false,854570554)}),_c('elsa-form-group',{staticClass:"col-12 pr-md-3 pl-0",attrs:{"label":_vm.$t('jarjestysnumero')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid},model:{value:(_vm.kategoria.jarjestysnumero),callback:function ($$v) {_vm.$set(_vm.kategoria, "jarjestysnumero", $$v)},expression:"kategoria.jarjestysnumero"}})]}}],null,false,1995833280)}),_c('hr',{staticClass:"mt-6"}),_c('div',{staticClass:"text-right"},[_c('elsa-button',{attrs:{"variant":"back","to":{
              name: _vm.kategoria.id == null ? 'erikoisala' : 'suoritteen-kategoria',
              hash: _vm.kategoria.id == null ? '#suoritteet' : ''
            }}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")]),_c('elsa-button',{staticClass:"ml-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tallenna'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1):_c('div',[_c('h5',[_vm._v(_vm._s(_vm.$t('kategorian-nimi')))]),_c('p',[_vm._v(_vm._s(_vm.kategoria.nimi))]),_c('h5',[_vm._v(_vm._s(_vm.$t('jarjestysnumero')))]),_c('p',[_vm._v(_vm._s(_vm.kategoria.jarjestysnumero != null ? _vm.kategoria.jarjestysnumero : '-'))]),_c('hr')])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }