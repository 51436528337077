

































import { Component, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import store from '@/store'

@Component({
  components: {
    ElsaButton
  }
})
export default class TeoriakoulutusTallennettu extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('teoriakoulutukset'),
      to: { name: 'teoriakoulutukset' }
    },
    {
      text: this.$t('lisaa-teoriakoulutus'),
      active: true
    }
  ]

  get teoriakoulutusId() {
    return this.$route.params.teoriakoulutusId
  }

  get account() {
    return store.getters['auth/account']
  }
}
