


















































































import { Component, Vue } from 'vue-property-decorator'

import { getAvoimetAsiat } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import { AvoinAsia } from '@/types'
import { AvoinAsiaTyyppi } from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton
  }
})
export default class AvoimetAsiatCard extends Vue {
  avoimetAsiat: AvoinAsia[] | null = null
  loading = true

  async mounted() {
    try {
      this.avoimetAsiat = (await getAvoimetAsiat()).data
    } catch (err) {
      toastFail(this, this.$t('avoimien-asioiden-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  get fields() {
    return [
      {
        key: 'asia',
        label: this.$t('asia'),
        sortable: true
      },
      {
        key: 'pvm',
        label: this.$t('pvm'),
        sortable: true
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        class: 'actions'
      }
    ]
  }

  getComponentName(tyyppi: AvoinAsiaTyyppi) {
    switch (tyyppi) {
      case AvoinAsiaTyyppi.KOULUTUSSOPIMUS:
        return 'koulutussopimus-erikoistuva'
      case AvoinAsiaTyyppi.ALOITUSKESKUSTELU:
        return 'koejakson-aloituskeskustelu'
      case AvoinAsiaTyyppi.VASTUUHENKILON_ARVIO:
        return 'koejakson-vastuuhenkilon-arvio'
      case AvoinAsiaTyyppi.VALMISTUMISPYYNTO:
        return 'valmistumispyynto'
      case AvoinAsiaTyyppi.KOULUTTAJAVALTUUTUS:
        return 'profiili'
      default:
        return null
    }
  }

  getComponentPath(tyyppi: AvoinAsiaTyyppi, id: number) {
    switch (tyyppi) {
      case AvoinAsiaTyyppi.SEURANTAJAKSO:
        return `/seurantakeskustelut/seurantajakso/${id}`
      case AvoinAsiaTyyppi.TERVEYSKESKUSKOULUTUSJAKSO:
        return '/tyoskentelyjaksot/terveyskeskuskoulutusjakson-hyvaksynta'
      default:
        return ''
    }
  }

  getHash(tyyppi: AvoinAsiaTyyppi) {
    return tyyppi === AvoinAsiaTyyppi.KOULUTTAJAVALTUUTUS ? '#katseluoikeudet' : ''
  }

  get rows() {
    return this.avoimetAsiat?.length ?? 0
  }
}
