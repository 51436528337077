var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('erikoistuja')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('user-avatar',{attrs:{"id":uid,"src-base64":_vm.avatar,"src-content-type":"image/jpeg"}})]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelyjakso'),"add-new-enabled":true,"add-new-label":_vm.$t('lisaa-tyoskentelyjakso'),"required":true},on:{"submit":_vm.onTyoskentelyjaksoSubmit},scopedSlots:_vm._u([{key:"modal-content",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
var skipRouteExitConfirm = ref.skipRouteExitConfirm;
return [_c('tyoskentelyjakso-form',{attrs:{"kunnat":_vm.kunnat,"erikoisalat":_vm.erikoisalat},on:{"submit":submit,"cancel":cancel,"skipRouteExitConfirm":skipRouteExitConfirm}})]}},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.tyoskentelyjaksotFormatted,"state":_vm.validateState('tyoskentelyjakso'),"label":"label","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)},"select":_vm.onTyoskentelyjaksoSelect},model:{value:(_vm.form.tyoskentelyjakso),callback:function ($$v) {_vm.$set(_vm.form, "tyoskentelyjakso", $$v)},expression:"form.tyoskentelyjakso"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('arvioitavat-kokonaisuudet'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.arvioitavanKokonaisuudenKategoriat,"multiple":true,"state":_vm.validateArvioitavatKokonaisuudet(),"group-values":"arvioitavatKokonaisuudet","group-label":"nimi","group-select":false,"label":"nimi","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.$isLabel)?_c('span',[_vm._v(_vm._s(props.option.$groupLabel))]):_c('span',{staticClass:"d-inline-block ml-3"},[_vm._v(_vm._s(props.option.nimi))])]}}],null,true),model:{value:(_vm.arvioitavatKokonaisuudet),callback:function ($$v) {_vm.arvioitavatKokonaisuudet=$$v},expression:"arvioitavatKokonaisuudet"}}),_c('small',{staticClass:"form-text text-muted"},[_vm._v(" "+_vm._s(_vm.$t('valitse-arvioitavat-kokonaisuudet-help'))+" ")]),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-md-8",attrs:{"label":_vm.$t('arvioitava-tapahtuma')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"aria-describedby":(uid + "-feedback")},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.arvioitavaTapahtuma),callback:function ($$v) {_vm.$set(_vm.form, "arvioitavaTapahtuma", $$v)},expression:"form.arvioitavaTapahtuma"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('elsa-form-group',{staticClass:"col-md-4",attrs:{"label":_vm.$t('tapahtuman-ajankohta'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"tapahtumanAjankohta",attrs:{"id":uid,"value":_vm.form.tapahtumanAjankohta,"min":_vm.tyoskentelyjaksonAlkamispaiva,"max":_vm.tyoskentelyjaksonPaattymispaiva,"min-error-text":_vm.$t('tapahtuman-ajankohta-ei-voi-olla-ennen-tyoskentelyjakson-alkamista'),"max-error-text":_vm.$t('tapahtuman-ajankohta-ei-voi-olla-tyoskentelyjakson-paattymisen-jalkeen')},on:{"update:value":function($event){return _vm.$set(_vm.form, "tapahtumanAjankohta", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}}):_vm._e()]}}])})],1),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-md-12",attrs:{"label":_vm.$t('kouluttaja-tai-vastuuhenkilo'),"add-new-enabled":!_vm.editing,"add-new-label":_vm.$t('lisaa-kouluttaja'),"required":true},on:{"submit":_vm.onKouluttajaSubmit},scopedSlots:_vm._u([{key:"modal-content",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
var skipRouteExitConfirm = ref.skipRouteExitConfirm;
return [_c('kouluttaja-form',{attrs:{"kouluttajat":_vm.kouluttajatAndVastuuhenkilot},on:{"submit":submit,"cancel":cancel,"skipRouteExitConfirm":skipRouteExitConfirm}})]}},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.formattedKouluttajatAndVastuuhenkilot,"state":_vm.validateState('arvioinninAntaja'),"disabled":_vm.editing,"label":"nimi","track-by":"nimi"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [(option.nimi != null)?_c('div',[_vm._v(_vm._s(option.nimi))]):_vm._e()]}}],null,true),model:{value:(_vm.form.arvioinninAntaja),callback:function ($$v) {_vm.$set(_vm.form, "arvioinninAntaja", $$v)},expression:"form.arvioinninAntaja"}}),_c('small',{staticClass:"form-text text-muted"},[_vm._v(" "+_vm._s(_vm.$t('valitse-arvioija-help'))+" "),_c('b-link',{attrs:{"to":{ name: 'profiili' }}},[_vm._v(_vm._s(_vm.$t('profiilissasi')))])],1),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])})],1),_c('elsa-form-group',{attrs:{"label":_vm.$t('lisatiedot')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-textarea',{attrs:{"id":uid,"rows":"5"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.lisatiedot),callback:function ($$v) {_vm.$set(_vm.form, "lisatiedot", $$v)},expression:"form.lisatiedot"}})]}}])}),_c('div',{staticClass:"text-right mb-2"},[_c('elsa-button',{attrs:{"variant":"back","to":{ name: 'arvioinnit' }}},[_vm._v(_vm._s(_vm.$t('peruuta')))]),(_vm.editing)?_c('elsa-button',{staticClass:"ml-2",attrs:{"loading":_vm.params.deleting,"variant":"outline-danger"},on:{"click":_vm.onArviointipyyntoDelete}},[_vm._v(" "+_vm._s(_vm.$t('poista-arviointipyynto'))+" ")]):_vm._e(),_c('elsa-button',{staticClass:"ml-2",attrs:{"loading":_vm.params.saving,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.editing ? _vm.$t('tallenna') : _vm.$t('laheta'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }