






































































import { Component, Prop, Vue } from 'vue-property-decorator'

import AsiakirjatContent from '../asiakirjat/asiakirjat-content.vue'
import ElsaPoissaolotDisplay from '../poissaolot-display/poissaolot-display.vue'

import { Tyoskentelyjakso } from '@/types'
import { KaytannonKoulutusTyyppi, TyoskentelyjaksoTyyppi } from '@/utils/constants'
import {
  tyoskentelyjaksoKaytannonKoulutusLabel,
  tyoskentelypaikkaTyyppiLabel
} from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    AsiakirjatContent,
    ElsaPoissaolotDisplay
  }
})
export default class YekTyoskentelyjaksotList extends Vue {
  @Prop({ required: true, type: Array, default: () => [] })
  tyoskentelyjaksot!: Tyoskentelyjakso[]

  @Prop({ required: false, default: '' })
  asiakirjaDataEndpointUrl!: string

  displayTyoskentelypaikkaTyyppiLabel(muu: string | null, tyyppi: TyoskentelyjaksoTyyppi) {
    return muu ? muu : tyoskentelypaikkaTyyppiLabel(this, tyyppi)
  }

  displayKaytannonKoulutus(value: KaytannonKoulutusTyyppi) {
    return tyoskentelyjaksoKaytannonKoulutusLabel(this, value)
  }
}
