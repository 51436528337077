























































import { Component, Vue } from 'vue-property-decorator'

import { postMuokkausoikeudet } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import store from '@/store'
import { toastSuccess, toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton
  }
})
export default class Kayttooikeus extends Vue {
  get account() {
    return store.getters['auth/account']
  }

  async paivitaOikeus(muokkausoikeus: boolean) {
    try {
      await postMuokkausoikeudet(muokkausoikeus)
      toastSuccess(
        this,
        muokkausoikeus
          ? this.$t('muokkausoikeudet-myonnetty')
          : this.$t('muokkausoikeudet-poistettu')
      )
      this.account.erikoistuvaLaakari.muokkausoikeudetVirkailijoilla = muokkausoikeus
    } catch (err) {
      toastFail(this, this.$t('muokkausoikeuksien-paivittaminen-epaonnistui'))
    }
  }

  get muokkausoikeudet() {
    return this.account.erikoistuvaLaakari.muokkausoikeudetVirkailijoilla
  }
}
