















































































































import { Component, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import store from '@/store'
import { AloituskeskusteluLomake, Koejakso, KoejaksonVaiheButtonStates } from '@/types'
import { LomakeTilat } from '@/utils/constants'
import { checkCurrentRouteAndRedirect } from '@/utils/functions'
import { toastFail, toastSuccess } from '@/utils/toast'
import ArviointilomakeAloituskeskusteluForm from '@/views/koejakso/erikoistuva/arviointilomake-aloituskeskustelu/arviointilomake-aloituskeskustelu-form.vue'
import ArviointilomakeAloituskeskusteluReadonly from '@/views/koejakso/erikoistuva/arviointilomake-aloituskeskustelu/arviointilomake-aloituskeskustelu-readonly.vue'

@Component({
  components: {
    ElsaConfirmationModal,
    ElsaButton,
    ErikoistuvaDetails,
    ArviointilomakeAloituskeskusteluForm,
    ArviointilomakeAloituskeskusteluReadonly
  }
})
export default class ErikoistuvaArviointilomakeAloituskeskustelu extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('koejakson-aloituskeskustelu'),
      active: true
    }
  ]
  loading = true
  aloituskeskusteluLomake: null | AloituskeskusteluLomake = null

  get account() {
    return store.getters['auth/account']
  }

  get editable() {
    if (this.account.impersonated) {
      return false
    }
    switch (this.koejaksoData.aloituskeskustelunTila) {
      case LomakeTilat.PALAUTETTU_KORJATTAVAKSI:
        return true
      case LomakeTilat.UUSI:
        return true
      case LomakeTilat.TALLENNETTU_KESKENERAISENA:
        return true
    }
    return false
  }

  get showWaitingForAcceptance() {
    return (
      this.koejaksoData.aloituskeskustelunTila === LomakeTilat.ODOTTAA_HYVAKSYNTAA ||
      this.koejaksoData.aloituskeskustelunTila === LomakeTilat.ODOTTAA_ESIMIEHEN_HYVAKSYNTAA
    )
  }

  get showReturned() {
    return this.koejaksoData.aloituskeskustelunTila === LomakeTilat.PALAUTETTU_KORJATTAVAKSI
  }

  get showAcceptedByEveryone() {
    return this.koejaksoData.aloituskeskustelunTila === LomakeTilat.HYVAKSYTTY
  }

  get kouluttajat() {
    return store.getters['erikoistuva/kouluttajatJaVastuuhenkilot'] || []
  }

  get koejaksoData(): Koejakso {
    return store.getters['erikoistuva/koejakso']
  }

  get korjausehdotus() {
    return this.koejaksoData.aloituskeskustelu?.korjausehdotus
  }

  setKoejaksoData() {
    if (this.koejaksoData.aloituskeskustelu) {
      this.aloituskeskusteluLomake = this.koejaksoData.aloituskeskustelu
    }
    if (!this.editable) {
      this.$emit('skipRouteExitConfirm', true)
    }
  }

  async onSaveDraftAndExit(
    form: AloituskeskusteluLomake,
    buttonStates: KoejaksonVaiheButtonStates
  ) {
    buttonStates.secondaryButtonLoading = true
    this.aloituskeskusteluLomake = form
    try {
      if (this.koejaksoData.aloituskeskustelunTila === LomakeTilat.UUSI) {
        await store.dispatch('erikoistuva/postAloituskeskustelu', this.aloituskeskusteluLomake)
      } else if (
        this.koejaksoData.aloituskeskustelunTila === LomakeTilat.TALLENNETTU_KESKENERAISENA
      ) {
        await store.dispatch('erikoistuva/putAloituskeskustelu', this.aloituskeskusteluLomake)
      }

      toastSuccess(this, this.$t('aloituskeskustelu-tallennettu-onnistuneesti'))
      this.$emit('skipRouteExitConfirm', true)
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
    } catch {
      toastFail(this, this.$t('aloituskeskustelu-tallennus-epaonnistui'))
    }
    buttonStates.secondaryButtonLoading = false
  }

  async saveNewForm() {
    try {
      await store.dispatch('erikoistuva/postAloituskeskustelu', this.aloituskeskusteluLomake)
      toastSuccess(this, this.$t('aloituskeskustelu-lisatty-onnistuneesti'))
      this.$emit('skipRouteExitConfirm', true)
    } catch {
      toastFail(this, this.$t('aloituskeskustelu-lisaaminen-epaonnistui'))
    }
  }

  async updateSentForm() {
    try {
      await store.dispatch('erikoistuva/putAloituskeskustelu', this.aloituskeskusteluLomake)
      toastSuccess(this, this.$t('aloituskeskustelu-lisatty-onnistuneesti'))
      this.$emit('skipRouteExitConfirm', true)
    } catch {
      toastFail(this, this.$t('aloituskeskustelu-lisaaminen-epaonnistui'))
    }
  }

  async onSubmit(form: AloituskeskusteluLomake, buttonStates: KoejaksonVaiheButtonStates) {
    buttonStates.primaryButtonLoading = true
    this.aloituskeskusteluLomake = form
    this.aloituskeskusteluLomake.lahetetty = true

    if (
      this.koejaksoData.aloituskeskustelunTila === LomakeTilat.TALLENNETTU_KESKENERAISENA ||
      this.koejaksoData.aloituskeskustelunTila === LomakeTilat.PALAUTETTU_KORJATTAVAKSI
    ) {
      await this.updateSentForm()
    } else {
      await this.saveNewForm()
    }
    buttonStates.primaryButtonLoading = false
  }

  watch() {
    if (!this.editable) {
      this.$emit('skipRouteExitConfirm', true)
    }
  }

  get deletable() {
    return this.koejaksoData.aloituskeskustelunTila === LomakeTilat.ODOTTAA_HYVAKSYNTAA
  }

  async onFormDelete() {
    try {
      await store.dispatch('erikoistuva/deleteAloituskeskustelu', this.aloituskeskusteluLomake)
      toastSuccess(this, this.$t('lomake-tyhjennetty-onnistuneesti'))
    } catch {
      toastFail(this, this.$t('lomakkeen-tyhjennys-epaonnistui'))
    }
  }

  onValidateAndConfirm(modalId: string) {
    return this.$bvModal.show(modalId)
  }

  async mounted() {
    this.loading = true
    if (!this.koejaksoData) {
      await store.dispatch('erikoistuva/getKoejakso')
    }
    await store.dispatch('erikoistuva/getKouluttajatJaVastuuhenkilot')
    this.setKoejaksoData()
    this.loading = false
    if (!this.editable) {
      this.$emit('skipRouteExitConfirm', true)
    }
  }

  skipRouteExitConfirm(value: boolean) {
    this.$emit('skipRouteExitConfirm', value)
  }
}
