































































































































import { Component, Vue, Prop } from 'vue-property-decorator'

import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaProgressBar from '@/components/progress-bar/progress-bar.vue'
import { Opintosuoritus } from '@/types'

@Component({
  components: {
    ElsaFormGroup,
    ElsaProgressBar
  }
})
export default class OpintosuoritusTab extends Vue {
  @Prop({ required: true, type: Array })
  suoritukset!: Opintosuoritus[]

  @Prop({ required: true, type: String })
  variant!: string

  @Prop({ required: false, type: Number })
  suoritettu!: number

  @Prop({ required: false, type: Number })
  vaadittu!: number

  @Prop({ required: false, type: Boolean, default: false })
  progress!: boolean

  localizeName(o: any) {
    const key = `nimi_${this.$i18n.locale}`
    return o[key]
  }
}
