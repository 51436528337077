




























































































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'

import ArvioinninKategoriat from '@/components/arvioinnin-kategoriat/arvioinnin-kategoriat.vue'
import ArviointipyyntoCard from '@/components/arviointipyynto-card/arviointipyynto-card.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import store from '@/store'
import {
  Suoritusarviointi,
  SuoritusarviointiFilter,
  SuoritusarvioinnitOptions,
  ArvioitavaKokonaisuus,
  ArvioitavanKokonaisuudenKategoria,
  Tyoskentelyjakso,
  Kayttaja,
  SuoritusarvioinninArvioitavaKokonaisuus
} from '@/types'
import { sortByDateDesc } from '@/utils/date'
import { formatList } from '@/utils/kouluttajaAndVastuuhenkiloListFormatter'
import { sortByAsc } from '@/utils/sort'
import { tyoskentelyjaksoLabel } from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    ArvioinninKategoriat,
    ArviointipyyntoCard,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaButton
  }
})
export default class ArvioinnitErikoistuva extends Vue {
  selected = {
    tyoskentelyjakso: null,
    arvioitavaKokonaisuus: null,
    kouluttajaOrVastuuhenkilo: null
  } as SuoritusarviointiFilter
  suoritusArvioinnitOptions = {
    tyoskentelyjaksot: [],
    arvioitavatKokonaisuudet: [],
    tapahtumat: [],
    kouluttajatAndVastuuhenkilot: []
  } as SuoritusarvioinnitOptions
  arvioinnit: null | Suoritusarviointi[] = null
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('arvioinnit'),
      active: true
    }
  ]
  kategoriat: null | ArvioitavanKokonaisuudenKategoria[] = null
  aiemmatKategoriat: null | ArvioitavanKokonaisuudenKategoria[] = null

  async mounted() {
    await this.fetchOptions()
    await this.fetch()
  }

  get account() {
    return store.getters['auth/account']
  }

  onTabChange(tabIndex: number) {
    this.selected = {
      tyoskentelyjakso: null,
      arvioitavaKokonaisuus: null,
      kouluttajaOrVastuuhenkilo: null
    }
    this.arvioinnit = null
    if (tabIndex === 0) {
      this.fetch()
    } else if (tabIndex === 1) {
      // Hetaan arviointipyynnöt pelkästään
      this.fetch({
        'arviointiAika.specified': false
      })
    }
  }

  async onTyoskentelyjaksoSelect(selected: Tyoskentelyjakso) {
    this.selected.tyoskentelyjakso = selected
    await this.fetch()
  }

  async onTyoskentelyjaksoReset() {
    this.selected.tyoskentelyjakso = null
    await this.fetch()
  }

  async onArvioitavaKokonaisuusSelect(selected: ArvioitavaKokonaisuus) {
    this.selected.arvioitavaKokonaisuus = selected
    await this.fetch()
  }

  async onArvioitavaKokonaisuusReset() {
    this.selected.arvioitavaKokonaisuus = null
    await this.fetch()
  }

  async onKouluttajaOrVastuuhenkiloSelect(selected: Kayttaja) {
    this.selected.kouluttajaOrVastuuhenkilo = selected
    await this.fetch()
  }

  async onKouluttajaOrVastuuhenkiloReset() {
    this.selected.kouluttajaOrVastuuhenkilo = null
    await this.fetch()
  }

  async resetFilters() {
    this.selected = {
      tyoskentelyjakso: null,
      arvioitavaKokonaisuus: null,
      kouluttajaOrVastuuhenkilo: null
    }
    await this.fetch()
  }

  async fetchOptions() {
    this.suoritusArvioinnitOptions = (
      await axios.get('erikoistuva-laakari/suoritusarvioinnit-rajaimet')
    ).data
    if (this.suoritusArvioinnitOptions) {
      this.suoritusArvioinnitOptions.kouluttajatAndVastuuhenkilot = formatList(
        this,
        this.suoritusArvioinnitOptions?.kouluttajatAndVastuuhenkilot
      )
    }
  }

  async fetch(options: any = {}) {
    try {
      this.arvioinnit = (
        await axios.get('erikoistuva-laakari/suoritusarvioinnit', {
          params: {
            ...options,
            'tyoskentelyjaksoId.equals': this.selected.tyoskentelyjakso?.id,
            arvioitavaKokonaisuusId: this.selected.arvioitavaKokonaisuus?.id,
            'arvioinninAntajaId.equals': this.selected.kouluttajaOrVastuuhenkilo?.id
          }
        })
      ).data?.sort((s1: Suoritusarviointi, s2: Suoritusarviointi) => {
        return sortByDateDesc(s1?.tapahtumanAjankohta, s2?.tapahtumanAjankohta)
      })
      this.kategoriat = this.solveKategoriat()
    } catch {
      this.arvioinnit = []
    }
  }

  solveKategoriat() {
    // Muodostetaan arvioitavat kokonaisuudet -lista
    const arvioitavatKokonaisuudetArray = (
      this.selected.tyoskentelyjakso || this.selected.kouluttajaOrVastuuhenkilo
        ? this.arvioinnit?.flatMap((arviointi: Suoritusarviointi) =>
            arviointi.arvioitavatKokonaisuudet.map(
              (kokonaisuus: SuoritusarvioinninArvioitavaKokonaisuus) =>
                kokonaisuus.arvioitavaKokonaisuus
            )
          )
        : this.suoritusArvioinnitOptions?.arvioitavatKokonaisuudet.filter(
            (a: ArvioitavaKokonaisuus) =>
              this.selected.arvioitavaKokonaisuus
                ? a.id === this.selected.arvioitavaKokonaisuus?.id
                : true
          )
    )
      ?.filter((x) => x != null)
      .map((a: ArvioitavaKokonaisuus | null) => ({
        ...a,
        arvioinnit: [],
        visible: false
      })) as ArvioitavaKokonaisuus[]
    const arvioitavatKokonaisuudetMap = [
      ...new Map(
        arvioitavatKokonaisuudetArray.map((a: ArvioitavaKokonaisuus) => [a['id'], a])
      ).values()
    ]

    // Muodostetaan kategoriat lista
    let kategoriat: ArvioitavanKokonaisuudenKategoria[] = []
    arvioitavatKokonaisuudetMap?.forEach((a: ArvioitavaKokonaisuus) => {
      kategoriat.push({
        ...a.kategoria,
        arvioitavatKokonaisuudet: [],
        visible: false
      })
    })
    kategoriat = [
      ...new Map(
        kategoriat.map((kategoria: ArvioitavanKokonaisuudenKategoria) => [
          kategoria['id'],
          kategoria
        ])
      ).values()
    ]

    const aiemmatKokonaisuudet: ArvioitavaKokonaisuus[] = []

    // Liitetään arvioinnit arvioitaviin kokonaisuuksiin
    if (this.arvioinnit) {
      this.arvioinnit.forEach((arviointi) => {
        arviointi.arvioitavatKokonaisuudet.forEach((kokonaisuus) => {
          const arvioitavaKokonaisuus = arvioitavatKokonaisuudetMap?.find(
            (a: ArvioitavaKokonaisuus | null) => a?.id === kokonaisuus.arvioitavaKokonaisuusId
          )
          if (arvioitavaKokonaisuus) {
            arvioitavaKokonaisuus.arvioinnit.push({
              ...arviointi,
              arviointiasteikonTaso: kokonaisuus.arviointiasteikonTaso as number,
              itsearviointiArviointiasteikonTaso:
                kokonaisuus.itsearviointiArviointiasteikonTaso as number,
              arvioitavaKokonaisuusId: kokonaisuus.arvioitavaKokonaisuusId
            })
          } else {
            // Aiemmat arvioinnit
            let aiempiKokonaisuus = aiemmatKokonaisuudet.find(
              (a) => a.id === kokonaisuus.arvioitavaKokonaisuusId
            )
            if (!aiempiKokonaisuus) {
              aiempiKokonaisuus = {
                ...kokonaisuus.arvioitavaKokonaisuus,
                arvioinnit: [],
                visible: false
              }
              aiemmatKokonaisuudet.push(aiempiKokonaisuus)
            }
            aiempiKokonaisuus.arvioinnit.push({
              ...arviointi,
              arviointiasteikonTaso: kokonaisuus.arviointiasteikonTaso as number,
              itsearviointiArviointiasteikonTaso:
                kokonaisuus.itsearviointiArviointiasteikonTaso as number,
              arvioitavaKokonaisuusId: kokonaisuus.arvioitavaKokonaisuusId
            })
          }
        })
      })
    }

    const aiemmatKategoriat = new Map<number, ArvioitavanKokonaisuudenKategoria>()
    aiemmatKokonaisuudet.forEach((k) => {
      if (k.kategoria.id) {
        if (!aiemmatKategoriat.get(k.kategoria.id)) {
          aiemmatKategoriat.set(k.kategoria.id, {
            ...k.kategoria,
            arvioitavatKokonaisuudet: [],
            visible: true
          })
        }
        aiemmatKategoriat.get(k.kategoria.id)?.arvioitavatKokonaisuudet.push(k)
      }
    })
    this.aiemmatKategoriat = [...aiemmatKategoriat.values()]
      .map((kategoria) => ({
        ...kategoria,
        arvioitavatKokonaisuudet: kategoria.arvioitavatKokonaisuudet.sort(
          (a: ArvioitavaKokonaisuus, b: ArvioitavaKokonaisuus) => sortByAsc(a.nimi, b.nimi)
        )
      }))
      .sort(
        (a: ArvioitavanKokonaisuudenKategoria, b: ArvioitavanKokonaisuudenKategoria) =>
          sortByAsc(a.jarjestysnumero, b.jarjestysnumero) || sortByAsc(a.nimi, b.nimi)
      )

    // Liitetään arvioitavat kokonaisuudet kategorioihin
    arvioitavatKokonaisuudetMap?.forEach((a: ArvioitavaKokonaisuus) => {
      const kategoria = kategoriat.find(
        (k: ArvioitavanKokonaisuudenKategoria) => k.id === a.kategoria.id
      )
      if (kategoria) {
        if (a.arvioinnit.length > 0) {
          kategoria.visible = true
        }
        kategoria.arvioitavatKokonaisuudet.push(a)
      }
    })
    return kategoriat
      .map((kategoria) => ({
        ...kategoria,
        arvioitavatKokonaisuudet: kategoria.arvioitavatKokonaisuudet.sort(
          (a: ArvioitavaKokonaisuus, b: ArvioitavaKokonaisuus) => sortByAsc(a.nimi, b.nimi)
        )
      }))
      .sort(
        (a: ArvioitavanKokonaisuudenKategoria, b: ArvioitavanKokonaisuudenKategoria) =>
          sortByAsc(a.jarjestysnumero, b.jarjestysnumero) || sortByAsc(a.nimi, b.nimi)
      )
  }

  get tyoskentelyjaksotFormatted() {
    return this.suoritusArvioinnitOptions?.tyoskentelyjaksot.map((tj: Tyoskentelyjakso) => ({
      ...tj,
      label: tyoskentelyjaksoLabel(this, tj)
    }))
  }

  get isFiltered() {
    return (
      this.selected.tyoskentelyjakso ||
      this.selected.arvioitavaKokonaisuus ||
      this.selected.kouluttajaOrVastuuhenkilo
    )
  }

  get arvioitavatKokonaisuudetSorted() {
    return this.suoritusArvioinnitOptions.arvioitavatKokonaisuudet.sort(
      (a: ArvioitavaKokonaisuus, b: ArvioitavaKokonaisuus) => sortByAsc(a.nimi, b.nimi)
    )
  }
}
