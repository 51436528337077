


























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'

@Component({
  components: {
    ElsaButton
  }
})
export default class ElsaPopover extends Vue {
  @Prop({ required: false, type: String })
  title?: string

  popoverShow = false

  get uid() {
    return `elsa-popover-${(this as any)._uid}`
  }

  onClose() {
    this.popoverShow = false
  }
}
