var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('poissaolon-syy'),"required":true},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',{attrs:{"title":_vm.$t('poissaolon-syy')}},[_c('elsa-poissaolon-syyt')],1)]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.poissaolonSyytSorted,"state":_vm.validateState('poissaolonSyy'),"label":"nimi","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.poissaolonSyy),callback:function ($$v) {_vm.$set(_vm.form, "poissaolonSyy", $$v)},expression:"form.poissaolonSyy"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelyjakso'),"required":true},on:{"submit":_vm.onTyoskentelyjaksoSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.tyoskentelyjaksotFormatted,"state":_vm.validateState('tyoskentelyjakso'),"label":"label","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)},"select":_vm.onTyoskentelyjaksoSelect},model:{value:(_vm.form.tyoskentelyjakso),callback:function ($$v) {_vm.$set(_vm.form, "tyoskentelyjakso", $$v)},expression:"form.tyoskentelyjakso"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pr-sm-3",attrs:{"label":_vm.$t('alkamispaiva'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"alkamispaiva",attrs:{"id":uid,"value":_vm.form.alkamispaiva,"initial-date":_vm.alkamispaivaInitialDate,"min":_vm.minAlkamispaiva,"min-error-text":_vm.$t('poissaolon-alkamispaiva-ei-voi-olla-ennen-tyoskentelyjakson-alkamista'),"max":_vm.maxAlkamispaiva,"max-error-text":_vm.$t('poissaolon-taytyy-alkaa-ennen-paattymispaivaa')},on:{"update:value":function($event){return _vm.$set(_vm.form, "alkamispaiva", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}}):_vm._e()]}}])}),_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pl-sm-3",attrs:{"label":_vm.$t('paattymispaiva'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"paattymispaiva",staticClass:"datepicker-range",attrs:{"id":uid,"value":_vm.form.paattymispaiva,"initial-date":_vm.paattymispaivaInitialDate,"min":_vm.minPaattymispaiva,"min-error-text":_vm.$t('poissaolon-paattymispaivan-taytyy-olla-alkamispaivan-jalkeen'),"max":_vm.maxPaattymispaiva,"max-error-text":_vm.$t('poissaolon-paattymispaivan-taytyy-olla-ennen-tyoskentelyjakson-paattymista')},on:{"update:value":function($event){return _vm.$set(_vm.form, "paattymispaiva", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])})],1),_c('b-form-checkbox',{class:{ 'mb-3': !_vm.form.kokoTyoajanPoissaolo },on:{"change":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.kokoTyoajanPoissaolo),callback:function ($$v) {_vm.$set(_vm.form, "kokoTyoajanPoissaolo", $$v)},expression:"form.kokoTyoajanPoissaolo"}},[_vm._v(" "+_vm._s(_vm.$t('koko-tyoajan-poissaolo'))+" ")]),_c('b-form-row',[(!_vm.form.kokoTyoajanPoissaolo)?_c('elsa-form-group',{staticClass:"col-sm-5",attrs:{"label":((_vm.$t('poissaolo-nykyisesta-taydesta-tyoajasta')) + " (0-100 %)"),"required":true},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',[_vm._v(" "+_vm._s(_vm.$t('poissaoloprosentti-tooltip'))+" ")])]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('poissaoloprosentti'),"type":"number","step":"any"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.poissaoloprosentti),callback:function ($$v) {_vm.$set(_vm.form, "poissaoloprosentti", _vm._n($$v))},expression:"form.poissaoloprosentti"}}),_c('span',{staticClass:"mx-3"},[_vm._v("%")])],1),_c('b-form-invalid-feedback',{style:({
            display: _vm.validateState('poissaoloprosentti') === false ? 'block' : 'none'
          }),attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(((_vm.$t('poissaoloprosentti-validointivirhe')) + " 0-100 %"))+" ")])]}}],null,false,2875574962)}):_vm._e()],1),_c('hr'),_c('div',{staticClass:"text-right"},[_c('elsa-button',{attrs:{"variant":"back","to":_vm.backButtonRoute}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")]),(_vm.poissaolo.id && !_vm.account.impersonated)?_c('elsa-button',{attrs:{"loading":_vm.params.deleting,"variant":"outline-danger"},on:{"click":_vm.onDelete}},[_vm._v(" "+_vm._s(_vm.$t('poista-poissaolo'))+" ")]):_vm._e(),_c('elsa-button',{staticClass:"ml-2",attrs:{"loading":_vm.params.saving,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tallenna'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }