var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"teoriakoulutus"},[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('teoriakoulutus')))]),_c('hr'),(_vm.teoriakoulutus)?_c('div',[(_vm.teoriakoulutus.koulutuksenNimi)?_c('elsa-form-group',{attrs:{"label":_vm.$t('koulutuksen-nimi')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.teoriakoulutus.koulutuksenNimi))])]}}],null,false,1903842626)}):_vm._e(),(_vm.teoriakoulutus.koulutuksenPaikka)?_c('elsa-form-group',{attrs:{"label":_vm.$t('paikka')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.teoriakoulutus.koulutuksenPaikka))])]}}],null,false,2697219320)}):_vm._e(),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"label":_vm.$t('alkamispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.$date(_vm.teoriakoulutus.alkamispaiva)))])]}}],null,false,1367491413)}),(_vm.teoriakoulutus.paattymispaiva)?_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pl-md-3",attrs:{"label":_vm.$t('paattymispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{staticClass:"datepicker-range",attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.$date(_vm.teoriakoulutus.paattymispaiva))+" ")])]}}],null,false,1528407099)}):_vm._e()],1),(_vm.teoriakoulutus.erikoistumiseenHyvaksyttavaTuntimaara)?_c('elsa-form-group',{attrs:{"label":_vm.$t('erikoistumiseen-hyvaksyttava-tuntimaara')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.teoriakoulutus.erikoistumiseenHyvaksyttavaTuntimaara)+" "+_vm._s(_vm.$t('t'))+" ")])]}}],null,false,3227136918)}):_vm._e(),_c('elsa-form-group',{attrs:{"label":_vm.$t('todistus')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('asiakirjat-content',{attrs:{"id":uid,"asiakirjat":_vm.teoriakoulutus.todistukset,"sorting-enabled":false,"pagination-enabled":false,"enable-search":false,"enable-delete":false,"no-results-info-text":_vm.$t('ei-liitetiedostoja'),"loading":_vm.loading}})]}}],null,false,3311827902)}),(_vm.teoriakoulutus.todistukset.length === 0)?_c('hr'):_vm._e(),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[(_vm.muokkausoikeudet)?_c('elsa-button',{staticClass:"ml-2 mb-3",attrs:{"to":{ name: 'muokkaa-teoriakoulutusta' },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('muokkaa-teoriakoulutusta'))+" ")]):_vm._e(),(_vm.muokkausoikeudet)?_c('elsa-button',{staticClass:"mb-3",attrs:{"loading":_vm.deleting,"variant":"outline-danger"},on:{"click":_vm.onTeoriakoulutusDelete}},[_vm._v(" "+_vm._s(_vm.$t('poista-teoriakoulutus'))+" ")]):_vm._e(),_c('elsa-button',{staticClass:"mb-3 mr-auto font-weight-500 teoriakoulutukset-link",attrs:{"to":{ name: 'teoriakoulutukset' },"variant":"link"}},[_vm._v(" "+_vm._s(_vm.$t('palaa-teoriakoulutuksiin'))+" ")])],1)],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }