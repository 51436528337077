











































import Vue from 'vue'
import Component from 'vue-class-component'

import TerveyskeskuskoulutusjaksotEl from './terveyskeskuskoulutusjaksot-el.vue'
import TerveyskeskuskoulutusjaksotYek from './terveyskeskuskoulutusjaksot-yek.vue'

import { getErikoisalat } from '@/api/virkailija'
import { Erikoisala } from '@/types'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    TerveyskeskuskoulutusjaksotEl,
    TerveyskeskuskoulutusjaksotYek
  }
})
export default class Terveyskeskuskoulutusjaksot extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('terveyskeskuskoulutusjaksot'),
      active: true
    }
  ]

  erikoisalat: Erikoisala[] | null = null
  loading = true

  tabIndex = 0
  tabs = ['#erikoislaakarikoulutus', '#yek']

  beforeMount() {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  }

  async mounted() {
    try {
      await this.fetchRajaimet()
    } catch {
      toastFail(this, this.$t('terveyskeskuskoulutusjaksojen-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  async fetchRajaimet() {
    this.erikoisalat = this.$isVirkailija() ? (await getErikoisalat()).data : []
  }
}
