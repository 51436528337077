















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import store from '@/store'
import { Suoritusarviointi } from '@/types'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    UserAvatar
  }
})
export default class ArviointipyyntoCard extends Vue {
  @Prop({})
  value!: Suoritusarviointi

  get account() {
    return store.getters['auth/account']
  }
}
