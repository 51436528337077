












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class ElsaButton extends Vue {
  @Prop({ required: false })
  loading?: boolean

  @Prop({ required: false })
  disabled?: boolean

  get loadingEnabled() {
    return this.loading !== undefined
  }
}
