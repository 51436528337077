
























































import { Component, Vue } from 'vue-property-decorator'

import { getIlmoitukset } from '@/api/julkinen'
import { deleteIlmoitus } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import ElsaTextEditor from '@/components/text-editor/text-editor.vue'
import { Ilmoitus } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaTextEditor
  }
})
export default class MuokkaaIlmoituksia extends Vue {
  ilmoitukset: Ilmoitus[] | null = null

  loading = true
  deleting = false

  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('julkiset-ilmoitukset'),
        active: true
      }
    ]
  }

  async mounted() {
    await this.fetchIlmoitukset()
    this.loading = false
  }

  async fetchIlmoitukset() {
    try {
      this.ilmoitukset = (await getIlmoitukset()).data
    } catch (err) {
      toastFail(this, this.$t('ilmoituksien-hakeminen-epaonnistui'))
    }
  }

  async poistaIlmoitus(id?: number) {
    if (
      id &&
      (await confirmDelete(
        this,
        this.$t('poista-ilmoitus') as string,
        (this.$t('ilmoituksen') as string).toLowerCase()
      ))
    ) {
      try {
        this.deleting = true
        await deleteIlmoitus(id)
        toastSuccess(this, this.$t('ilmoitus-poistettu-onnistuneesti'))
        await this.fetchIlmoitukset()
      } catch {
        toastFail(this, this.$t('ilmoituksen-poistaminen-epaonnistui'))
      }
      this.deleting = false
    }
  }
}
