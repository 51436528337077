























































































































































































































































































































































































































































































































































import { AxiosError } from 'axios'
import { Component, Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

import { ELSA_API_LOCATION } from '@/api'
import {
  getValmistumispyyntoHyvaksynta,
  putValmistumispyyntoHyvaksynta
} from '@/api/vastuuhenkilo'
import AsiakirjaButton from '@/components/asiakirjat/asiakirja-button.vue'
import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaReturnToSenderModal from '@/components/modal/return-to-sender-modal.vue'
import ValmistumispyyntoMixin from '@/mixins/valmistumispyynto'
import store from '@/store'
import {
  ValmistumispyyntoArviointienTila,
  ValmistumispyyntoVirkailijanTarkistus,
  ValmistumispyyntoHyvaksynta,
  ElsaError
} from '@/types'
import { confirmExit } from '@/utils/confirm'
import { phoneNumber } from '@/utils/constants'
import { toastSuccess, toastFail } from '@/utils/toast'
import OpintosuoritusTab from '@/views/opintosuoritukset/opintosuoritus-tab.vue'

@Component({
  components: {
    AsiakirjaButton,
    ElsaButton,
    ElsaFormGroup,
    ErikoistuvaDetails,
    ElsaConfirmationModal,
    ElsaReturnToSenderModal,
    OpintosuoritusTab
  }
})
export default class ValmistumispyynnonHyvaksynta extends Mixins<ValmistumispyyntoMixin>(
  validationMixin,
  ValmistumispyyntoMixin
) {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('valmistumispyynnot'),
      to: { name: 'valmistumispyynnot' }
    },
    {
      text: this.$t('valmistumispyynnon-hyvaksynta'),
      active: true
    }
  ]

  form: ValmistumispyyntoHyvaksynta = {
    id: null,
    korjausehdotus: null,
    sahkoposti: null,
    puhelinnumero: null
  }

  virkailijanTarkistus: ValmistumispyyntoVirkailijanTarkistus | null = null

  valmistumispyyntoArviointienTila: ValmistumispyyntoArviointienTila | null = null
  loading = true
  sending = false
  yhteenvetoAsiakirjaUrl: string | null = null
  liitteetAsiakirjaUrl: string | null = null
  skipRouteExitConfirm = true

  validations() {
    return {
      form: {
        sahkoposti: {
          required,
          email
        },
        puhelinnumero: {
          required,
          phoneNumber
        }
      }
    }
  }

  async mounted() {
    const valmistumispyyntoId = this.$route?.params?.valmistumispyyntoId
    if (valmistumispyyntoId) {
      try {
        await getValmistumispyyntoHyvaksynta(parseInt(valmistumispyyntoId)).then((response) => {
          this.virkailijanTarkistus = response.data
          if (response.data.valmistumispyynto) {
            this.valmistumispyynto = response.data.valmistumispyynto
            if (this.valmistumispyynto.yhteenvetoAsiakirjaId) {
              this.yhteenvetoAsiakirjaUrl = `/vastuuhenkilo/valmistumispyynto/${this.valmistumispyynto.id}/asiakirja/`
            }
            if (this.valmistumispyynto.liitteetAsiakirjaId) {
              this.liitteetAsiakirjaUrl = `/vastuuhenkilo/valmistumispyynto/${this.valmistumispyynto.id}/asiakirja/`
            }
          }
          this.form.sahkoposti = this.account.email
          this.form.puhelinnumero = this.account.phoneNumber
        })
        this.loading = false
      } catch {
        toastFail(this, this.$t('valmistumispyynnon-hakeminen-epaonnistui'))
        this.$router.replace({ name: 'valmistumispyynnot' })
      }
    }
  }

  get account() {
    return store.getters['auth/account']
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? !$error : null
  }

  async vaihdaRooli(id: number | undefined) {
    if (this.odottaaHyvaksyntaa && !this.skipRouteExitConfirm) {
      if (!(await confirmExit(this))) {
        return
      }
    }

    this.$emit('skipRouteExitConfirm', true)
    window.location.href = `${ELSA_API_LOCATION}/api/login/impersonate?opintooikeusId=${id}&originalUrl=${window.location.href}`
  }

  onSubmit() {
    return this.$bvModal.show('confirm-send')
  }

  async onSend() {
    try {
      this.sending = true
      if (this.valmistumispyynto.id) {
        this.form = (
          await putValmistumispyyntoHyvaksynta({
            id: this.valmistumispyynto.id,
            puhelinnumero: this.form.puhelinnumero,
            sahkoposti: this.form.sahkoposti
          })
        ).data
        const account = store.getters['auth/account']
        account.email = this.form.sahkoposti
        account.phoneNumber = this.form.puhelinnumero
        this.$emit('skipRouteExitConfirm', true)
        toastSuccess(this, this.$t('valmistumispyynto-hyvaksynta-lahetys-onnistui'))
        this.$router.replace({ name: 'valmistumispyynnot' })
      }
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('valmistumispyynto-hyvaksynta-lahetys-epaonnistui')}: ${this.$t(message)}`
          : this.$t('valmistumispyynto-hyvaksynta-lahetys-epaonnistui')
      )
    }
    this.sending = false
  }

  async onReturnToSender(korjausehdotus: string) {
    try {
      this.sending = true
      if (this.valmistumispyynto.id) {
        this.form = (
          await putValmistumispyyntoHyvaksynta({
            id: this.valmistumispyynto.id,
            korjausehdotus: korjausehdotus,
            puhelinnumero: this.form.puhelinnumero,
            sahkoposti: this.form.sahkoposti
          })
        ).data
        this.$emit('skipRouteExitConfirm', true)
        toastSuccess(this, this.$t('valmistumispyynto-hyvaksynta-lahetys-palautettu'))
        this.$router.replace({ name: 'valmistumispyynnot' })
      }
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('valmistumispyynto-hyvaksynta-palautus-epaonnistui')}: ${this.$t(message)}`
          : this.$t('valmistumispyynto-hyvaksynta-palautus-epaonnistui')
      )
    }
    this.sending = false
  }

  onSkipRouteExitConfirm() {
    this.skipRouteExitConfirm = false
    this.$emit('skipRouteExitConfirm', false)
  }
}
