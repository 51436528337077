


























































































































































import { Component, Vue, Prop } from 'vue-property-decorator'

import AsiakirjaButton from '@/components/asiakirjat/asiakirja-button.vue'
import { Valmistumispyynto } from '@/types'

@Component({
  components: {
    AsiakirjaButton
  }
})
export default class ValmistumispyynnonTilaErikoistuja extends Vue {
  @Prop({ required: true })
  valmistumispyynto!: Valmistumispyynto

  get lahetetty() {
    return this.valmistumispyynto.erikoistujanKuittausaika
      ? this.$date(this.valmistumispyynto.erikoistujanKuittausaika)
      : null
  }

  get vastuuhenkiloOsaamisenArvioijaKuittausaika() {
    return this.valmistumispyynto.vastuuhenkiloOsaamisenArvioijaKuittausaika
      ? this.$date(this.valmistumispyynto.vastuuhenkiloOsaamisenArvioijaKuittausaika)
      : null
  }

  get virkailijanKuittausaika() {
    return this.valmistumispyynto.virkailijanKuittausaika
      ? this.$date(this.valmistumispyynto.virkailijanKuittausaika)
      : null
  }

  get vastuuhenkiloHyvaksyjaKuittausaika() {
    return this.valmistumispyynto.vastuuhenkiloHyvaksyjaKuittausaika
      ? this.$date(this.valmistumispyynto.vastuuhenkiloHyvaksyjaKuittausaika)
      : null
  }

  get allekirjoitusaika() {
    return this.valmistumispyynto.allekirjoitusaika
      ? this.$date(this.valmistumispyynto.allekirjoitusaika)
      : null
  }

  get yhteenvetoAsiakirjaUrl() {
    return this.valmistumispyynto.yhteenvetoAsiakirjaId ? `erikoistuva-laakari/asiakirjat/` : null
  }

  get liitteetAsiakirjaUrl() {
    return this.valmistumispyynto.liitteetAsiakirjaId ? `erikoistuva-laakari/asiakirjat/` : null
  }

  get koulutussuunnitelmaAsiakirjaUrl() {
    return this.valmistumispyynto.erikoistujanTiedotAsiakirjaId
      ? `erikoistuva-laakari/asiakirjat/`
      : null
  }

  tilaIcon(vaiheHyvaksytty: boolean) {
    return vaiheHyvaksytty ? ['far', 'check-circle'] : ['fas', 'info-circle']
  }

  allekirjoitettuTilaIcon(allekirjoitettu: boolean) {
    return allekirjoitettu ? ['fas', 'check-circle'] : ['fas', 'info-circle']
  }

  tilaIconClass(vaiheHyvaksytty: boolean) {
    return vaiheHyvaksytty ? 'text-success' : 'text-muted'
  }
}
