


















































































































































































































































































import _get from 'lodash/get'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'

import { getLoppukeskustelu as getLoppukeskusteluKouluttaja } from '@/api/kouluttaja'
import { getLoppukeskustelu as getLoppukeskusteluVastuuhenkilo } from '@/api/vastuuhenkilo'
import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import KoulutuspaikanArvioijat from '@/components/koejakson-vaiheet/koulutuspaikan-arvioijat.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaReturnToSenderModal from '@/components/modal/return-to-sender-modal.vue'
import store from '@/store'
import {
  LoppukeskusteluLomake,
  KoejaksonVaiheButtonStates,
  KoejaksonVaiheAllekirjoitus,
  KoejaksonVaihe
} from '@/types'
import { resolveRolePath } from '@/utils/apiRolePathResolver'
import { LomakeTilat, LomakeTyypit } from '@/utils/constants'
import { checkCurrentRouteAndRedirect } from '@/utils/functions'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ErikoistuvaDetails,
    ElsaFormGroup,
    ElsaFormError,
    ElsaButton,
    ElsaConfirmationModal,
    ElsaReturnToSenderModal,
    KoulutuspaikanArvioijat,
    KoejaksonVaiheAllekirjoitukset
  },
  validations: {
    loppukeskustelu: {
      esitetaanKoejaksonHyvaksymista: {
        required
      },
      jatkotoimenpiteet: {
        required: requiredIf((value) => {
          return value.esitetaanKoejaksonHyvaksymista === false
        })
      }
    }
  }
})
export default class ArviointilomakeLoppukeskustelu extends Mixins(validationMixin) {
  skipRouteExitConfirm!: boolean
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('loppukeskustelu-kouluttaja'),
      active: true
    }
  ]

  hyvaksyttyVaihtoehdot = [
    {
      text: this.$t('loppukeskustelu-kayty-hyvaksytty'),
      value: true
    },
    {
      text: this.$t('loppukeskustelu-kayty-jatkotoimenpiteet'),
      value: false
    }
  ]

  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }

  loading = true

  loppukeskustelu: LoppukeskusteluLomake | null = null

  validateState(value: string) {
    const form = this.$v.loppukeskustelu
    const { $dirty, $error } = _get(form, value) as any
    return $dirty ? ($error ? false : null) : null
  }

  get loppukeskustelunTila() {
    return store.getters[`${resolveRolePath()}/koejaksot`].find(
      (k: KoejaksonVaihe) =>
        k.id === this.loppukeskustelu?.id && k.tyyppi === LomakeTyypit.LOPPUKESKUSTELU
    )?.tila
  }

  get loppukeskusteluId() {
    return Number(this.$route.params.id)
  }

  get editable() {
    if (
      this.loppukeskustelu?.lahiesimies.id == this.loppukeskustelu?.lahikouluttaja.id &&
      !this.loppukeskustelu?.lahiesimies.sopimusHyvaksytty &&
      !this.loppukeskustelu?.lahikouluttaja.sopimusHyvaksytty
    ) {
      return true
    }
    return (
      (this.$isKouluttaja() || this.$isVastuuhenkilo()) &&
      !this.isCurrentUserLahiesimies &&
      !this.loppukeskustelu?.lahikouluttaja.sopimusHyvaksytty
    )
  }

  get editableForEsimies() {
    return (
      this.isCurrentUserLahiesimies &&
      this.loppukeskustelu?.lahikouluttaja.sopimusHyvaksytty &&
      !this.loppukeskustelu?.lahiesimies.sopimusHyvaksytty
    )
  }

  get isCurrentUserLahiesimies() {
    const currentUser = store.getters['auth/account']
    return this.loppukeskustelu?.lahiesimies.kayttajaUserId === currentUser.id
  }

  get erikoistuvanAvatar() {
    return this.loppukeskustelu?.erikoistuvanAvatar
  }

  get erikoistuvanNimi() {
    return this.loppukeskustelu?.erikoistuvanNimi
  }

  get showWaitingForLahiesimiesOrErikoistuva() {
    return (
      !this.isCurrentUserLahiesimies &&
      this.loppukeskustelu?.lahikouluttaja.sopimusHyvaksytty &&
      !this.loppukeskustelu?.lahiesimies.sopimusHyvaksytty
    )
  }

  get acceptedByEveryone() {
    return this.loppukeskustelu?.lahiesimies.sopimusHyvaksytty
  }

  get returned() {
    return this.loppukeskustelunTila === LomakeTilat.PALAUTETTU_KORJATTAVAKSI
  }

  get allekirjoitukset(): KoejaksonVaiheAllekirjoitus[] {
    const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
      this,
      this.loppukeskustelu?.erikoistuvanNimi,
      this.loppukeskustelu?.erikoistuvanKuittausaika
    )
    const allekirjoitusLahikouluttaja = allekirjoituksetHelper.mapAllekirjoitusLahikouluttaja(
      this,
      this.loppukeskustelu?.lahikouluttaja
    )
    const allekirjoitusLahiesimies = allekirjoituksetHelper.mapAllekirjoitusLahiesimies(
      this,
      this.loppukeskustelu?.lahiesimies
    )

    return [
      allekirjoitusLahikouluttaja,
      allekirjoitusLahiesimies,
      allekirjoitusErikoistuva
    ].filter((a): a is KoejaksonVaiheAllekirjoitus => a !== null)
  }

  async onReturnToSender(korjausehdotus: string) {
    const form = {
      ...this.loppukeskustelu,
      korjausehdotus: korjausehdotus,
      lahetetty: false
    }

    try {
      this.buttonStates.secondaryButtonLoading = true
      await store.dispatch('kouluttaja/putLoppukeskustelu', form)
      this.buttonStates.secondaryButtonLoading = false
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('loppukeskustelu-palautettu-muokattavaksi'))
    } catch {
      toastFail(this, this.$t('loppukeskustelu-palautus-epaonnistui'))
    }
  }

  async onSign() {
    if (!this.loppukeskustelu) return
    if (this.loppukeskustelu.esitetaanKoejaksonHyvaksymista === true) {
      this.loppukeskustelu.jatkotoimenpiteet = null
    }
    try {
      this.buttonStates.primaryButtonLoading = true
      await store.dispatch('kouluttaja/putLoppukeskustelu', this.loppukeskustelu)
      this.buttonStates.primaryButtonLoading = false

      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('loppukeskustelu-lahetys-onnistui'))
    } catch {
      toastFail(this, this.$t('loppukeskustelun-tallennus-epaonnistui'))
    }
  }

  naytaKehittamistoimenpideKategoria(kategoria: string, muuKategoria: string | null) {
    if (kategoria === 'MUU') return muuKategoria
    return this.$t('kehittamistoimenpidekategoria-' + kategoria)
  }

  onValidateAndConfirm() {
    this.$v.$touch()
    if (this.$v.$anyError) {
      return
    }
    return this.$bvModal.show('confirm-send')
  }

  async mounted() {
    this.loading = true
    await store.dispatch(`${resolveRolePath()}/getKoejaksot`)

    try {
      const { data } = await (this.$isVastuuhenkilo()
        ? getLoppukeskusteluVastuuhenkilo(this.loppukeskusteluId)
        : getLoppukeskusteluKouluttaja(this.loppukeskusteluId))
      this.loppukeskustelu = data
      this.loading = false
    } catch {
      toastFail(this, this.$t('loppukeskustelun-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'koejakso' })
    }
  }
}
