














































import { Component, Vue } from 'vue-property-decorator'

import { getEtusivuSeurantajaksot as getEtusivuSeurantajaksotKouluttaja } from '@/api/kouluttaja'
import { getEtusivuSeurantajaksot as getEtusivuSeurantajaksotVastuuhenkilo } from '@/api/vastuuhenkilo'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import { Seurantajakso } from '@/types'
import { SeurantajaksoTila } from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton
  }
})
export default class SeurantajaksotCard extends Vue {
  seurantajaksot: Seurantajakso[] | null = null
  loading = true

  async mounted() {
    try {
      if (this.$isVastuuhenkilo()) {
        this.seurantajaksot = (await getEtusivuSeurantajaksotVastuuhenkilo()).data
      } else {
        this.seurantajaksot = (await getEtusivuSeurantajaksotKouluttaja()).data
      }
    } catch (err) {
      toastFail(this, this.$t('seurantajaksojen-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  get fields() {
    return [
      {
        key: 'erikoistujanNimi',
        label: this.$t('erikoistuja'),
        sortable: true
      },
      {
        key: 'pvm',
        label: this.$t('pvm'),
        sortable: true
      },
      {
        key: 'seurantajakso',
        label: this.$t('seurantajakso'),
        class: 'seurantajakso',
        sortable: true
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        class: 'actions'
      }
    ]
  }

  get rows() {
    return this.seurantajaksot?.length ?? 0
  }

  getActionTitle(tila: SeurantajaksoTila) {
    if (
      tila === SeurantajaksoTila.ODOTTAA_ARVIOINTIA ||
      tila === SeurantajaksoTila.ODOTTAA_ARVIOINTIA_JA_YHTEISIA_MERKINTOJA
    ) {
      return this.$t('tee-arviointi')
    }
    return this.$t('hyvaksy')
  }
}
