











































import { Component, Vue } from 'vue-property-decorator'

import { getEtusivuArviointipyynnot as getArviointipyynnotKouluttaja } from '@/api/kouluttaja'
import { getEtusivuArviointipyynnot as getArviointipyynnotVastuuhenkilo } from '@/api/vastuuhenkilo'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import { Arviointipyynto } from '@/types'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton
  }
})
export default class ArviointipyynnotCard extends Vue {
  arviointipyynnot: Arviointipyynto[] | null = null
  loading = true

  async mounted() {
    try {
      this.arviointipyynnot = this.$isVastuuhenkilo()
        ? (await getArviointipyynnotVastuuhenkilo()).data
        : (await getArviointipyynnotKouluttaja()).data
    } catch (err) {
      toastFail(this, this.$t('arviointipyyntojen-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  get fields() {
    return [
      {
        key: 'erikoistujanNimi',
        label: this.$t('erikoistuja'),
        sortable: true
      },
      {
        key: 'pyynnonAika',
        label: this.$t('pvm'),
        sortable: true
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        class: 'actions'
      }
    ]
  }

  get rows() {
    return this.arviointipyynnot?.length ?? 0
  }
}
