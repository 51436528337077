











































import { Component, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ErikoistuvaLaakariForm from '@/forms/uusi-erikoistuva-laakari-form.vue'
import PaakayttajaForm from '@/forms/uusi-paakayttaja-form.vue'
import VastuuhenkiloForm from '@/forms/uusi-vastuuhenkilo-form.vue'
import VirkailijaForm from '@/forms/uusi-virkailija-form.vue'
import { ELSA_ROLE } from '@/utils/roles'

@Component({
  components: {
    ElsaFormGroup,
    ErikoistuvaLaakariForm,
    VastuuhenkiloForm,
    VirkailijaForm,
    PaakayttajaForm,
    ElsaButton
  }
})
export default class UusiKayttajaView extends Vue {
  items = [
    {
      text: this.$t('kayttajahallinta'),
      to: { name: 'kayttajahallinta' }
    },
    {
      text: this.$t('uusi-kayttaja'),
      active: true
    }
  ]
  form = {
    rooli: null
  }

  roolit = [
    {
      text: this.$t('erikoistuja'),
      value: this.erikoistuvaLaakariRole
    },
    {
      text: this.$t('vastuuhenkilo'),
      value: this.vastuuhenkiloRole
    },
    {
      text: this.$t('virkailija'),
      value: this.virkailijaRole
    }
  ]

  mounted() {
    if (this.$isTekninenPaakayttaja()) {
      this.roolit.push({
        text: this.$t('paakayttaja'),
        value: this.paakayttajaRole
      })
    }
  }

  onCancel() {
    this.$router.push({
      name: 'kayttajahallinta'
    })
  }

  get erikoistuvaLaakariRole() {
    return ELSA_ROLE.ErikoistuvaLaakari
  }

  get vastuuhenkiloRole() {
    return ELSA_ROLE.Vastuuhenkilo
  }

  get virkailijaRole() {
    return ELSA_ROLE.OpintohallinnonVirkailija
  }

  get paakayttajaRole() {
    return ELSA_ROLE.TekninenPaakayttaja
  }

  skipRouteExitConfirm(value: boolean) {
    this.$emit('skipRouteExitConfirm', value)
  }
}
