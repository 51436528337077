























































































































































































































































































































import Avatar from 'vue-avatar'
import { Component, Mixins } from 'vue-property-decorator'

import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import NavbarMixin from '@/mixins/navbar'

@Component({
  components: {
    Avatar,
    UserAvatar
  }
})
export default class MobileNav extends Mixins(NavbarMixin) {
  featurePreviewModeEnabled = process.env.VUE_APP_FEATURE_PREVIEW_MODE_ENABLED === 'true'

  get isImpersonated() {
    return this.account?.impersonated
  }
}
