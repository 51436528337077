









import Vue from 'vue'
import Component from 'vue-class-component'

import SeurantajaksoViewErikoistuja from '@/views/seurantakeskustelut/erikoistuva/seurantajakso-erikoistuja.vue'
import SeurantajaksoViewKouluttajaVastuuhenkilo from '@/views/seurantakeskustelut/kouluttaja-vastuuhenkilo/seurantajakso-kouluttaja-vastuuhenkilo.vue'

@Component({
  components: {
    SeurantajaksoViewErikoistuja,
    SeurantajaksoViewKouluttajaVastuuhenkilo
  }
})
export default class SeurantajaksoContainer extends Vue {}
