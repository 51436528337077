






























































import axios from 'axios'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    UserAvatar,
    ElsaFormGroup,
    ElsaButton
  }
})
export default class KommenttiCard extends Vue {
  @Prop({ required: true })
  value!: any

  @Prop({ required: false, type: Boolean, default: false })
  locked!: boolean

  editing = false
  kommentti = {
    teksti: null
  } as any
  saving = false

  startEditing() {
    this.kommentti.teksti = this.value.kommentti.teksti
    this.editing = true
  }

  cancelEditing() {
    this.editing = false
  }

  async onSubmit() {
    this.saving = true
    try {
      const kommentti = (
        await axios.put(
          `suoritusarvioinnit/${this.value.kommentti.suoritusarviointiId}/kommentti`,
          {
            ...this.value.kommentti,
            teksti: this.kommentti.teksti
          }
        )
      ).data
      this.value.kommentti = kommentti
      this.editing = false
      this.kommentti = {
        teksti: null
      }
      this.$emit('updated', kommentti)
    } catch {
      toastFail(this, this.$t('kommentin-tallentaminen-epaonnistui'))
    }
    this.saving = false
  }

  get selfClass() {
    if (this.value.self) {
      return 'bg-primary-dark kommentti-right px-3 py-2 mb-3 text-white ml-md-6'
    } else {
      return 'bg-light kommentti-left px-3 py-2 mb-3 mr-md-6'
    }
  }
}
