













import { Vue, Component } from 'vue-property-decorator'

import NavbarNotLoggedIn from '@/components/navbar/navbar-not-logged-in.vue'
import TietosuojaselosteContent from '@/components/tietosuojaseloste/tietosuojaseloste-content.vue'

@Component({
  components: {
    NavbarNotLoggedIn,
    TietosuojaselosteContent
  }
})
export default class TietosuojaselosteView extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('tietosuojaseloste'),
      active: true
    }
  ]
}
