



















































































import { Vue, Component } from 'vue-property-decorator'

import { getTeoriakoulutukset } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaProgressBar from '@/components/progress-bar/progress-bar.vue'
import ElsaVanhaAsetusVaroitus from '@/components/vanha-asetus-varoitus/vanha-asetus-varoitus.vue'
import store from '@/store'
import { Asiakirja, Teoriakoulutus } from '@/types'
import { fetchAndOpenBlob } from '@/utils/blobs'
import { sortByDateDesc } from '@/utils/date'
import { ELSA_ROLE } from '@/utils/roles'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaProgressBar,
    ElsaVanhaAsetusVaroitus
  }
})
export default class Teoriakoulutukset extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('teoriakoulutukset'),
      active: true
    }
  ]
  loading = true
  fields = [
    {
      key: 'nimi',
      label: this.$t('koulutuksen-nimi'),
      sortable: true
    },
    {
      key: 'koulutuksenPaikka',
      label: this.$t('paikka'),
      sortable: true
    },
    {
      key: 'pvm',
      label: this.$t('pvm'),
      sortable: true
    },
    {
      key: 'erikoistumiseenHyvaksyttavaTuntimaara',
      label: this.$t('tunnit'),
      sortable: true
    },
    {
      key: 'todistus',
      label: this.$t('todistus'),
      sortable: false
    }
  ]
  teoriakoulutukset: Teoriakoulutus[] = []
  erikoisalanVaatimaTeoriakoulutustenVahimmaismaara: number | null = null

  async mounted() {
    await this.fetchTeoriakoulutukset()
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  async fetchTeoriakoulutukset() {
    try {
      const { teoriakoulutukset, erikoisalanVaatimaTeoriakoulutustenVahimmaismaara } = (
        await getTeoriakoulutukset()
      ).data
      this.teoriakoulutukset = teoriakoulutukset
      this.erikoisalanVaatimaTeoriakoulutustenVahimmaismaara =
        erikoisalanVaatimaTeoriakoulutustenVahimmaismaara
    } catch (err) {
      toastFail(this, this.$t('teoriakoulutuksien-hakeminen-epaonnistui'))
    }
  }

  get teoriakoulutuksetFormatted() {
    return this.teoriakoulutukset
      .sort((a, b) => sortByDateDesc(a.alkamispaiva, b.alkamispaiva))
      .map((teoriakoulutus) => ({
        ...teoriakoulutus,
        nimi: teoriakoulutus.koulutuksenNimi,
        pvm: teoriakoulutus.alkamispaiva
          ? `${this.$date(teoriakoulutus.alkamispaiva)}${
              teoriakoulutus.paattymispaiva ? `-${this.$date(teoriakoulutus.paattymispaiva)}` : ''
            }`
          : null,
        todistus: teoriakoulutus.todistukset
      }))
  }

  get suoritettuTeoriakoulutusMaara() {
    return this.teoriakoulutukset
      .map((teoriakoulutus) => teoriakoulutus.erikoistumiseenHyvaksyttavaTuntimaara)
      .reduce((a, b) => (a ?? 0) + (b ?? 0), 0)
  }

  get opintooppaastaLinkki() {
    return `<a href="https://www.laaketieteelliset.fi/ammatillinen-jatkokoulutus/opinto-oppaat/" target="_blank" rel="noopener noreferrer">${(
      this.$t('opinto-oppaasta') as string
    ).toLowerCase()}</a>`
  }

  get kopiLinkki() {
    return `<a href="https://www.kopi.fi/" target="_blank" rel="noopener noreferrer">www.kopi.fi</a>`
  }

  async onViewAsiakirja(asiakirja: Asiakirja) {
    if (asiakirja.id) {
      Vue.set(asiakirja, 'disablePreview', true)
      const success = await fetchAndOpenBlob('erikoistuva-laakari/asiakirjat/', asiakirja.id)
      if (!success) {
        toastFail(this, this.$t('asiakirjan-sisallon-hakeminen-epaonnistui'))
      }

      Vue.set(asiakirja, 'disablePreview', false)
    }
  }

  get muokkausoikeudet() {
    if (!this.account.impersonated) {
      return true
    }

    if (
      this.account.originalUser.authorities.includes(ELSA_ROLE.OpintohallinnonVirkailija) &&
      this.account.erikoistuvaLaakari.muokkausoikeudetVirkailijoilla
    ) {
      return true
    }

    return false
  }
}
