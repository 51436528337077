



































































































































































































import Component from 'vue-class-component'
import { Vue, Mixins, Prop } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'

import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import AsiakirjatUpload from '@/components/asiakirjat/asiakirjat-upload.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import { Koulutussuunnitelma } from '@/types'
import { mapFile } from '@/utils/fileMapper'

@Component({
  components: {
    AsiakirjatUpload,
    AsiakirjatContent,
    ElsaButton,
    ElsaFormGroup,
    ElsaFormError,
    ElsaPopover
  },
  validations: {
    form: {}
  }
})
export default class KoulutussuunnitelmaForm extends Mixins(validationMixin) {
  @Prop({
    required: true
  })
  value!: Koulutussuunnitelma

  @Prop({ required: true })
  reservedAsiakirjaNimet!: string[]

  form: Koulutussuunnitelma = {
    id: null,
    motivaatiokirje: null,
    motivaatiokirjeYksityinen: false,
    opiskeluJaTyohistoria: null,
    opiskeluJaTyohistoriaYksityinen: false,
    vahvuudet: null,
    vahvuudetYksityinen: false,
    tulevaisuudenVisiointi: null,
    tulevaisuudenVisiointiYksityinen: false,
    osaamisenKartuttaminen: null,
    osaamisenKartuttaminenYksityinen: false,
    elamankentta: null,
    elamankenttaYksityinen: false,
    koulutussuunnitelmaAsiakirja: null,
    motivaatiokirjeAsiakirja: null,
    koulutussuunnitelmaFile: null,
    koulutussuunnitelmaAsiakirjaUpdated: false,
    motivaatiokirjeFile: null,
    motivaatiokirjeAsiakirjaUpdated: false,
    muokkauspaiva: null
  }

  params = {
    saving: false
  }

  mounted() {
    this.form = {
      ...this.value,
      koulutussuunnitelmaAsiakirjaUpdated: false,
      motivaatiokirjeAsiakirjaUpdated: false
    }
  }

  onKoulutussuunnitelmaFileAdded(files: File[]) {
    const file = files[0]
    Vue.set(this.form, 'koulutussuunnitelmaAsiakirja', mapFile(file))
    this.form.koulutussuunnitelmaFile = file
    this.form.koulutussuunnitelmaAsiakirjaUpdated = true
    this.$emit('skipRouteExitConfirm', false)
  }

  onKoulutussuunnitelmaFileDeleted() {
    Vue.set(this.form, 'koulutussuunnitelmaAsiakirja', null)
    this.form.koulutussuunnitelmaAsiakirjaUpdated = true
    this.$emit('skipRouteExitConfirm', false)
  }

  onMotivaatiokirjeFileAdded(files: File[]) {
    const file = files[0]
    Vue.set(this.form, 'motivaatiokirjeAsiakirja', mapFile(file))
    this.form.motivaatiokirjeFile = file
    this.form.motivaatiokirjeAsiakirjaUpdated = true
    this.$emit('skipRouteExitConfirm', false)
  }

  onMotivaatiokirjeFileDeleted() {
    Vue.set(this.form, 'motivaatiokirjeAsiakirja', null)
    this.form.motivaatiokirjeAsiakirjaUpdated = true
    this.$emit('skipRouteExitConfirm', false)
  }

  onSubmit() {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) {
      return
    }
    this.$emit(
      'submit',
      {
        ...this.form,
        koulutussuunnitelmaAsiakirja: null,
        motivaatiokirjeAsiakirja: null
      },
      this.params
    )
  }

  onCancel() {
    this.$emit('cancel')
  }

  get koulutussuunnitelmaAsiakirjatTableItems() {
    return this.form.koulutussuunnitelmaAsiakirja ? [this.form.koulutussuunnitelmaAsiakirja] : []
  }

  get motivaatiokirjeAsiakirjatTableItems() {
    return this.form.motivaatiokirjeAsiakirja ? [this.form.motivaatiokirjeAsiakirja] : []
  }
}
