































































































































































import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, integer, between } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPoissaolonSyyt from '@/components/poissaolon-syyt/poissaolon-syyt.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import TyoskentelyjaksoForm from '@/forms/tyoskentelyjakso-form.vue'
import TyoskentelyjaksoMixin from '@/mixins/tyoskentelyjakso'
import store from '@/store'
import { Poissaolo, PoissaolonSyy, Tyoskentelyjakso } from '@/types'
import { dateBetween } from '@/utils/date'
import { sortByAsc } from '@/utils/sort'
import { tyoskentelyjaksoLabel } from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    TyoskentelyjaksoForm,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    ElsaPopover,
    ElsaFormDatepicker,
    ElsaButton,
    ElsaPoissaolonSyyt
  },
  validations: {
    form: {
      poissaolonSyy: {
        required
      },
      tyoskentelyjakso: {
        required
      },
      kokoTyoajanPoissaolo: {
        required
      },
      poissaoloprosentti: {
        required: requiredIf((poissaolo) => {
          return poissaolo.kokoTyoajanPoissaolo === false
        }),
        between: between(0, 100),
        integer
      }
    }
  }
})
export default class PoissaoloForm extends Mixins<TyoskentelyjaksoMixin>(
  validationMixin,
  TyoskentelyjaksoMixin
) {
  $refs!: {
    alkamispaiva: ElsaFormDatepicker
    paattymispaiva: ElsaFormDatepicker
  }

  @Prop({ required: true, default: () => [] })
  poissaolonSyyt!: PoissaolonSyy[]

  @Prop({ required: false, type: Number })
  tyoskentelyjaksoId?: number

  @Prop({
    required: false,
    default: () => ({
      poissaolonSyy: null,
      tyoskentelyjakso: null,
      alkamispaiva: null,
      paattymispaiva: null,
      kokoTyoajanPoissaolo: true,
      poissaoloprosentti: null
    })
  })
  poissaolo!: Poissaolo

  declare form: Poissaolo
  params = {
    saving: false,
    deleting: false
  }
  childDataReceived = false

  mounted() {
    this.form = this.poissaolo

    const selectedTyoskentelyjakso =
      this.tyoskentelyjaksoId &&
      this.tyoskentelyjaksotFormatted.find((t) => t.id === this.tyoskentelyjaksoId)

    if (selectedTyoskentelyjakso) {
      this.form.tyoskentelyjakso = selectedTyoskentelyjakso
    }

    this.childDataReceived = true
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    const validations = [
      this.validateForm(),
      this.$refs.alkamispaiva.validateForm(),
      this.$refs.paattymispaiva.validateForm()
    ]
    if (validations.includes(false)) {
      return
    }
    this.$emit(
      'submit',
      {
        ...this.form,
        poissaolonSyyId: this.form.poissaolonSyy?.id,
        tyoskentelyjaksoId: this.form.tyoskentelyjakso?.id,
        poissaoloprosentti: this.form.kokoTyoajanPoissaolo ? 100 : this.form.poissaoloprosentti
      },
      this.params
    )
  }

  onDelete() {
    this.$emit('delete', this.params)
  }

  onTyoskentelyjaksoSelect(tyoskentelyjakso: Tyoskentelyjakso) {
    if (!tyoskentelyjakso.alkamispaiva) {
      return
    }

    if (
      this.form?.alkamispaiva &&
      !dateBetween(
        this.form.alkamispaiva,
        tyoskentelyjakso.alkamispaiva,
        tyoskentelyjakso.paattymispaiva ?? undefined
      )
    ) {
      this.form.alkamispaiva = undefined
    }
    if (
      this.form?.paattymispaiva &&
      !dateBetween(
        this.form.paattymispaiva,
        tyoskentelyjakso.alkamispaiva,
        tyoskentelyjakso.paattymispaiva ?? undefined
      )
    ) {
      this.form.paattymispaiva = undefined
    }
  }

  get minAlkamispaiva() {
    return this.form.tyoskentelyjakso?.alkamispaiva
  }

  get maxAlkamispaiva() {
    return this.form.paattymispaiva || this.form.tyoskentelyjakso?.paattymispaiva
  }

  get minPaattymispaiva() {
    return this.form.alkamispaiva || this.form.tyoskentelyjakso?.alkamispaiva
  }

  get maxPaattymispaiva() {
    return this.form.tyoskentelyjakso?.paattymispaiva
  }

  get poissaolonSyytSorted() {
    return [...this.poissaolonSyyt.sort((a, b) => sortByAsc(a.nimi, b.nimi))]
  }

  get backButtonRoute() {
    return this.tyoskentelyjaksoId
      ? { name: 'tyoskentelyjakso', id: this.tyoskentelyjaksoId }
      : { name: 'tyoskentelyjaksot' }
  }

  get alkamispaivaInitialDate() {
    return this.form.tyoskentelyjakso?.alkamispaiva
  }

  get paattymispaivaInitialDate() {
    return this.form.alkamispaiva
  }

  get account() {
    return store.getters['auth/account']
  }

  get tyoskentelyjaksotFormatted() {
    return this.tyoskentelyjaksot.map((tj) => ({
      ...tj,
      label: tyoskentelyjaksoLabel(this, tj)
    }))
  }
}
