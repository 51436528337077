























































































import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, email, sameAs } from 'vuelidate/lib/validators'

import { ELSA_API_LOCATION } from '@/api'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import store from '@/store'
import { Opintooikeus, KaytonAloitusModel } from '@/types'
@Component({
  components: {
    ElsaFormGroup,
    ElsaFormMultiselect,
    UserAvatar,
    ElsaButton
  }
})
export default class KaytonAloitusForm extends Mixins(validationMixin) {
  validations() {
    return {
      form: {
        sahkoposti: {
          required,
          email
        },
        sahkopostiUudelleen: {
          required,
          email,
          sameAsSahkoposti: sameAs('sahkoposti')
        },
        opintooikeusId: {
          required: requiredIf(() => {
            return this.hasMultipleOpintooikeus
          })
        }
      }
    }
  }

  @Prop({ required: true, default: [] })
  opintooikeudet!: Opintooikeus[]

  form: KaytonAloitusModel = {
    sahkoposti: null,
    sahkopostiUudelleen: null,
    opintooikeusId: null
  }

  get logoutUrl() {
    return ELSA_API_LOCATION + '/api/logout'
  }

  async logout() {
    await store.dispatch('auth/logout')

    if (store.getters['auth/sloEnabled'] === true) {
      const logoutForm = this.$refs.logoutForm as HTMLFormElement
      logoutForm.submit()
    }
  }

  get hasMultipleOpintooikeus() {
    return this.opintooikeudet?.length > 1
  }

  get opintooikeudetOptions() {
    return this.opintooikeudet.map((o: Opintooikeus) => ({
      text: `${o.erikoisalaNimi}, ${this.$t(`yliopisto-nimi.${o.yliopistoNimi}`)}`,
      value: o.id
    }))
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  onSubmit() {
    this.$v.form.$touch()
    if (!this.$v.$anyError) {
      this.$emit('submit', this.form)
    }
  }
}
