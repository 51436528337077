






































































import { Component, Vue } from 'vue-property-decorator'

import { getErikoisalat } from '@/api/tekninen-paakayttaja'
import Pagination from '@/components/pagination/pagination.vue'
import SearchInput from '@/components/search-input/search-input.vue'
import { Erikoisala } from '@/types'
import { sortByAsc } from '@/utils/sort'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    SearchInput,
    Pagination
  }
})
export default class Opetussuunnitelmat extends Vue {
  erikoisalat: Erikoisala[] = []

  loading = true

  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('opetussuunnitelmat'),
      active: true
    }
  ]

  fields = [
    {
      key: 'nimi',
      label: this.$t('erikoisala'),
      class: 'nimi',
      sortable: true
    },
    {
      key: 'tyyppi',
      label: this.$t('tyyppi'),
      class: 'tyyppi',
      sortable: true
    }
  ]
  perPage = 20
  currentPage = 1
  hakutermi = ''

  async mounted() {
    this.loading = true
    try {
      this.erikoisalat = (await getErikoisalat()).data.sort((a, b) => sortByAsc(a.nimi, b.nimi))
    } catch {
      toastFail(this, this.$t('erikoisalojen-hakeminen-epaonnistui'))
      this.erikoisalat = []
    }
    this.loading = false
  }

  get rows() {
    return this.tulokset?.length ?? 0
  }

  get tulokset() {
    if (this.hakutermi) {
      this.currentPage = 1
      return this.erikoisalat?.filter((item: Erikoisala) =>
        item.nimi?.toLowerCase().includes(this.hakutermi.toLowerCase())
      )
    }

    return this.erikoisalat
  }
}
