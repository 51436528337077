import { render, staticRenderFns } from "./tyoskentelyjakso-form.vue?vue&type=template&id=8d59d850&scoped=true&"
import script from "./tyoskentelyjakso-form.vue?vue&type=script&lang=ts&"
export * from "./tyoskentelyjakso-form.vue?vue&type=script&lang=ts&"
import style0 from "./tyoskentelyjakso-form.vue?vue&type=style&index=0&id=8d59d850&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d59d850",
  null
  
)

export default component.exports