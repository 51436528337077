
























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import { LomakeTyypit, LomakeTilat, TaskStatus } from '@/utils/constants'

@Component({
  components: {
    ElsaSearchInput,
    ElsaPagination
  }
})
export default class KoejaksonVaiheetList extends Vue {
  @Prop({ required: true, default: undefined })
  koejaksot!: []

  @Prop({ required: false, type: Boolean, default: false })
  loading!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  vastuuhenkilo!: boolean

  @Prop({ required: true, type: Map, default: undefined })
  componentLinks!: Map<string, string>

  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      active: true
    }
  ]

  fields = [
    {
      key: 'erikoistuvanNimi',
      label: this.$t('erikoistuja'),
      class: 'nimi',
      sortable: true
    },
    {
      key: 'tyyppi',
      label: this.$t('koejakson-vaihe'),
      class: 'tyyppi',
      sortable: true
    },
    {
      key: 'tila',
      label: this.$t('tila'),
      class: 'tila',
      sortable: true
    },
    {
      key: 'pvm',
      label: this.$t('pvm'),
      class: 'pvm',
      sortable: true
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      class: 'actions'
    }
  ]
  perPage = 20
  currentPage = 1
  hakutermi = ''

  get rows() {
    return this.tulokset?.length ?? 0
  }

  linkComponent(type: string) {
    return this.componentLinks.get(type)
  }

  taskIcon(status: string) {
    switch (status) {
      case LomakeTilat.ODOTTAA_HYVAKSYNTAA:
        return ['far', 'clock']
      case LomakeTilat.PALAUTETTU_KORJATTAVAKSI:
        return ['fas', 'undo-alt']
      case LomakeTilat.HYVAKSYTTY:
        return ['fas', 'check-circle']
      case LomakeTilat.ALLEKIRJOITETTU:
        return ['fas', 'check-circle']
      case LomakeTilat.ODOTTAA_ALLEKIRJOITUSTA:
        return ['far', 'clock']
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA:
        return ['far', 'check-circle']
      case LomakeTilat.ODOTTAA_ESIMIEHEN_HYVAKSYNTAA:
        return ['far', 'check-circle']
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        return ['far', 'check-circle']
      case LomakeTilat.ODOTTAA_TOISEN_KOULUTTAJAN_HYVAKSYNTAA:
        return ['far', 'check-circle']
    }
  }

  taskClass(status: string) {
    switch (status) {
      case LomakeTilat.ODOTTAA_HYVAKSYNTAA:
        return 'text-warning'
      case LomakeTilat.PALAUTETTU_KORJATTAVAKSI:
        return ''
      case LomakeTilat.HYVAKSYTTY:
        return 'text-success'
      case LomakeTilat.ALLEKIRJOITETTU:
        return 'text-success'
      case LomakeTilat.ODOTTAA_ALLEKIRJOITUSTA:
        return 'text-warning'
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA:
        return 'text-success'
      case LomakeTilat.ODOTTAA_ESIMIEHEN_HYVAKSYNTAA:
        return 'text-success'
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        return 'text-success'
      case LomakeTilat.ODOTTAA_TOISEN_KOULUTTAJAN_HYVAKSYNTAA:
        return 'text-success'
    }
  }

  taskStatus(status: string) {
    switch (status) {
      case LomakeTilat.ODOTTAA_HYVAKSYNTAA:
        return this.$t('lomake-tila-' + TaskStatus.AVOIN)
      case LomakeTilat.PALAUTETTU_KORJATTAVAKSI:
        return this.$t('lomake-tila-' + TaskStatus.PALAUTETTU)
      case LomakeTilat.HYVAKSYTTY:
        return this.$t('lomake-tila-' + TaskStatus.HYVAKSYTTY)
      case LomakeTilat.ALLEKIRJOITETTU:
        return this.$t('lomake-tila-' + TaskStatus.ALLEKIRJOITETTU)
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA:
        return this.$isKouluttaja()
          ? this.$t('lomake-tila-' + TaskStatus.VALMIS_ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA)
          : this.$t('lomake-tila-' + TaskStatus.ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA)
      case LomakeTilat.ODOTTAA_ALLEKIRJOITUSTA:
        return this.$t('lomake-tila-' + TaskStatus.ODOTTAA_ALLEKIRJOITUSTA)
      case LomakeTilat.ODOTTAA_ESIMIEHEN_HYVAKSYNTAA:
        return this.$t('lomake-tila-' + TaskStatus.ODOTTAA_ESIMIEHEN_HYVAKSYNTAA)
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        return this.$t('lomake-tila-' + TaskStatus.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA)
      case LomakeTilat.ODOTTAA_TOISEN_KOULUTTAJAN_HYVAKSYNTAA:
        return this.$t('lomake-tila-' + TaskStatus.ODOTTAA_TOISEN_KOULUTTAJAN_HYVAKSYNTAA)
    }
  }

  showCompleted(item: any) {
    const hyvaksytytVaiheet = Object.values(item.hyvaksytytVaiheet).some((x) => x !== null)
    if (item.tyyppi !== LomakeTyypit.KOULUTUSSOPIMUS && hyvaksytytVaiheet) {
      return true
    }
  }

  get tulokset() {
    if (this.hakutermi) {
      this.currentPage = 1
      return this.koejaksot?.filter((item: any) =>
        item.erikoistuvanNimi.toLowerCase().includes(this.hakutermi.toLowerCase())
      )
    }

    return this.koejaksot
  }
}
