
















































import { Component, Vue } from 'vue-property-decorator'

import { getYekTeoriakoulutukset } from '@/api/yek-koulutettava'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaProgressBar from '@/components/progress-bar/progress-bar.vue'
import ElsaVanhaAsetusVaroitus from '@/components/vanha-asetus-varoitus/vanha-asetus-varoitus.vue'
import store from '@/store'
import { Opintosuoritus } from '@/types'
import { sortByDateDesc } from '@/utils/date'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaProgressBar,
    ElsaVanhaAsetusVaroitus
  }
})
export default class YekTeoriakoulutukset extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('teoriakoulutukset'),
      active: true
    }
  ]
  loading = true
  fields = [
    {
      key: 'nimi',
      label: this.$t('yek.koulutus'),
      sortable: true
    },
    {
      key: 'pvm',
      label: this.$t('yek.suorituspvm'),
      sortable: true
    },
    {
      key: 'merkinta',
      label: this.$t('yek.merkinta'),
      sortable: false
    }
  ]
  opintosuoritukset: Opintosuoritus[] = []

  async mounted() {
    await this.fetchTeoriakoulutukset()
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  async fetchTeoriakoulutukset() {
    try {
      this.opintosuoritukset = (await getYekTeoriakoulutukset()).data
    } catch (err) {
      toastFail(this, this.$t('teoriakoulutuksien-hakeminen-epaonnistui'))
    }
  }

  get teoriakoulutuksetFormatted() {
    return this.opintosuoritukset
      .sort((a, b) => sortByDateDesc(a.suorituspaiva, b.suorituspaiva))
      .map((opintosuoritus) => ({
        ...opintosuoritus,
        nimi: `${opintosuoritus.nimi_fi}`,
        pvm: this.$date(opintosuoritus.suorituspaiva),
        merkinta: opintosuoritus.hyvaksytty
      }))
  }
}
