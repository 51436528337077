





















































import { Component, Vue } from 'vue-property-decorator'

import { getTerveyskeskuskoulutusjaksot as getTerveyskeskuskoulutusjaksotVastuuhenkilo } from '@/api/vastuuhenkilo'
import { getTerveyskeskuskoulutusjaksot as getTerveyskeskuskoulutusjaksotVirkailija } from '@/api/virkailija'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import { TerveyskeskuskoulutusjaksonVaihe } from '@/types'
import { ERIKOISALA_YEK_ID } from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton
  }
})
export default class YekTerveyskeskuskoulutusjaksotCard extends Vue {
  vaiheet: TerveyskeskuskoulutusjaksonVaihe[] | null = null

  get fields() {
    return [
      {
        key: 'erikoistuvanNimi',
        label: this.$t('yek.koulutettava'),
        sortable: true
      },
      {
        key: 'pvm',
        label: this.$t('pvm'),
        sortable: true
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        class: 'actions'
      }
    ]
  }

  loading = true

  async mounted() {
    try {
      const avoinParams = {
        page: 0,
        size: 10,
        sort: 'muokkauspaiva,asc',
        'erikoisalaId.equals': ERIKOISALA_YEK_ID,
        avoin: true
      }
      this.vaiheet = (
        await (this.$isVirkailija()
          ? getTerveyskeskuskoulutusjaksotVirkailija(avoinParams)
          : getTerveyskeskuskoulutusjaksotVastuuhenkilo(avoinParams))
      ).data.content
    } catch (err) {
      toastFail(this, this.$t('terveyskeskuskoulutusjaksojen-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  get rows() {
    return this.vaiheet?.length ?? 0
  }
}
