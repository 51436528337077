var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('koulutusjakso'),"add-new-enabled":true,"add-new-label":_vm.$t('lisaa-koulutusjakso')},on:{"submit":_vm.onKoulutusjaksoSubmit},scopedSlots:_vm._u([{key:"modal-content",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
return [_c('koulutusjakso-form',{attrs:{"tyoskentelyjaksot":_vm.tyoskentelyjaksot,"kunnat":_vm.kunnat,"arvioitavan-kokonaisuuden-kategoriat":_vm.arvioitavanKokonaisuudenKategoriat},on:{"submit":submit,"cancel":cancel}})]}},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_vm._l((_vm.form.koulutusjaksot),function(_,index){return _c('div',{key:index,staticClass:"mb-1",attrs:{"id":uid}},[_c('elsa-form-multiselect',{attrs:{"id":uid,"value":_vm.form.koulutusjaksot,"options":_vm.koulutusjaksotFiltered,"custom-label":_vm.koulutusjaksoLabel,"label":"label","track-by":"id"},model:{value:(_vm.form.koulutusjaksot[index]),callback:function ($$v) {_vm.$set(_vm.form.koulutusjaksot, index, $$v)},expression:"form.koulutusjaksot[index]"}}),(index !== 0)?_c('elsa-button',{staticClass:"text-decoration-none shadow-none p-0",attrs:{"variant":"link","size":"sm"},on:{"click":function($event){return _vm.deleteKoulutusjakso(index)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trash-alt'],"fixed-width":"","size":"sm"}}),_vm._v(" "+_vm._s(_vm.$t('poista-koulutusjakso'))+" ")],1):_vm._e()],1)}),_c('small',{staticClass:"form-text text-muted"},[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-koulutusjakso-help'))+" ")]),_c('elsa-button',{staticClass:"text-decoration-none shadow-none p-0",attrs:{"variant":"link","size":"sm"},on:{"click":_vm.addKoulutusjakso}},[_c('font-awesome-icon',{staticClass:"text-lowercase",attrs:{"icon":"plus","fixed-width":"","size":"sm"}}),_vm._v(" "+_vm._s(_vm.$t('useampi-koulutusjakso'))+" ")],1),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pr-sm-3 mb-0",attrs:{"label":_vm.$t('seurantajakso-alkaa'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"alkamispaiva",attrs:{"id":uid,"value":_vm.form.alkamispaiva,"max":_vm.maxAlkamispaiva,"max-error-text":_vm.$t('alkamispaiva-ei-voi-olla-paattymispaivan-jalkeen')},on:{"update:value":function($event){return _vm.$set(_vm.form, "alkamispaiva", $event)}}}):_vm._e()]}}])}),_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pl-sm-3 mb-0",attrs:{"label":_vm.$t('seurantajakso-paattyy'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"paattymispaiva",staticClass:"datepicker-range",attrs:{"id":uid,"value":_vm.form.paattymispaiva,"min":_vm.minPaattymispaiva,"min-error-text":_vm.$t('paattymispaiva-ei-voi-olla-ennen-alkamispaivaa'),"aria-describedby":(uid + "-help")},on:{"update:value":function($event){return _vm.$set(_vm.form, "paattymispaiva", $event)}}}):_vm._e()]}}])}),_c('small',{staticClass:"form-text text-muted pl-1"},[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-aikajakso-help'))+" ")])],1),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap mt-4"},[_c('elsa-button',{staticClass:"ml-2 mb-2",attrs:{"loading":_vm.params.saving,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('hae-tiedot'))+" ")]),_c('elsa-button',{staticClass:"mb-2",attrs:{"variant":"back"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCancel($event)}}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }