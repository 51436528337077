














































































































































































import { AxiosError } from 'axios'
import { BModal } from 'bootstrap-vue'
import Component from 'vue-class-component'
import { Prop, Mixins } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'

import { postLahikouluttaja } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import KouluttajaForm from '@/forms/kouluttaja-form.vue'
import TyoskentelyjaksoForm from '@/forms/tyoskentelyjakso-form.vue'
import TyoskentelyjaksoMixin from '@/mixins/tyoskentelyjakso'
import store from '@/store'
import {
  ArvioitavanKokonaisuudenKategoria,
  Erikoisala,
  Kayttaja,
  Kunta,
  Suoritusarviointi,
  ElsaError,
  ArvioitavaKokonaisuus
} from '@/types'
import { formatList } from '@/utils/kouluttajaAndVastuuhenkiloListFormatter'
import { toastSuccess, toastFail } from '@/utils/toast'
import { tyoskentelyjaksoLabel } from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    KouluttajaForm,
    TyoskentelyjaksoForm,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    UserAvatar,
    ElsaFormDatepicker,
    ElsaButton
  },
  validations: {
    form: {
      tyoskentelyjakso: {
        required
      },
      arvioinninAntaja: {
        required
      }
    },
    arvioitavatKokonaisuudet: {
      required
    }
  }
})
export default class ArviointipyyntoForm extends Mixins(TyoskentelyjaksoMixin) {
  $refs!: {
    tapahtumanAjankohta: ElsaFormDatepicker
  }

  @Prop({ required: false, default: () => [] })
  kunnat!: Kunta[]

  @Prop({ required: false, default: () => [] })
  erikoisalat!: Erikoisala[]

  @Prop({ required: false, default: () => [] })
  arvioitavanKokonaisuudenKategoriat!: ArvioitavanKokonaisuudenKategoria[]

  @Prop({ required: false, default: () => [] })
  kouluttajatAndVastuuhenkilot!: Kayttaja[]

  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({
    required: false,
    type: Object,
    default: () => ({
      tyoskentelyjakso: null,
      arvioitavatKokonaisuudet: [],
      arvioitavaTapahtuma: null,
      kouluttajaOrVastuuhenkilo: null,
      tapahtumanAjankohta: null,
      lisatiedot: null
    })
  })
  value?: Suoritusarviointi

  form: Partial<Suoritusarviointi> = {
    arvioitavatKokonaisuudet: [],
    arvioitavaTapahtuma: null,
    lisatiedot: null,
    arvioinninAntaja: undefined
  }

  arvioitavatKokonaisuudet: (ArvioitavaKokonaisuus | null)[] = []

  params = {
    saving: false,
    deleting: false
  }
  childDataReceived = false

  mounted() {
    this.form.tyoskentelyjakso = this.value?.tyoskentelyjakso
    if (this.form.tyoskentelyjakso) {
      this.form.tyoskentelyjakso.label = tyoskentelyjaksoLabel(this, this.value?.tyoskentelyjakso)
    }
    this.form.arvioitavatKokonaisuudet = this.value?.arvioitavatKokonaisuudet
    if (this.form.arvioitavatKokonaisuudet) {
      this.arvioitavatKokonaisuudet = this.form.arvioitavatKokonaisuudet?.map(
        (k) => k.arvioitavaKokonaisuus
      )
    }
    this.form.arvioitavaTapahtuma = this.value?.arvioitavaTapahtuma
    this.form.arvioinninAntaja = this.value?.arvioinninAntaja
    this.form.lisatiedot = this.value?.lisatiedot
    this.form.tapahtumanAjankohta = this.value?.tapahtumanAjankohta
    this.childDataReceived = true
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateArvioitavatKokonaisuudet() {
    const { $dirty, $error } = this.$v.arvioitavatKokonaisuudet as any
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    this.$v.arvioitavatKokonaisuudet.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    const validations = [this.validateForm(), this.$refs.tapahtumanAjankohta.validateForm()]

    if (validations.includes(false)) {
      return
    }

    this.$emit(
      'submit',
      {
        tyoskentelyjaksoId: this.form.tyoskentelyjakso?.id,
        arvioitavatKokonaisuudet: this.arvioitavatKokonaisuudet?.map((k) => {
          return { arvioitavaKokonaisuusId: k?.id }
        }),
        arvioitavaTapahtuma: this.form.arvioitavaTapahtuma,
        arvioinninAntajaId: this.form.arvioinninAntaja?.id,
        tapahtumanAjankohta: this.form.tapahtumanAjankohta,
        lisatiedot: this.form.lisatiedot
      } as Partial<Suoritusarviointi>,
      this.params
    )
  }

  onArviointipyyntoDelete() {
    this.$emit('delete', this.params)
  }

  async onKouluttajaSubmit(value: Kayttaja, params: any, modal: BModal) {
    params.saving = true
    try {
      const kouluttaja = (await postLahikouluttaja(value)).data

      if (
        !this.kouluttajatAndVastuuhenkilot.some(
          (kayttaja: Kayttaja) => kayttaja.id === kouluttaja.id
        )
      ) {
        this.kouluttajatAndVastuuhenkilot.push(kouluttaja)
      }
      this.form.arvioinninAntaja = kouluttaja
      modal.hide('confirm')
      toastSuccess(this, this.$t('uusi-kouluttaja-lisatty'))
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('uuden-kouluttajan-lisaaminen-epaonnistui')}: ${this.$t(message)}`
          : this.$t('uuden-kouluttajan-lisaaminen-epaonnistui')
      )
    }
    params.saving = false
  }

  get account() {
    return store.getters['auth/account']
  }

  get avatar() {
    if (this.account) {
      return this.account.avatar
    }
    return undefined
  }

  get formattedKouluttajatAndVastuuhenkilot() {
    return formatList(this, this.kouluttajatAndVastuuhenkilot)
  }
}
