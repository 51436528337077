








































import { Component, Vue } from 'vue-property-decorator'

import { deleteKurssikoodi, getKurssikoodi } from '@/api/virkailija'
import ElsaButton from '@/components/button/button.vue'
import KurssikoodiForm from '@/forms/kurssikoodi-form.vue'
import { Kurssikoodi } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    KurssikoodiForm
  }
})
export default class KurssikoodiView extends Vue {
  kurssikoodi: Kurssikoodi | null = null

  loading = true
  deleting = false

  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('kurssikoodien-yllapito'),
        to: { name: 'kurssikoodit' }
      },
      {
        text: this.$t('kurssikoodi'),
        active: true
      }
    ]
  }

  async mounted() {
    await this.fetchKurssikoodi()
    this.loading = false
  }

  async fetchKurssikoodi() {
    try {
      this.kurssikoodi = (await getKurssikoodi(this.$route?.params?.kurssikoodiId)).data
    } catch (err) {
      toastFail(this, this.$t('kurssikoodin-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'kurssikoodit' })
    }
  }

  async onKurssikoodiDelete() {
    if (
      await confirmDelete(
        this,
        this.$t('poista-kurssikoodi') as string,
        (this.$t('kurssikoodin') as string).toLowerCase()
      )
    ) {
      this.deleting = true
      try {
        await deleteKurssikoodi(this.$route?.params?.kurssikoodiId)
        toastSuccess(this, this.$t('kurssikoodi-poistettu-onnistuneesti'))
        this.$emit('skipRouteExitConfirm', true)
        this.$router.push({
          name: 'kurssikoodit'
        })
      } catch {
        toastFail(this, this.$t('kurssikoodin-poistaminen-epaonnistui'))
      }
      this.deleting = false
    }
  }
}
