





























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { clamp } from '@/utils/functions'

@Component
export default class ElsaProgressBar extends Vue {
  @Prop({ required: true })
  value!: number

  @Prop({ required: false })
  minRequired!: number

  @Prop({ required: false, default: false })
  showRequiredText!: boolean

  @Prop({ required: false, default: false })
  showRequiredDuration!: boolean

  @Prop({ required: false })
  color?: string

  @Prop({ required: false, default: 'white' })
  textColor?: string

  @Prop({ required: false })
  backgroundColor?: string

  @Prop({ required: false })
  customUnit?: string

  get ratio() {
    return clamp((this.value / this.minRequired) * 100, 0, 100)
  }
}
