




































import Vue from 'vue'
import Component from 'vue-class-component'
import Multiselect from 'vue-multiselect'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    Multiselect
  }
})
export default class ElsaFormMultiselect extends Vue {
  @Prop({ required: false, type: String })
  id?: string

  @Prop({ required: false, type: String })
  placeholder?: string

  @Prop({ required: false, type: String })
  tagPlaceholder?: string

  @Prop({ required: false, type: String })
  selectLabel?: string

  @Prop({ required: false, type: String })
  selectGroupLabel?: string

  @Prop({ required: false, type: String })
  selectedLabel?: string

  @Prop({ required: false, type: String })
  deselectLabel?: string

  @Prop({ required: false, type: String })
  deselectGroupLabel?: string

  @Prop({ required: false, type: Number })
  max?: number

  @Prop({ required: false, type: Boolean, default: undefined })
  state?: boolean

  @Prop({ required: false, type: Boolean, default: false })
  allowEmpty!: boolean

  @Prop({ required: false, type: Function })
  // eslint-disable-next-line @typescript-eslint/ban-types
  customLabel?: Function

  get isValid() {
    return this.state
  }

  get isInvalid() {
    return this.state === false
  }

  get placeholderText() {
    return this.placeholder || this.$t('valitse')
  }

  get tagPlaceholderText() {
    return ''
  }

  get selectLabelText() {
    return ''
  }

  get selectGroupLabelText() {
    return ''
  }

  get selectedLabelText() {
    return ''
  }

  get deselectLabelText() {
    return ''
  }

  get deselectGroupLabelText() {
    return ''
  }

  clearMultiselect() {
    this.$emit('valueToBeCleared', this.$attrs.value)
    this.$emit('input', null)
    this.$emit('clearMultiselect', null)
  }
}
