
















































































































































































































































































import { format } from 'date-fns'
import _get from 'lodash/get'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, email, between } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KoulutuspaikanArvioijat from '@/components/koejakson-vaiheet/koulutuspaikan-arvioijat.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import {
  AloituskeskusteluLomake,
  Kouluttaja,
  UserAccount,
  KoejaksonVaiheHyvaksyja,
  KoejaksonVaiheButtonStates,
  Opintooikeus
} from '@/types'
import { resolveOpintooikeusKaytossa } from '@/utils/opintooikeus'

@Component({
  mixins: [validationMixin],
  components: {
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormDatepicker,
    ElsaButton,
    ElsaPopover,
    ElsaFormMultiselect,
    ElsaConfirmationModal,
    KoulutuspaikanArvioijat
  }
})
export default class ArviointilomakeAloituskeskusteluForm extends Vue {
  $refs!: {
    koulutuspaikanArvioijat: KoulutuspaikanArvioijat
    koejaksonAlkamispaiva: ElsaFormDatepicker
    koejaksonPaattymispaiva: ElsaFormDatepicker
  }

  @Prop({ required: true, default: {} })
  data!: AloituskeskusteluLomake

  @Prop({ required: true, default: {} })
  account!: UserAccount

  @Prop({ required: false, default: () => [] })
  kouluttajat!: Kouluttaja[]

  validations() {
    return {
      form: {
        erikoistuvanSahkoposti: {
          required,
          email
        },
        koejaksonSuorituspaikka: {
          required
        },
        koejaksonToinenSuorituspaikka: {
          required: requiredIf(() => {
            return this.local.toinenSuorituspaikka
          })
        },
        suoritettuKokoaikatyossa: {
          required
        },
        tyotunnitViikossa: {
          required: requiredIf((value) => {
            return value.suoritettuKokoaikatyossa == false
          }),
          between: between(19.125, 38.25)
        },
        lahikouluttaja: {
          nimi: {
            required
          }
        },
        lahiesimies: {
          nimi: {
            required
          }
        },
        koejaksonOsaamistavoitteet: {
          required
        }
      }
    }
  }

  suorittettuKokoaikatyossaOptions = [
    { value: true, text: this.$t('kylla') },
    { value: false, text: this.$t('ei') }
  ]

  form: AloituskeskusteluLomake = {
    erikoistuvanErikoisala: '',
    erikoistuvanNimi: '',
    erikoistuvanOpiskelijatunnus: '',
    erikoistuvanSahkoposti: '',
    erikoistuvanYliopisto: '',
    id: null,
    koejaksonAlkamispaiva: '',
    koejaksonOsaamistavoitteet: '',
    koejaksonPaattymispaiva: '',
    koejaksonSuorituspaikka: '',
    koejaksonToinenSuorituspaikka: '',
    korjausehdotus: '',
    lahetetty: false,
    lahiesimies: {
      id: null,
      kayttajaUserId: null,
      kuittausaika: '',
      nimi: '',
      nimike: null,
      sopimusHyvaksytty: false
    },
    lahikouluttaja: {
      id: null,
      kayttajaUserId: null,
      kuittausaika: '',
      nimi: '',
      nimike: null,
      sopimusHyvaksytty: false
    },
    muokkauspaiva: '',
    suoritettuKokoaikatyossa: null,
    tyotunnitViikossa: null
  }

  local: any = {
    tyotunnitViikossa: String,
    toinenSuorituspaikka: false
  }

  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }
  childDataReceived = false

  get hasErrors() {
    return this.$v.$anyError
  }

  validateState(value: string) {
    const form = this.$v.form
    const { $dirty, $error } = _get(form, value) as any
    return $dirty ? ($error ? false : null) : null
  }

  deleteToinenSuorituspaikka() {
    this.form.koejaksonToinenSuorituspaikka = ''
    this.local.toinenSuorituspaikka = false
  }

  optionDisplayName(option: any) {
    return option.nimike ? option.nimi + ', ' + option.nimike : option.nimi
  }

  onTyotunnitViikossaChange(val: string) {
    this.form.tyotunnitViikossa = parseFloat(val.replace(',', '.'))
  }

  get opintooikeusKaytossa(): Opintooikeus | undefined {
    return resolveOpintooikeusKaytossa(this.account.erikoistuvaLaakari)
  }

  get maxKoejaksonAlkamispaiva() {
    const dateFormat = 'yyyy-MM-dd'
    if (!this.opintooikeusKaytossa?.opintooikeudenPaattymispaiva) {
      return null
    }

    const opintooikeudenPaattymispaivaDate = new Date(
      this.opintooikeusKaytossa.opintooikeudenPaattymispaiva
    )
    // Koejakson voi aloittaa viimeistään 6kk ennen määrä-aikaisen
    // opinto-oikeuden päättymispäivää, koska koejakson kesto on 6kk.
    opintooikeudenPaattymispaivaDate.setMonth(opintooikeudenPaattymispaivaDate.getMonth() - 6)
    return format(opintooikeudenPaattymispaivaDate, dateFormat)
  }

  get minKoejaksonPaattymispaiva() {
    const dateFormat = 'yyyy-MM-dd'
    const koejaksonAlkamispaiva = this.form.koejaksonAlkamispaiva
    if (!koejaksonAlkamispaiva) {
      return null
    }

    const koejaksonAlkamispaivaDate = new Date(koejaksonAlkamispaiva)
    // Koejakson kesto on vähintään 6kk.
    koejaksonAlkamispaivaDate.setMonth(koejaksonAlkamispaivaDate.getMonth() + 6)
    return format(koejaksonAlkamispaivaDate, dateFormat)
  }

  get maxKoejaksonPaattymispaiva() {
    const dateFormat = 'yyyy-MM-dd'
    const koejaksonAlkamispaiva = this.form.koejaksonAlkamispaiva
    if (!this.opintooikeusKaytossa?.opintooikeudenPaattymispaiva || !koejaksonAlkamispaiva) {
      return null
    }

    const koejaksonAlkamispaivaMaxDate = new Date(this.form.koejaksonAlkamispaiva)
    // Koejakson kesto on maksimissaan 2 vuotta.
    koejaksonAlkamispaivaMaxDate.setFullYear(koejaksonAlkamispaivaMaxDate.getFullYear() + 2)
    const opintooikeudenPaattymispaivaDate = new Date(
      this.opintooikeusKaytossa.opintooikeudenPaattymispaiva
    )
    // Mikäli maksimikesto 2 vuotta ylittää opinto-oikeuden päättymispäivän,
    // on maksimi päättymispäivä opinto-oikeuden päättymispäivä.
    if (koejaksonAlkamispaivaMaxDate > opintooikeudenPaattymispaivaDate) {
      return format(opintooikeudenPaattymispaivaDate, dateFormat)
    }

    return format(koejaksonAlkamispaivaMaxDate, dateFormat)
  }

  get opintooikeudenMyontamispaiva() {
    return this.opintooikeusKaytossa?.opintooikeudenMyontamispaiva
  }

  onLahikouluttajaSelect(lahikouluttaja: KoejaksonVaiheHyvaksyja) {
    this.form.lahikouluttaja = lahikouluttaja
  }

  onLahiesimiesSelect(lahiesimies: KoejaksonVaiheHyvaksyja) {
    this.form.lahiesimies = lahiesimies
  }

  saveAndExit() {
    this.$emit('saveAndExit', this.form, this.buttonStates)
  }

  onSubmit() {
    this.$emit('submit', this.form, this.buttonStates)
  }

  resetValidationsAndConfirmSave() {
    this.$v.$reset()
    this.$refs.koulutuspaikanArvioijat.$v.$reset()
    this.$refs.koejaksonAlkamispaiva.$v.$reset()
    this.$refs.koejaksonPaattymispaiva.$v.$reset()

    return this.$bvModal.show('confirm-send')
  }

  validateAndConfirmSend() {
    const validations = [
      this.validateForm(),
      this.$refs.koulutuspaikanArvioijat.validateForm(),
      this.$refs.koejaksonAlkamispaiva.validateForm(),
      this.$refs.koejaksonPaattymispaiva.validateForm()
    ]

    if (validations.includes(false)) {
      return
    }

    return this.$bvModal.show('confirm-send')
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  hideModal(id: string) {
    return this.$bvModal.hide(id)
  }

  mounted(): void {
    if (this.data !== null) {
      this.form = this.data
    }

    if (!this.form.erikoistuvanSahkoposti) {
      this.form.erikoistuvanSahkoposti = this.account.email
    }

    // Asetetaan ei-muokattavien kenttien arvot
    this.form.erikoistuvanNimi = `${this.account.firstName} ${this.account.lastName}`
    this.form.erikoistuvanOpiskelijatunnus = this.opintooikeusKaytossa?.opiskelijatunnus
    this.form.erikoistuvanErikoisala = this.opintooikeusKaytossa?.erikoisalaNimi
    this.form.erikoistuvanYliopisto = this.account.erikoistuvaLaakari.yliopisto

    if (!this.form.koejaksonAlkamispaiva) {
      this.form.koejaksonAlkamispaiva = this.data?.koejaksonAlkamispaiva
    }

    this.local.tyotunnitViikossa = this.form.tyotunnitViikossa?.toString().replace('.', ',')
    this.childDataReceived = true
  }
}
