


















































































































import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { required, requiredIf } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import {
  PaivakirjaAihekategoria,
  Paivakirjamerkinta,
  PaivakirjamerkintaForm,
  Teoriakoulutus
} from '@/types'
import { paivakirjamerkintaMuuAiheId } from '@/utils/constants'

@Component({
  components: {
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormDatepicker,
    ElsaButton,
    ElsaPopover,
    ElsaFormMultiselect
  },
  validations: {
    form: {
      oppimistapahtumanNimi: {
        required
      },
      aihekategoriat: {
        required
      },
      muunAiheenNimi: {
        required: requiredIf((value: Paivakirjamerkinta) => {
          return value.aihekategoriat.find((aihe) => aihe.id === paivakirjamerkintaMuuAiheId)
        })
      }
    }
  }
})
export default class PaivittainenMerkintaFormClass extends Vue {
  $refs!: {
    paivamaara: ElsaFormDatepicker
  }

  @Prop({ required: false, default: () => [] })
  aihekategoriat!: PaivakirjaAihekategoria[]

  @Prop({ required: false, default: () => [] })
  teoriakoulutukset!: Teoriakoulutus[]

  @Prop({
    required: false,
    type: Object
  })
  value!: Paivakirjamerkinta

  form: PaivakirjamerkintaForm = {
    paivamaara: null,
    oppimistapahtumanNimi: null,
    muunAiheenNimi: null,
    reflektio: null,
    yksityinen: false,
    aihekategoriat: [],
    teoriakoulutus: null
  }
  params = {
    saving: false
  }
  childDataReceived = false

  async mounted() {
    this.form = {
      ...this.value
    }
    this.childDataReceived = true
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    const validations = [this.validateForm(), this.$refs.paivamaara.validateForm()]

    if (validations.includes(false)) {
      return
    }
    this.$emit('submit', { ...this.form }, this.params)
  }

  onCancel() {
    this.$emit('cancel')
  }

  onAihekategoriatChanged() {
    if (!this.koulutuksetSelected) {
      this.form.teoriakoulutus = null
    }
    if (!this.muuAiheSelected) {
      this.form.muunAiheenNimi = null
    }
  }

  get koulutuksetSelected() {
    return this.form.aihekategoriat.find((aihe) => aihe.teoriakoulutus)
  }

  get muuAiheSelected() {
    return this.form.aihekategoriat.find((aihe) => aihe.muunAiheenNimi)
  }

  get aihekategoriatSorted() {
    return this.aihekategoriat?.sort(
      (a, b) => (a.jarjestysnumero ?? 0) - (b.jarjestysnumero ?? 0)
    )
  }
}
