























































































































































































































import { AxiosError } from 'axios'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'

import { getErikoistuvaLaakariLomake, postErikoistuvaLaakari } from '@/api/kayttajahallinta'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import {
  ElsaError,
  ErikoistuvaLaakariLomake,
  UusiErikoistuvaLaakari,
  OpintoopasSimple,
  Yliopisto
} from '@/types'
import { dateBetween } from '@/utils/date'
import { sortByAsc } from '@/utils/sort'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaFormGroup,
    ElsaFormError,
    ElsaButton,
    ElsaFormDatepicker,
    ElsaFormMultiselect
  },
  validations: {
    form: {
      etunimi: {
        required
      },
      sukunimi: {
        required
      },
      sahkopostiosoite: {
        required,
        email
      },
      sahkopostiosoiteUudelleen: {
        required,
        sameAsSahkopostiosoite: sameAs('sahkopostiosoite')
      },
      yliopisto: {
        required
      },
      erikoisala: {
        required
      },
      asetusId: {
        required
      },
      opintoopas: {
        required
      },
      osaamisenArvioinninOppaanPvm: {
        required
      }
    }
  }
})
export default class ErikoistuvaLaakariForm extends Mixins(validationMixin) {
  $refs!: {
    opiskeluoikeudenAlkamispaiva: ElsaFormDatepicker
    opiskeluoikeudenPaattymispaiva: ElsaFormDatepicker
    osaamisenArvioinninOppaanPvm: ElsaFormDatepicker
  }

  form: UusiErikoistuvaLaakari = {
    etunimi: null,
    sukunimi: null,
    sahkopostiosoite: null,
    sahkopostiosoiteUudelleen: null,
    yliopisto: null,
    erikoisala: null,
    opiskelijatunnus: null,
    opintooikeusAlkaa: null,
    opintooikeusPaattyy: null,
    asetusId: null,
    opintoopas: null,
    osaamisenArvioinninOppaanPvm: null
  }
  erikoistuvaLaakariLomake: null | ErikoistuvaLaakariLomake = null
  saving = false
  loading = true

  async mounted() {
    await this.fetchLomake()
    if (this.asetuksetSorted && this.asetuksetSorted.length > 0) {
      this.form.asetusId = this.asetuksetSorted[0].id ?? null
    }
    this.loading = false
  }

  async fetchLomake() {
    try {
      this.erikoistuvaLaakariLomake = (await getErikoistuvaLaakariLomake()).data
      if (this.yliopistot.length === 1) {
        this.form.yliopisto = this.yliopistot[0]
      }
    } catch {
      toastFail(this, this.$t('kayttajalomakkeen-hakeminen-epaonnistui'))
    }
  }

  async onSubmit() {
    const validations = [
      this.validateForm(),
      this.$refs.opiskeluoikeudenAlkamispaiva.validateForm(),
      this.$refs.opiskeluoikeudenPaattymispaiva.validateForm(),
      this.$refs.osaamisenArvioinninOppaanPvm.validateForm()
    ]
    if (validations.includes(false)) {
      return
    }

    try {
      let kayttajaId: number | null = null
      kayttajaId = (
        await postErikoistuvaLaakari({
          ...this.form,
          yliopistoId: this.form.yliopisto?.id,
          erikoisalaId: this.form.erikoisala?.id,
          opintoopasId: this.form.opintoopas?.id
        })
      ).data.kayttajaId

      toastSuccess(this, this.$t('kayttaja-lisatty-ja-kutsulinkki-lahetetty'))
      this.$emit('skipRouteExitConfirm', true)
      this.$router.push({
        name: 'erikoistuva-laakari',
        params: { kayttajaId: `${kayttajaId}` }
      })
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('uuden-kayttajan-lisaaminen-epaonnistui')}: ${this.$t(message)}`
          : this.$t('uuden-kayttajan-lisaaminen-epaonnistui')
      )
    }
    this.saving = false
  }

  onCancel() {
    this.$router.push({
      name: 'kayttajahallinta'
    })
  }

  get yliopistot() {
    return this.erikoistuvaLaakariLomake?.yliopistot ?? []
  }

  get erikoisalat() {
    return this.erikoistuvaLaakariLomake?.erikoisalat ?? []
  }

  get asetukset() {
    return this.erikoistuvaLaakariLomake?.asetukset ?? []
  }

  get opintooppaat() {
    return this.erikoistuvaLaakariLomake?.opintooppaat ?? []
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form?.[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  get maxAlkamispaiva() {
    return this.form.opintooikeusPaattyy
  }

  get minPaattymispaiva() {
    return this.form.opintooikeusAlkaa
  }

  onOpiskeluoikeudenAlkamispaivaChange(value: string) {
    this.$emit('skipRouteExitConfirm', false)

    this.form.osaamisenArvioinninOppaanPvm = value

    const opintoopas = this.opintooppaatFilteredAndSorted.find((o) =>
      dateBetween(value, o.voimassaoloAlkaa, o.voimassaoloPaattyy ?? undefined)
    )
    if (opintoopas) {
      this.form.opintoopas = opintoopas
    }
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  onErikoisalaSelected() {
    this.form.opintoopas = null
  }

  get opintooppaatFilteredAndSorted() {
    let opintooppaat: OpintoopasSimple[]
    if (this.form.erikoisala) {
      opintooppaat = this.opintooppaat.filter((o) => o.erikoisalaId === this.form.erikoisala?.id)
    } else {
      opintooppaat = this.opintooppaat
    }
    return opintooppaat.sort((a, b) => sortByAsc(a.nimi, b.nimi))
  }

  get asetuksetSorted() {
    if (this.asetukset) {
      return this.asetukset.sort((a, b) => (a.id ?? 0) - (b.id ?? 0)).reverse()
    } else {
      return []
    }
  }

  get erikoisalatSorted() {
    return this.erikoisalat.sort((a, b) => sortByAsc(a.nimi, b.nimi))
  }

  yliopistoLabel(value: Yliopisto) {
    return this.$t(`yliopisto-nimi.${value.nimi}`)
  }
}
