







































































































































































































































































































































































































































import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { Validation, validationMixin } from 'vuelidate'
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'

import { ELSA_API_LOCATION } from '@/api'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import AsiakirjatUpload from '@/components/asiakirjat/asiakirjat-upload.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaReturnToSenderModal from '@/components/modal/return-to-sender-modal.vue'
import ElsaPoissaolotDisplay from '@/components/poissaolot-display/poissaolot-display.vue'
import {
  Asiakirja,
  TerveyskeskuskoulutusjaksonHyvaksyminen,
  TerveyskeskuskoulutusjaksonHyvaksyntaForm,
  Tyoskentelyjakso
} from '@/types'
import { saveBlob } from '@/utils/blobs'
import { confirmExit } from '@/utils/confirm'
import {
  KaytannonKoulutusTyyppi,
  TerveyskeskuskoulutusjaksonTila,
  TyoskentelyjaksoTyyppi
} from '@/utils/constants'
import { mapFile, mapFiles } from '@/utils/fileMapper'
import {
  tyoskentelyjaksoKaytannonKoulutusLabel,
  tyoskentelypaikkaTyyppiLabel
} from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    AsiakirjatContent,
    AsiakirjatUpload,
    ElsaConfirmationModal,
    ElsaButton,
    ElsaFormError,
    ElsaFormDatepicker,
    ElsaFormGroup,
    ElsaReturnToSenderModal,
    ElsaPoissaolotDisplay,
    ErikoistuvaDetails
  }
})
export default class TerveyskeskuskoulutusjaksoForm extends Mixins(validationMixin) {
  $refs!: {
    laillistamispaiva: ElsaFormDatepicker
  }

  validations() {
    return {
      hyvaksynta: {
        tyoskentelyjaksot: {
          $each: {
            asiakirjat: {
              required,
              minLength: minLength(3)
            }
          }
        }
      },
      form: {
        laillistamispaiva: {
          required: requiredIf(() => {
            return this.hyvaksynta?.laillistamispaiva == null
          })
        },
        laillistamispaivanLiite: {
          required: requiredIf(() => {
            return this.laillistamispaivaAsiakirjat.length === 0
          })
        }
      }
    }
  }

  @Prop({ required: false, default: null })
  hyvaksynta!: TerveyskeskuskoulutusjaksonHyvaksyminen | null

  @Prop({ required: false, default: () => [] })
  reservedAsiakirjaNimetMutable!: string[] | undefined

  @Prop({ required: false, default: false })
  editable!: boolean

  @Prop({ required: false, default: '' })
  asiakirjaDataEndpointUrl!: string

  @Prop({ required: false, default: false })
  yek!: boolean

  form: TerveyskeskuskoulutusjaksonHyvaksyntaForm = {
    laillistamispaiva: null,
    laillistamispaivanLiite: null,
    tyoskentelyjaksoAsiakirjat: []
  }

  laillistamispaivaAsiakirjat: Asiakirja[] = []
  laillistaminenMuokattavissa = false
  skipRouteExitConfirm = true

  params = {
    saving: false
  }

  async mounted() {
    if (this.hyvaksynta?.laillistamispaivanLiite) {
      const data = Uint8Array.from(atob(this.hyvaksynta?.laillistamispaivanLiite), (c) =>
        c.charCodeAt(0)
      )
      this.laillistamispaivaAsiakirjat.push(
        mapFile(
          new File([data], this.hyvaksynta?.laillistamispaivanLiitteenNimi || '', {
            type: this.hyvaksynta?.laillistamispaivanLiitteenTyyppi
          })
        )
      )
    }
    if (this.hyvaksynta?.laillistamispaiva) {
      this.form.laillistamispaiva = this.hyvaksynta?.laillistamispaiva
    }
  }

  get existingFileNamesInCurrentView() {
    return this.hyvaksynta
      ? this.hyvaksynta.tyoskentelyjaksot?.flatMap((item) =>
          item.asiakirjat?.map((asiakirja) => asiakirja.nimi)
        )
      : []
  }

  get showVirkailijaKuittaus() {
    return (
      this.hyvaksynta?.tila ===
        TerveyskeskuskoulutusjaksonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA ||
      this.hyvaksynta?.tila === TerveyskeskuskoulutusjaksonTila.HYVAKSYTTY
    )
  }

  get showVastuuhenkiloKuittaus() {
    return this.hyvaksynta?.tila === TerveyskeskuskoulutusjaksonTila.HYVAKSYTTY
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    this.$v.hyvaksynta.$touch()
    return !this.$v.$anyError
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as Validation
    return $dirty ? ($error ? false : null) : null
  }

  validateTyoskentelyjaksoState(index: number) {
    const { $dirty, $error } = this.$v.hyvaksynta?.tyoskentelyjaksot?.$each[index] as Validation
    return $dirty ? ($error ? false : null) : null
  }

  onValidateAndConfirm(modalId: string) {
    const validations = [
      this.validateForm(),
      this.$refs.laillistamispaiva ? this.$refs.laillistamispaiva.validateForm() : true
    ]

    if (validations.includes(false) || this.hyvaksynta == null) {
      return
    }
    return this.$bvModal.show(modalId)
  }

  onSubmit() {
    this.params.saving = true

    const submitData = {
      lisatiedotVirkailijalta: this.hyvaksynta?.lisatiedotVirkailijalta,
      form: this.form
    }

    if (this.$isVirkailija() && !this.laillistaminenMuokattavissa) {
      this.form.laillistamispaiva = null
      this.form.laillistamispaivanLiite = null
    }

    this.$emit('submit', submitData, this.params)
  }

  onCancel() {
    this.$emit('cancel')
  }

  returnToSender(korjausehdotus: string) {
    if (this.$isVirkailija() && !this.laillistaminenMuokattavissa) {
      this.form.laillistamispaiva = null
      this.form.laillistamispaivanLiite = null
    }

    this.$emit(
      'submit',
      {
        korjausehdotus: korjausehdotus,
        form: this.form
      },
      this.params
    )
  }

  kaytannonKoulutusLabel(tyoskentelyjakso: Tyoskentelyjakso) {
    if (tyoskentelyjakso.kaytannonKoulutus) {
      return tyoskentelyjaksoKaytannonKoulutusLabel(this, tyoskentelyjakso.kaytannonKoulutus)
    }
    return undefined
  }

  tyyppiLabel(tyoskentelyjakso: Tyoskentelyjakso) {
    if (tyoskentelyjakso.tyoskentelypaikka?.tyyppi) {
      return tyoskentelypaikkaTyyppiLabel(this, tyoskentelyjakso.tyoskentelypaikka?.tyyppi)
    }
    return undefined
  }

  displayTyoskentelypaikkaTyyppiLabel(muu: string | null, tyyppi: TyoskentelyjaksoTyyppi) {
    return muu ? muu : tyoskentelypaikkaTyyppiLabel(this, tyyppi)
  }

  displayKaytannonKoulutus(value: KaytannonKoulutusTyyppi) {
    return tyoskentelyjaksoKaytannonKoulutusLabel(this, value)
  }

  onFilesAdded(tyoskentelyjakso: Tyoskentelyjakso, files: File[]) {
    const tjaksoAsiakirjat = this.form.tyoskentelyjaksoAsiakirjat.filter(
      (tjakso) => tjakso.id === tyoskentelyjakso.id
    )

    if (tjaksoAsiakirjat?.length === 0) {
      this.form.tyoskentelyjaksoAsiakirjat.push({
        id: tyoskentelyjakso.id,
        addedFiles: files,
        deletedFiles: []
      })
    } else {
      tjaksoAsiakirjat[0].addedFiles.push(...files)
    }

    tyoskentelyjakso.asiakirjat?.push(...mapFiles(files))
  }

  async onDeleteLiitetiedosto(tyoskentelyjakso: Tyoskentelyjakso, asiakirja: Asiakirja) {
    const tjaksoAsiakirjat = this.form.tyoskentelyjaksoAsiakirjat.filter(
      (tjakso) => tjakso.id === tyoskentelyjakso.id
    )

    if (asiakirja.id) {
      if (tjaksoAsiakirjat?.length === 0) {
        this.form.tyoskentelyjaksoAsiakirjat.push({
          id: tyoskentelyjakso.id,
          addedFiles: [],
          deletedFiles: [asiakirja.id]
        })
      } else {
        tjaksoAsiakirjat[0].deletedFiles.push(asiakirja.id)
      }
      tyoskentelyjakso.asiakirjat = tyoskentelyjakso.asiakirjat?.filter(
        (a) => a.nimi !== asiakirja.nimi
      )
    } else {
      tjaksoAsiakirjat[0].addedFiles = tjaksoAsiakirjat[0].addedFiles.filter(
        (file) => file.name !== asiakirja.nimi
      )
      tyoskentelyjakso.asiakirjat = tyoskentelyjakso.asiakirjat?.filter(
        (a) => a.nimi !== asiakirja.nimi
      )
    }
    this.onSkipRouteExitConfirm()
  }

  onLaillistamispaivaFilesAdded(files: File[]) {
    this.form.laillistamispaivanLiite = files[0]
    this.laillistamispaivaAsiakirjat.push(...mapFiles(files))
  }

  async onDeleteLaillistamispaivanLiite() {
    this.form.laillistamispaivanLiite = null
    this.laillistamispaivaAsiakirjat = []
    this.onSkipRouteExitConfirm()
  }

  async onDownloadLaillistamistodistus() {
    if (this.hyvaksynta?.laillistamispaivanLiite != null) {
      const data = Uint8Array.from(atob(this.hyvaksynta?.laillistamispaivanLiite), (c) =>
        c.charCodeAt(0)
      )
      saveBlob(
        this.hyvaksynta?.laillistamispaivanLiitteenNimi || '',
        data,
        this.hyvaksynta?.laillistamispaivanLiitteenTyyppi || ''
      )
    }
  }

  muokkaaLaillistamista() {
    this.laillistaminenMuokattavissa = true
  }

  async vaihdaRooli(id: number | undefined | null) {
    if (this.editable && !this.skipRouteExitConfirm) {
      if (!(await confirmExit(this))) {
        return
      }
    }

    this.$emit('skipRouteExitConfirm', true)
    window.location.href = `${ELSA_API_LOCATION}/api/login/impersonate?opintooikeusId=${id}&originalUrl=${window.location.href}`
  }

  onSkipRouteExitConfirm() {
    this.skipRouteExitConfirm = false
    this.$emit('skipRouteExitConfirm', false)
  }

  get isHakija() {
    return this.$isErikoistuva() || this.$isYekKoulutettava()
  }
}
