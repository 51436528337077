var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[(_vm.editing)?_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('elsa-form-group',{staticClass:"col-12 pr-md-3 pl-0",attrs:{"label":_vm.$t('opintooppaan-nimi'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-input-group',{staticClass:"mb-2"},[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"input-group-fi"},[_vm._v(_vm._s('FI'))])],1),_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('nimi')},model:{value:(_vm.opas.nimi),callback:function ($$v) {_vm.$set(_vm.opas, "nimi", $$v)},expression:"opas.nimi"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('nimi')}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-input-group',{attrs:{"prepend":"SV"}},[_c('b-form-input',{attrs:{"id":uid},model:{value:(_vm.opas.nimiSv),callback:function ($$v) {_vm.$set(_vm.opas, "nimiSv", $$v)},expression:"opas.nimiSv"}})],1)]}}],null,false,3389315802)}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 col-md-4 pr-sm-3",attrs:{"label":_vm.$t('voimassaolon-alkupaiva'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-datepicker',{ref:"voimassaoloAlkaa",attrs:{"id":uid,"state":_vm.validateState('voimassaoloAlkaa')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.opas.voimassaoloAlkaa),callback:function ($$v) {_vm.$set(_vm.opas, "voimassaoloAlkaa", $$v)},expression:"opas.voimassaoloAlkaa"}})]}}],null,false,428821948)}),_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 col-md-4 pl-sm-3",attrs:{"label":_vm.$t('voimassaolon-paattymispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-datepicker',{ref:"voimassaoloPaattyy",staticClass:"datepicker-range",attrs:{"id":uid,"required":false},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.opas.voimassaoloPaattyy),callback:function ($$v) {_vm.$set(_vm.opas, "voimassaoloPaattyy", $$v)},expression:"opas.voimassaoloPaattyy"}})]}}],null,false,424234744)})],1),_c('hr'),_c('h2',[_vm._v(_vm._s(_vm.$t('erikoisalan-vaatimukset')))]),(_vm.opas.id == null)?_c('elsa-button',{staticClass:"mt-2 mb-4",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.tuoOppaanTiedot()}}},[_vm._v(" "+_vm._s(_vm.$t('tuo-edellisen-oppaan-tiedot'))+" ")]):_vm._e(),_c('elsa-form-group',{attrs:{"label":_vm.$t('kaytannon-koulutuksen-vahimmaispituus'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('kaytannonKoulutuksenVahimmaispituusVuodet')},model:{value:(_vm.opas.kaytannonKoulutuksenVahimmaispituusVuodet),callback:function ($$v) {_vm.$set(_vm.opas, "kaytannonKoulutuksenVahimmaispituusVuodet", $$v)},expression:"opas.kaytannonKoulutuksenVahimmaispituusVuodet"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('kaytannonKoulutuksenVahimmaispituusVuodet')}},[_vm._v(" "+_vm._s(_vm.opas.kaytannonKoulutuksenVahimmaispituusVuodet ? _vm.$t('virheellinen-arvo') : _vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-col',{staticClass:"pl-1 mt-2",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('v'))])])],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('kaytannonKoulutuksenVahimmaispituusKuukaudet')},model:{value:(_vm.opas.kaytannonKoulutuksenVahimmaispituusKuukaudet),callback:function ($$v) {_vm.$set(_vm.opas, "kaytannonKoulutuksenVahimmaispituusKuukaudet", $$v)},expression:"opas.kaytannonKoulutuksenVahimmaispituusKuukaudet"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('kaytannonKoulutuksenVahimmaispituusKuukaudet')}},[_vm._v(" "+_vm._s(_vm.opas.kaytannonKoulutuksenVahimmaispituusKuukaudet ? _vm.$t('virheellinen-arvo') : _vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-col',{staticClass:"pl-1 mt-2",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('kk'))])])],1)],1)],1)]}}],null,false,1636517337)}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 col-md-6 pr-sm-3",attrs:{"label":_vm.$t('yek.tk-jakson-vahimmaispituus'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('terveyskeskuskoulutusjaksonVahimmaispituusVuodet')},model:{value:(_vm.opas.terveyskeskuskoulutusjaksonVahimmaispituusVuodet),callback:function ($$v) {_vm.$set(_vm.opas, "terveyskeskuskoulutusjaksonVahimmaispituusVuodet", $$v)},expression:"opas.terveyskeskuskoulutusjaksonVahimmaispituusVuodet"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('terveyskeskuskoulutusjaksonVahimmaispituusVuodet')}},[_vm._v(" "+_vm._s(_vm.opas.terveyskeskuskoulutusjaksonVahimmaispituusVuodet ? _vm.$t('virheellinen-arvo') : _vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-col',{staticClass:"pl-1 mt-2",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('v'))])])],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet')},model:{value:(_vm.opas.terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet),callback:function ($$v) {_vm.$set(_vm.opas, "terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet", $$v)},expression:"opas.terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet')}},[_vm._v(" "+_vm._s(_vm.opas.terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet ? _vm.$t('virheellinen-arvo') : _vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-col',{staticClass:"pl-1 mt-2",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('kk'))])])],1)],1)],1)]}}],null,false,1861687481)})],1),_c('elsa-form-group',{attrs:{"label":_vm.$t('yek.sairaalajakson-vahimmaispituus'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('yliopistosairaalajaksonVahimmaispituusVuodet')},model:{value:(_vm.opas.yliopistosairaalajaksonVahimmaispituusVuodet),callback:function ($$v) {_vm.$set(_vm.opas, "yliopistosairaalajaksonVahimmaispituusVuodet", $$v)},expression:"opas.yliopistosairaalajaksonVahimmaispituusVuodet"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('yliopistosairaalajaksonVahimmaispituusVuodet')}},[_vm._v(" "+_vm._s(_vm.opas.yliopistosairaalajaksonVahimmaispituusVuodet ? _vm.$t('virheellinen-arvo') : _vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-col',{staticClass:"pl-1 mt-2",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('v'))])])],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('yliopistosairaalajaksonVahimmaispituusKuukaudet')},model:{value:(_vm.opas.yliopistosairaalajaksonVahimmaispituusKuukaudet),callback:function ($$v) {_vm.$set(_vm.opas, "yliopistosairaalajaksonVahimmaispituusKuukaudet", $$v)},expression:"opas.yliopistosairaalajaksonVahimmaispituusKuukaudet"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('yliopistosairaalajaksonVahimmaispituusKuukaudet')}},[_vm._v(" "+_vm._s(_vm.opas.yliopistosairaalajaksonVahimmaispituusKuukaudet ? _vm.$t('virheellinen-arvo') : _vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-col',{staticClass:"pl-1 mt-2",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('kk'))])])],1)],1)],1)]}}],null,false,2684996665)}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 col-md-6 pr-sm-3",attrs:{"label":_vm.$t('yek.muun-koulutuksen-vahimmaispituus'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState(
                            'yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet'
                          )},model:{value:(
                          _vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet
                        ),callback:function ($$v) {_vm.$set(_vm.opas, "yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet", $$v)},expression:"\n                          opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet\n                        "}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState(
                            'yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet'
                          )}},[_vm._v(" "+_vm._s(_vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet ? _vm.$t('virheellinen-arvo') : _vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-col',{staticClass:"pl-1 mt-2",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('v'))])])],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState(
                            'yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet'
                          )},model:{value:(
                          _vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet
                        ),callback:function ($$v) {_vm.$set(_vm.opas, "yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet", $$v)},expression:"\n                          opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet\n                        "}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState(
                            'yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet'
                          )}},[_vm._v(" "+_vm._s(_vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet ? _vm.$t('virheellinen-arvo') : _vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-col',{staticClass:"pl-1 mt-2",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('kk'))])])],1)],1),_c('b-col'),_c('b-col',{staticClass:"mt-2"},[_c('span',[_vm._v(_vm._s('-'))])])],1)]}}],null,false,3009856512)}),_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 col-md-6 pr-sm-3",attrs:{"label":_vm.$t('yek.muun-koulutuksen-maksimipituus')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var uid = ref.uid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState(
                            'yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet'
                          )},model:{value:(
                          _vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet
                        ),callback:function ($$v) {_vm.$set(_vm.opas, "yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet", $$v)},expression:"\n                          opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet\n                        "}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState(
                            'yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet'
                          )}},[_vm._v(" "+_vm._s(_vm.$t('virheellinen-arvo'))+" ")])],1),_c('b-col',{staticClass:"pl-1 mt-2",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('v'))])])],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState(
                            'yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet'
                          )},model:{value:(
                          _vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet
                        ),callback:function ($$v) {_vm.$set(_vm.opas, "yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet", $$v)},expression:"\n                          opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet\n                        "}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState(
                            'yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet'
                          )}},[_vm._v(" "+_vm._s(_vm.$t('virheellinen-arvo'))+" ")])],1),_c('b-col',{staticClass:"pl-1 mt-2",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('kk'))])])],1)],1)],1)]}}],null,false,813265633)})],1),_c('elsa-form-group',{attrs:{"label":_vm.$t('teoriakoulutusten-vahimmaismaara'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var uid = ref.uid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"pr-1"},[_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('erikoisalanVaatimaTeoriakoulutustenVahimmaismaara')},model:{value:(_vm.opas.erikoisalanVaatimaTeoriakoulutustenVahimmaismaara),callback:function ($$v) {_vm.$set(_vm.opas, "erikoisalanVaatimaTeoriakoulutustenVahimmaismaara", $$v)},expression:"opas.erikoisalanVaatimaTeoriakoulutustenVahimmaismaara"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('erikoisalanVaatimaTeoriakoulutustenVahimmaismaara')}},[_vm._v(" "+_vm._s(_vm.opas.erikoisalanVaatimaTeoriakoulutustenVahimmaismaara ? _vm.$t('virheellinen-arvo') : _vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-col',{staticClass:"pl-1",attrs:{"cols":"1"}},[_c('span',[_vm._v(_vm._s('tuntia'))])])],1)],1)],1)]}}],null,false,1333711677)}),_c('div',{staticClass:"text-right"},[_c('elsa-button',{attrs:{"variant":"back","to":{
              name: _vm.opas.id == null ? 'erikoisala' : 'opintoopas'
            }}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")]),_c('elsa-button',{staticClass:"ml-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tallenna'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1):_c('div',[_c('h5',[_vm._v(_vm._s(_vm.$t('opintooppaan-nimi')))]),_c('p',[_vm._v(_vm._s(_vm.opas.nimi))]),_c('h5',[_vm._v(_vm._s(_vm.$t('voimassaolo')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$date(_vm.opas.voimassaoloAlkaa))+" - "+_vm._s(_vm.opas.voimassaoloPaattyy != null ? _vm.$date(_vm.opas.voimassaoloPaattyy) : '')+" ")]),_c('hr'),_c('h5',[_vm._v(_vm._s(_vm.$t('kaytannon-koulutuksen-vahimmaispituus')))]),_c('p',[(_vm.opas.kaytannonKoulutuksenVahimmaispituusVuodet > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.opas.kaytannonKoulutuksenVahimmaispituusVuodet)+" "+_vm._s('v')+" ")]):_vm._e(),(
              _vm.opas.kaytannonKoulutuksenVahimmaispituusKuukaudet > 0 ||
              _vm.opas.kaytannonKoulutuksenVahimmaispituusVuodet === 0
            )?_c('span',[_vm._v(" "+_vm._s(_vm.opas.kaytannonKoulutuksenVahimmaispituusKuukaudet)+" "+_vm._s('kk')+" ")]):_vm._e()]),_c('h5',[_vm._v(_vm._s(_vm.$t('yek.tk-jakson-vahimmaispituus')))]),_c('p',[(_vm.opas.terveyskeskuskoulutusjaksonVahimmaispituusVuodet > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.opas.terveyskeskuskoulutusjaksonVahimmaispituusVuodet)+" "+_vm._s('v')+" ")]):_vm._e(),(
              _vm.opas.terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet > 0 ||
              _vm.opas.terveyskeskuskoulutusjaksonVahimmaispituusVuodet === 0
            )?_c('span',[_vm._v(" "+_vm._s(_vm.opas.terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet)+" "+_vm._s('kk')+" ")]):_vm._e()]),_c('h5',[_vm._v(_vm._s(_vm.$t('yek.sairaalajakson-vahimmaispituus')))]),_c('p',[(_vm.opas.yliopistosairaalajaksonVahimmaispituusVuodet > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.opas.yliopistosairaalajaksonVahimmaispituusVuodet)+" "+_vm._s('v')+" ")]):_vm._e(),(
              _vm.opas.yliopistosairaalajaksonVahimmaispituusKuukaudet > 0 ||
              _vm.opas.yliopistosairaalajaksonVahimmaispituusVuodet === 0
            )?_c('span',[_vm._v(" "+_vm._s(_vm.opas.yliopistosairaalajaksonVahimmaispituusKuukaudet)+" "+_vm._s('kk')+" ")]):_vm._e()]),_c('h5',[_vm._v(_vm._s(_vm.$t('yek.muun-koulutuksen-pituus')))]),_c('p',[(_vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet)+" "+_vm._s('v')+" ")]):_vm._e(),(
              _vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet > 0 ||
              _vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet === 0
            )?_c('span',[_vm._v(" "+_vm._s(_vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet)+" "+_vm._s('kk')+" ")]):_vm._e(),(_vm.showMuunKoulutuksenMaksimipituus)?_c('span',[_c('span',[_vm._v(_vm._s('-'))]),(
                _vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet &&
                _vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet > 0
              )?_c('span',[_vm._v(" "+_vm._s(_vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet)+" "+_vm._s('v')+" ")]):_vm._e(),(
                (_vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet &&
                  _vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet > 0) ||
                _vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet === 0
              )?_c('span',[_vm._v(" "+_vm._s(_vm.opas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet)+" "+_vm._s('kk')+" ")]):_vm._e()]):_vm._e()]),_c('h5',[_vm._v(_vm._s(_vm.$t('teoriakoulutusten-vahimmaismaara')))]),_c('p',[_vm._v(_vm._s(_vm.opas.erikoisalanVaatimaTeoriakoulutustenVahimmaismaara)+" "+_vm._s(_vm.$t('tuntia')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }