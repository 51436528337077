var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',[_vm._l((_vm.form.yliopistotAndErikoisalat),function(yliopistoErikoisala,yliopistoErikoisalaIndex){return _c('div',{key:yliopistoErikoisala.id,staticClass:"border rounded p-3 mb-4"},[_c('elsa-form-group',{attrs:{"label":_vm.$t('erikoisala'),"required":_vm.allowEditing && !yliopistoErikoisala.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('div',{class:[_vm.allowEditing && !yliopistoErikoisala.id ? 'd-flex' : 'd-inline-flex']},[(_vm.allowEditing && !yliopistoErikoisala.id)?_c('div',{staticClass:"mr-2 w-100"},[_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.erikoisalatOptions,"label":"nimi","state":_vm.validateErikoisala(yliopistoErikoisalaIndex),"track-by":"id"},on:{"select":function($event){return _vm.onErikoisalaSelect($event, yliopistoErikoisalaIndex, yliopistoErikoisala)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(yliopistoErikoisala.erikoisala),callback:function ($$v) {_vm.$set(yliopistoErikoisala, "erikoisala", $$v)},expression:"yliopistoErikoisala.erikoisala"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.validateErikoisala(yliopistoErikoisalaIndex)}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])],1):(yliopistoErikoisala.erikoisala)?_c('div',[_c('span',{staticClass:"mr-1",attrs:{"id":uid}},[_vm._v(_vm._s(yliopistoErikoisala.erikoisala.nimi))])]):_vm._e(),(
              _vm.allowEditing &&
              _vm.form.yliopistotAndErikoisalat.length > 1 &&
              yliopistoErikoisala.erikoisala
            )?_c('elsa-button',{staticClass:"border-0 p-0",attrs:{"variant":"outline-primary","disabled":!_vm.allowErikoisalaDelete(yliopistoErikoisala.erikoisala)},on:{"click":function($event){return _vm.onDeleteErikoisala(
                yliopistoErikoisalaIndex,
                yliopistoErikoisala.erikoisala,
                yliopistoErikoisala.vastuuhenkilonTehtavat || []
              )}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trash-alt'],"fixed-width":"","size":"lg"}})],1):_vm._e()],1),_c('div',{staticClass:"mb-3 mt-2"},[(
              _vm.allowEditing &&
              yliopistoErikoisala.erikoisala &&
              _vm.erikoisalatWhichShouldBeAddedIntoAnotherVastuuhenkilo.includes(
                yliopistoErikoisala.erikoisala.id
              )
            )?_c('span',{staticClass:"text-danger text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('kay-ensin-lisaamassa-erikoisala-toiselle-vastuuhenkilolle'))+" ")]):(
              _vm.allowEditing &&
              yliopistoErikoisala.erikoisala &&
              _vm.erikoisalatWithTehtavatWhichShouldBeReassigned.includes(
                yliopistoErikoisala.erikoisala.id
              )
            )?_c('span',{staticClass:"text-danger text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('lisaa-tehtavat-ensin-toiselle-vastuuhenkilolle'))+" ")]):_vm._e()])]}}],null,true)}),(yliopistoErikoisala && yliopistoErikoisala.erikoisala)?_c('elsa-form-group',{attrs:{"label":_vm.$t('vastuualueet-elsassa')},scopedSlots:_vm._u([(
          _vm.allowEditing &&
          _vm.getVastuuhenkilotByErikoisala(yliopistoErikoisala.erikoisala.id).length === 0
        )?{key:"label-help",fn:function(){return [_c('elsa-popover',{staticClass:"align-top"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('taman-erikoisalan-tehtavia-ei-voi-muokata')))])])]},proxy:true}:null,{key:"default",fn:function(ref){
        var uid = ref.uid;
return [(
            yliopistoErikoisala.vastuuhenkilonTehtavat &&
            (!_vm.allowEditing ||
              _vm.getVastuuhenkilotByErikoisala(yliopistoErikoisala.erikoisala.id).length === 0)
          )?_c('div',[(!_vm.hasAnyTehtava(yliopistoErikoisala.vastuuhenkilonTehtavat))?_c('div',[_vm._v(" "+_vm._s(_vm.$t('ei-vastuualueita'))+" ")]):_vm._l((yliopistoErikoisala.vastuuhenkilonTehtavat),function(tehtava,index){return _c('div',{key:index,staticClass:"mb-1",attrs:{"id":uid}},[(tehtava)?_c('div',[_vm._v(" "+_vm._s(_vm.$t(("vastuualue." + (tehtava.nimi))))+" ")]):_vm._e()])})],2):(yliopistoErikoisala.erikoisala && yliopistoErikoisala.vastuuhenkilonTehtavat)?_c('div',_vm._l((_vm.getVastuuhenkilonTehtavatyypit(
              yliopistoErikoisala.erikoisala.id
            )),function(tehtava,tehtavaIndex){return _c('div',{key:tehtava.id},[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"value":tehtava,"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)},"change":function($event){return _vm.onTehtavaChanged(
                  $event,
                  yliopistoErikoisala,
                  tehtava.id,
                  yliopistoErikoisalaIndex,
                  tehtavaIndex
                )}},model:{value:(yliopistoErikoisala.vastuuhenkilonTehtavat[tehtavaIndex]),callback:function ($$v) {_vm.$set(yliopistoErikoisala.vastuuhenkilonTehtavat, tehtavaIndex, $$v)},expression:"yliopistoErikoisala.vastuuhenkilonTehtavat[tehtavaIndex]"}},[_vm._v(" "+_vm._s(_vm.$t(("vastuualue." + (tehtava.nimi))))+" ")]),(
                !_vm.tehtavatContains(yliopistoErikoisala, tehtava.id) &&
                _vm.existingTehtavaRemoved(yliopistoErikoisala, tehtava.id)
              )?_c('div',{staticClass:"pl-4"},[_c('div',{staticClass:"font-weight-500 mt-2"},[_vm._v(" "+_vm._s(_vm.$t('valitse-kenelle-haluat-siirtaa-vastuualueen'))+" ")]),_c('elsa-form-group',{staticClass:"mb-3 mt-2",attrs:{"required":true,"label":_vm.$t('vastuuhenkilo')}},[_c('elsa-form-multiselect',{attrs:{"options":_vm.vastuuhenkilotOptionsByErikoisalaId(
                      yliopistoErikoisala.erikoisala.id,
                      tehtava.id
                    ),"state":_vm.validateTehtavat(yliopistoErikoisalaIndex, tehtavaIndex),"disabled":_vm.disabled,"label":"label","track-by":tehtava.id},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(
                    _vm.form.erikoisalatForTehtavat[yliopistoErikoisalaIndex].reassignedTehtavat[
                      tehtavaIndex
                    ]
                  ),callback:function ($$v) {_vm.$set(_vm.form.erikoisalatForTehtavat[yliopistoErikoisalaIndex].reassignedTehtavat, 
                      tehtavaIndex
                    , $$v)},expression:"\n                    form.erikoisalatForTehtavat[yliopistoErikoisalaIndex].reassignedTehtavat[\n                      tehtavaIndex\n                    ]\n                  "}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.validateTehtavat(yliopistoErikoisalaIndex, tehtavaIndex)}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-vastuualue-ensin-toiselle-kayttajalle'))+" ")])],1)],1):_vm._e(),(_vm.showTehtavaRemovedFromVastuuhenkiloText(yliopistoErikoisala, tehtava.id))?_c('div',{staticClass:"pl-4 mb-2"},[_c('span',[_vm._v(" "+_vm._s(((_vm.$t( 'valinta-poistaa-vastuualueen-aiemmalta-vastuuhenkilolta' )) + ": " + (_vm.getNimiForVastuuhenkiloWithRemovedTehtava( yliopistoErikoisala.erikoisala.id, tehtava.id ))))+" ")])]):_vm._e()],1)}),0):_vm._e()]}}],null,true)}):_vm._e()],1)}),(_vm.allowEditing)?_c('elsa-button',{staticClass:"text-decoration-none shadow-none p-0",attrs:{"variant":"link","size":"md"},on:{"click":function($event){return _vm.addErikoisala(false)}}},[_c('font-awesome-icon',{attrs:{"icon":"plus","fixed-width":"","size":"sm"}}),_vm._v(" "+_vm._s(_vm.$t('useampi-erikoisala'))+" ")],1):_vm._e()],2):_c('div',{staticClass:"text-center mb-4"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }