var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t('henkilokohtainen-koulutussuunnitelma-liitteena-kuvaus'))+" "),_c('elsa-popover',[_vm._v(" "+_vm._s(_vm.$t('sallitut-tiedostoformaatit-pdf'))+" ")])],1),_c('asiakirjat-upload',{attrs:{"is-primary-button":false,"allow-multiples-files":false,"existing-file-names-in-current-view":_vm.motivaatiokirjeAsiakirjatTableItems.map(function (k) { return k.nimi; }),"existing-file-names-in-other-views":_vm.reservedAsiakirjaNimet,"button-text":_vm.$t('lisaa-liitetiedosto'),"wrong-file-type-error-message":_vm.$t('sallitut-tiedostoformaatit-pdf'),"allowed-file-types":['application/pdf']},on:{"selectedFiles":_vm.onKoulutussuunnitelmaFileAdded}}),_c('asiakirjat-content',{staticClass:"px-0 col-md-6 col-lg-12 col-xl-6",attrs:{"asiakirjat":_vm.koulutussuunnitelmaAsiakirjatTableItems,"sorting-enabled":false,"pagination-enabled":false,"enable-search":false,"show-info-if-empty":false},on:{"deleteAsiakirja":_vm.onKoulutussuunnitelmaFileDeleted}}),_c('hr',{staticClass:"mt-0"}),_c('elsa-form-group',{attrs:{"label":_vm.$t('motivaatiokirje')},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',[_vm._v(" "+_vm._s(_vm.$t('sallitut-tiedostoformaatit-pdf'))+" ")])]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('p',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('motivaatiokirje-kuvas')))]),_c('asiakirjat-upload',{attrs:{"is-primary-button":false,"allow-multiples-files":false,"existing-file-names-in-current-view":_vm.koulutussuunnitelmaAsiakirjatTableItems.map(function (k) { return k.nimi; }),"existing-file-names-in-other-views":_vm.reservedAsiakirjaNimet,"button-text":_vm.$t('lisaa-liitetiedosto'),"wrong-file-type-error-message":_vm.$t('sallitut-tiedostoformaatit-pdf'),"allowed-file-types":['application/pdf']},on:{"selectedFiles":_vm.onMotivaatiokirjeFileAdded}}),_c('asiakirjat-content',{staticClass:"px-0 col-md-6 col-lg-12 col-xl-6",attrs:{"asiakirjat":_vm.motivaatiokirjeAsiakirjatTableItems,"sorting-enabled":false,"pagination-enabled":false,"enable-search":false,"show-info-if-empty":false},on:{"deleteAsiakirja":_vm.onMotivaatiokirjeFileDeleted}}),_c('b-form-textarea',{attrs:{"id":uid,"rows":"3"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.motivaatiokirje),callback:function ($$v) {_vm.$set(_vm.form, "motivaatiokirje", $$v)},expression:"form.motivaatiokirje"}})]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('opiskelu-ja-tyohistoria')},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',[_vm._v(" "+_vm._s(_vm.$t('opiskelu-ja-tyohistoria-tooltip'))+" ")])]},proxy:true},{key:"label-right",fn:function(){return [_c('b-form-checkbox',{staticClass:"py-0",on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.opiskeluJaTyohistoriaYksityinen),callback:function ($$v) {_vm.$set(_vm.form, "opiskeluJaTyohistoriaYksityinen", $$v)},expression:"form.opiskeluJaTyohistoriaYksityinen"}},[_vm._v(" "+_vm._s(_vm.$t('piilota-kouluttajilta-kuvaus'))+" ")])]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-textarea',{attrs:{"id":uid,"rows":"3"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.opiskeluJaTyohistoria),callback:function ($$v) {_vm.$set(_vm.form, "opiskeluJaTyohistoria", $$v)},expression:"form.opiskeluJaTyohistoria"}})]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('vahvuudet')},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',[_vm._v(" "+_vm._s(_vm.$t('vahvuudet-tooltip'))+" ")])]},proxy:true},{key:"label-right",fn:function(){return [_c('b-form-checkbox',{staticClass:"py-0",model:{value:(_vm.form.vahvuudetYksityinen),callback:function ($$v) {_vm.$set(_vm.form, "vahvuudetYksityinen", $$v)},expression:"form.vahvuudetYksityinen"}},[_vm._v(" "+_vm._s(_vm.$t('piilota-kouluttajilta-kuvaus'))+" ")])]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-textarea',{attrs:{"id":uid,"rows":"3"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.vahvuudet),callback:function ($$v) {_vm.$set(_vm.form, "vahvuudet", $$v)},expression:"form.vahvuudet"}})]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('tulevaisuuden-visiointi')},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',[_vm._v(" "+_vm._s(_vm.$t('tulevaisuuden-visiointi-tooltip'))+" ")])]},proxy:true},{key:"label-right",fn:function(){return [_c('b-form-checkbox',{staticClass:"py-0",on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.tulevaisuudenVisiointiYksityinen),callback:function ($$v) {_vm.$set(_vm.form, "tulevaisuudenVisiointiYksityinen", $$v)},expression:"form.tulevaisuudenVisiointiYksityinen"}},[_vm._v(" "+_vm._s(_vm.$t('piilota-kouluttajilta-kuvaus'))+" ")])]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-textarea',{attrs:{"id":uid,"rows":"3"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.tulevaisuudenVisiointi),callback:function ($$v) {_vm.$set(_vm.form, "tulevaisuudenVisiointi", $$v)},expression:"form.tulevaisuudenVisiointi"}})]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('osaamisen-kartuttaminen')},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',[_vm._v(" "+_vm._s(_vm.$t('osaamisen-kartuttaminen-tooltip'))+" ")])]},proxy:true},{key:"label-right",fn:function(){return [_c('b-form-checkbox',{staticClass:"py-0",on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.osaamisenKartuttaminenYksityinen),callback:function ($$v) {_vm.$set(_vm.form, "osaamisenKartuttaminenYksityinen", $$v)},expression:"form.osaamisenKartuttaminenYksityinen"}},[_vm._v(" "+_vm._s(_vm.$t('piilota-kouluttajilta-kuvaus'))+" ")])]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-textarea',{attrs:{"id":uid,"rows":"3"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.osaamisenKartuttaminen),callback:function ($$v) {_vm.$set(_vm.form, "osaamisenKartuttaminen", $$v)},expression:"form.osaamisenKartuttaminen"}})]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('elamankentta')},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',[_vm._v(" "+_vm._s(_vm.$t('elamankentta-tooltip'))+" ")])]},proxy:true},{key:"label-right",fn:function(){return [_c('b-form-checkbox',{staticClass:"py-0",on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.elamankenttaYksityinen),callback:function ($$v) {_vm.$set(_vm.form, "elamankenttaYksityinen", $$v)},expression:"form.elamankenttaYksityinen"}},[_vm._v(" "+_vm._s(_vm.$t('piilota-kouluttajilta-kuvaus'))+" ")])]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-textarea',{attrs:{"id":uid,"rows":"3"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.elamankentta),callback:function ($$v) {_vm.$set(_vm.form, "elamankentta", $$v)},expression:"form.elamankentta"}})]}}])}),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[_c('elsa-button',{staticClass:"ml-2 mb-2",attrs:{"loading":_vm.params.saving,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tallenna'))+" ")]),_c('elsa-button',{staticClass:"mb-2",attrs:{"variant":"back"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCancel($event)}}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }