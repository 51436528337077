





















import Vue from 'vue'
import Avatar from 'vue-avatar'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import store from '@/store'

@Component({
  components: {
    Avatar
  }
})
export default class UserAvatar extends Vue {
  @Prop({ required: false, type: String })
  displayName?: string

  @Prop({ required: false, type: String })
  src?: string

  @Prop({ required: false, type: String })
  srcContentType?: string

  @Prop({ required: false, type: String })
  srcBase64?: string

  @Prop({ required: false, type: String })
  title?: string

  @Prop({ required: false, type: Number, default: 32 })
  imageSize?: number

  get imageSrc() {
    if (this.srcContentType && this.srcBase64) {
      return `data:${this.srcContentType};base64,${this.srcBase64}`
    } else if (this.src) {
      return this.src
    } else {
      return undefined
    }
  }

  get currentAccountName() {
    const account = store.getters['auth/account']
    if (account) {
      return `${account.firstName} ${account.lastName}`
    } else {
      return ''
    }
  }

  get displayNameOrAccountName() {
    return this.displayName ? this.displayName : this.currentAccountName
  }
}
