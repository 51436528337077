































import { Component, Vue } from 'vue-property-decorator'

import BCardSkeleton from '@/components/card/card.vue'
import ArvioinninTyokalutCard from '@/components/etusivu-cards/arvioinnin-tyokalut-card.vue'
import ArviointipyynnotCard from '@/components/etusivu-cards/arviointipyynnot-card.vue'
import ArvioitavatKokonaisuudetCard from '@/components/etusivu-cards/arvioitavat-kokonaisuudet-card.vue'
import ErikoistujienSeurantaCard from '@/components/etusivu-cards/erikoistujien-seuranta-card.vue'
import HenkilotiedotCard from '@/components/etusivu-cards/henkilotiedot-card.vue'
import KoejaksotCard from '@/components/etusivu-cards/koejaksot-card.vue'
import SeurantajaksotCard from '@/components/etusivu-cards/seurantajaksot-card.vue'
import VanhenevatKatseluoikeudetCard from '@/components/etusivu-cards/vanhenevat-katseluoikeudet-card.vue'

@Component({
  components: {
    ArvioinninTyokalutCard,
    ArviointipyynnotCard,
    BCardSkeleton,
    ErikoistujienSeurantaCard,
    HenkilotiedotCard,
    KoejaksotCard,
    SeurantajaksotCard,
    VanhenevatKatseluoikeudetCard,
    ArvioitavatKokonaisuudetCard
  }
})
export default class EtusivuKouluttaja extends Vue {}
