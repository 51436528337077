






























































































































































































































































































































































































































































































































































































































































































import _get from 'lodash/get'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, email } from 'vuelidate/lib/validators'

import { getVastuuhenkilonArvio } from '@/api/vastuuhenkilo'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaReturnToSenderModal from '@/components/modal/return-to-sender-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPoissaolotDisplay from '@/components/poissaolot-display/poissaolot-display.vue'
import store from '@/store'
import {
  Koejakso,
  KoejaksonVaiheAllekirjoitus,
  KoejaksonVaiheButtonStates,
  VastuuhenkilonArvioLomake,
  KoejaksonVaihe
} from '@/types'
import {
  KaytannonKoulutusTyyppi,
  LomakeTilat,
  LomakeTyypit,
  TyoskentelyjaksoTyyppi,
  phoneNumber
} from '@/utils/constants'
import { checkCurrentRouteAndRedirect } from '@/utils/functions'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'
import { toastFail, toastSuccess } from '@/utils/toast'
import {
  tyoskentelypaikkaTyyppiLabel,
  tyoskentelyjaksoKaytannonKoulutusLabel
} from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    ErikoistuvaDetails,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaButton,
    ElsaPoissaolotDisplay,
    ElsaConfirmationModal,
    ElsaReturnToSenderModal,
    KoejaksonVaiheAllekirjoitukset,
    AsiakirjatContent
  },
  validations: {
    vastuuhenkilonArvio: {
      vastuuhenkilonPuhelinnumero: {
        required,
        phoneNumber
      },
      vastuuhenkilonSahkoposti: {
        required,
        email
      },
      koejaksoHyvaksytty: {
        required
      },
      perusteluHylkaamiselle: {
        required: requiredIf((value) => {
          return value.koejaksoHyvaksytty === false
        })
      },
      hylattyArviointiKaytyLapiKeskustellen: {
        checked: function (val) {
          return this.$data.vastuuhenkilonArvio.koejaksoHyvaksytty === true || val === true
        }
      }
    }
  }
})
export default class VastuuhenkilonArvioVastuuhenkilo extends Mixins(validationMixin) {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('koejakson-vastuuhenkilon-arvio'),
      active: true
    }
  ]

  koejaksoHyvaksyttyVaihtoehdot = [
    {
      text: this.$t('hyvaksytty'),
      value: true
    },
    {
      text: this.$t('hylatty'),
      value: false
    }
  ]

  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }
  vastuuhenkilonArvio: VastuuhenkilonArvioLomake | null = null
  loading = true

  validateState(value: string) {
    const form = this.$v.vastuuhenkilonArvio
    const { $dirty, $error } = _get(form, value) as any
    return $dirty ? ($error ? false : null) : null
  }

  get account() {
    return store.getters['auth/account']
  }

  get editable() {
    return (
      this.vastuuhenkilonArvioTila === LomakeTilat.ODOTTAA_HYVAKSYNTAA ||
      this.vastuuhenkilonArvioTila === LomakeTilat.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
    )
  }

  get returned() {
    return this.vastuuhenkilonArvio?.vastuuhenkilonKorjausehdotus != null
  }

  get waitingForSignatures() {
    return this.vastuuhenkilonArvioTila === LomakeTilat.ODOTTAA_ALLEKIRJOITUSTA
  }

  get acceptedByEveryone() {
    return this.vastuuhenkilonArvioTila === LomakeTilat.ALLEKIRJOITETTU
  }

  get koejaksoData(): Koejakso {
    return store.getters['vastuuhenkilo/koejaksot']
  }

  get vastuuhenkilonArvioTila() {
    return store.getters['vastuuhenkilo/koejaksot'].find(
      (k: KoejaksonVaihe) =>
        k.id === this.vastuuhenkilonArvio?.id && k.tyyppi == LomakeTyypit.VASTUUHENKILON_ARVIO
    )?.tila
  }

  get vastuuhenkilonArvioId() {
    return Number(this.$route.params.id)
  }

  get erikoistuvanAvatar() {
    return this.vastuuhenkilonArvio?.erikoistuvanAvatar
  }

  get erikoistuvanNimi() {
    return this.vastuuhenkilonArvio?.erikoistuvanNimi
  }

  get allekirjoitukset(): KoejaksonVaiheAllekirjoitus[] {
    const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
      this,
      this.vastuuhenkilonArvio?.erikoistuvanNimi,
      this.vastuuhenkilonArvio?.erikoistuvanKuittausaika
    )
    const allekirjoitusVastuuhenkilo = allekirjoituksetHelper.mapAllekirjoitusVastuuhenkilo(
      this.vastuuhenkilonArvio?.vastuuhenkilo ?? null
    ) as KoejaksonVaiheAllekirjoitus

    return [allekirjoitusVastuuhenkilo, allekirjoitusErikoistuva].filter(
      (a): a is KoejaksonVaiheAllekirjoitus => a !== null
    )
  }

  get asiakirjaDataEndpointUrlTyoskentelyjakso() {
    return `/vastuuhenkilo/koejakso/vastuuhenkilonarvio/${this.vastuuhenkilonArvio?.id}/tyoskentelyjakso-liite`
  }

  get asiakirjaDataEndpointUrl() {
    return `/vastuuhenkilo/koejakso/vastuuhenkilonarvio/${this.vastuuhenkilonArvio?.id}/liite`
  }

  hideModal(id: string) {
    return this.$bvModal.hide(id)
  }

  onValidateAndConfirm(modalId: string) {
    this.$v.$touch()
    if (this.$v.$anyError) {
      return
    }

    return this.$bvModal.show(modalId)
  }

  displayTyoskentelypaikkaTyyppiLabel(muu: string | null, tyyppi: TyoskentelyjaksoTyyppi) {
    return muu ? muu : tyoskentelypaikkaTyyppiLabel(this, tyyppi)
  }

  displayKehittamistoimenpideKategoria(kategoria: string) {
    if (kategoria === 'MUU') return this.vastuuhenkilonArvio?.valiarviointi?.muuKategoria
    return this.$t('kehittamistoimenpidekategoria-' + kategoria)
  }

  displayKaytannonKoulutus(value: KaytannonKoulutusTyyppi) {
    return tyoskentelyjaksoKaytannonKoulutusLabel(this, value)
  }

  yhdistaPoissaolot(data: VastuuhenkilonArvioLomake) {
    let obj = data

    obj.koejaksonSuorituspaikat?.tyoskentelyjaksot.forEach((tj) => {
      tj.poissaolot = obj.koejaksonSuorituspaikat?.keskeytykset?.filter(
        (keskeytys) => keskeytys.tyoskentelyjaksoId == tj.id
      )
    })
    return obj
  }

  async onSign() {
    try {
      this.buttonStates.primaryButtonLoading = true
      await store.dispatch('vastuuhenkilo/putVastuuhenkilonArvio', this.vastuuhenkilonArvio)
      this.buttonStates.primaryButtonLoading = false
      this.$emit('skipRouteExitConfirm', true)
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('vastuuhenkilon-arvio-allekirjoitettu-onnistuneesti'))
    } catch {
      toastFail(this, this.$t('vastuuhenkilon-arvio-allekirjoitus-epaonnistui'))
    }
  }

  async onReturnToSender(korjausehdotus: string) {
    try {
      this.buttonStates.secondaryButtonLoading = true
      if (this.vastuuhenkilonArvio != null) {
        this.vastuuhenkilonArvio.vastuuhenkilonKorjausehdotus = korjausehdotus
      }
      await store.dispatch('vastuuhenkilo/putVastuuhenkilonArvio', this.vastuuhenkilonArvio)
      this.buttonStates.primaryButtonLoading = false
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('koejakso-palautettu-muokattavaksi'))
    } catch {
      toastFail(this, this.$t('koejakso-palautus-epaonnistui'))
    }
  }

  async mounted() {
    this.loading = true
    await store.dispatch('vastuuhenkilo/getKoejaksot')

    try {
      const { data } = await getVastuuhenkilonArvio(this.vastuuhenkilonArvioId)
      this.vastuuhenkilonArvio = data
      this.vastuuhenkilonArvio.vastuuhenkilonSahkoposti = this.account.email
      this.vastuuhenkilonArvio.vastuuhenkilonPuhelinnumero = this.account.phoneNumber
      this.vastuuhenkilonArvio = this.yhdistaPoissaolot(this.vastuuhenkilonArvio)
      this.loading = false
    } catch {
      toastFail(this, this.$t('vastuuhenkilon-arvion-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'koejakso' })
    }
  }
}
