













import { Vue, Component, Prop } from 'vue-property-decorator'

import TietosuojaselosteContent from '@/components/tietosuojaseloste/tietosuojaseloste-content.vue'

@Component({
  components: { TietosuojaselosteContent }
})
export default class TietosuojaselosteModal extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('tietosuojaseloste'),
      active: true
    }
  ]

  @Prop({ required: true, type: Boolean, default: false })
  value!: boolean
}
