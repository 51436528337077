


















































































































import { Component, Vue } from 'vue-property-decorator'

import { getSeurantajaksot } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import store from '@/store'
import { Seurantajakso } from '@/types'
import { SeurantajaksoTila } from '@/utils/constants'
import { sortByDateDesc } from '@/utils/date'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton
  }
})
export default class SeurantakeskustelutCard extends Vue {
  seurantajaksot: Seurantajakso[] = []
  naytaKuvaus = false
  loading = true

  async mounted() {
    this.loading = true
    try {
      this.seurantajaksot = (await getSeurantajaksot()).data.sort(
        (s1: Seurantajakso, s2: Seurantajakso) => {
          return sortByDateDesc(s1.alkamispaiva, s2.alkamispaiva)
        }
      )
    } catch {
      toastFail(this, this.$t('seurantakeskustelujen-hakeminen-epaonnistui'))
      this.seurantajaksot = []
    }
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  showHyvaksytty(seurantajakso: Seurantajakso) {
    return seurantajakso.tila === SeurantajaksoTila.HYVAKSYTTY
  }

  showKorjattavaa(seurantajakso: Seurantajakso) {
    return seurantajakso.tila === SeurantajaksoTila.PALAUTETTU_KORJATTAVAKSI
  }

  showKouluttajaArvioinut(seurantajakso: Seurantajakso) {
    return seurantajakso.tila === SeurantajaksoTila.ODOTTAA_YHTEISIA_MERKINTOJA
  }

  showOdottaaHyvaksyntaa(seurantajakso: Seurantajakso) {
    return seurantajakso.tila === SeurantajaksoTila.ODOTTAA_HYVAKSYNTAA
  }
}
