var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{attrs:{"lg":""}},[(!_vm.loading)?_c('b-col',[(_vm.editing)?_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('elsa-form-group',{staticClass:"col-12 pr-md-3 pl-0",attrs:{"label":_vm.$t('kategorian-nimi'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.kategoriaEditable)?_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.sortedKategoriat,"label":"nimi","track-by":"nimi","state":_vm.validateState('kategoria')},model:{value:(_vm.form.kategoria),callback:function ($$v) {_vm.$set(_vm.form, "kategoria", $$v)},expression:"form.kategoria"}}):_c('p',[_vm._v(_vm._s(_vm.suorite.kategoria.nimi))])]}}],null,false,2582155354)}),_c('elsa-form-group',{staticClass:"col-12 pr-md-3 pl-0",attrs:{"label":_vm.$t('suoritteen-nimi'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-input-group',{staticClass:"mb-2"},[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"input-group-fi"},[_vm._v(_vm._s('FI'))])],1),_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('nimi')},model:{value:(_vm.form.nimi),callback:function ($$v) {_vm.$set(_vm.form, "nimi", $$v)},expression:"form.nimi"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('nimi')}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])],1),_c('b-input-group',{attrs:{"prepend":"SV"}},[_c('b-form-input',{attrs:{"id":uid},model:{value:(_vm.form.nimiSv),callback:function ($$v) {_vm.$set(_vm.form, "nimiSv", $$v)},expression:"form.nimiSv"}})],1)]}}],null,false,3687821530)}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 col-md-4 pr-sm-3",attrs:{"label":_vm.$t('voimassaolon-alkupaiva'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-datepicker',{ref:"voimassaolonAlkamispaiva",attrs:{"id":uid,"state":_vm.validateState('voimassaolonAlkamispaiva')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.voimassaolonAlkamispaiva),callback:function ($$v) {_vm.$set(_vm.form, "voimassaolonAlkamispaiva", $$v)},expression:"form.voimassaolonAlkamispaiva"}})]}}],null,false,257562032)}),_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 col-md-4 pl-sm-3",attrs:{"label":_vm.$t('voimassaolon-paattymispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-datepicker',{ref:"voimassaolonPaattymispaiva",staticClass:"datepicker-range",attrs:{"id":uid,"required":false},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.voimassaolonPaattymispaiva),callback:function ($$v) {_vm.$set(_vm.form, "voimassaolonPaattymispaiva", $$v)},expression:"form.voimassaolonPaattymispaiva"}})]}}],null,false,415443363)})],1),_c('elsa-form-group',{staticClass:"col-12 pr-md-3 pl-0",attrs:{"label":_vm.$t('vaadittu-lukumaara')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid},model:{value:(_vm.form.vaadittulkm),callback:function ($$v) {_vm.$set(_vm.form, "vaadittulkm", $$v)},expression:"form.vaadittulkm"}})]}}],null,false,2677014958)}),_c('hr',{staticClass:"mt-6"}),_c('div',{staticClass:"text-right"},[_c('elsa-button',{attrs:{"variant":"back","to":{
              name: _vm.suorite.id == null ? 'erikoisala' : 'suorite',
              hash: _vm.suorite.id == null ? '#suoritteet' : ''
            }}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")]),_c('elsa-button',{staticClass:"ml-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tallenna'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1):_c('div',[_c('h5',[_vm._v(_vm._s(_vm.$t('kategorian-nimi')))]),_c('p',[_vm._v(_vm._s(_vm.suorite.kategoria.nimi))]),_c('hr'),_c('h5',[_vm._v(_vm._s(_vm.$t('suoritteen-nimi')))]),_c('p',[_vm._v(_vm._s(_vm.suorite.nimi))]),_c('h5',[_vm._v(_vm._s(_vm.$t('voimassaolo')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$date(_vm.suorite.voimassaolonAlkamispaiva))+" - "+_vm._s(_vm.suorite.voimassaolonPaattymispaiva != null ? _vm.$date(_vm.suorite.voimassaolonPaattymispaiva) : '')+" ")]),_c('h5',[_vm._v(_vm._s(_vm.$t('vaadittu-lukumaara')))]),_c('p',[_vm._v(_vm._s(_vm.suorite.vaadittulkm != null ? _vm.suorite.vaadittulkm : '-'))]),_c('hr')])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }