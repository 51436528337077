var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('h1',[_vm._v(_vm._s(_vm.$t('koejakso')))]),_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('koejakso-ingressi-virkailija')))]),_c('b-row',{attrs:{"lg":""}},[_c('b-col',{attrs:{"cols":"12","lg":"5"}},[_c('elsa-search-input',{staticClass:"mb-4 hakutermi",attrs:{"hakutermi":_vm.hakutermi,"placeholder":_vm.$t('hae-erikoistujan-nimella')},on:{"update:hakutermi":function($event){_vm.hakutermi=$event}}})],1),_c('b-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[(_vm.erikoisalat != null && _vm.erikoisalat.length > 1)?_c('div',{staticClass:"filter"},[_c('elsa-form-group',{staticClass:"mb-4",attrs:{"label":_vm.$t('erikoisala')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.erikoisalatSorted,"label":"nimi"},on:{"select":_vm.onErikoisalaSelect,"clearMultiselect":_vm.onErikoisalaReset},model:{value:(_vm.filtered.erikoisala),callback:function ($$v) {_vm.$set(_vm.filtered, "erikoisala", $$v)},expression:"filtered.erikoisala"}})]}}],null,false,1055674681)})],1):_vm._e()])],1),_c('b-row',[_c('b-col',[_c('h3',[_vm._v(_vm._s(_vm.$t('avoimet')))]),(!_vm.loading)?_c('div',[(_vm.avoimetRows === 0)?_c('b-alert',{attrs:{"variant":"dark","show":""}},[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),(_vm.hakutermi.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-hakutuloksia'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-avoimia-tarkistuksia'))+" ")])],1):_c('div',[(_vm.avoimetKoejaksot != null)?_c('b-table',{staticClass:"koejakson-vaiheet-table",attrs:{"fixed":"","items":_vm.avoimetKoejaksot.content,"fields":_vm.fields,"stacked":"md"},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(){return [_c('col',{attrs:{"span":"1","width":"43%"}}),_c('col',{attrs:{"span":"1","width":"27%"}}),_c('col',{attrs:{"span":"1","width":"10%"}}),_c('col',{attrs:{"span":"1","width":"20%"}})]},proxy:true},{key:"head()",fn:function(scope){return [_c('div',{staticClass:"border-top-0"},[_vm._v(_vm._s(scope.label))])]}},{key:"cell(erikoistuvanNimi)",fn:function(data){return [_c('b-link',{staticClass:"task-type",attrs:{"to":{
                    name: 'virkailijan-tarkistus',
                    params: { id: data.item.id }
                  }}},[_vm._v(" "+_vm._s(data.item.erikoistuvanNimi)+" ")])]}},{key:"cell(tila)",fn:function(data){return [_c('font-awesome-icon',{class:_vm.taskClass(data.item.tila),attrs:{"icon":_vm.taskIcon(data.item.tila)}}),_vm._v(" "+_vm._s(_vm.taskStatus(data.item.tila))+" ")]}},{key:"cell(pvm)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.pvm ? _vm.$date(data.item.pvm) : '')+" ")])]}},{key:"cell(actions)",fn:function(row){return [_c('elsa-button',{attrs:{"variant":"primary","to":{
                    name: 'virkailijan-tarkistus',
                    params: { id: row.item.id }
                  }}},[_vm._v(" "+_vm._s(_vm.$t('tarkista'))+" ")])]}}],null,false,3882395059)}):_vm._e(),_c('elsa-pagination',{attrs:{"current-page":_vm.currentAvoinPage,"per-page":_vm.perPage,"rows":_vm.avoimetRows},on:{"update:currentPage":_vm.onAvoinPageInput}})],1)],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1),_c('h3',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.$t('valmiit-allekirjoitetut-palautetut')))]),(!_vm.loading)?_c('div',[(_vm.muutRows === 0)?_c('b-alert',{attrs:{"variant":"dark","show":""}},[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),(_vm.hakutermi.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-hakutuloksia'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-koejaksoja'))+" ")])],1):_c('div',[(_vm.muutKoejaksot != null)?_c('b-table',{staticClass:"koejakson-vaiheet-table",attrs:{"fixed":"","items":_vm.muutKoejaksot.content,"fields":_vm.fields,"stacked":"md"},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(){return [_c('col',{attrs:{"span":"1","width":"43%"}}),_c('col',{attrs:{"span":"1","width":"27%"}}),_c('col',{attrs:{"span":"1","width":"10%"}}),_c('col',{attrs:{"span":"1","width":"20%"}})]},proxy:true},{key:"head()",fn:function(scope){return [_c('div',{staticClass:"border-top-0"},[_vm._v(_vm._s(scope.label))])]}},{key:"cell(erikoistuvanNimi)",fn:function(data){return [_c('b-link',{staticClass:"task-type",attrs:{"to":{
                    name: 'virkailijan-tarkistus',
                    params: { id: data.item.id }
                  }}},[_vm._v(" "+_vm._s(data.item.erikoistuvanNimi)+" ")])]}},{key:"cell(tila)",fn:function(data){return [_c('font-awesome-icon',{class:_vm.taskClass(data.item.tila),attrs:{"icon":_vm.taskIcon(data.item.tila)}}),_vm._v(" "+_vm._s(_vm.taskStatus(data.item.tila))+" ")]}},{key:"cell(pvm)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.pvm ? _vm.$date(data.item.pvm) : '')+" ")])]}}],null,false,2098334118)}):_vm._e(),_c('elsa-pagination',{attrs:{"current-page":_vm.currentMuutPage,"per-page":_vm.perPage,"rows":_vm.muutRows},on:{"update:currentPage":_vm.onMuutPageInput}})],1)],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }