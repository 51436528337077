import { render, staticRenderFns } from "./muokkaa-arvioitavaa-kokonaisuutta.vue?vue&type=template&id=39ed034b&scoped=true&"
import script from "./muokkaa-arvioitavaa-kokonaisuutta.vue?vue&type=script&lang=ts&"
export * from "./muokkaa-arvioitavaa-kokonaisuutta.vue?vue&type=script&lang=ts&"
import style0 from "./muokkaa-arvioitavaa-kokonaisuutta.vue?vue&type=style&index=0&id=39ed034b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ed034b",
  null
  
)

export default component.exports