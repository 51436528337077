var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[(_vm.editing)?_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[(_vm.kurssikoodi.id != null && _vm.kurssikoodi.tyyppi != null)?_c('elsa-form-group',{staticClass:"col-12 pr-md-3 pl-0",attrs:{"label":_vm.$t('tyyppi')},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t('kurssikoodi-tyyppi-' + _vm.kurssikoodi.tyyppi.nimi))+" ")]}}],null,false,3097823944)}):_c('elsa-form-group',{staticClass:"col-12 pr-md-3 pl-0",attrs:{"label":_vm.$t('tyyppi')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_vm._l((_vm.tyypit),function(tyyppi,index){return _c('b-form-radio',{key:index,attrs:{"state":_vm.validateState('tyyppi'),"name":tyyppi.nimi,"value":tyyppi},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.kurssikoodi.tyyppi),callback:function ($$v) {_vm.$set(_vm.kurssikoodi, "tyyppi", $$v)},expression:"kurssikoodi.tyyppi"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('kurssikoodi-tyyppi-' + tyyppi.nimi))+" ")])])}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('tyyppi')}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,830677801)}),_c('elsa-form-group',{staticClass:"col-md-12 col-lg-4 pr-md-3 pl-0",attrs:{"label":_vm.$t('tunniste'),"required":true},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',{staticStyle:{"white-space":"pre-line"}},[_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('kurssikoodi-tunniste-ohje')))]),_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('kurssikoodi-tunniste-ohje2')))]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('kurssikoodi-tunniste-ohje3')))]),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('kurssikoodi-tunniste-ohje4')))]),_c('li',[_vm._v(_vm._s(_vm.$t('kurssikoodi-tunniste-ohje5')))])]),_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('kurssikoodi-tunniste-ohje6')))]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('kurssikoodi-tunniste-ohje3')))]),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('kurssikoodi-tunniste-ohje7')))])])])]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('tunniste')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.kurssikoodi.tunniste),callback:function ($$v) {_vm.$set(_vm.kurssikoodi, "tunniste", $$v)},expression:"kurssikoodi.tunniste"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,1572619595)}),_c('div',{staticClass:"text-right"},[(_vm.kurssikoodi == null)?_c('elsa-button',{attrs:{"variant":"back","to":{
              name: 'kurssikoodit'
            }}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")]):_c('elsa-button',{attrs:{"variant":"back","to":{
              name: 'kurssikoodit',
              kurssikoodiId: _vm.kurssikoodi.id
            }}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")]),_c('elsa-button',{staticClass:"ml-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tallenna'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1):_c('div',[(_vm.kurssikoodi.tyyppi)?_c('elsa-form-group',{staticClass:"col-12 pr-md-3 pl-0",attrs:{"label":_vm.$t('tyyppi')},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t('kurssikoodi-tyyppi-' + _vm.kurssikoodi.tyyppi.nimi))+" ")]}}],null,false,3097823944)}):_vm._e(),_c('elsa-form-group',{staticClass:"col-12 pr-md-3 pl-0",attrs:{"label":_vm.$t('tunniste')},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.kurssikoodi.tunniste)+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }