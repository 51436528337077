































































































































































































import { AxiosError } from 'axios'
import { Component, Mixins } from 'vue-property-decorator'
import { required, email, sameAs } from 'vuelidate/lib/validators'

import { getKayttaja, patchPaakayttaja } from '@/api/kayttajahallinta'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KayttajahallintaKayttajaMixin from '@/mixins/kayttajahallinta-kayttaja'
import { KayttajahallintaUpdateKayttaja, ElsaError } from '@/types'
import { confirmExit } from '@/utils/confirm'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormError,
    ElsaFormGroup
  },
  validations: {
    form: {
      sahkoposti: {
        required,
        email
      },
      sahkopostiUudelleen: {
        required,
        email,
        sameAsSahkoposti: sameAs('sahkoposti')
      },
      eppn: {
        required
      }
    }
  }
})
export default class PaakayttajaView extends Mixins(KayttajahallintaKayttajaMixin) {
  items = [
    {
      text: this.$t('kayttajahallinta'),
      to: { name: 'kayttajahallinta' }
    },
    {
      text: this.$t('kayttaja'),
      active: true
    }
  ]

  form: Partial<KayttajahallintaUpdateKayttaja> = {
    sahkoposti: null,
    sahkopostiUudelleen: null,
    eppn: null
  }

  async mounted() {
    await this.fetchKayttaja()
    this.loading = false
  }

  async fetchKayttaja() {
    try {
      this.kayttajaWrapper = (await getKayttaja(this.$route?.params?.kayttajaId)).data
      this.initForm()
    } catch (err) {
      toastFail(this, this.$t('kayttajan-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'kayttajahallinta' })
    }
  }

  initForm() {
    const sahkoposti = this.sahkoposti
    this.form.sahkoposti = sahkoposti
    this.form.sahkopostiUudelleen = sahkoposti
    this.form.eppn = this.eppn
  }

  onEditUser() {
    this.editing = true
  }

  async onCancel() {
    if (this.skipRouteExitConfirm || (await confirmExit(this))) {
      this.initForm()
      this.$v.form.$reset()
      this.skipRouteExitConfirm = true
      this.editing = false
    }
  }

  async onSave() {
    if (!this.kayttajaWrapper?.kayttaja?.id || !this.validateForm()) {
      return
    }
    this.updatingKayttaja = true

    try {
      await patchPaakayttaja(this.kayttajaWrapper.kayttaja.id, {
        sahkoposti: this.form.sahkoposti,
        eppn: this.form.eppn
      })
      toastSuccess(this, this.$t('kayttajan-tiedot-paivitetty'))
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('tietojen-tallennus-epaonnistui')}: ${this.$t(message)}`
          : this.$t('tietojen-tallennus-epaonnistui')
      )
    }

    this.editing = false
    this.updatingKayttaja = false
    this.skipRouteExitConfirm = true
  }
}
