
















































import { Component, Vue } from 'vue-property-decorator'

import { deleteSuorite, getSuorite } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import SuoriteForm from '@/forms/suorite-form.vue'
import { SuoriteWithErikoisala } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    SuoriteForm
  }
})
export default class SuoriteView extends Vue {
  suorite: SuoriteWithErikoisala | null = null

  loading = true
  deleting = false

  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('opetussuunnitelmat'),
        to: { name: 'opetussuunnitelmat' }
      },
      {
        text: this.suorite?.kategoria?.erikoisala.nimi,
        to: { name: 'erikoisala' }
      },
      {
        text: this.$t('suorite'),
        active: true
      }
    ]
  }

  async mounted() {
    await this.fetchSuorite()
    this.loading = false
  }

  async fetchSuorite() {
    try {
      this.suorite = (await getSuorite(this.$route?.params?.suoriteId)).data
    } catch (err) {
      toastFail(this, this.$t('suoritteen-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'erikoisala', hash: '#suoritteet' })
    }
  }

  async onDelete() {
    if (
      await confirmDelete(
        this,
        this.$t('poista-suorite') as string,
        (this.$t('suoritteen') as string).toLowerCase()
      )
    ) {
      this.deleting = true
      try {
        await deleteSuorite(this.$route?.params?.suoriteId)
        toastSuccess(this, this.$t('suoritteen-poistaminen-onnistui'))
        this.$router.replace({ name: 'erikoisala', hash: '#suoritteet' })
      } catch (err) {
        toastFail(this, this.$t('suoritteen-poistaminen-epaonnistui'))
      }
      this.deleting = false
    }
  }
}
