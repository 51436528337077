











import Vue from 'vue'
import Component from 'vue-class-component'

import SeurantakeskustelutViewErikoistuva from '@/views/seurantakeskustelut/erikoistuva/seurantakeskustelut-erikoistuja.vue'
import SeurantakeskustelutViewKouluttajaVastuuhenkilo from '@/views/seurantakeskustelut/kouluttaja-vastuuhenkilo/seurantakeskustelut-kouluttaja-vastuuhenkilo.vue'

@Component({
  components: {
    SeurantakeskustelutViewErikoistuva,
    SeurantakeskustelutViewKouluttajaVastuuhenkilo
  }
})
export default class SeurantakeskustelutContainer extends Vue {}
