





















































import Component from 'vue-class-component'
import { Vue, Prop } from 'vue-property-decorator'
import { required, requiredIf } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import { Koulutuspaikka } from '@/types'
import { defaultKoulutuspaikka } from '@/utils/constants'

@Component({
  components: {
    ElsaFormGroup,
    ElsaButton,
    ElsaPopover
  },
  validations: {
    form: {
      nimi: {
        required
      },
      koulutussopimusOmanYliopistonKanssa: {
        required
      },
      yliopistoId: {
        required: requiredIf((value) => {
          return value.koulutussopimusOmanYliopistonKanssa === false
        })
      }
    }
  }
})
export default class KoulutuspaikkaDetails extends Vue {
  @Prop({ required: true, default: () => [] })
  yliopistot!: []

  @Prop({ required: true, default: null })
  koulutuspaikka!: Koulutuspaikka

  form: Koulutuspaikka = defaultKoulutuspaikka

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? !$error : null
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    if (this.$v.$anyError) {
      return false
    }
    return true
  }

  mounted(): void {
    this.form = this.koulutuspaikka
  }

  get yliopistotOptions() {
    return this.yliopistot.map((y: any) => ({
      text: this.$t(`yliopisto-nimi.${y.nimi}`),
      value: y.id
    }))
  }

  get linkki() {
    return `<a
              href="https://www.laaketieteelliset.fi/ammatillinen-jatkokoulutus/koulutuspaikat"
              target="_blank"
              rel="noopener noreferrer"
            >${this.$t('tarkista')}</a>`
  }
}
