
































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios, { AxiosError } from 'axios'
import { BModal } from 'bootstrap-vue'
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { Validation, validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'

import { postLahikouluttaja } from '@/api/erikoistuva'
import ElsaArviointiasteikonTaso from '@/components/arviointiasteikon-taso/arviointiasteikon-taso.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaReturnToSenderModal from '@/components/modal/return-to-sender-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import ArviointiForm from '@/forms/arviointi-form.vue'
import KouluttajaForm from '@/forms/kouluttaja-form.vue'
import TyoskentelyjaksoMixin from '@/mixins/tyoskentelyjakso'
import store from '@/store'
import {
  ElsaError,
  Kayttaja,
  Seurantajakso,
  SeurantajaksonTiedot,
  Suoritemerkinta,
  Suoritusarviointi,
  Tyoskentelyjakso
} from '@/types'
import { resolveRolePath } from '@/utils/apiRolePathResolver'
import { formatList } from '@/utils/kouluttajaAndVastuuhenkiloListFormatter'
import { toastFail, toastSuccess } from '@/utils/toast'
import { ajankohtaLabel } from '@/utils/tyoskentelyjakso'
import SuoritemerkintaDetails from '@/views/suoritemerkinnat/suoritemerkinta-details.vue'

@Component({
  components: {
    ArviointiForm,
    ElsaArviointiasteikonTaso,
    ElsaButton,
    ElsaConfirmationModal,
    ElsaFormDatepicker,
    ElsaFormError,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaPopover,
    ElsaReturnToSenderModal,
    ErikoistuvaDetails,
    KouluttajaForm,
    SuoritemerkintaDetails
  },
  validations() {
    const vm = this as SeurantajaksoForm
    return {
      form: {
        kouluttaja: {
          required
        },
        omaArviointi: {
          required
        },
        seurantakeskustelunYhteisetMerkinnat: {
          required: requiredIf(function () {
            return vm.$isErikoistuva() && !vm.uusiJakso
          })
        },
        edistyminenTavoitteidenMukaista: {
          required: requiredIf(function () {
            return !vm.$isErikoistuva()
          })
        },
        huolenaiheet: {
          required: requiredIf(function () {
            return !vm.$isErikoistuva() && vm.form.edistyminenTavoitteidenMukaista === false
          })
        },
        kouluttajanArvio: {
          required: requiredIf(function () {
            return !vm.$isErikoistuva()
          })
        }
      }
    }
  }
})
export default class SeurantajaksoForm extends Mixins(validationMixin, TyoskentelyjaksoMixin) {
  $refs!: {
    seuraavanKeskustelunAjankohta: ElsaFormDatepicker
  }

  @Prop({
    required: false,
    type: Object,
    default: () => ({
      osaamistavoitteet: [],
      muutOsaamistavoitteet: [],
      arvioinnit: [],
      arviointienMaara: 0,
      suoritemerkinnat: [],
      suoritemerkinnatMaara: 0,
      teoriakoulutukset: []
    })
  })
  seurantajaksonTiedot!: SeurantajaksonTiedot

  @Prop({
    required: false,
    default: () => ({
      alkamispaiva: null,
      paattymispaiva: null,
      koulutusjaksot: []
    })
  })
  seurantajakso!: Seurantajakso

  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({ required: false, default: () => [] })
  kouluttajat?: Kayttaja[]

  form: Partial<Seurantajakso> = {
    koulutusjaksot: [],
    omaArviointi: null,
    lisahuomioita: null,
    seuraavanJaksonTavoitteet: null,
    kouluttaja: null,
    seurantakeskustelunYhteisetMerkinnat: null,
    seuraavanKeskustelunAjankohta: null
  }

  selectedArviointi: Suoritusarviointi | null = null
  selectedSuoritemerkinta: Suoritemerkinta | null = null

  params = {
    saving: false,
    deleting: false
  }

  edistyminenVaihtoehdot = [
    {
      text: this.$t('kylla'),
      value: true
    },
    {
      text: this.$t('ei-huolenaiheita-on'),
      value: false
    }
  ]
  childDataReceived = false

  async mounted() {
    this.form = {
      ...this.seurantajakso
    }
    this.childDataReceived = true
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as Validation
    return $dirty ? ($error ? false : null) : null
  }

  get formattedKouluttajat(): Kayttaja[] {
    return formatList(this, this.kouluttajat)
  }

  get uusiJakso() {
    return this.form.id == null
  }

  get minAlkamispaiva(): Date {
    return new Date()
  }

  get minSeuraavaKeskustelu(): Date {
    const now = new Date()
    return new Date(now.setMonth(now.getMonth() + 6))
  }

  get account() {
    return store.getters['auth/account']
  }

  async onKouluttajaSubmit(value: Kayttaja, params: { saving: boolean }, modal: BModal) {
    params.saving = true
    try {
      const kouluttaja = (await postLahikouluttaja(value)).data
      this.formattedKouluttajat.push(kouluttaja)
      this.form.kouluttaja = kouluttaja
      modal.hide('confirm')
      toastSuccess(this, this.$t('uusi-kouluttaja-lisatty'))
      await store.dispatch('erikoistuva/getKouluttajat')
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('uuden-kouluttajan-lisaaminen-epaonnistui')}: ${this.$t(message)}`
          : this.$t('uuden-kouluttajan-lisaaminen-epaonnistui')
      )
    }
    params.saving = false
  }

  async showArviointi(suoritusarviointiId: number) {
    this.selectedArviointi = (
      await axios.get(`${resolveRolePath()}/suoritusarvioinnit/${suoritusarviointiId}`)
    ).data
    this.$bvModal.show('arviointi-modal')
  }

  hideArviointiModal() {
    this.$bvModal.hide('arviointi-modal')
  }

  showSuoritemerkinta(suoritemerkinta: Suoritemerkinta) {
    this.selectedSuoritemerkinta = suoritemerkinta
    this.$bvModal.show('suoritemerkinta-modal')
  }

  hideSuoritemerkintaModal() {
    this.$bvModal.hide('suoritemerkinta-modal')
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    const validations = [
      this.validateForm(),
      this.$refs.seuraavanKeskustelunAjankohta
        ? this.$refs.seuraavanKeskustelunAjankohta.validateForm()
        : true
    ]

    if (validations.includes(false)) {
      return
    }

    this.$bvModal.show('confirm-modal')
  }

  onSend() {
    this.$emit('submit', this.form, this.params)
  }

  onCancel() {
    this.$emit('cancel')
  }

  onUusiHaku() {
    this.$emit('uusiHaku')
  }

  onSeurantajaksoDelete() {
    this.$emit('delete', this.params)
  }

  returnToSender(korjausehdotus: string) {
    this.form.korjausehdotus = korjausehdotus
    this.$emit('submit', this.form, this.params)
  }

  ajankohtaLabel(tyoskentelyjakso: Tyoskentelyjakso) {
    return `(${ajankohtaLabel(this, tyoskentelyjakso)})`
  }
}
