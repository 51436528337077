


























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import { saveBlob } from '@/utils/blobs'

@Component({
  components: {
    UserAvatar,
    ElsaButton
  }
})
export default class ErikoistuvaDetails extends Vue {
  @Prop({ required: false, type: String })
  avatar!: string

  @Prop({ required: true, type: String })
  name!: string

  @Prop({ required: true, type: String })
  erikoisala!: string

  @Prop({ required: true, type: String })
  opiskelijatunnus!: string

  @Prop({ required: false, type: String })
  syntymaaika?: string

  @Prop({ required: true, type: String })
  yliopisto!: string

  @Prop({ required: false, type: String })
  kehittamistoimenpiteet?: string

  @Prop({ required: false, default: true })
  showBirthdate!: boolean

  @Prop({ required: false, type: String })
  opintooikeudenMyontamispaiva?: string

  @Prop({ required: false, type: String })
  laillistamispaiva?: string

  @Prop({ required: false, type: String })
  laillistamistodistus?: string

  @Prop({ required: false, type: String })
  laillistamistodistusNimi?: string

  @Prop({ required: false, type: String })
  laillistamistodistusTyyppi?: string

  @Prop({ required: false, type: Boolean, default: false })
  laillistamisenMuokkausSallittu?: boolean

  @Prop({ required: false, type: String })
  asetus?: string

  @Prop({ required: false, default: false })
  yek!: boolean

  laillistaminenMuokattavissa = false

  get displayName() {
    return this.name
  }

  get displayNameAndErikoisala() {
    return this.erikoisala !== '' ? `${this.displayName}, ${this.erikoisala}` : this.displayName
  }

  get showLaillistamispaiva() {
    return (
      this.laillistamispaiva &&
      this.laillistamistodistus &&
      this.laillistamistodistusNimi &&
      this.laillistamistodistusTyyppi &&
      !this.laillistaminenMuokattavissa
    )
  }

  async onDownloadLaillistamistodistus() {
    if (
      this.laillistamistodistus &&
      this.laillistamistodistusNimi &&
      this.laillistamistodistusTyyppi
    ) {
      const data = Uint8Array.from(atob(this.laillistamistodistus), (c) => c.charCodeAt(0))
      saveBlob(this.laillistamistodistusNimi, data, this.laillistamistodistusTyyppi)
    }
  }

  muokkaaLaillistamista() {
    this.laillistaminenMuokattavissa = true
    this.$emit('muokkaaLaillistamista')
  }
}
