var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('elsa-form-group',{attrs:{"label":_vm.$t('lahikouluttaja'),"add-new-enabled":true,"add-new-label":_vm.$t('lisaa-kouluttaja'),"required":true},on:{"submit":_vm.onKouluttajaSubmit},scopedSlots:_vm._u([{key:"modal-content",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
return [_c('kouluttaja-form',{attrs:{"kouluttajat":_vm.kouluttajat},on:{"submit":submit,"cancel":cancel}})]}},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.kouluttajat,"state":_vm.validateState('kouluttaja'),"label":"nimi","track-by":"id"},on:{"select":_vm.onKouluttajaSelect,"valueToBeCleared":_vm.clearKouluttaja},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [(option.nimi)?_c('div',[_vm._v(_vm._s(_vm.optionDisplayName(option)))]):_vm._e()]}}],null,true),model:{value:(_vm.form.kouluttaja),callback:function ($$v) {_vm.$set(_vm.form, "kouluttaja", $$v)},expression:"form.kouluttaja"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }