var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('koulutusjakson-nimi'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('nimi')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.nimi),callback:function ($$v) {_vm.$set(_vm.form, "nimi", $$v)},expression:"form.nimi"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelyjakso'),"add-new-enabled":true,"add-new-label":_vm.$t('lisaa-tyoskentelyjakso')},on:{"submit":_vm.onTyoskentelyjaksoSubmit},scopedSlots:_vm._u([{key:"modal-content",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
return [_c('tyoskentelyjakso-form',{attrs:{"kunnat":_vm.kunnat,"erikoisalat":_vm.erikoisalat},on:{"submit":submit,"cancel":cancel}})]}},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_vm._l((_vm.form.tyoskentelyjaksot),function(tyoskentelyjakso,index){return _c('div',{key:index,staticClass:"mb-2",attrs:{"id":uid}},[_c('elsa-form-multiselect',{attrs:{"id":uid,"value":_vm.form.tyoskentelyjaksot,"options":_vm.tyoskentelyjaksotFiltered,"label":"label","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)},"select":_vm.onTyoskentelyjaksoSelect},model:{value:(_vm.form.tyoskentelyjaksot[index]),callback:function ($$v) {_vm.$set(_vm.form.tyoskentelyjaksot, index, $$v)},expression:"form.tyoskentelyjaksot[index]"}}),(index !== 0)?_c('elsa-button',{staticClass:"text-decoration-none shadow-none p-0",attrs:{"variant":"link","size":"sm"},on:{"click":function($event){return _vm.deleteTyoskentelyjakso(index)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trash-alt'],"fixed-width":"","size":"sm"}}),_vm._v(" "+_vm._s(_vm.$t('poista-tyoskentelyjakso'))+" ")],1):_vm._e()],1)}),_c('elsa-button',{staticClass:"text-decoration-none shadow-none p-0",attrs:{"variant":"link","size":"sm"},on:{"click":_vm.addTyoskentelyjakso}},[_c('font-awesome-icon',{staticClass:"text-lowercase",attrs:{"icon":"plus","fixed-width":"","size":"sm"}}),_vm._v(" "+_vm._s(_vm.$t('useampi-jakso'))+" ")],1)]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('osaamistavoitteet-omalta-erikoisalalta')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{staticClass:"osaamistavoitteetSelect",attrs:{"id":uid,"options":_vm.arvioitavanKokonaisuudenKategoriatSorted,"group-values":"arvioitavatKokonaisuudet","group-label":"nimi","group-select":false,"label":"nimi","track-by":"id","allow-empty":true,"multiple":true,"taggable":true},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.$isLabel)?_c('span',[_vm._v(_vm._s(props.option.$groupLabel))]):_c('span',{staticClass:"d-inline-block ml-3"},[_vm._v(_vm._s(props.option.nimi))])]}}],null,true),model:{value:(_vm.form.osaamistavoitteet),callback:function ($$v) {_vm.$set(_vm.form, "osaamistavoitteet", $$v)},expression:"form.osaamistavoitteet"}})]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('muut-osaamistavoitteet')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-textarea',{attrs:{"id":uid,"rows":"5"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.muutOsaamistavoitteet),callback:function ($$v) {_vm.$set(_vm.form, "muutOsaamistavoitteet", $$v)},expression:"form.muutOsaamistavoitteet"}})]}}])}),_c('hr'),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[_c('elsa-button',{staticClass:"ml-2 mb-2",attrs:{"loading":_vm.params.saving,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tallenna-koulutusjakso'))+" ")]),_c('elsa-button',{staticClass:"mb-2",attrs:{"variant":"back"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCancel($event)}}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }