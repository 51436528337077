var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kayttaja"},[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('kayttaja')))]),_c('hr'),(_vm.kayttajaWrapper)?_c('div',[_c('elsa-form-group',{attrs:{"label":_vm.$t('tilin-tila')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{class:_vm.tilaColor,attrs:{"id":uid}},[_vm._v(_vm._s(_vm.tilinTilaText))])]}}],null,false,6220229)}),(_vm.rooli)?_c('elsa-form-group',{attrs:{"label":_vm.$t('rooli')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.rooli))])]}}],null,false,1168796380)}):_vm._e(),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"label":_vm.$t('etunimi')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.etunimi))])]}}],null,false,1371021964)}),_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pl-md-3",attrs:{"label":_vm.$t('sukunimi')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.sukunimi))])]}}],null,false,3972014864)})],1),_c('elsa-form-group',{attrs:{"required":_vm.editing && _vm.isKutsuttu,"label":_vm.$t('yliopiston-kayttajatunnus')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.editing && _vm.isKutsuttu)?_c('div',[_c('b-form-input',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"id":uid,"state":_vm.validateState('eppn')},on:{"input":function($event){_vm.skipRouteExitConfirm = false}},model:{value:(_vm.form.eppn),callback:function ($$v) {_vm.$set(_vm.form, "eppn", $$v)},expression:"form.eppn"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])],1):_c('div',[_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.form.eppn))])])]}}],null,false,2205645359)}),(_vm.editing)?_c('div',[_c('elsa-form-group',{attrs:{"label":_vm.$t('sahkopostiosoite'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sahkoposti')},on:{"input":function($event){_vm.skipRouteExitConfirm = false}},model:{value:(_vm.form.sahkoposti),callback:function ($$v) {_vm.$set(_vm.form, "sahkoposti", $$v)},expression:"form.sahkoposti"}}),(_vm.$v.form.sahkoposti && !_vm.$v.form.sahkoposti.required)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")]):_vm._e(),(_vm.$v.form.sahkoposti && !_vm.$v.form.sahkoposti.email)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('sahkopostiosoite-ei-kelvollinen'))+" ")]):_vm._e()]}}],null,false,3919665580)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('sahkopostiosoite-uudelleen'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sahkopostiUudelleen')},on:{"input":function($event){_vm.skipRouteExitConfirm = false}},model:{value:(_vm.form.sahkopostiUudelleen),callback:function ($$v) {_vm.$set(_vm.form, "sahkopostiUudelleen", $$v)},expression:"form.sahkopostiUudelleen"}}),(_vm.$v.form.sahkopostiUudelleen && !_vm.$v.form.sahkopostiUudelleen.required)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")]):_vm._e(),(_vm.$v.form.sahkopostiUudelleen && !_vm.$v.form.sahkopostiUudelleen.email)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('sahkopostiosoite-ei-kelvollinen'))+" ")]):_vm._e(),(
                    _vm.$v.form.sahkopostiUudelleen &&
                    _vm.$v.form.sahkopostiUudelleen.required &&
                    _vm.$v.form.sahkopostiUudelleen.email &&
                    !_vm.$v.form.sahkopostiUudelleen.sameAsSahkoposti
                  )?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('sahkopostiosoitteet-eivat-tasmaa'))+" ")]):_vm._e()]}}],null,false,1161975200)})],1):_c('div',[_c('elsa-form-group',{attrs:{"label":_vm.$t('sahkopostiosoite')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.form.sahkoposti)+" ")])]}}],null,false,1583341916)})],1),_c('hr'),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[(_vm.editing)?_c('elsa-button',{staticClass:"mb-3 ml-3",attrs:{"variant":"primary","loading":_vm.updatingKayttaja},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t('tallenna'))+" ")]):_c('elsa-button',{staticClass:"mb-3 ml-3",attrs:{"variant":"primary","disabled":_vm.updatingTila},on:{"click":_vm.onEditUser}},[_vm._v(" "+_vm._s(_vm.$t('muokkaa-kayttajaa'))+" ")]),(_vm.isPassiivinen)?_c('elsa-button',{staticClass:"mb-3",attrs:{"variant":"outline-success","loading":_vm.updatingTila,"disabled":_vm.updatingKayttaja},on:{"click":_vm.onActivateKayttaja}},[_vm._v(" "+_vm._s(_vm.$t('aktivoi-kayttaja'))+" ")]):(_vm.isAktiivinen || _vm.isKutsuttu)?_c('elsa-button',{staticClass:"mb-3",attrs:{"variant":"outline-danger","loading":_vm.updatingTila,"disabled":_vm.updatingKayttaja},on:{"click":_vm.onPassivateKayttaja}},[_vm._v(" "+_vm._s(_vm.$t('passivoi-kayttaja'))+" ")]):_vm._e(),(_vm.editing)?_c('elsa-button',{staticClass:"mb-3 mr-3",attrs:{"variant":"back","disabled":_vm.updatingKayttaja},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCancel($event)}}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")]):_vm._e(),(!_vm.editing)?_c('elsa-button',{staticClass:"mb-3 mr-auto font-weight-500 kayttajahallinta-link",attrs:{"disabled":_vm.updatingTila,"to":{ name: 'kayttajahallinta', hash: '#paakayttajat' },"variant":"link"}},[_vm._v(" "+_vm._s(_vm.$t('palaa-kayttajahallintaan'))+" ")]):_vm._e()],1)],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)])],1),_c('b-row',[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }