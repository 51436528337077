







































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ElsaArviointiasteikonTasoTooltipContent from '@/components/arviointiasteikon-taso/arviointiasteikon-taso-tooltip.vue'
import ElsaArviointiasteikonTaso from '@/components/arviointiasteikon-taso/arviointiasteikon-taso.vue'
import ElsaBadge from '@/components/badge/badge.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import ElsaVaativuustasoTooltipContent from '@/components/vaativuustaso/vaativuustaso-tooltip-content.vue'
import ElsaVaativuustaso from '@/components/vaativuustaso/vaativuustaso.vue'
import { Suoritemerkinta } from '@/types'
import { ArviointiasteikkoTyyppi, vaativuustasot } from '@/utils/constants'
import { tyoskentelyjaksoLabel } from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    ElsaFormGroup,
    ElsaPopover,
    ElsaBadge,
    ElsaArviointiasteikonTaso,
    ElsaVaativuustaso,
    ElsaButton,
    ElsaVaativuustasoTooltipContent,
    ElsaArviointiasteikonTasoTooltipContent
  }
})
export default class SuoritemerkintaDetails extends Vue {
  @Prop({ required: true, default: () => [] })
  value!: Suoritemerkinta

  suoritemerkinta: Suoritemerkinta | null = null

  vaativuustasot = vaativuustasot

  mounted() {
    this.suoritemerkinta = {
      ...this.value
    }
  }

  get suoritemerkintaWrapper() {
    if (this.suoritemerkinta) {
      return {
        ...this.suoritemerkinta,
        tyoskentelyjakso: {
          ...this.suoritemerkinta.tyoskentelyjakso,
          label: tyoskentelyjaksoLabel(this, this.suoritemerkinta.tyoskentelyjakso)
        }
      }
    } else {
      return undefined
    }
  }

  get arviointiAsteikonNimi() {
    return this.suoritemerkinta?.arviointiasteikko?.nimi === ArviointiasteikkoTyyppi.EPA
      ? this.$t('luottamuksen-taso')
      : this.$t('etappi')
  }
}
