var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"koulutusjakso"},[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('koulutusjakso')))]),_c('hr'),(_vm.koulutusjakso)?_c('div',[_c('elsa-form-group',{attrs:{"label":_vm.$t('koulutusjakson-nimi')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.koulutusjakso.nimi))])]}}],null,false,263935104)}),(_vm.koulutusjakso.tyoskentelyjaksot.length > 0)?_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelyjaksot')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('div',{attrs:{"id":uid}},_vm._l((_vm.koulutusjakso.tyoskentelyjaksot),function(tyoskentelyjakso){return _c('div',{key:tyoskentelyjakso.id},[_vm._v(" "+_vm._s(tyoskentelyjakso.tyoskentelypaikka.nimi)+" ("+_vm._s(tyoskentelyjakso.alkamispaiva ? _vm.$date(tyoskentelyjakso.alkamispaiva) : '')+" – "),_c('span',{class:tyoskentelyjakso.paattymispaiva ? '' : 'text-lowercase'},[_vm._v(" "+_vm._s(tyoskentelyjakso.paattymispaiva ? _vm.$date(tyoskentelyjakso.paattymispaiva) : _vm.$t('kesken'))+") ")])])}),0)]}}],null,false,2270414406)}):_vm._e(),(_vm.koulutusjakso.osaamistavoitteet.length > 0)?_c('elsa-form-group',{attrs:{"label":_vm.$t('osaamistavoitteet-omalta-erikoisalalta')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('div',{attrs:{"id":uid}},_vm._l((_vm.koulutusjakso.osaamistavoitteet),function(osaamistavoite){return _c('b-badge',{key:osaamistavoite.id,staticClass:"font-weight-400 mr-2",attrs:{"pill":"","variant":"light"}},[_vm._v(" "+_vm._s(osaamistavoite.nimi)+" ")])}),1)]}}],null,false,2591733573)}):_vm._e(),(_vm.koulutusjakso.muutOsaamistavoitteet)?_c('elsa-form-group',{attrs:{"label":_vm.$t('muut-osaamistavoitteet')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{staticClass:"text-preline",attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.koulutusjakso.muutOsaamistavoitteet)+" ")])]}}],null,false,530493369)}):_vm._e(),_c('hr'),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[(!_vm.account.impersonated)?_c('elsa-button',{staticClass:"ml-2 mb-3",attrs:{"disabled":_vm.koulutusjakso.lukittu,"to":{ name: 'muokkaa-koulutusjaksoa' },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('muokkaa-koulutusjaksoa'))+" ")]):_vm._e(),(!_vm.account.impersonated)?_c('elsa-button',{staticClass:"mb-3",attrs:{"disabled":_vm.koulutusjakso.lukittu,"loading":_vm.deleting,"variant":"outline-danger"},on:{"click":_vm.onKoulutusjaksoDelete}},[_vm._v(" "+_vm._s(_vm.$t('poista-koulutusjakso'))+" ")]):_vm._e(),_c('elsa-button',{staticClass:"mb-3 mr-auto font-weight-500 koulutusjaksot-link",attrs:{"to":{ name: 'koulutussuunnitelma' },"variant":"link"}},[_vm._v(" "+_vm._s(_vm.$t('palaa-koulutusjaksoihin'))+" ")])],1)],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)])],1),(_vm.koulutusjakso != null && _vm.koulutusjakso.lukittu && !_vm.account.impersonated)?_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex flex-row mb-4"},[_c('em',{staticClass:"align-middle"},[_c('font-awesome-icon',{staticClass:"text-muted mr-1",attrs:{"icon":"info-circle","fixed-width":""}})],1),_c('div',[_c('span',{staticClass:"text-size-sm"},[_vm._v(_vm._s(_vm.$t('koulutusjakso-on-lukittu')))])])])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }