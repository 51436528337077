var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arvioinnit"},[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('arvioinnit')))]),_c('p',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('arvioinnit-kuvaus')))]),(!_vm.account.impersonated)?_c('elsa-button',{staticClass:"mb-4",attrs:{"variant":"primary","to":{ name: 'arviointipyynto' }}},[_vm._v(" "+_vm._s(_vm.$t('pyyda-arviointia'))+" ")]):_vm._e(),_c('b-tabs',{attrs:{"content-class":"mt-3","no-fade":true},on:{"input":_vm.onTabChange}},[_c('b-tab',{attrs:{"title":_vm.$t('arvioinnit'),"active":""}},[_c('small',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t('rajaa-arviointeja')))]),_c('b-container',{staticClass:"px-0 mt-2",attrs:{"fluid":""}},[_c('b-row',{class:{ 'mb-3': !_vm.isFiltered }},[_c('b-col',{attrs:{"md":"4"}},[_c('elsa-form-group',{staticClass:"mb-md-0",attrs:{"label":_vm.$t('tyoskentelyjakso')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.tyoskentelyjaksotFormatted,"label":"label","track-by":"id"},on:{"select":_vm.onTyoskentelyjaksoSelect,"clearMultiselect":_vm.onTyoskentelyjaksoReset},model:{value:(_vm.selected.tyoskentelyjakso),callback:function ($$v) {_vm.$set(_vm.selected, "tyoskentelyjakso", $$v)},expression:"selected.tyoskentelyjakso"}})]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('elsa-form-group',{staticClass:"mb-md-0",attrs:{"label":_vm.$t('arvioitava-kokonaisuus')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.arvioitavatKokonaisuudetSorted,"label":"nimi","track-by":"id"},on:{"select":_vm.onArvioitavaKokonaisuusSelect,"clearMultiselect":_vm.onArvioitavaKokonaisuusReset},model:{value:(_vm.selected.arvioitavaKokonaisuus),callback:function ($$v) {_vm.$set(_vm.selected, "arvioitavaKokonaisuus", $$v)},expression:"selected.arvioitavaKokonaisuus"}})]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('elsa-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('kouluttaja-tai-vastuuhenkilo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.suoritusArvioinnitOptions.kouluttajatAndVastuuhenkilot,"label":"nimi","track-by":"id"},on:{"select":_vm.onKouluttajaOrVastuuhenkiloSelect,"clearMultiselect":_vm.onKouluttajaOrVastuuhenkiloReset},model:{value:(_vm.selected.kouluttajaOrVastuuhenkilo),callback:function ($$v) {_vm.$set(_vm.selected, "kouluttajaOrVastuuhenkilo", $$v)},expression:"selected.kouluttajaOrVastuuhenkilo"}})]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex flex-row-reverse"},[(
                        _vm.selected.tyoskentelyjakso ||
                        _vm.selected.arvioitavaKokonaisuus ||
                        _vm.selected.kouluttajaOrVastuuhenkilo
                      )?_c('elsa-button',{staticClass:"shadow-none text-size-sm font-weight-500",attrs:{"variant":"link"},on:{"click":_vm.resetFilters}},[_vm._v(" "+_vm._s(_vm.$t('tyhjenna-valinnat'))+" ")]):_vm._e()],1)])],1)],1),_c('div',{staticClass:"arvioinnit"},[(_vm.kategoriat)?_c('div',[_c('arvioinnin-kategoriat',{attrs:{"kategoriat":_vm.kategoriat}})],1):_vm._e(),(_vm.aiemmatKategoriat && _vm.aiemmatKategoriat.length > 0)?_c('div',[_c('h2',{staticClass:"mt-6"},[_vm._v(_vm._s(_vm.$t('aiemmat-arvioinnit')))]),_c('div',[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),_vm._v(" "+_vm._s(_vm.$t('aiemmat-arvioinnit-kuvaus'))+" ")],1),_c('arvioinnin-kategoriat',{attrs:{"kategoriat":_vm.aiemmatKategoriat}})],1):_vm._e(),(!_vm.kategoriat || !_vm.aiemmatKategoriat)?_c('div',{staticClass:"text-center mt-3"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1):_vm._e()])],1),_c('b-tab',{attrs:{"title":_vm.$t('arviointipyynnot')}},[(_vm.arvioinnit)?_c('div',[_vm._l((_vm.arvioinnit),function(arviointipyynto,index){return _c('div',{key:index},[_c('arviointipyynto-card',{attrs:{"value":arviointipyynto}})],1)}),(_vm.arvioinnit.length === 0)?_c('b-alert',{attrs:{"variant":"dark","show":""}},[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),_vm._v(" "+_vm._s(_vm.$t('kaikkiin-arviointipyyntoihisi-on-tehty-arviointi'))+" ")],1):_vm._e()],2):_c('div',{staticClass:"text-center mt-3"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }