import { render, staticRenderFns } from "./arvioitavat-kokonaisuudet.vue?vue&type=template&id=76ec7b16&scoped=true&"
import script from "./arvioitavat-kokonaisuudet.vue?vue&type=script&lang=ts&"
export * from "./arvioitavat-kokonaisuudet.vue?vue&type=script&lang=ts&"
import style0 from "./arvioitavat-kokonaisuudet.vue?vue&type=style&index=0&id=76ec7b16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76ec7b16",
  null
  
)

export default component.exports