




































import Avatar from 'vue-avatar'
import { Component, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import store from '@/store'
import { getTitleFromAuthorities } from '@/utils/functions'

@Component({
  components: {
    BCardSkeleton,
    Avatar,
    ElsaButton,
    UserAvatar
  }
})
export default class HenkilotiedotCard extends Vue {
  get account() {
    return store.getters['auth/account']
  }

  get activeAuthority() {
    if (this.account) {
      return this.account.activeAuthority
    }
    return ''
  }

  get sahkoposti() {
    return this.account.email
  }

  get puhelinnumero() {
    return this.account.phoneNumber || '-'
  }

  get title() {
    return getTitleFromAuthorities(this, this.activeAuthority)
  }
}
