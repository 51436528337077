var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.suoritemerkintaWrapper)?_c('div',[_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelyjakso')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.suoritemerkintaWrapper.tyoskentelyjakso.label))])]}}],null,false,3022830583)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('suorite')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.suoritemerkintaWrapper.suorite.nimi))])]}}],null,false,3463652376)}),_c('elsa-form-group',{staticClass:"template-placeholder",attrs:{"label":_vm.arviointiAsteikonNimi},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [(_vm.suoritemerkintaWrapper.arviointiasteikko)?_c('elsa-popover',{attrs:{"title":_vm.arviointiAsteikonNimi}},[_c('elsa-arviointiasteikon-taso-tooltip-content',{attrs:{"arviointiasteikon-tasot":_vm.suoritemerkintaWrapper.arviointiasteikko.tasot}})],1):_vm._e()]},proxy:true},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[(
            _vm.suoritemerkintaWrapper.arviointiasteikonTaso &&
            _vm.suoritemerkintaWrapper.arviointiasteikko
          )?_c('elsa-arviointiasteikon-taso',{attrs:{"value":_vm.suoritemerkintaWrapper.arviointiasteikonTaso,"tasot":_vm.suoritemerkintaWrapper.arviointiasteikko.tasot}}):_vm._e()],1)]}}],null,false,295827009)}),_c('elsa-form-group',{staticClass:"template-placeholder",attrs:{"label":_vm.$t('vaativuustaso')},scopedSlots:_vm._u([{key:"label-help",fn:function(){return [_c('elsa-popover',{attrs:{"title":_vm.$t('vaativuustaso')}},[_c('elsa-vaativuustaso-tooltip-content')],1)]},proxy:true},{key:"default",fn:function(ref){
          var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[(_vm.value.vaativuustaso)?_c('elsa-vaativuustaso',{attrs:{"value":_vm.suoritemerkintaWrapper.vaativuustaso}}):_vm._e()],1)]}}],null,false,1301568302)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('suorituspaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.suoritemerkintaWrapper.suorituspaiva ? _vm.$date(_vm.suoritemerkintaWrapper.suorituspaiva) : '')+" ")])]}}],null,false,3022350047)}),(_vm.suoritemerkintaWrapper.lisatiedot)?_c('elsa-form-group',{attrs:{"label":_vm.$t('lisatiedot')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var uid = ref.uid;
return [_c('span',{staticClass:"text-preline",attrs:{"id":uid}},[_vm._v(_vm._s(_vm.suoritemerkintaWrapper.lisatiedot))])]}}],null,false,3639820287)}):_vm._e()],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }