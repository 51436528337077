























































































































import { parseISO, differenceInMonths } from 'date-fns'
import { Vue, Component } from 'vue-property-decorator'

import { getErikoistumisenEdistyminen } from '@/api/yek-koulutettava'
import ElsaArviointiasteikonTasoTooltipContent from '@/components/arviointiasteikon-taso/arviointiasteikon-taso-tooltip.vue'
import ElsaArviointiasteikonTaso from '@/components/arviointiasteikon-taso/arviointiasteikon-taso.vue'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import ElsaProgressBar from '@/components/progress-bar/progress-bar.vue'
import TyoskentelyjaksotYekBarChart from '@/components/yek/tyoskentelyjaksot-yek-bar-chart.vue'
import { ErikoistumisenEdistyminen } from '@/types'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton,
    ElsaFormGroup,
    ElsaPopover,
    TyoskentelyjaksotYekBarChart,
    ElsaProgressBar,
    ElsaArviointiasteikonTaso,
    ElsaArviointiasteikonTasoTooltipContent
  }
})
export default class YekKoulutuksenEdistyminenCard extends Vue {
  initializing = true

  edistyminen: ErikoistumisenEdistyminen | null = null

  async mounted() {
    this.edistyminen = (await getErikoistumisenEdistyminen()).data
    this.initializing = false
  }

  showOpintooikeusAlert(opintooikeudenPaattymispaiva: string) {
    return differenceInMonths(parseISO(opintooikeudenPaattymispaiva), new Date()) <= 6
  }
}
