var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('div',[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"label":_vm.$t('etunimi'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('etunimi')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.etunimi),callback:function ($$v) {_vm.$set(_vm.form, "etunimi", $$v)},expression:"form.etunimi"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,3331684268)}),_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pl-md-3",attrs:{"label":_vm.$t('sukunimi'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sukunimi')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.sukunimi),callback:function ($$v) {_vm.$set(_vm.form, "sukunimi", $$v)},expression:"form.sukunimi"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,19777484)})],1),_c('elsa-form-group',{attrs:{"label":_vm.$t('sahkopostiosoite'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sahkoposti')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.sahkoposti),callback:function ($$v) {_vm.$set(_vm.form, "sahkoposti", $$v)},expression:"form.sahkoposti"}}),(_vm.$v.form.sahkoposti && !_vm.$v.form.sahkoposti.required)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")]):_vm._e(),(_vm.$v.form.sahkoposti && !_vm.$v.form.sahkoposti.email)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('sahkopostiosoite-ei-kelvollinen'))+" ")]):_vm._e()]}}],null,false,2086059847)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('sahkopostiosoite-uudelleen'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sahkopostiUudelleen')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.sahkopostiUudelleen),callback:function ($$v) {_vm.$set(_vm.form, "sahkopostiUudelleen", $$v)},expression:"form.sahkopostiUudelleen"}}),(_vm.$v.form.sahkopostiUudelleen && !_vm.$v.form.sahkopostiUudelleen.required)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")]):_vm._e(),(_vm.$v.form.sahkopostiUudelleen && !_vm.$v.form.sahkopostiUudelleen.email)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('sahkopostiosoite-ei-kelvollinen'))+" ")]):_vm._e(),(
              _vm.$v.form.sahkopostiUudelleen &&
              _vm.$v.form.sahkopostiUudelleen.required &&
              _vm.$v.form.sahkopostiUudelleen.email &&
              !_vm.$v.form.sahkopostiUudelleen.sameAsSahkoposti
            )?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('sahkopostiosoitteet-eivat-tasmaa'))+" ")]):_vm._e()]}}],null,false,2619919627)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('yliopisto'),"required":_vm.yliopistot.length > 1},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var uid = ref.uid;
return [(_vm.yliopistot.length > 1)?_c('div',[_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.yliopistot,"state":_vm.validateState('yliopisto'),"custom-label":_vm.yliopistoLabel,"track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.yliopisto),callback:function ($$v) {_vm.$set(_vm.form, "yliopisto", $$v)},expression:"form.yliopisto"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('yliopisto')}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])],1):(_vm.form.yliopisto)?_c('div',[_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.$t(("yliopisto-nimi." + (_vm.form.yliopisto.nimi)))))])]):_vm._e()]}}],null,false,3260954463)}),_c('elsa-form-group',{attrs:{"required":true,"label":_vm.$t('yliopiston-kayttajatunnus')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var uid = ref.uid;
return [_c('b-form-input',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"id":uid,"state":_vm.validateState('eppn')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.eppn),callback:function ($$v) {_vm.$set(_vm.form, "eppn", $$v)},expression:"form.eppn"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,1283865206)}),_c('hr'),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[_c('elsa-button',{staticClass:"mb-3 ml-3",attrs:{"variant":"primary","type":"submit","loading":_vm.saving}},[_vm._v(" "+_vm._s(_vm.$t('tallenna'))+" ")]),_c('elsa-button',{staticClass:"mb-3 mr-3",attrs:{"variant":"back","disabled":_vm.saving},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCancel($event)}}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1)],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }