






















































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import { getErikoistujatJaKouluttajat } from '@/api/kayttajahallinta'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import KayttajahallintaMixin from '@/mixins/kayttajahallinta'
import {
  Erikoisala,
  KayttajahallintaYhdistaKayttajatilejaListItem,
  Page,
  SortByEnum,
  YhdistaKayttajatilejaForm
} from '@/types'
import { KayttajaJarjestys } from '@/utils/constants'
import { ELSA_ROLE } from '@/utils/roles'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaPagination,
    ElsaSearchInput
  }
})
export default class ErikoistujatJaKouluttajat extends Mixins(KayttajahallintaMixin) {
  @Prop({ required: true })
  form!: YhdistaKayttajatilejaForm
  fields = [
    {
      key: 'nimi',
      label: this.$t('nimi'),
      sortable: false
    },
    {
      key: 'opintooikeus',
      label: this.$t('opintooikeus'),
      sortable: false
    },
    {
      key: 'tila',
      label: this.$t('tilin-tila'),
      sortable: false
    },
    {
      key: 'sahkoposti',
      label: this.$t('sahkoposti'),
      sortable: false
    },
    {
      key: 'button',
      label: '',
      sortable: false
    }
  ]

  loading = false
  currentPage = 1
  perPage = 20
  debounce?: number
  hakutermi = ''

  kayttajat: Page<KayttajahallintaYhdistaKayttajatilejaListItem> | null = null

  filtered: {
    nimi: string | null
    erikoisala: Erikoisala | null
    useaOpintooikeus: boolean
    sortBy: string | null
  } = {
    nimi: null,
    erikoisala: null,
    useaOpintooikeus: false,
    sortBy: null
  }

  async fetch() {
    if (!this.filtered.nimi) {
      return
    }
    this.kayttajat = (
      await getErikoistujatJaKouluttajat({
        page: this.currentPage - 1,
        size: this.perPage,
        ...(this.filtered.nimi ? { 'nimi.contains': this.filtered.nimi } : {})
      })
    ).data
  }

  @Watch('hakutermi')
  onPropertyChanged(value: string) {
    this.debounceSearch(value)
  }

  onErikoisalaSelect(erikoisala: Erikoisala) {
    this.filtered.erikoisala = erikoisala
    this.filterResults()
  }

  onErikoisalaReset() {
    this.filtered.erikoisala = null
    this.filterResults()
  }

  onUseaOpintooikeusInput(checked: boolean) {
    this.filtered.useaOpintooikeus = checked
    this.filterResults()
  }

  onPageInput(value: number) {
    this.currentPage = value
    this.fetch()
  }

  onSortBySelect(sortByEnum: SortByEnum) {
    switch (sortByEnum.value) {
      case KayttajaJarjestys.SUKUNIMI_ASC:
        this.filtered.sortBy = 'kayttaja.user.lastName,asc'
        break
      case KayttajaJarjestys.SUKUNIMI_DESC:
        this.filtered.sortBy = 'kayttaja.user.lastName,desc'
        break
    }
    this.filterResults()
  }

  debounceSearch(value: string) {
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      this.filtered.nimi = value
      this.filterResults()
    }, 400)
  }

  async filterResults() {
    this.loading = true
    this.currentPage = 1
    await this.fetch()
    this.loading = false
  }

  get rows() {
    return this.kayttajat?.totalElements ?? 0
  }

  get erikoistujat() {
    return this.kayttajat?.content.filter((k) =>
      k.authorities.some(
        (authority: string) =>
          authority === ELSA_ROLE.ErikoistuvaLaakari || authority === ELSA_ROLE.YEKKoulutettava
      )
    )
  }

  get kouluttajat() {
    return this.kayttajat?.content.filter((k) =>
      k.authorities.some((authority: string) => authority === ELSA_ROLE.Kouluttaja)
    )
  }
}
