






























































































import Vue from 'vue'
import Component from 'vue-class-component'

import { getSeurantajakso, getSeurantajaksonTiedot } from '@/api/kouluttaja'
import ElsaButton from '@/components/button/button.vue'
import SeurantajaksoForm from '@/forms/seurantajakso-form.vue'
import store from '@/store'
import { Seurantajakso, SeurantajaksonTiedot } from '@/types'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    SeurantajaksoForm
  }
})
export default class SeurantajaksoViewKouluttaja extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('seurantakeskustelut'),
      to: { name: 'seurantakeskustelut' }
    },
    {
      text: this.$t('seurantajakso'),
      active: true
    }
  ]
  loading = true

  seurantajakso: Seurantajakso | null = null
  seurantajaksonTiedot: SeurantajaksonTiedot | null = null

  params = {
    saving: false
  }

  async mounted() {
    this.loading = true
    try {
      this.seurantajakso = (await getSeurantajakso(this.$route?.params?.seurantajaksoId)).data
      if (this.seurantajakso.id != null) {
        this.seurantajaksonTiedot = (await getSeurantajaksonTiedot(this.seurantajakso.id)).data
      }
    } catch {
      toastFail(this, this.$t('seurantajakson-tietojen-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'seurantakeskustelut' })
    }
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  get showOdottaaKeskustelua() {
    return this.seurantajakso?.seurantakeskustelunYhteisetMerkinnat == null
  }

  get showOdottaaArviointia() {
    return this.seurantajakso?.kouluttajanArvio == null
  }

  get showPalautettuKorjattavaksi() {
    return this.seurantajakso?.korjausehdotus != null
  }

  get showHyvaksytty() {
    return this.seurantajakso?.hyvaksytty === true
  }

  get canEdit() {
    return this.seurantajakso?.hyvaksytty !== true && this.seurantajakso?.korjausehdotus == null
  }
}
