










import Vue from 'vue'
import Component from 'vue-class-component'

import { Vaativuustaso } from '@/types'
import { vaativuustasot } from '@/utils/constants'

@Component({})
export default class ElsaVaativuustasoTooltipContent extends Vue {
  vaativuustasot: Vaativuustaso[] = vaativuustasot
}
