





























import { Component, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'

@Component({
  components: {
    ElsaButton
  }
})
export default class ArviointipyyntoLahetetty extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('arvioinnit'),
      to: { name: 'arvioinnit' }
    },
    {
      text: this.$t('arviointipyynto-lahetetty'),
      active: true
    }
  ]

  mounted() {
    if (this.$router && !(this.$route && this.$route.params && this.$route.params.arviointiId)) {
      this.$router.replace({ name: 'arvioinnit' })
    }
  }

  get itsearviointiLinkki() {
    if (this.$route && this.$route.params && this.$route.params.arviointiId) {
      return {
        name: 'itsearviointi',
        params: { arviointiId: this.$route.params.arviointiId }
      }
    } else {
      return false
    }
  }
}
