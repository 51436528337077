




















































import { Component, Prop, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaPoissaolonSyyt from '@/components/poissaolon-syyt/poissaolon-syyt.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import { Keskeytysaika } from '@/types'

@Component({
  components: {
    ElsaButton,
    ElsaPoissaolonSyyt,
    ElsaPopover
  }
})
export default class ElsaPoissaolotDisplay extends Vue {
  isOpen = false

  @Prop({ required: true, default: () => [] })
  poissaolot!: Keskeytysaika[]

  toggleIsOpen() {
    this.isOpen = !this.isOpen
  }
}
