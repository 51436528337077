var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"seurantakeskustelut"},[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('seurantakeskustelut')))]),_c('p',{staticClass:"mt-3 mb-1"},[_vm._v(" "+_vm._s(_vm.$t('seurantakeskustelut-kuvaus-1'))+" ")]),(_vm.naytaKuvaus)?_c('p',{staticClass:"mt-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('seurantakeskustelut-kuvaus-2'))+" ")]):_vm._e(),(_vm.naytaKuvaus)?_c('p',{staticClass:"mb-1 mt-1"},[_vm._v(" "+_vm._s(_vm.$t('seurantakeskustelut-kuvaus-3'))+" ")]):_vm._e()])],1),_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.toggleKuvaus}},[_vm._v(" "+_vm._s(_vm.naytaKuvaus ? _vm.$t('nayta-vahemman') : _vm.$t('nayta-lisaa'))+" ")])],1),_c('hr'),(!_vm.account.impersonated)?_c('elsa-button',{staticClass:"mb-4",attrs:{"to":{ name: 'lisaa-seurantajakso' },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-seurantajakso'))+" ")]):_vm._e(),(!_vm.loading)?_c('div',[(_vm.seurantajaksot.length > 0)?_c('div',_vm._l((_vm.seurantajaksot),function(seurantajakso,index){return _c('b-row',{key:index,attrs:{"lg":""}},[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-center border rounded pt-1 pb-1 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0",attrs:{"to":{
                    name: 'seurantajakso',
                    params: { seurantajaksoId: seurantajakso.id }
                  },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('seurantajakso'))+" "+_vm._s(_vm.$date(seurantajakso.alkamispaiva))+" "),(seurantajakso.paattymispaiva != null)?_c('span',[_vm._v(" - "+_vm._s(_vm.$date(seurantajakso.paattymispaiva))+" ")]):_vm._e()])]),(seurantajakso.koulutusjaksot.length > 0)?_c('p',{staticClass:"mb-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('koulutusjaksot'))+":")]),_c('span',[_vm._v(" "+_vm._s(_vm.koulutusjaksotLabel(seurantajakso))+" ")])]):_vm._e(),_c('p',{staticClass:"mb-2"},[(_vm.showHyvaksytty(seurantajakso))?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fas', 'check-circle']}}):_vm._e(),(_vm.showKorjattavaa(seurantajakso))?_c('font-awesome-icon',{staticClass:"text-error",attrs:{"icon":['fas', 'exclamation-circle']}}):_vm._e(),(_vm.showHyvaksytty(seurantajakso))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-tila-hyvaksytty'))+" "),(seurantajakso.huolenaiheet != null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-sisaltaa-huolia'))+" ")]):_vm._e()]):(_vm.showKorjattavaa(seurantajakso))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-tila-palautettu-korjattavaksi'))+" "),_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$t('syy'))+" "),_c('span',{staticClass:"font-weight-500"},[_vm._v(_vm._s(seurantajakso.korjausehdotus))])]),_c('elsa-button',{staticClass:"mb-2 mt-3",attrs:{"to":{
                        name: 'muokkaa-seurantajaksoa',
                        params: { seurantajaksoId: seurantajakso.id }
                      },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('muokkaa-yhteisia-merkintoja'))+" ")])],1):(
                      _vm.showKouluttajaArvioinut(seurantajakso) ||
                      _vm.showOdottaaHyvaksyntaa(seurantajakso)
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-tila-kouluttaja-arvioinut'))+" "),(seurantajakso.huolenaiheet != null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-sisaltaa-huolia'))+" ")]):_vm._e(),(_vm.showOdottaaHyvaksyntaa(seurantajakso))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-tila-merkinnat-lahetetty'))+" ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-tila-lahetetty-kouluttajalle'))+" "),(seurantajakso.seurantakeskustelunYhteisetMerkinnat != null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-tila-merkinnat-lahetetty'))+" ")]):_vm._e()])],1),(seurantajakso.seurantakeskustelunYhteisetMerkinnat == null)?_c('div',[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('seurantajakso-tila-taydenna-tiedot-1'))+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('seurantajakso-tila-taydenna-tiedot-2')))]),_c('li',[_vm._v(_vm._s(_vm.$t('seurantajakso-tila-taydenna-tiedot-3')))])]),(!_vm.account.impersonated)?_c('elsa-button',{staticClass:"mb-3",attrs:{"to":{
                      name: 'muokkaa-seurantajaksoa',
                      params: { seurantajaksoId: seurantajakso.id }
                    },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-yhteiset-merkinnat'))+" ")]):_vm._e()],1):_vm._e()],1)])])],1)}),1):_c('div',[_c('b-alert',{attrs:{"show":true,"variant":"dark"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('em',{staticClass:"align-middle"},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'info-circle']}})],1),_c('div',[_vm._v(_vm._s(_vm.$t('seurantakeskusteluja-ei-viela-kayty')))])])])],1)]):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }