































































































































import Editor from '@tinymce/tinymce-vue'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Validation, validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaTextEditor from '@/components/text-editor/text-editor.vue'
import { SuoriteWithErikoisala, SuoritteenKategoria } from '@/types'
import { sortByAsc } from '@/utils/sort'

@Component({
  components: {
    Editor,
    ElsaButton,
    ElsaFormError,
    ElsaFormGroup,
    ElsaFormDatepicker,
    ElsaFormMultiselect,
    ElsaTextEditor
  }
})
export default class SuoriteForm extends Mixins(validationMixin) {
  $refs!: {
    voimassaolonAlkamispaiva: ElsaFormDatepicker
    voimassaolonPaattymispaiva: ElsaFormDatepicker
  }

  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({ required: false, default: true })
  kategoriaEditable!: boolean

  @Prop({ required: true, type: Object })
  suorite!: SuoriteWithErikoisala

  @Prop({ required: false, type: Array, default: () => [] })
  kategoriat!: SuoritteenKategoria[]

  params = {
    saving: false
  }

  loading = true

  form: Partial<SuoriteWithErikoisala> = {}

  mounted() {
    this.form = { ...this.suorite }
    this.loading = false
  }

  get sortedKategoriat() {
    return this.kategoriat.sort((a, b) => sortByAsc(a.nimi, b.nimi))
  }

  validations() {
    return {
      form: {
        kategoria: {
          required
        },
        nimi: {
          required
        },
        voimassaolonAlkamispaiva: {
          required
        }
      }
    }
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as Validation
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    const validations = [this.validateForm(), this.$refs.voimassaolonAlkamispaiva.validateForm()]

    if (validations.includes(false)) {
      return
    }

    this.$emit(
      'submit',
      {
        ...this.form
      },
      this.params
    )
  }
}
