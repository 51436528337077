import { render, staticRenderFns } from "./arviointilomake-valiarviointi.vue?vue&type=template&id=a3089bbc&scoped=true&"
import script from "./arviointilomake-valiarviointi.vue?vue&type=script&lang=ts&"
export * from "./arviointilomake-valiarviointi.vue?vue&type=script&lang=ts&"
import style0 from "./arviointilomake-valiarviointi.vue?vue&type=style&index=0&id=a3089bbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3089bbc",
  null
  
)

export default component.exports