














































import { Component, Vue } from 'vue-property-decorator'

import { getSuoritteenKategoria } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import SuoritteenKategoriaForm from '@/forms/suoritteen-kategoria-form.vue'
import { SuoritteenKategoriaWithErikoisala } from '@/types'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    SuoritteenKategoriaForm
  }
})
export default class SuoritteenKategoriaView extends Vue {
  kategoria: SuoritteenKategoriaWithErikoisala | null = null

  loading = true

  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('opetussuunnitelmat'),
        to: { name: 'opetussuunnitelmat' }
      },
      {
        text: this.kategoria?.erikoisala.nimi,
        to: { name: 'erikoisala' }
      },
      {
        text: this.$t('kategoria'),
        active: true
      }
    ]
  }

  async mounted() {
    await this.fetchKategoria()
    this.loading = false
  }

  async fetchKategoria() {
    try {
      this.kategoria = (await getSuoritteenKategoria(this.$route?.params?.kategoriaId)).data
    } catch (err) {
      toastFail(this, this.$t('suoritteen-kategorian-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'erikoisala', hash: '#suoritteet' })
    }
  }
}
