


























































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'

import ElsaAccordian from '@/components/accordian/accordian.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import store from '@/store'
import { ArvioitavaKokonaisuus } from '@/types'
import { toastFail } from '@/utils/toast'
import ArvioitavatKokonaisuudetListaVastuuhenkilo from '@/views/arvioitavat-kokonaisuudet/arvioitavat-kokonaisuudet-lista-vastuuhenkilo.vue'
import ArvioitavatKokonaisuudetLista from '@/views/arvioitavat-kokonaisuudet/arvioitavat-kokonaisuudet-lista.vue'

type ErikoisalaSelectItem = {
  nimi: string
  id: number
}

@Component({
  components: {
    ElsaAccordian,
    ArvioitavatKokonaisuudetLista,
    ArvioitavatKokonaisuudetListaVastuuhenkilo,
    ElsaFormMultiselect,
    ElsaFormGroup
  }
})
export default class ArvioitavatKokonaisuudet extends Vue {
  endpointUrl = ''
  erikoisalatUrl = ''
  erikoisala: ErikoisalaSelectItem | null = null
  arvioitavatKokonaisuudet: ArvioitavaKokonaisuus[] = []
  loading = false
  erikoisalatLoading = false
  showErikoisalaDropdown = false
  erikoisalat: ErikoisalaSelectItem[] = []
  selectedErikoisala: number | null = null
  selectedErikoisalaName = ''
  tabIndex = 0
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('arvioitavat-kokonaisuudet'),
      active: true
    }
  ]

  async mounted() {
    await this.fetch()
  }

  get account() {
    return store.getters['auth/account']
  }

  async fetch() {
    try {
      this.loading = true
      this.erikoisalatLoading = true
      if (this.$isKouluttaja()) {
        this.endpointUrl = 'kouluttaja/arvioitavatkokonaisuudet'
        this.erikoisalatUrl = 'kouluttaja/erikoisalat'
      } else if (this.$isVastuuhenkilo()) {
        this.endpointUrl = 'vastuuhenkilo/arvioitavatkokonaisuudet'
        this.erikoisalatUrl = 'vastuuhenkilo/erikoisalat'
      } else {
        this.endpointUrl = 'erikoistuva-laakari/arvioitavatkokonaisuudet'
      }
      if (this.endpointUrl && (this.$isKouluttaja() || this.$isVastuuhenkilo())) {
        this.erikoisalat = (await axios.get(this.erikoisalatUrl)).data
        if (this.erikoisalat[0].id) {
          this.erikoisala = this.erikoisalat[0]
          this.selectedErikoisala = this.erikoisalat[0].id
          this.selectedErikoisalaName = this.erikoisalat[0].nimi
        }
        this.erikoisalatLoading = false
      } else {
        this.arvioitavatKokonaisuudet = (await axios.get(this.endpointUrl)).data
      }
      this.loading = false
    } catch {
      toastFail(this, this.$t('arvioitavien-kokonaisuuksien-hakeminen-epaonnistui'))
      this.loading = false
    }
  }

  onErikoisalaSelect(erikoisala: ErikoisalaSelectItem) {
    this.selectedErikoisala = erikoisala.id
    this.selectedErikoisalaName = erikoisala.nimi
  }

  onErikoisalaReset() {
    this.selectedErikoisala = null
    this.selectedErikoisalaName = ''
  }
}
