























import { Component, Vue } from 'vue-property-decorator'

import { getErikoisala, postArvioitavanKokonaisuudenKategoria } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import ArvioitavanKokonaisuudenKategoriaForm from '@/forms/arvioitavan-kokonaisuuden-kategoria-form.vue'
import {
  ArvioitavanKokonaisuudenKategoriaWithErikoisala,
  UusiArvioitavanKokonaisuudenKategoria
} from '@/types'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ArvioitavanKokonaisuudenKategoriaForm,
    ElsaButton
  }
})
export default class LisaaArvioitavanKokonaisuudenKategoria extends Vue {
  kategoria: UusiArvioitavanKokonaisuudenKategoria = {
    nimi: null,
    nimiSv: null,
    jarjestysnumero: null,
    erikoisala: null
  }

  loading = true

  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('opetussuunnitelmat'),
        to: { name: 'opetussuunnitelmat' }
      },
      {
        text: this.kategoria?.erikoisala?.nimi,
        to: { name: 'erikoisala' }
      },
      {
        text: this.$t('lisaa-kategoria'),
        active: true
      }
    ]
  }

  async mounted() {
    await this.fetchErikoisala()
    this.loading = false
  }

  async fetchErikoisala() {
    try {
      this.kategoria.erikoisala = (await getErikoisala(this.$route.params.erikoisalaId)).data
    } catch (err) {
      toastFail(this, this.$t('erikoisalan-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'opetussuunnitelmat', hash: '#arvioitavat-kokonaisuudet' })
    }
  }

  async onSubmit(
    value: ArvioitavanKokonaisuudenKategoriaWithErikoisala,
    params: { saving: boolean }
  ) {
    params.saving = true
    try {
      const uusiKategoria = await (await postArvioitavanKokonaisuudenKategoria(value)).data
      toastSuccess(this, this.$t('kategorian-tallentaminen-onnistui'))
      this.$emit('skipRouteExitConfirm', true)
      this.$router.push({
        name: 'arvioitavan-kokonaisuuden-kategoria',
        params: { kategoriaId: `${uusiKategoria.id}` }
      })
    } catch (err) {
      toastFail(this, this.$t('kategorian-tallentaminen-epaonnistui'))
    }
    params.saving = false
  }
}
