


























































































































import Vue from 'vue'
import Component from 'vue-class-component'

import KoejaksoCardContent from './koejakso-card-content.vue'

import ElsaButton from '@/components/button/button.vue'
import store from '@/store'
import { LomakeTilat } from '@/utils/constants'

@Component({
  components: {
    KoejaksoCardContent,
    ElsaButton
  }
})
export default class VastuuhenkilonArvioCard extends Vue {
  get account() {
    return store.getters['auth/account']
  }

  get koejakso() {
    return store.getters['erikoistuva/koejakso']
  }

  get korjausehdotus() {
    if (this.koejakso.vastuuhenkilonArvio) {
      return this.koejakso.vastuuhenkilonArvio.virkailijanKorjausehdotus != null
        ? this.koejakso.vastuuhenkilonArvio.virkailijanKorjausehdotus
        : this.koejakso.vastuuhenkilonArvio.vastuuhenkilonKorjausehdotus
    }
    return null
  }

  get tila() {
    return this.koejakso.vastuuhenkilonArvionTila
  }

  get lomaketilat() {
    return LomakeTilat
  }

  get url() {
    return 'koejakson-vastuuhenkilon-arvio'
  }
}
