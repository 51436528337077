
















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import ConfirmRouteExit from '@/mixins/confirm-route-exit'
import store from '@/store'
import PageNotFoundContent from '@/views/404/page-not-found-content.vue'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    PageNotFoundContent
  }
})
export default class ErikoistuvaRoute extends Mixins(ConfirmRouteExit) {
  @Prop({ required: true })
  routeComponent!: any

  @Prop({ required: true, default: [] })
  allowedRoles!: string[]

  @Prop({ required: false, type: Boolean, default: true })
  confirmRouteExit!: boolean

  loading = true

  get isAllowedRoute() {
    const authorities = store.getters['auth/account'].authorities
    return this.allowedRoles.some((r) => authorities.includes(r))
  }

  onSkipRouteExitConfirm(val: boolean) {
    this.skipRouteExitConfirm = val
  }

  @Watch('$route', { immediate: true, deep: true })
  async onUrlChange() {
    this.skipRouteExitConfirm = this.confirmRouteExit
    if (!store.getters['auth/isLoggedIn']) {
      await store.dispatch('auth/authorize')
    }
    this.loading = false
  }
}
