












































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import ElsaBadge from '@/components/badge/badge.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import store from '@/store'
import { ArvioitavaKokonaisuus, ArvioitavanKokonaisuudenKategoria } from '@/types'

@Component({
  components: {
    ElsaBadge,
    ElsaButton,
    ElsaSearchInput,
    ElsaPagination
  }
})
export default class ArvioinninKategoriat extends Vue {
  @Prop({ required: true, default: undefined })
  kategoriat!: ArvioitavanKokonaisuudenKategoria[]

  arvioinnitCountByKategoria(kategoria: ArvioitavanKokonaisuudenKategoria) {
    return kategoria.arvioitavatKokonaisuudet
      .map((a: ArvioitavaKokonaisuus) => a.arvioinnit.length)
      .reduce((a, b) => a + b)
  }

  get account() {
    return store.getters['auth/account']
  }
}
