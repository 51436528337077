






















import { Component, Vue } from 'vue-property-decorator'

import ValmistumispyynnotVastuuhenkiloYek from '../../valmistumispyynnot-yek/vastuuhenkilo/valmistumispyynnot-vastuuhenkilo-yek.vue'

import ValmistumispyynnotVastuuhenkiloErikoistuvaLaakari from './valmistumispyynnot-vastuuhenkilo-erikoistuva-laakari.vue'

@Component({
  components: {
    ValmistumispyynnotVastuuhenkiloErikoistuvaLaakari,
    ValmistumispyynnotVastuuhenkiloYek
  }
})
export default class ValmistumispyynnotVastuuhenkilo extends Vue {
  tabIndex = 0
  tabs = ['#erikoislaakarikoulutus', '#yek']

  beforeMount() {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  }
}
