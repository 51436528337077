






























































import axios from 'axios'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import store from '@/store'
import { Suoritemerkinta } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { vaativuustasot } from '@/utils/constants'
import { toastFail, toastSuccess } from '@/utils/toast'
import SuoritemerkintaDetails from '@/views/suoritemerkinnat/suoritemerkinta-details.vue'

@Component({
  components: {
    ElsaButton,
    SuoritemerkintaDetails
  }
})
export default class SuoritemerkintaView extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('suoritemerkinnat'),
      to: { name: 'suoritemerkinnat' }
    },
    {
      text: this.$t('suoritemerkinta'),
      active: true
    }
  ]
  suoritemerkinta: Suoritemerkinta | null = null
  vaativuustasot = vaativuustasot
  deleting = false

  async mounted() {
    const suoritemerkintaId = this.$route?.params?.suoritemerkintaId
    if (suoritemerkintaId) {
      try {
        this.suoritemerkinta = (
          await axios.get(`erikoistuva-laakari/suoritemerkinnat/${suoritemerkintaId}`)
        ).data
      } catch {
        toastFail(this, this.$t('suoritemerkinnan-hakeminen-epaonnistui'))
        this.$router.replace({ name: 'suoritemerkinnat' })
      }
    }
  }

  get account() {
    return store.getters['auth/account']
  }

  async onSuoritemerkintaDelete() {
    if (
      await confirmDelete(
        this,
        this.$t('poista-suoritemerkinta') as string,
        (this.$t('suoritemerkinnan') as string).toLowerCase()
      )
    ) {
      this.deleting = true
      try {
        await axios.delete(`erikoistuva-laakari/suoritemerkinnat/${this.suoritemerkinta?.id}`)
        toastSuccess(this, this.$t('suoritemerkinta-poistettu-onnistuneesti'))
        this.$router.push({
          name: 'suoritemerkinnat'
        })
      } catch {
        toastFail(this, this.$t('suoritemerkinnan-poistaminen-epaonnistui'))
      }
      this.deleting = false
    }
  }
}
