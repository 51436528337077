
















































import { Component, Vue } from 'vue-property-decorator'

import { deletePaivittainenMerkinta, getPaivakirjamerkinta } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import store from '@/store'
import { Paivakirjamerkinta } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton
  }
})
export default class PaivittainenMerkintaView extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('paivittaiset-merkinnat'),
      to: { name: 'paivittaiset-merkinnat' }
    },
    {
      text: this.$t('merkinta'),
      active: true
    }
  ]
  paivakirjamerkinta: Paivakirjamerkinta | null = null
  loading = true
  deleting = false

  async mounted() {
    try {
      this.paivakirjamerkinta = (
        await getPaivakirjamerkinta(this.$route?.params?.paivakirjamerkintaId)
      ).data
    } catch (err) {
      toastFail(this, this.$t('paivittaisen-merkinnan-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'paivittaiset-merkinnat' })
    }
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  async onPaivakirjamerkintaDelete() {
    if (
      await confirmDelete(
        this,
        this.$t('poista-paivittainen-merkinta') as string,
        (this.$t('paivittaisen-merkinnan') as string).toLowerCase()
      )
    ) {
      this.deleting = true
      try {
        await deletePaivittainenMerkinta(this.paivakirjamerkinta?.id)
        toastSuccess(this, this.$t('paivittainen-merkinta-poistettu'))
        this.$router.push({
          name: 'paivittaiset-merkinnat'
        })
      } catch (err) {
        toastFail(this, this.$t('paivittaisen-merkinnan-poistaminen-epaonnistui'))
      }
      this.deleting = false
    }
  }
}
