










































































































































































































































































































































import { parseISO, differenceInMonths } from 'date-fns'
import { Vue, Component } from 'vue-property-decorator'

import { getErikoistumisenEdistyminen } from '@/api/erikoistuva'
import ElsaArviointiasteikonTasoTooltipContent from '@/components/arviointiasteikon-taso/arviointiasteikon-taso-tooltip.vue'
import ElsaArviointiasteikonTaso from '@/components/arviointiasteikon-taso/arviointiasteikon-taso.vue'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import ElsaProgressBar from '@/components/progress-bar/progress-bar.vue'
import TyoskentelyjaksotBarChart from '@/components/tyoskentelyjaksot-bar-chart.vue'
import { ErikoistumisenEdistyminen } from '@/types'
import { LomakeTilat, ArviointiasteikkoTyyppi } from '@/utils/constants'
import { getKeskiarvoFormatted } from '@/utils/keskiarvoFormatter'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton,
    ElsaFormGroup,
    ElsaPopover,
    TyoskentelyjaksotBarChart,
    ElsaProgressBar,
    ElsaArviointiasteikonTaso,
    ElsaArviointiasteikonTasoTooltipContent
  }
})
export default class ErikoistujienSeurantaCard extends Vue {
  initializing = true

  edistyminen: ErikoistumisenEdistyminen | null = null

  async mounted() {
    this.edistyminen = (await getErikoistumisenEdistyminen()).data
    this.initializing = false
  }

  koejaksoComponent(suoritusmerkintaExists: boolean) {
    return suoritusmerkintaExists ? 'opintosuoritukset' : 'koejakso'
  }

  koejaksoComponentHash(suoritusmerkintaExists: boolean) {
    return suoritusmerkintaExists ? '#muut' : ''
  }

  koejaksoIcon(status: string, opintooikeudenPaattymispaiva: string) {
    switch (status) {
      case LomakeTilat.HYVAKSYTTY:
        return ['fas', 'check-circle']
      case LomakeTilat.ODOTTAA_HYVAKSYNTAA:
        return ['far', 'clock']
      case LomakeTilat.EI_AKTIIVINEN:
        if (differenceInMonths(parseISO(opintooikeudenPaattymispaiva), new Date()) <= 12) {
          return ['fas', 'exclamation-circle']
        } else return ['fas', 'info-circle']
    }
  }

  koejaksoIconClass(status: string, opintooikeudenPaattymispaiva: string) {
    switch (status) {
      case LomakeTilat.HYVAKSYTTY:
        return 'text-success'
      case LomakeTilat.ODOTTAA_HYVAKSYNTAA:
        return 'text-warning'
      case LomakeTilat.EI_AKTIIVINEN:
        if (differenceInMonths(parseISO(opintooikeudenPaattymispaiva), new Date()) <= 12) {
          return 'text-danger'
        } else return 'text-warning'
    }
  }

  koejaksoStatusText(status: string) {
    switch (status) {
      case LomakeTilat.HYVAKSYTTY:
        return this.$t('hyvaksytty')
      case LomakeTilat.ODOTTAA_HYVAKSYNTAA:
        return this.$t('kesken')
      case LomakeTilat.EI_AKTIIVINEN:
        return this.$t('aloittamatta')
    }
  }

  koejaksoStatusClass(status: string, opintooikeudenPaattymispaiva: string) {
    if (
      status === LomakeTilat.EI_AKTIIVINEN &&
      differenceInMonths(parseISO(opintooikeudenPaattymispaiva), new Date()) <= 12
    )
      return 'text-danger'
  }

  terveyskeskusjaksoComponent(suoritusmerkintaExists: boolean) {
    return suoritusmerkintaExists ? 'opintosuoritukset' : 'tyoskentelyjaksot'
  }

  terveyskeskusjaksoComponentHash(suoritusmerkintaExists: boolean) {
    return suoritusmerkintaExists ? '#muut' : ''
  }

  terveyskeskusjaksoIcon(suoritusmerkintaExists: boolean) {
    if (suoritusmerkintaExists) {
      return ['fas', 'check-circle']
    }
  }

  terveyskeskusjaksoIconClass(suoritusmerkintaExists: boolean) {
    if (suoritusmerkintaExists) {
      return 'text-success mr-1'
    }
  }

  terveyskeskusjaksoStatusText(suoritusmerkintaExists: boolean) {
    if (suoritusmerkintaExists) {
      return this.$t('hyvaksytty')
    } else {
      return this.$t('ei-suoritettu')
    }
  }

  showOpintooikeusAlert(opintooikeudenPaattymispaiva: string) {
    return differenceInMonths(parseISO(opintooikeudenPaattymispaiva), new Date()) <= 6
  }

  get arviointiAsteikonNimi() {
    return this.edistyminen?.arviointiasteikko.nimi === ArviointiasteikkoTyyppi.EPA
      ? this.$t('luottamuksen-tason-keskiarvo')
      : this.$t('etapin-keskiarvo')
  }

  get arviointiAsteikonSelite() {
    return this.edistyminen?.arviointiasteikko.nimi === ArviointiasteikkoTyyppi.EPA
      ? this.$t('arviointien-ka-selite-epa')
      : this.$t('arviointien-ka-selite-etappi')
  }

  keskiarvoFormatted(keskiarvo: number) {
    return getKeskiarvoFormatted(keskiarvo)
  }
}
