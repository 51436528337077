







































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import { KoulutussopimusLomake, KoejaksonVaiheAllekirjoitus } from '@/types'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'

@Component({
  components: {
    KoejaksonVaiheAllekirjoitukset
  }
})
export default class KoulutussopimusReadonly extends Vue {
  @Prop({ required: true, default: {} })
  data!: KoulutussopimusLomake

  get erikoistuvaAllekirjoitus() {
    return this.data.erikoistuvanAllekirjoitusaika
  }

  get kouluttajatAllekirjoitus() {
    return this.data.kouluttajat.map((a) => {
      if (a.kuittausaika) {
        return a
      }
      return 0
    })
  }

  get allekirjoitukset(): KoejaksonVaiheAllekirjoitus[] {
    const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
      this,
      this.data.erikoistuvanNimi,
      this.data.erikoistuvanAllekirjoitusaika
    ) as KoejaksonVaiheAllekirjoitus
    const allekirjoituksetKouluttajat =
      allekirjoituksetHelper.mapAllekirjoituksetSopimuksenKouluttajat(
        this.data.kouluttajat
      ) as KoejaksonVaiheAllekirjoitus[]
    const allekirjoitusVastuuhenkilo = allekirjoituksetHelper.mapAllekirjoitusVastuuhenkilo(
      this.data.vastuuhenkilo
    ) as KoejaksonVaiheAllekirjoitus

    return [
      allekirjoitusErikoistuva,
      ...allekirjoituksetKouluttajat,
      allekirjoitusVastuuhenkilo
    ].filter((a): a is KoejaksonVaiheAllekirjoitus => a !== null)
  }
}
