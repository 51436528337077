


















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class ElsaSearchInput extends Vue {
  @Prop({ required: true, type: String, default: undefined })
  hakutermi!: string

  @Prop({ required: false, type: Boolean, default: true })
  visible!: boolean

  @Prop({ required: true, type: String })
  placeholder?: string

  get hakutermiMutable() {
    return this.hakutermi
  }

  set hakutermiMutable(val) {
    this.$emit('update:hakutermi', val)
  }
}
