












































































































































































import { format } from 'date-fns'
import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'

import { getAloituskeskustelu as getAloituskeskusteluKouluttaja } from '@/api/kouluttaja'
import { getAloituskeskustelu as getAloituskeskusteluVastuuhenkilo } from '@/api/vastuuhenkilo'
import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import KoulutuspaikanArvioijat from '@/components/koejakson-vaiheet/koulutuspaikan-arvioijat.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaReturnToSenderModal from '@/components/modal/return-to-sender-modal.vue'
import store from '@/store'
import {
  KoejaksonVaiheAllekirjoitus,
  KoejaksonVaiheButtonStates,
  AloituskeskusteluLomake,
  KoejaksonVaihe
} from '@/types'
import { resolveRolePath } from '@/utils/apiRolePathResolver'
import { LomakeTilat, LomakeTyypit } from '@/utils/constants'
import { checkCurrentRouteAndRedirect } from '@/utils/functions'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ErikoistuvaDetails,
    ElsaFormGroup,
    ElsaButton,
    KoejaksonVaiheAllekirjoitukset,
    ElsaConfirmationModal,
    ElsaReturnToSenderModal,
    KoulutuspaikanArvioijat
  }
})
export default class ArviointilomakeAloituskeskustelu extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('aloituskeskustelu-kouluttaja'),
      active: true
    }
  ]
  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }
  loading = true
  aloituskeskustelu: null | AloituskeskusteluLomake = null
  koejaksonVaihe = this.$t('aloituskeskustelu')

  get aloituskeskusteluId() {
    return Number(this.$route.params.id)
  }

  get aloituskeskustelunTila() {
    return store.getters[`${resolveRolePath()}/koejaksot`].find(
      (k: KoejaksonVaihe) =>
        k.id === this.aloituskeskusteluId && k.tyyppi === LomakeTyypit.ALOITUSKESKUSTELU
    )?.tila
  }

  get showAllekirjoitukset() {
    return (
      this.aloituskeskustelu?.erikoistuvanKuittausaika !== null ||
      this.aloituskeskustelu?.lahikouluttaja.sopimusHyvaksytty ||
      this.aloituskeskustelu?.lahiesimies.sopimusHyvaksytty
    )
  }

  get returned() {
    return this.aloituskeskustelunTila === LomakeTilat.PALAUTETTU_KORJATTAVAKSI
  }

  get isCurrentUserLahiesimies() {
    const currentUser = store.getters['auth/account']
    return this.aloituskeskustelu?.lahiesimies.kayttajaUserId === currentUser.id
  }

  get editable() {
    return (
      (this.$isKouluttaja() || this.$isVastuuhenkilo()) &&
      this.aloituskeskustelunTila !== LomakeTilat.PALAUTETTU_KORJATTAVAKSI &&
      ((this.isCurrentUserLahiesimies &&
        !this.aloituskeskustelu?.lahiesimies.sopimusHyvaksytty) ||
        !this.aloituskeskustelu?.lahikouluttaja.sopimusHyvaksytty)
    )
  }

  get acceptedByEveryone() {
    return (
      this.aloituskeskustelunTila !== LomakeTilat.PALAUTETTU_KORJATTAVAKSI &&
      this.aloituskeskustelu?.lahikouluttaja.sopimusHyvaksytty &&
      this.aloituskeskustelu?.lahiesimies.sopimusHyvaksytty
    )
  }

  get tyotunnitViikossa() {
    return this.aloituskeskustelu?.tyotunnitViikossa?.toString().replace('.', ',')
  }

  get showWaitingForLahiesimies() {
    return (
      !this.isCurrentUserLahiesimies &&
      this.aloituskeskustelunTila !== LomakeTilat.PALAUTETTU_KORJATTAVAKSI &&
      this.aloituskeskustelu?.lahikouluttaja.sopimusHyvaksytty &&
      !this.aloituskeskustelu?.lahiesimies.sopimusHyvaksytty
    )
  }

  get erikoistuvanAvatar() {
    return this.aloituskeskustelu?.erikoistuvanAvatar
  }

  get erikoistuvanNimi() {
    return this.aloituskeskustelu?.erikoistuvanNimi
  }

  get allekirjoitukset() {
    const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
      this,
      this.aloituskeskustelu?.erikoistuvanNimi,
      this.aloituskeskustelu?.erikoistuvanKuittausaika
    ) as KoejaksonVaiheAllekirjoitus
    const allekirjoitusLahikouluttaja = allekirjoituksetHelper.mapAllekirjoitusLahikouluttaja(
      this,
      this.aloituskeskustelu?.lahikouluttaja
    )
    const allekirjoitusLahiesimies = allekirjoituksetHelper.mapAllekirjoitusLahiesimies(
      this,
      this.aloituskeskustelu?.lahiesimies
    )

    return [
      allekirjoitusErikoistuva,
      allekirjoitusLahikouluttaja,
      allekirjoitusLahiesimies
    ].filter((a): a is KoejaksonVaiheAllekirjoitus => a !== null)
  }

  async returnToSender(korjausehdotus: string) {
    const form = {
      ...this.aloituskeskustelu,
      korjausehdotus: korjausehdotus,
      lahetetty: false
    }
    try {
      this.buttonStates.secondaryButtonLoading = true
      await store.dispatch('kouluttaja/putAloituskeskustelu', form)
      this.buttonStates.secondaryButtonLoading = false
      this.$emit('skipRouteExitConfirm', true)
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('aloituskeskustelu-palautettu-erikoistuvalle-muokattavaksi'))
    } catch {
      toastFail(this, this.$t('aloituskeskustelu-palautus-epaonnistui'))
    }
  }

  async onSubmit() {
    const form = this.isCurrentUserLahiesimies
      ? {
          ...this.aloituskeskustelu,
          lahiesimies: {
            sopimusHyvaksytty: true,
            kuittausaika: format(new Date(), 'yyyy-MM-dd')
          }
        }
      : {
          ...this.aloituskeskustelu,
          lahikouluttaja: {
            sopimusHyvaksytty: true,
            kuittausaika: format(new Date(), 'yyyy-MM-dd')
          }
        }
    try {
      this.buttonStates.primaryButtonLoading = true
      await store.dispatch('kouluttaja/putAloituskeskustelu', form)
      this.buttonStates.primaryButtonLoading = false
      this.$emit('skipRouteExitConfirm', true)
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
      toastSuccess(this, this.$t('aloituskeskustelu-lisatty-onnistuneesti'))
    } catch {
      toastFail(this, this.$t('aloituskeskustelu-lisaaminen-epaonnistui'))
    }
  }

  async mounted() {
    this.loading = true
    await store.dispatch(`${resolveRolePath()}/getKoejaksot`)

    try {
      const { data } = await (this.$isVastuuhenkilo()
        ? getAloituskeskusteluVastuuhenkilo(this.aloituskeskusteluId)
        : getAloituskeskusteluKouluttaja(this.aloituskeskusteluId))
      this.aloituskeskustelu = data
      this.loading = false

      if (!this.editable || this.returned) {
        this.$emit('skipRouteExitConfirm', true)
      }
    } catch {
      toastFail(this, this.$t('aloituskeskustelun-hakeminen-epaonnistui'))
      this.$emit('skipRouteExitConfirm', true)
      this.$router.replace({ name: 'koejakso' })
    }
  }
}
