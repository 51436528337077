import { render, staticRenderFns } from "./kurssikoodit.vue?vue&type=template&id=d24c055e&scoped=true&"
import script from "./kurssikoodit.vue?vue&type=script&lang=ts&"
export * from "./kurssikoodit.vue?vue&type=script&lang=ts&"
import style0 from "./kurssikoodit.vue?vue&type=style&index=0&id=d24c055e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d24c055e",
  null
  
)

export default component.exports