















import Component from 'vue-class-component'
import { Vue, Prop } from 'vue-property-decorator'

import { ArviointiasteikonTaso } from '@/types'

@Component({})
export default class ElsaArviointiasteikonTasoTooltipContent extends Vue {
  @Prop({ required: false, type: String, default: undefined })
  selite?: string

  @Prop({ required: true })
  arviointiasteikonTasot!: ArviointiasteikonTaso[]
}
