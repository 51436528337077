import { render, staticRenderFns } from "./lisaa-seurantajakso.vue?vue&type=template&id=512e9d8a&scoped=true&"
import script from "./lisaa-seurantajakso.vue?vue&type=script&lang=ts&"
export * from "./lisaa-seurantajakso.vue?vue&type=script&lang=ts&"
import style0 from "./lisaa-seurantajakso.vue?vue&type=style&index=0&id=512e9d8a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "512e9d8a",
  null
  
)

export default component.exports