



































import { Component, Vue } from 'vue-property-decorator'

import { getErikoisala } from '@/api/tekninen-paakayttaja'
import Pagination from '@/components/pagination/pagination.vue'
import SearchInput from '@/components/search-input/search-input.vue'
import { Erikoisala } from '@/types'
import { ERIKOISALA_YEK_ID } from '@/utils/constants'
import { toastFail } from '@/utils/toast'
import ArvioitavatKokonaisuudet from '@/views/opetussuunnitelmat/arvioitava-kokonaisuus/arvioitavat-kokonaisuudet.vue'
import Opintooppaat from '@/views/opetussuunnitelmat/opintoopas/opintooppaat.vue'
import Suoritteet from '@/views/opetussuunnitelmat/suorite/suoritteet.vue'

@Component({
  components: {
    SearchInput,
    Pagination,
    Opintooppaat,
    ArvioitavatKokonaisuudet,
    Suoritteet
  }
})
export default class ErikoisalaView extends Vue {
  erikoisala: Erikoisala | null = null

  loading = true

  tabIndex = 0
  tabs = ['#opinto-oppaat', '#arvioitavat-kokonaisuudet', '#suoritteet']

  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('opetussuunnitelmat'),
        to: { name: 'opetussuunnitelmat' }
      },
      {
        text: this.erikoisala?.nimi,
        active: true
      }
    ]
  }

  beforeMount() {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  }

  async mounted() {
    await this.fetchErikoisala()
    this.loading = false
  }

  async fetchErikoisala() {
    try {
      this.erikoisala = (await getErikoisala(this.$route?.params?.erikoisalaId)).data
    } catch (err) {
      toastFail(this, this.$t('erikoisalan-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'opetussuunnitelmat' })
    }
  }

  get onkoYEK() {
    return this.erikoisala?.id == ERIKOISALA_YEK_ID
  }
}
