



























































































































































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'

import { getKoulutettavienSeurantaList as getKoulutettavienSeurantaListVastuuhenkilo } from '@/api/vastuuhenkilo'
import { getKoulutettavienSeurantaList as getKoulutettavienSeurantaListVirkailija } from '@/api/virkailija'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaProgressBar from '@/components/progress-bar/progress-bar.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import KoulutettavienSeurantaMixin from '@/mixins/koulutettavien-seuranta'
import { Page, Erikoisala, Asetus, SortByEnum, KoulutettavanEteneminen } from '@/types'
import { ERIKOISALA_YEK_ID, ErikoistuvanSeurantaJarjestys } from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaPagination,
    ElsaProgressBar,
    ElsaSearchInput
  }
})
export default class YekKoulutettavienSeurantaCard extends Mixins(KoulutettavienSeurantaMixin) {
  @Prop({ required: true })
  yliopisto!: string

  sortFields: SortByEnum[] = [
    {
      name: this.$t('opintooikeus-paattymassa'),
      value: ErikoistuvanSeurantaJarjestys.OPINTOOIKEUS_PAATTYMASSA
    } as SortByEnum,
    {
      name: this.$t('opintooikeus-alkaen'),
      value: ErikoistuvanSeurantaJarjestys.OPINTOOIKEUS_ALKAEN
    } as SortByEnum,
    {
      name: this.$t('sukunimi-a-o'),
      value: ErikoistuvanSeurantaJarjestys.SUKUNIMI_ASC
    } as SortByEnum,
    {
      name: this.$t('sukunimi-o-a'),
      value: ErikoistuvanSeurantaJarjestys.SUKUNIMI_DESC
    } as SortByEnum
  ]
  sortBy = this.sortFields[0]

  initializing = true
  loadingResults = false

  filtered: {
    nimi: string | null
    erikoisala: Erikoisala | null
    asetus: Asetus | null
    naytaPaattyneet: boolean | null
    sortBy: string | null
  } = {
    nimi: null,
    erikoisala: null,
    asetus: null,
    naytaPaattyneet: null,
    sortBy: null
  }
  currentPage = 1
  perPage = 20
  debounce?: number

  hakutermi = ''
  koulutettavat: Page<KoulutettavanEteneminen> | null = null

  async mounted() {
    try {
      await this.fetch()
    } catch {
      toastFail(this, this.$t('yek.koulutettavien-seurannan-hakeminen-epaonnistui'))
    }
    this.initializing = false
  }

  async fetch() {
    const params = {
      page: this.currentPage - 1,
      size: this.perPage,
      sort: this.filtered.sortBy ?? 'opintooikeudenPaattymispaiva,asc',
      ...(this.filtered.nimi ? { 'nimi.contains': this.filtered.nimi } : {}),
      ...{ 'erikoisalaId.equals': ERIKOISALA_YEK_ID },
      ...(this.filtered.asetus?.id ? { 'asetusId.equals': this.filtered.asetus.id } : {}),
      ...(this.filtered.naytaPaattyneet ? { naytaPaattyneet: this.filtered.naytaPaattyneet } : {})
    }
    this.koulutettavat = this.$isVirkailija()
      ? (await getKoulutettavienSeurantaListVirkailija(params)).data
      : (await getKoulutettavienSeurantaListVastuuhenkilo(params)).data
  }

  get rows() {
    return this.koulutettavat?.totalElements ?? 0
  }

  @Watch('hakutermi')
  onPropertyChanged(value: string) {
    this.debounceSearch(value)
  }

  debounceSearch(value: string) {
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      this.filtered.nimi = value
      this.onResultsFiltered()
    }, 400)
  }

  async onErikoisalaSelect(erikoisala: Erikoisala) {
    this.filtered.erikoisala = erikoisala
    await this.onResultsFiltered()
  }

  async onErikoisalaReset() {
    this.filtered.erikoisala = null
    await this.onResultsFiltered()
  }

  async onAsetusSelect(asetus: Asetus) {
    this.filtered.asetus = asetus
    await this.onResultsFiltered()
  }

  async onAsetusReset() {
    this.filtered.asetus = null
    await this.onResultsFiltered()
  }

  async onNaytaPaattyneetSelect() {
    await this.onResultsFiltered()
  }

  async onSortBySelect(sortByEnum: SortByEnum) {
    switch (sortByEnum.value) {
      case ErikoistuvanSeurantaJarjestys.OPINTOOIKEUS_PAATTYMASSA:
        this.filtered.sortBy = 'opintooikeudenPaattymispaiva,asc'
        break
      case ErikoistuvanSeurantaJarjestys.OPINTOOIKEUS_ALKAEN:
        this.filtered.sortBy = 'opintooikeudenMyontamispaiva,asc'
        break
      case ErikoistuvanSeurantaJarjestys.SUKUNIMI_ASC:
        this.filtered.sortBy = 'erikoistuvaLaakari.kayttaja.user.lastName,asc'
        break
      case ErikoistuvanSeurantaJarjestys.SUKUNIMI_DESC:
        this.filtered.sortBy = 'erikoistuvaLaakari.kayttaja.user.lastName,desc'
        break
    }
    this.onResultsFiltered()
  }

  onPageInput(value: number) {
    this.currentPage = value
    this.fetch()
  }

  private async onResultsFiltered() {
    this.loadingResults = true
    this.currentPage = 1
    await this.fetch()
    this.loadingResults = false
  }

  get yliopistoNimi() {
    if (this.yliopisto == null) {
      return ''
    } else return this.$t(`yliopisto-nimi.${this.yliopisto}`)
  }
}
