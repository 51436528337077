
































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'

import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaProgressBar from '@/components/progress-bar/progress-bar.vue'
import store from '@/store'
import { OpintosuorituksetWrapper, Opintosuoritus } from '@/types'
import { OpintosuoritusTyyppiEnum } from '@/utils/constants'
import { toastFail } from '@/utils/toast'
import OpintosuoritusTab from '@/views/opintosuoritukset/opintosuoritus-tab.vue'

@Component({
  components: {
    ElsaFormGroup,
    ElsaProgressBar,
    OpintosuoritusTab
  }
})
export default class Opintosuoritukset extends Vue {
  endpointUrl = 'erikoistuva-laakari/opintosuoritukset'
  opintosuorituksetWrapper: OpintosuorituksetWrapper | null = null
  johtamisopinnot: Opintosuoritus[] = []
  sateilysuojelukoulutukset: Opintosuoritus[] = []
  kuulustelut: Opintosuoritus[] = []
  muut: Opintosuoritus[] = []
  loading = false
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('opintosuoritukset'),
      active: true
    }
  ]

  tabIndex = 0
  tabs = ['#johtamisopinnot', '#sateilysuojakoulutukset', '#kuulustelu', '#muut']

  async mounted() {
    await this.fetch()
  }

  get account() {
    return store.getters['auth/account']
  }

  beforeMount() {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  }

  async fetch() {
    try {
      this.loading = true
      this.opintosuorituksetWrapper = (await axios.get(this.endpointUrl)).data
      this.opintosuorituksetWrapper?.opintosuoritukset?.forEach((os: Opintosuoritus) => {
        switch (os.tyyppi?.nimi) {
          case OpintosuoritusTyyppiEnum.JOHTAMISOPINTO:
            this.johtamisopinnot.push(os)
            break
          case OpintosuoritusTyyppiEnum.SATEILYSUOJAKOULUTUS:
            this.sateilysuojelukoulutukset.push(os)
            break
          case OpintosuoritusTyyppiEnum.VALTAKUNNALLINEN_KUULUSTELU:
            this.kuulustelut.push(os)
            break
          default:
            this.muut.push(os)
        }
      })
      this.loading = false
    } catch {
      toastFail(this, this.$t('opintosuoritusten-haku-epaonnistui'))
      this.loading = false
    }
  }
}
