


















import { Component, Vue } from 'vue-property-decorator'

import BCardSkeleton from '@/components/card/card.vue'
import AvoimetAsiatYekCard from '@/components/etusivu-cards/avoimet-asiat-yek-card.vue'
import HenkilotiedotCard from '@/components/etusivu-cards/henkilotiedot-card.vue'
import YekKoulutuksenEdistyminenCard from '@/components/etusivu-cards/yek-koulutuksen-edistyminen-card.vue'

@Component({
  components: {
    BCardSkeleton,
    AvoimetAsiatYekCard,
    HenkilotiedotCard,
    YekKoulutuksenEdistyminenCard
  }
})
export default class EtusivuErikoistuja extends Vue {}
