





































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import { getSeurantajakso, getSeurantajaksonTiedot } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import SeurantajaksoForm from '@/forms/seurantajakso-form.vue'
import store from '@/store'
import { Seurantajakso, SeurantajaksonTiedot } from '@/types'
import { SeurantajaksoTila } from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    SeurantajaksoForm
  }
})
export default class SeurantajaksoViewErikoistuja extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('seurantakeskustelut'),
      to: { name: 'seurantakeskustelut' }
    },
    {
      text: this.$t('seurantajakso'),
      active: true
    }
  ]
  loading = true

  seurantajakso: Seurantajakso | null = null
  seurantajaksonTiedot: SeurantajaksonTiedot | null = null

  params = {
    saving: false
  }

  async mounted() {
    this.loading = true
    try {
      this.seurantajakso = (await getSeurantajakso(this.$route?.params?.seurantajaksoId)).data
      this.seurantajaksonTiedot = (
        await getSeurantajaksonTiedot(
          this.seurantajakso.alkamispaiva || '',
          this.seurantajakso.paattymispaiva || '',
          this.seurantajakso.koulutusjaksot
            .map((k) => k.id)
            .filter((k): k is number => k !== null)
        )
      ).data
    } catch {
      toastFail(this, this.$t('seurantajakson-tietojen-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'seurantakeskustelut' })
    }
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  get showOdottaaKeskusteluaJaArviointia() {
    return (
      this.seurantajakso?.tila === SeurantajaksoTila.ODOTTAA_ARVIOINTIA_JA_YHTEISIA_MERKINTOJA
    )
  }

  get showOdottaaKeskustelua() {
    return this.seurantajakso?.tila === SeurantajaksoTila.ODOTTAA_YHTEISIA_MERKINTOJA
  }

  get showOdottaaArviointia() {
    return this.seurantajakso?.tila === SeurantajaksoTila.ODOTTAA_ARVIOINTIA
  }

  get showHyvaksytty() {
    return this.seurantajakso?.tila === SeurantajaksoTila.HYVAKSYTTY
  }

  get showPalautettuKorjattavaksi() {
    return this.seurantajakso?.tila === SeurantajaksoTila.PALAUTETTU_KORJATTAVAKSI
  }

  get showKouluttajaArvioinut() {
    return this.seurantajakso?.tila === SeurantajaksoTila.ODOTTAA_YHTEISIA_MERKINTOJA
  }

  get showOdottaaHyvaksyntaa() {
    return this.seurantajakso?.tila === SeurantajaksoTila.ODOTTAA_HYVAKSYNTAA
  }

  get showHuolenaiheet() {
    return this.seurantajakso?.huolenaiheet != null
  }

  get canEdit() {
    return (
      this.seurantajakso?.seurantakeskustelunYhteisetMerkinnat === null ||
      this.seurantajakso?.korjausehdotus !== null
    )
  }
}
