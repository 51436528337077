













































import { Component, Mixins } from 'vue-property-decorator'

import OmatTiedotErikoistuja from './omat-tiedot-erikoistuja.vue'
import OmatTiedot from './omat-tiedot.vue'

import ConfirmRouteExit from '@/mixins/confirm-route-exit'
import Katseluoikeudet from '@/views/profiili/katseluoikeudet.vue'
import Muokkausoikeudet from '@/views/profiili/muokkausoikeudet.vue'

@Component({
  components: {
    OmatTiedot,
    OmatTiedotErikoistuja,
    Katseluoikeudet,
    Muokkausoikeudet
  }
})
export default class Profiili extends Mixins(ConfirmRouteExit) {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('oma-profiili'),
      active: true
    }
  ]
  editing = false
  skipRouteExitConfirm = true

  tabIndex = 0
  tabs = ['#omat-tiedot', '#katseluoikeudet']

  beforeMount() {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  }

  changeEditing(event: boolean) {
    this.skipRouteExitConfirm = !event
    this.editing = event
  }
}
