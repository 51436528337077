

























































import Vue from 'vue'
import Component from 'vue-class-component'

import {
  deleteSeurantajakso,
  getSeurantajakso,
  getSeurantajaksonTiedot,
  putSeurantajakso
} from '@/api/erikoistuva'
import SeurantajaksoForm from '@/forms/seurantajakso-form.vue'
import store from '@/store'
import { Seurantajakso, SeurantajaksonTiedot } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    SeurantajaksoForm
  }
})
export default class MuokkaaSeurantajaksoaErikoistuja extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('seurantakeskustelut'),
      to: { name: 'seurantakeskustelut' }
    },
    {
      text: this.$t('muokkaa-seurantajaksoa'),
      active: true
    }
  ]
  loading = true

  seurantajakso: Seurantajakso | null = null
  seurantajaksonTiedot: SeurantajaksonTiedot | null = null

  params = {
    saving: false,
    deleting: false
  }

  async mounted() {
    this.loading = true
    try {
      this.seurantajakso = (await getSeurantajakso(this.$route?.params?.seurantajaksoId)).data
      this.seurantajaksonTiedot = (
        await getSeurantajaksonTiedot(
          this.seurantajakso.alkamispaiva || '',
          this.seurantajakso.paattymispaiva || '',
          this.seurantajakso.koulutusjaksot
            .map((k) => k.id)
            .filter((k): k is number => k !== null)
        )
      ).data
      await store.dispatch('erikoistuva/getKouluttajatJaVastuuhenkilot')
    } catch {
      toastFail(this, this.$t('seurantajakson-tietojen-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'seurantakeskustelut' })
    }
    this.loading = false
  }

  get showOdottaaKeskustelua() {
    return (
      this.seurantajakso?.seurantakeskustelunYhteisetMerkinnat === null &&
      this.seurantajakso.kouluttajanArvio === null
    )
  }

  get kouluttajat() {
    return store.getters['erikoistuva/kouluttajatJaVastuuhenkilot'] || []
  }

  async onSubmit(value: Seurantajakso, params: { saving: boolean }) {
    params.saving = true
    try {
      await putSeurantajakso(value)
      toastSuccess(this, this.$t('seurantajakson-tallennus-ja-lahetys-onnistui'))
      this.$emit('skipRouteExitConfirm')
      this.$router.push({
        name: 'seurantajakso',
        params: {
          seurantajaksoId: `${this.seurantajakso?.id}`
        }
      })
    } catch (err) {
      toastFail(this, this.$t('seurantajakson-tallentaminen-epaonnistui'))
    }
    params.saving = false
  }

  async onDelete(params: { deleting: boolean }) {
    if (
      await confirmDelete(
        this,
        this.$t('poista-seurantajakso') as string,
        (this.$t('seurantajakson') as string).toLowerCase()
      )
    ) {
      params.deleting = true
      try {
        await deleteSeurantajakso(this.seurantajakso?.id)
        toastSuccess(this, this.$t('seurantajakso-poistettu-onnistuneesti'))
        this.$emit('skipRouteExitConfirm')
        this.$router.push({
          name: 'seurantakeskustelut'
        })
      } catch {
        toastFail(this, this.$t('seurantajakson-poistaminen-epaonnistui'))
      }
      params.deleting = false
    }
  }

  onCancel() {
    this.$router.push({
      name: 'seurantajakso',
      params: {
        seurantajaksoId: `${this.seurantajakso?.id}`
      }
    })
  }

  skipRouteExitConfirm(value: boolean) {
    this.$emit('skipRouteExitConfirm', value)
  }
}
