

































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import { ArvioitavanKokonaisuudenKategoria } from '@/types'

@Component({
  components: {
    ElsaButton,
    ElsaFormError,
    ElsaFormGroup
  }
})
export default class ArvioitavanKokonaisuudenKategoriaForm extends Mixins(validationMixin) {
  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({ required: true, type: Object })
  kategoria!: ArvioitavanKokonaisuudenKategoria

  params = {
    saving: false
  }

  validations() {
    return {
      kategoria: {
        nimi: {
          required
        }
      }
    }
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.kategoria[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.kategoria.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    if (!this.validateForm()) {
      return
    }
    this.$emit(
      'submit',
      {
        ...this.kategoria
      },
      this.params
    )
  }
}
