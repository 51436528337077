var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paivittaiset-merkinnat"},[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('div',{staticClass:"mb-4"},[_c('h1',[_vm._v(_vm._s(_vm.$t('paivittaiset-merkinnat')))]),_c('p',[_vm._v(_vm._s(_vm.$t('paivittaiset-merkinnat-ingressi')))]),(!_vm.account.impersonated)?_c('elsa-button',{staticClass:"mb-2",attrs:{"variant":"primary","to":{ name: 'uusi-paivittainen-merkinta' }}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-merkinta'))+" ")]):_vm._e(),_c('hr'),(!_vm.loading && _vm.merkinnat)?_c('div',[_c('small',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t('rajaa-merkintoja')))]),_c('b-container',{staticClass:"px-0 mt-2",class:{ 'mb-md-5': !_vm.anyFilterSelected },attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('elsa-form-group',{staticClass:"mb-md-0",attrs:{"label":_vm.$t('aihe')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.aihekategoriat,"label":"nimi","track-by":"jarjestysnumero"},on:{"select":_vm.onAihekategoriaSelect,"clearMultiselect":_vm.resetFilters},model:{value:(_vm.selected.aihe),callback:function ($$v) {_vm.$set(_vm.selected, "aihe", $$v)},expression:"selected.aihe"}})]}}],null,false,2679652935)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('elsa-form-group',{staticClass:"mb-md-0",class:{ 'mb-0': _vm.anyFilterSelected },attrs:{"label":_vm.$t('ajankohta-alkaa')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-datepicker',{ref:"ajankohtaAlkaa",attrs:{"id":uid,"value":_vm.selected.ajankohtaAlkaa,"max":_vm.maxAjankohtaAlkaa,"max-error-text":_vm.$t('ajankohta-ei-voi-alkaa-paattymisen-jalkeen'),"required":false},on:{"input":_vm.onAjankohtaAlkaaSelect}})]}}],null,false,2447073985)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('elsa-form-group',{staticClass:"mb-md-0",class:{ 'mb-0': _vm.anyFilterSelected },attrs:{"label":_vm.$t('ajankohta-paattyy')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-datepicker',{ref:"ajankohtaPaattyy",staticClass:"datepicker-range",attrs:{"id":uid,"value":_vm.selected.ajankohtaPaattyy,"min":_vm.minAjankohtaPaattyy,"min-error-text":_vm.$t('paattymispaiva-ei-voi-olla-ennen-alkamispaivaa'),"required":false},on:{"input":_vm.onAjankohtaPaattyySelect}})]}}],null,false,3671872284)})],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex flex-row-reverse"},[(_vm.anyFilterSelected)?_c('elsa-button',{staticClass:"shadow-none text-size-sm font-weight-500",attrs:{"variant":"link"},on:{"click":_vm.resetFilters}},[_vm._v(" "+_vm._s(_vm.$t('tyhjenna-valinnat'))+" ")]):_vm._e()],1)])],1)],1),(_vm.merkinnat.empty)?_c('b-alert',{attrs:{"variant":"dark","show":""}},[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-paivittaisia-merkintoja'))+" ")])],1):_vm._l((_vm.merkinnat.content),function(paivittainenMerkinta,index){return _c('div',{key:index,staticClass:"d-flex flex-column border rounded p-2 mb-2"},[_c('elsa-button',{staticClass:"d-flex p-0 border-0 shadow-none font-weight-500 text-left",attrs:{"to":{
                  name: 'paivittainen-merkinta',
                  params: { paivakirjamerkintaId: paivittainenMerkinta.id }
                },"variant":"link"}},[_vm._v(" "+_vm._s(paivittainenMerkinta.oppimistapahtumanNimi)+" ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('small',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$date(paivittainenMerkinta.paivamaara)))]),_vm._l((paivittainenMerkinta.aihekategoriat),function(aihe){return _c('b-badge',{key:aihe.id,staticClass:"font-weight-400 mr-2 mb-2",attrs:{"pill":"","variant":"light"}},[_vm._v(" "+_vm._s(("" + (aihe.nimi) + (aihe.teoriakoulutus && paivittainenMerkinta.teoriakoulutus ? (": " + (paivittainenMerkinta.teoriakoulutus.koulutuksenNimi)) : '') + (aihe.muunAiheenNimi ? (": " + (paivittainenMerkinta.muunAiheenNimi)) : '')))+" ")])})],2),_c('div',{staticClass:"line-clamp"},[_vm._v(" "+_vm._s(paivittainenMerkinta.reflektio)+" ")])],1)}),_c('elsa-pagination',{attrs:{"current-page":_vm.currentPage,"per-page":_vm.perPage,"rows":_vm.rows},on:{"update:currentPage":_vm.onPageInput}})],2):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }