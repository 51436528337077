










































































































































































































































































































































































































































































































































































































































































import { AxiosError } from 'axios'
import { Component, Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'

import { ELSA_API_LOCATION } from '@/api'
import { getValmistumispyyntoTarkistus, putValmistumispyynto } from '@/api/virkailija'
import AsiakirjaButton from '@/components/asiakirjat/asiakirja-button.vue'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import AsiakirjatUpload from '@/components/asiakirjat/asiakirjat-upload.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaReturnToSenderModal from '@/components/modal/return-to-sender-modal.vue'
import YekTyoskentelyjaksotList from '@/components/tyoskentelyjaksot-list/yek-tyoskentelyjaksot-list.vue'
import ValmistumispyyntoMixin from '@/mixins/valmistumispyynto'
import {
  ValmistumispyyntoArviointienTila,
  ValmistumispyyntoVirkailijanTarkistus,
  ValmistumispyynnonVirkailijanTarkistusLomake,
  Asiakirja,
  ElsaError
} from '@/types'
import { confirmExit } from '@/utils/confirm'
import { ValmistumispyynnonTila } from '@/utils/constants'
import { mapFile, mapFiles } from '@/utils/fileMapper'
import { toastSuccess, toastFail } from '@/utils/toast'

@Component({
  components: {
    AsiakirjaButton,
    AsiakirjatContent,
    AsiakirjatUpload,
    ElsaButton,
    ElsaFormGroup,
    ElsaFormDatepicker,
    ElsaFormError,
    ErikoistuvaDetails,
    ElsaConfirmationModal,
    ElsaReturnToSenderModal,
    YekTyoskentelyjaksotList
  }
})
export default class ValmistumispyynnonTarkistusYek extends Mixins<ValmistumispyyntoMixin>(
  validationMixin,
  ValmistumispyyntoMixin
) {
  $refs!: {
    laillistamispaiva: ElsaFormDatepicker
  }
  validations() {
    return {
      form: {
        terveyskeskustyoTarkistettu: { checked: (value: boolean) => value === true },
        yliopistosairaalanUlkopuolinenTyoTarkistettu: {
          checked: (value: boolean) => value === true
        },
        yliopistosairaalatyoTarkistettu: { checked: (value: boolean) => value === true },
        kokonaistyoaikaTarkistettu: { checked: (value: boolean) => value === true },
        laillistamispaiva: {
          required
        },
        laillistamistodistus: {
          required: requiredIf(() => {
            return this.laillistamispaivaAsiakirjat.length === 0
          })
        }
      }
    }
  }

  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('valmistumispyynnot'),
      to: { name: 'valmistumispyynnot', hash: '#yek' }
    },
    {
      text: this.$t('yek.valmistumispyynto'),
      active: true
    }
  ]

  form: ValmistumispyynnonVirkailijanTarkistusLomake = {
    id: null,
    yekSuoritettu: false,
    yekSuorituspaiva: null,
    ptlSuoritettu: false,
    ptlSuorituspaiva: null,
    aiempiElKoulutusSuoritettu: false,
    aiempiElKoulutusSuorituspaiva: null,
    ltTutkintoSuoritettu: false,
    ltTutkintoSuorituspaiva: null,
    terveyskeskustyoTarkistettu: false,
    yliopistosairaalanUlkopuolinenTyoTarkistettu: false,
    yliopistosairaalatyoTarkistettu: false,
    kokonaistyoaikaTarkistettu: false,
    teoriakoulutusTarkistettu: false,
    koejaksoEiVaadittu: false,
    kommentitVirkailijoille: null,
    lisatiedotVastuuhenkilolle: null,
    keskenerainen: false,
    laillistamispaiva: null,
    laillistamistodistus: null
  }

  valmistumispyynnonTarkistus: ValmistumispyyntoVirkailijanTarkistus | null = null
  valmistumispyyntoArviointienTila: ValmistumispyyntoArviointienTila | null = null
  response: ValmistumispyynnonVirkailijanTarkistusLomake | null = null
  loading = true
  sending = false
  editable = true
  draft = false
  yekSuorituspaivaTila = false
  ptlSuorituspaivaTila = false
  aiempiElKoulutusSuorituspaivaTila = false
  ltTutkintoSuorituspaivaTila = false
  yhteenvetoAsiakirjaUrl: string | null = null
  liitteetAsiakirjaUrl: string | null = null
  laillistamispaivaAsiakirjat: Asiakirja[] = []
  laillistaminenMuokattavissa = false
  skipRouteExitConfirm = true
  showTerveystyo = false
  showSairaalatyo = false
  showMuu = false

  async mounted() {
    const valmistumispyyntoId = this.$route?.params?.valmistumispyyntoId
    if (valmistumispyyntoId) {
      try {
        await getValmistumispyyntoTarkistus(parseInt(valmistumispyyntoId)).then((response) => {
          this.valmistumispyynnonTarkistus = response.data
          this.form = {
            ...this.valmistumispyynnonTarkistus,
            laillistamispaiva: null,
            laillistamistodistus: null,
            keskenerainen: false
          }
          if (response.data.valmistumispyynto) {
            this.valmistumispyynto = response.data.valmistumispyynto
          }
          if (
            this.valmistumispyynto.tila ==
              ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA ||
            this.valmistumispyynto.tila ==
              ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_PALAUTETTU ||
            this.valmistumispyynto.tila == ValmistumispyynnonTila.HYVAKSYTTY ||
            this.valmistumispyynto.tila ==
              ValmistumispyynnonTila.VASTUUHENKILON_HYVAKSYNTA_PALAUTETTU
          ) {
            this.editable = false
          }
          if (
            this.valmistumispyynto.tila == ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN
          ) {
            this.draft = true
            this.editable = true
          }
          if (!this.draft || !this.editable) {
            this.yekSuorituspaivaTila = response.data.yekSuorituspaiva ? true : false
            this.ptlSuorituspaivaTila = response.data.ptlSuorituspaiva ? true : false
            this.aiempiElKoulutusSuorituspaivaTila = response.data.aiempiElKoulutusSuorituspaiva
              ? true
              : false
            this.ltTutkintoSuorituspaivaTila = response.data.ltTutkintoSuorituspaiva
              ? true
              : false
          } else {
            this.yekSuorituspaivaTila = false
            this.ptlSuorituspaivaTila = false
            this.aiempiElKoulutusSuorituspaivaTila = false
            this.ltTutkintoSuorituspaivaTila = false
          }
          if (this.valmistumispyynto.yhteenvetoAsiakirjaId) {
            this.yhteenvetoAsiakirjaUrl = `/virkailija/valmistumispyynto/${this.valmistumispyynto.id}/asiakirja/`
          }
          if (this.valmistumispyynto.liitteetAsiakirjaId) {
            this.liitteetAsiakirjaUrl = `/virkailija/valmistumispyynto/${this.valmistumispyynto.id}/asiakirja/`
          }
        })
        if (this.valmistumispyynto.erikoistujanLaillistamistodistus) {
          const data = Uint8Array.from(
            atob(this.valmistumispyynto.erikoistujanLaillistamistodistus),
            (c) => c.charCodeAt(0)
          )
          this.laillistamispaivaAsiakirjat.push(
            mapFile(
              new File(
                [data],
                this.valmistumispyynto?.erikoistujanLaillistamistodistusNimi || '',
                {
                  type: this.valmistumispyynto?.erikoistujanLaillistamistodistusTyyppi
                }
              )
            )
          )
        }
        if (this.valmistumispyynto.erikoistujanLaillistamispaiva) {
          this.form.laillistamispaiva = this.valmistumispyynto.erikoistujanLaillistamispaiva
        }
        this.loading = false
      } catch {
        toastFail(this, this.$t('valmistumispyynnon-hakeminen-epaonnistui'))
        this.loading = false
        this.$router.replace({ name: 'valmistumispyynnot', hash: '#yek' })
      }
    }
  }

  async vaihdaRooli(id: number | undefined) {
    if (this.editable && !this.skipRouteExitConfirm) {
      if (!(await confirmExit(this))) {
        return
      }
    }

    this.$emit('skipRouteExitConfirm', true)
    window.location.href = `${ELSA_API_LOCATION}/api/login/impersonate?opintooikeusId=${id}&originalUrl=${window.location.href}`
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    const validations = [
      this.validateForm(),
      this.$refs.laillistamispaiva ? this.$refs.laillistamispaiva.validateForm() : true
    ]
    if (validations.includes(false)) return
    return this.$bvModal.show('confirm-send')
  }

  saveAndExit() {
    this.form.keskenerainen = true
    this.onSend()
  }

  async onSend() {
    try {
      this.sending = true
      if (this.valmistumispyynto?.id) {
        this.form.id = this.valmistumispyynto.id
      }
      const form: ValmistumispyynnonVirkailijanTarkistusLomake = {
        id: this.form.id,
        yekSuoritettu: this.form.yekSuoritettu,
        yekSuorituspaiva: this.form.yekSuorituspaiva,
        ptlSuoritettu: this.form.ptlSuoritettu,
        ptlSuorituspaiva: this.form.ptlSuorituspaiva,
        aiempiElKoulutusSuoritettu: this.form.aiempiElKoulutusSuoritettu,
        aiempiElKoulutusSuorituspaiva: this.form.aiempiElKoulutusSuorituspaiva,
        ltTutkintoSuoritettu: this.form.ltTutkintoSuoritettu,
        ltTutkintoSuorituspaiva: this.form.ltTutkintoSuorituspaiva,
        terveyskeskustyoTarkistettu: this.form.terveyskeskustyoTarkistettu,
        yliopistosairaalanUlkopuolinenTyoTarkistettu:
          this.form.yliopistosairaalanUlkopuolinenTyoTarkistettu,
        yliopistosairaalatyoTarkistettu: this.form.yliopistosairaalatyoTarkistettu,
        kokonaistyoaikaTarkistettu: this.form.kokonaistyoaikaTarkistettu,
        teoriakoulutusTarkistettu: this.form.teoriakoulutusTarkistettu,
        kommentitVirkailijoille: this.form.kommentitVirkailijoille,
        koejaksoEiVaadittu: this.form.koejaksoEiVaadittu,
        lisatiedotVastuuhenkilolle: this.form.lisatiedotVastuuhenkilolle || null,
        keskenerainen: this.form.keskenerainen,
        laillistamispaiva: this.laillistaminenMuokattavissa ? this.form.laillistamispaiva : null,
        laillistamistodistus: this.laillistaminenMuokattavissa
          ? this.form.laillistamistodistus
          : null
      }
      this.response = (await putValmistumispyynto(form)).data
      this.$emit('skipRouteExitConfirm', true)
      if (this.form.keskenerainen) {
        toastSuccess(this, this.$t('virkailijan-tarkistus-tallennettu-keskeneraisena'))
      } else {
        toastSuccess(this, this.$t('virkailijan-tarkistus-lahetetty-onnistuneesti'))
      }
      this.$router.replace({ name: 'valmistumispyynnot', hash: '#yek' })
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('virkailijan-tarkistus-lahetys-epaonnistui')}: ${this.$t(message)}`
          : this.$t('virkailijan-tarkistus-lahetys-epaonnistui')
      )
    }
    this.sending = false
  }

  async onReturnToSender(korjausehdotus: string) {
    if (this.valmistumispyynto?.id) {
      this.form.id = this.valmistumispyynto.id
    }
    const form: ValmistumispyynnonVirkailijanTarkistusLomake = {
      id: this.form.id,
      yekSuoritettu: this.form.yekSuoritettu,
      yekSuorituspaiva: this.form.yekSuorituspaiva,
      ptlSuoritettu: this.form.ptlSuoritettu,
      ptlSuorituspaiva: this.form.ptlSuorituspaiva,
      aiempiElKoulutusSuoritettu: this.form.aiempiElKoulutusSuoritettu,
      aiempiElKoulutusSuorituspaiva: this.form.aiempiElKoulutusSuorituspaiva,
      ltTutkintoSuoritettu: this.form.ltTutkintoSuoritettu,
      ltTutkintoSuorituspaiva: this.form.ltTutkintoSuorituspaiva,
      terveyskeskustyoTarkistettu: this.form.terveyskeskustyoTarkistettu,
      yliopistosairaalanUlkopuolinenTyoTarkistettu:
        this.form.yliopistosairaalanUlkopuolinenTyoTarkistettu,
      yliopistosairaalatyoTarkistettu: this.form.yliopistosairaalatyoTarkistettu,
      kokonaistyoaikaTarkistettu: this.form.kokonaistyoaikaTarkistettu,
      teoriakoulutusTarkistettu: this.form.teoriakoulutusTarkistettu,
      koejaksoEiVaadittu: this.form.koejaksoEiVaadittu,
      kommentitVirkailijoille: this.form.kommentitVirkailijoille,
      keskenerainen: false,
      korjausehdotus: korjausehdotus,
      laillistamispaiva: this.laillistaminenMuokattavissa ? this.form.laillistamispaiva : null,
      laillistamistodistus: this.laillistaminenMuokattavissa
        ? this.form.laillistamistodistus
        : null
    }
    try {
      this.sending = true
      this.response = (await putValmistumispyynto(form)).data
      this.$emit('skipRouteExitConfirm', true)
      toastSuccess(this, this.$t('yek.virkailijan-tarkistus-palautettu-onnistuneesti'))
      this.$router.replace({ name: 'valmistumispyynnot', hash: '#yek' })
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('virkailijan-tarkistus-palautus-epaonnistui')}: ${this.$t(message)}`
          : this.$t('virkailijan-tarkistus-palautus-epaonnistui')
      )
    }
    this.sending = false
  }

  muokkaaLaillistamista() {
    this.laillistaminenMuokattavissa = true
  }

  onLaillistamistodistusFilesAdded(files: File[]) {
    this.form.laillistamistodistus = files[0]
    this.laillistamispaivaAsiakirjat.push(...mapFiles(files))
  }

  async onDeletelaillistamistodistus() {
    this.form.laillistamistodistus = null
    this.laillistamispaivaAsiakirjat = []
    this.onSkipRouteExitConfirm()
  }

  onSkipRouteExitConfirm() {
    this.skipRouteExitConfirm = false
    this.$emit('skipRouteExitConfirm', false)
  }

  get teoriakoulutusSuoritettu(): boolean {
    const suoritettu = Math.round(this.valmistumispyynnonTarkistus?.teoriakoulutusSuoritettu || 0)
    const vaadittu = Math.round(this.valmistumispyynnonTarkistus?.teoriakoulutusVaadittu || 0)
    return suoritettu >= vaadittu
  }

  get terveyskeskustyoLabel() {
    return `${this.$t('yek.terveyskeskustyo')} ${this.$t('yek.vah')} ${this.$duration(
      this.valmistumispyynnonTarkistus?.tyoskentelyjaksotTilastot
        .terveyskeskusVaadittuVahintaan || 0
    )}`
  }

  get sairaalatyoLabel() {
    return `${this.$t('yek.sairaalatyo')} ${this.$t('yek.vah')} ${this.$duration(
      this.valmistumispyynnonTarkistus?.tyoskentelyjaksotTilastot
        .yliopistosairaalaVaadittuVahintaan || 0
    )}`
  }

  get asiakirjaDataEndpointUrl() {
    return `virkailija/valmistumispyynto/tyoskentelyjakso-liite`
  }
}
