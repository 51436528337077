var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('koulutuksen-nimi'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('koulutuksenNimi')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.koulutuksenNimi),callback:function ($$v) {_vm.$set(_vm.form, "koulutuksenNimi", $$v)},expression:"form.koulutuksenNimi"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('paikka'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('koulutuksenPaikka')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.koulutuksenPaikka),callback:function ($$v) {_vm.$set(_vm.form, "koulutuksenPaikka", $$v)},expression:"form.koulutuksenPaikka"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pr-sm-3",attrs:{"label":_vm.$t('alkamispaiva'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('div',[(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"alkamispaiva",attrs:{"id":uid,"value":_vm.form.alkamispaiva,"max":_vm.form.paattymispaiva,"max-error-text":_vm.$t('alkamispaiva-ei-voi-olla-paattymispaivan-jalkeen')},on:{"update:value":function($event){return _vm.$set(_vm.form, "alkamispaiva", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}}):_vm._e()],1)]}}])}),_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pl-sm-3",attrs:{"label":_vm.$t('paattymispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.childDataReceived)?_c('elsa-form-datepicker',{ref:"paattymispaiva",staticClass:"datepicker-range",attrs:{"id":uid,"value":_vm.form.paattymispaiva,"min":_vm.form.alkamispaiva,"min-error-text":_vm.$t('paattymispaiva-ei-voi-olla-ennen-alkamispaivaa'),"required":false,"aria-describedby":(uid + "-help")},on:{"update:value":function($event){return _vm.$set(_vm.form, "paattymispaiva", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}}):_vm._e()]}}])})],1),_c('elsa-form-group',{attrs:{"label":_vm.$t('erikoistumiseen-hyvaksyttava-tuntimaara')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"col-sm-3",attrs:{"id":uid,"value":_vm.form.erikoistumiseenHyvaksyttavaTuntimaara,"type":"number","step":"any"},on:{"input":_vm.onErikoistumiseenHyvaksyttavaTuntimaaraInput}}),_c('span',{staticClass:"mx-3"},[_vm._v(_vm._s(_vm.$t('t')))])],1)])]}}])}),_c('elsa-form-group',{attrs:{"label":_vm.$t('todistus')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('div',{attrs:{"id":uid}},[_c('p',[_vm._v(_vm._s(_vm.$t('teoriakoulutus-todistus-kuvaus')))]),_c('asiakirjat-upload',{attrs:{"is-primary-button":false,"button-text":_vm.$t('lisaa-liitetiedosto'),"existing-file-names-in-current-view":_vm.existingFileNamesInCurrentView,"existing-file-names-in-other-views":_vm.existingFileNamesInOtherViews,"disabled":_vm.reservedAsiakirjaNimetMutable === undefined},on:{"selectedFiles":_vm.onFilesAdded}}),_c('asiakirjat-content',{attrs:{"asiakirjat":_vm.asiakirjatTableItems,"sorting-enabled":false,"pagination-enabled":false,"enable-search":false,"show-info-if-empty":false},on:{"deleteAsiakirja":_vm.onDeleteLiitetiedosto}})],1)]}}])}),(_vm.asiakirjatTableItems.length === 0)?_c('hr'):_vm._e(),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[_c('elsa-button',{staticClass:"ml-2 mb-2",attrs:{"loading":_vm.params.saving,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tallenna-teoriakoulutus'))+" ")]),_c('elsa-button',{staticClass:"mb-2",attrs:{"variant":"back"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCancel($event)}}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }