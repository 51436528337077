





























































































































































































































































import axios from 'axios'
import { parseISO } from 'date-fns'
import { Component, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaPoissaolonSyyt from '@/components/poissaolon-syyt/poissaolon-syyt.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import TyoskentelyjaksotBarChart from '@/components/tyoskentelyjaksot-bar-chart.vue'
import ElsaVanhaAsetusVaroitus from '@/components/vanha-asetus-varoitus/vanha-asetus-varoitus.vue'
import store from '@/store'
import {
  Keskeytysaika,
  TyoskentelyjaksotTable,
  TyoskentelyjaksotTilastotKaytannonKoulutus,
  TyoskentelyjaksotTilastotTyoskentelyjaksot
} from '@/types'
import { KaytannonKoulutusTyyppi, TerveyskeskuskoulutusjaksonTila } from '@/utils/constants'
import { sortByDateDesc } from '@/utils/date'
import { ELSA_ROLE } from '@/utils/roles'
import { toastFail } from '@/utils/toast'
import { ajankohtaLabel, tyoskentelyjaksoKaytannonKoulutusLabel } from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaPopover,
    TyoskentelyjaksotBarChart,
    ElsaPoissaolonSyyt,
    ElsaVanhaAsetusVaroitus
  }
})
export default class Tyoskentelyjaksot extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('tyoskentelyjaksot'),
      active: true
    }
  ]
  tyoskentelyjaksotTaulukko: TyoskentelyjaksotTable | null = null
  fields = [
    {
      key: 'tyoskentelypaikkaLabel',
      label: this.$t('tyoskentelypaikka'),
      sortable: true
    },
    {
      key: 'ajankohtaDate',
      label: this.$t('ajankohta'),
      sortable: true
    },
    {
      key: 'tyoskentelyaikaLabel',
      label: this.$t('tyoskentelyaika'),
      sortable: true
    },
    {
      key: 'osaaikaprosenttiLabel',
      label: this.$t('tyoaika'),
      sortable: true
    },
    {
      key: 'hyvaksyttyAiempaanErikoisalaanLabel',
      label: this.$t('hyvaksytty-toiselle-erikoisalalle'),
      sortable: true
    },
    {
      key: 'keskeytyksetLength',
      label: this.$t('poissaolot'),
      sortable: true
    }
  ]
  loading = true

  async mounted() {
    await this.fetchTyoskentelyjaksot()
    this.loading = false
  }

  get account() {
    return store.getters['auth/account']
  }

  async fetchTyoskentelyjaksot() {
    try {
      this.tyoskentelyjaksotTaulukko = (
        await axios.get(`erikoistuva-laakari/tyoskentelyjaksot-taulukko`)
      ).data
    } catch {
      toastFail(this, this.$t('tyoskentelyjaksojen-hakeminen-epaonnistui'))
    }
  }

  get tyoskentelyjaksot() {
    if (this.tyoskentelyjaksotTaulukko) {
      return this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot
    } else {
      return []
    }
  }

  get keskeytykset() {
    if (this.tyoskentelyjaksotTaulukko) {
      return this.tyoskentelyjaksotTaulukko.keskeytykset
    } else {
      return []
    }
  }

  get tilastot() {
    if (this.tyoskentelyjaksotTaulukko) {
      return this.tyoskentelyjaksotTaulukko.tilastot
    } else {
      return undefined
    }
  }

  get tilastotKaytannonKoulutus() {
    if (this.tilastot) {
      return this.tilastot.kaytannonKoulutus
    } else {
      return []
    }
  }

  get tilastotTyoskentelyjaksot() {
    if (this.tilastot) {
      return this.tilastot.tyoskentelyjaksot
    } else {
      return []
    }
  }

  get tilastotKaytannonKoulutusSorted() {
    return [
      this.tilastotKaytannonKoulutus.find(
        (kk) => kk.kaytannonKoulutus === KaytannonKoulutusTyyppi.OMAN_ERIKOISALAN_KOULUTUS
      ),
      this.tilastotKaytannonKoulutus.find(
        (kk) => kk.kaytannonKoulutus === KaytannonKoulutusTyyppi.OMAA_ERIKOISALAA_TUKEVA_KOULUTUS
      ),
      this.tilastotKaytannonKoulutus.find(
        (kk) => kk.kaytannonKoulutus === KaytannonKoulutusTyyppi.TUTKIMUSTYO
      ),
      this.tilastotKaytannonKoulutus.find(
        (kk) => kk.kaytannonKoulutus === KaytannonKoulutusTyyppi.TERVEYSKESKUSTYO
      )
    ].filter((kk) => kk !== null) as TyoskentelyjaksotTilastotKaytannonKoulutus[]
  }

  get donutSeries() {
    return this.tilastotKaytannonKoulutusSorted.map((kk) => kk.suoritettu)
  }

  get donutOptions() {
    this.tilastotKaytannonKoulutus.map(
      (kk) =>
        `${tyoskentelyjaksoKaytannonKoulutusLabel(this, kk.kaytannonKoulutus)}: ${this.$duration(
          kk.suoritettu
        )}`
    )

    return {
      colors: ['#4EBDEF', '#FF8B06', '#808080', '#FFB406'],
      labels: [
        `${this.$t('oma-erikoisala')}: ${this.$duration(
          this.tilastotKaytannonKoulutusSorted[0].suoritettu
        )}`,
        `${this.$t('omaa-erikoisalaa-tukeva')}: ${this.$duration(
          this.tilastotKaytannonKoulutusSorted[1].suoritettu
        )}`,
        `${this.$t('tutkimustyo')}: ${this.$duration(
          this.tilastotKaytannonKoulutusSorted[2].suoritettu
        )}`,
        `${this.$t('pakollinen-terveyskeskuskoulutusjakso-lyh')}: ${this.$duration(
          this.tilastotKaytannonKoulutusSorted[3].suoritettu
        )}`
      ],
      legend: {
        fontSize: '13px',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
        onItemClick: {
          toggleDataSeries: false
        },
        onItemHover: {
          highlightDataSeries: false
        },
        offsetY: '1px'
      },
      chart: {
        type: 'donut',
        animations: {
          enabled: false
        }
      },
      dataLabels: {
        formatter: function (val: number) {
          return Math.round(val) + '%'
        },
        style: {
          fontSize: '8px',
          fontFamily: 'Montserrat, Helvetica, Arial, sans-serif'
        },
        dropShadow: {
          enabled: false
        }
      },
      plotOptions: {
        pie: {
          expandOnClick: false
        }
      },
      stroke: {
        show: false
      },
      states: {
        hover: {
          filter: {
            type: 'normal'
          }
        },
        active: {
          filter: {
            type: 'normal'
          }
        }
      },
      tooltip: {
        enabled: false
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'bottom',
              offsetY: 0
            }
          }
        }
      ]
    }
  }

  get opintooppaastaLinkki() {
    return `<a href="https://www.laaketieteelliset.fi/ammatillinen-jatkokoulutus/opinto-oppaat/" target="_blank" rel="noopener noreferrer">${(
      this.$t('tarkemmat-vaatimukset-opinto-oppaasta') as string
    ).toLowerCase()}</a>`
  }

  get tyoskentelyjaksotFormatted() {
    const keskeytyksetGroupByTyoskentelyjakso = this.keskeytykset.reduce(
      (result: { [key: number]: Partial<Keskeytysaika>[] }, keskeytysaika: Keskeytysaika) => {
        const tyoskentelyjaksoId = keskeytysaika?.tyoskentelyjakso?.id
        if (tyoskentelyjaksoId) {
          if (tyoskentelyjaksoId in result) {
            result[tyoskentelyjaksoId].push({
              id: keskeytysaika.id,
              alkamispaiva: keskeytysaika.alkamispaiva,
              paattymispaiva: keskeytysaika.paattymispaiva,
              poissaoloprosentti: keskeytysaika.poissaoloprosentti,
              poissaolonSyy: keskeytysaika.poissaolonSyy
            })
          } else {
            result[tyoskentelyjaksoId] = [
              {
                id: keskeytysaika.id,
                alkamispaiva: keskeytysaika.alkamispaiva,
                paattymispaiva: keskeytysaika.paattymispaiva,
                poissaoloprosentti: keskeytysaika.poissaoloprosentti,
                poissaolonSyy: keskeytysaika.poissaolonSyy
              }
            ]
          }
        }
        return result
      },
      {}
    )

    const tilastotTyoskentelyjaksotMap = this.tilastotTyoskentelyjaksot.reduce(
      (
        result: { [key: number]: number },
        tyoskentelyjakso: TyoskentelyjaksotTilastotTyoskentelyjaksot
      ) => {
        result[tyoskentelyjakso.id] = tyoskentelyjakso.suoritettu
        return result
      },
      {}
    )

    return this.tyoskentelyjaksot
      .map((tj) => ({
        ...tj,
        tyoskentelypaikkaLabel: tj.tyoskentelypaikka.nimi,
        ajankohtaDate: tj.alkamispaiva ? parseISO(tj.alkamispaiva) : null,
        ajankohta: ajankohtaLabel(this, tj),
        tyoskentelyaikaLabel: tj.id ? this.$duration(tilastotTyoskentelyjaksotMap[tj.id]) : null,
        osaaikaprosenttiLabel: `${tj.osaaikaprosentti} %`,
        hyvaksyttyAiempaanErikoisalaanLabel: tj.hyvaksyttyAiempaanErikoisalaan
          ? this.$t('kylla')
          : this.$t('ei'),
        keskeytykset: (tj.id ? keskeytyksetGroupByTyoskentelyjakso[tj.id] : undefined) || [],
        keskeytyksetLength: (
          (tj.id ? keskeytyksetGroupByTyoskentelyjakso[tj.id] : undefined) || []
        ).length
      }))
      .sort((a, b) => sortByDateDesc(a.paattymispaiva, b.paattymispaiva))
  }

  get terveyskeskuskoulutusjaksoPalautettuKorjattavaksi() {
    return (
      this.tyoskentelyjaksotTaulukko?.terveyskeskuskoulutusjaksonTila ===
      TerveyskeskuskoulutusjaksonTila.PALAUTETTU_KORJATTAVAKSI
    )
  }

  get terveyskeskuskoulutusjaksoLahetetty() {
    return (
      this.tyoskentelyjaksotTaulukko?.terveyskeskuskoulutusjaksonTila ===
        TerveyskeskuskoulutusjaksonTila.ODOTTAA_VIRKAILIJAN_TARKISTUSTA ||
      this.tyoskentelyjaksotTaulukko?.terveyskeskuskoulutusjaksonTila ===
        TerveyskeskuskoulutusjaksonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
    )
  }

  get terveyskeskuskoulutusjaksoUusi() {
    return (
      this.tyoskentelyjaksotTaulukko?.terveyskeskuskoulutusjaksonTila ===
      TerveyskeskuskoulutusjaksonTila.UUSI
    )
  }

  get muokkausoikeudet() {
    if (!this.account.impersonated) {
      return true
    }

    if (
      this.account.originalUser.authorities.includes(ELSA_ROLE.OpintohallinnonVirkailija) &&
      this.account.erikoistuvaLaakari.muokkausoikeudetVirkailijoilla
    ) {
      return true
    }

    return false
  }
}
