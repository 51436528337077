




































































































































import { AxiosError } from 'axios'
import { Component, Vue } from 'vue-property-decorator'

import { getTerveyskeskuskoulutusjakso, putTerveyskeskuskoulutusjakso } from '@/api/virkailija'
import ElsaButton from '@/components/button/button.vue'
import TerveyskeskuskoulutusjaksoForm from '@/forms/terveyskeskuskoulutusjakso-form.vue'
import {
  ElsaError,
  TerveyskeskuskoulutusjaksonHyvaksyminen,
  TerveyskeskuskoulutusjaksonHyvaksyntaForm
} from '@/types'
import { ERIKOISALA_YEK_ID, TerveyskeskuskoulutusjaksonTila } from '@/utils/constants'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    TerveyskeskuskoulutusjaksoForm
  }
})
export default class TerveyskeskuskoulutusjaksonTarkistus extends Vue {
  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('terveyskeskuskoulutusjaksot'),
        to: { name: 'terveyskeskuskoulutusjaksot' }
      },
      {
        text: this.onkoYek
          ? this.$t('yek.terveyskeskuskoulutusjakson-tarkistus')
          : this.$t('terveyskeskuskoulutusjakson-tarkistus'),
        active: true
      }
    ]
  }

  params = {
    saving: false
  }

  hyvaksynta: TerveyskeskuskoulutusjaksonHyvaksyminen | null = null

  async mounted() {
    try {
      this.hyvaksynta = (
        await getTerveyskeskuskoulutusjakso(this.$route.params.terveyskeskuskoulutusjaksoId)
      ).data
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('terveyskeskuskoulutusjakson-tietojen-hakeminen-epaonnistui')}: ${this.$t(
              message
            )}`
          : this.$t('terveyskeskuskoulutusjakson-tietojen-hakeminen-epaonnistui')
      )
      this.$router.replace({ name: 'terveyskeskuskoulutusjaksot', hash: this.hash })
    }
  }

  get editable() {
    return (
      this.hyvaksynta?.tila === TerveyskeskuskoulutusjaksonTila.ODOTTAA_VIRKAILIJAN_TARKISTUSTA
    )
  }

  get showReturned() {
    return this.hyvaksynta?.tila === TerveyskeskuskoulutusjaksonTila.PALAUTETTU_KORJATTAVAKSI
  }

  get showSent() {
    return (
      this.hyvaksynta?.tila === TerveyskeskuskoulutusjaksonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
    )
  }

  get showAcceptedByEveryone() {
    return this.hyvaksynta?.tila === TerveyskeskuskoulutusjaksonTila.HYVAKSYTTY
  }

  get asiakirjaDataEndpointUrl() {
    return `virkailija/terveyskeskuskoulutusjakso/tyoskentelyjakso-liite`
  }

  async onSubmit(formData: {
    korjausehdotus?: string
    lisatiedotVirkailijalta: string
    form: TerveyskeskuskoulutusjaksonHyvaksyntaForm
  }) {
    this.params.saving = true

    try {
      await putTerveyskeskuskoulutusjakso(
        this.$route.params.terveyskeskuskoulutusjaksoId,
        formData.form,
        formData?.korjausehdotus,
        formData?.lisatiedotVirkailijalta
      )

      toastSuccess(
        this,
        formData.korjausehdotus != null
          ? this.$t('terveyskeskuskoulutusjakso-palautettu-muokattavaksi')
          : this.$t('terveyskeskuskoulutusjakso-tarkistettu')
      )

      this.$emit('skipRouteExitConfirm', true)
      this.$router.push({
        name: 'terveyskeskuskoulutusjaksot',
        hash: this.hash
      })
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('terveyskeskuskoulutusjakson-lahetys-epaonnistui')}: ${this.$t(message)}`
          : this.$t('terveyskeskuskoulutusjakson-lahetys-epaonnistui')
      )
    }
    this.params.saving = false
  }

  async onCancel() {
    this.$router.push({
      name: 'terveyskeskuskoulutusjaksot',
      hash: this.hash
    })
  }

  get onkoYek() {
    return this.hyvaksynta?.erikoisalaId === ERIKOISALA_YEK_ID
  }

  get hash() {
    return this.onkoYek ? '#yek' : '#erikoislaakarikoulutus'
  }
}
