var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rows === 0)?_c('div',[_c('b-alert',{staticClass:"mb-4 pt-3 pb-3",attrs:{"variant":"light","show":""}},[_c('p',{staticClass:"d-flex mb-0"},[_c('font-awesome-icon',{staticClass:"text-muted text-size-md mr-2 mt-1",attrs:{"icon":"info-circle","fixed-width":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('yek.ei-avoimia-asioita-yek-koulutettava-info.voit'))+" "),_c('b-link',{attrs:{"to":{ path: '/yektyoskentelyjaksot' }}},[_vm._v(" "+_vm._s(_vm.$t('yek.ei-avoimia-asioita-yek-koulutettava-info.lisata-tyoskentelyjaksoja'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('yek.ei-avoimia-asioita-yek-koulutettava-info.ja'))+" "),_c('b-link',{attrs:{"to":{ path: '/yekteoriakoulutukset' }}},[_vm._v(" "+_vm._s(_vm.$t('yek.ei-avoimia-asioita-yek-koulutettava-info.tarkistaa-opintosuorituksesi'))+" ")]),_c('span',[_vm._v(".")]),_vm._v(" "+_vm._s(_vm.$t('yek.ei-avoimia-asioita-yek-koulutettava-info.kun-olet-suorittanut'))+" "),_c('b-link',{attrs:{"to":{ path: '/yekvalmistumispyynto' }}},[_vm._v(" "+_vm._s(_vm.$t('yek.ei-avoimia-asioita-yek-koulutettava-info.laheta-vamistusmispyyntö'))+" ")])],1)],1)])],1):_c('div',[_c('b-card-skeleton',{staticClass:"mb-5",attrs:{"header":_vm.$t('avoimet-asiat'),"loading":_vm.loading}},[_c('b-table',{attrs:{"items":_vm.avoimetAsiat,"fields":_vm.fields,"stacked":"md","responsive":""},scopedSlots:_vm._u([{key:"cell(asia)",fn:function(row){return [_c('b-link',{staticClass:"task-type",attrs:{"to":{
            name: _vm.getComponentName(row.item.tyyppi),
            path: _vm.getComponentPath(row.item.tyyppi),
            hash: _vm.getHash(row.item.tyyppi)
          }}},[_vm._v(" "+_vm._s(row.item.asia)+" ")])]}},{key:"cell(pvm)",fn:function(row){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(row.item.pvm ? _vm.$date(row.item.pvm) : '')+" ")])]}},{key:"cell(actions)",fn:function(row){return [_c('elsa-button',{staticClass:"pt-1 pb-1",attrs:{"variant":"primary","to":{
            name: _vm.getComponentName(row.item.tyyppi),
            path: _vm.getComponentPath(row.item.tyyppi),
            hash: _vm.getHash(row.item.tyyppi)
          }}},[_vm._v(" "+_vm._s(_vm.$t('avaa'))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }