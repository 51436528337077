



















































































































import { Component, Vue } from 'vue-property-decorator'

import { getSuoritteet } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import { Suorite, SuoritteenKategoria } from '@/types'
import { dateBetween } from '@/utils/date'
import { sortByAsc } from '@/utils/sort'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormDatepicker,
    ElsaFormGroup
  }
})
export default class Suoritteet extends Vue {
  kategoriat: SuoritteenKategoria[] = []

  voimassaolo: string | null = null

  loading = true

  fields = [
    {
      key: 'jarjestys',
      label: this.$t('jarjestys'),
      class: 'jarjestys'
    },
    {
      key: 'nimi',
      label: this.$t('nimi'),
      class: 'nimi'
    },
    {
      key: 'voimassaolo',
      label: this.$t('voimassaolo'),
      class: 'voimassaolo'
    },
    {
      key: 'vaadittulkm',
      label: this.$t('vaadittulkm'),
      class: 'vaadittulkm'
    }
  ]

  async mounted() {
    await this.fetchKategoriat()
    this.loading = false
  }

  async fetchKategoriat() {
    try {
      this.kategoriat = (await getSuoritteet(this.$route?.params?.erikoisalaId)).data
    } catch (err) {
      toastFail(this, this.$t('suoritteiden-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'opetussuunnitelmat' })
    }
  }

  get kategoriatSorted() {
    return this.kategoriat
      .map((k) => ({ ...k, _showDetails: 'true' }))
      .sort((a, b) => sortByAsc(a.jarjestysnumero, b.jarjestysnumero, true))
  }

  get rows() {
    return this.kategoriatSorted?.length ?? 0
  }

  filteredSuoritteet(suoritteet: Suorite[]) {
    return suoritteet
      .filter((s) =>
        dateBetween(
          this.voimassaolo ?? undefined,
          s.voimassaolonAlkamispaiva,
          s.voimassaolonPaattymispaiva ?? undefined
        )
      )
      .sort((a, b) => sortByAsc(a.nimi, b.nimi))
  }
}
