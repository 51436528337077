















import { Component, Vue } from 'vue-property-decorator'

import ValmistumispyynnotVastuuhenkilo from '@/views/valmistumispyynnot/vastuuhenkilo/valmistumispyynnot-vastuuhenkilo.vue'
import ValmistumispyynnotVirkailija from '@/views/valmistumispyynnot/virkailija/valmistumispyynnot-virkailija.vue'

@Component({
  components: {
    ValmistumispyynnotVastuuhenkilo,
    ValmistumispyynnotVirkailija
  }
})
export default class Valmistumispyynnot extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('valmistumispyynnot'),
      active: true
    }
  ]
}
