























































































































































































































































































import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { Validation } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'

import ElsaArviointiasteikonTasoTooltipContent from '@/components/arviointiasteikon-taso/arviointiasteikon-taso-tooltip.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import ElsaVaativuustasoTooltipContent from '@/components/vaativuustaso/vaativuustaso-tooltip-content.vue'
import TyoskentelyjaksoForm from '@/forms/tyoskentelyjakso-form.vue'
import TyoskentelyjaksoMixin from '@/mixins/tyoskentelyjakso'
import {
  Arviointiasteikko,
  ArviointiasteikonTaso,
  Erikoisala,
  Kunta,
  SuoritemerkinnanSuorite,
  Suoritemerkinta,
  SuoritteenKategoria,
  Vaativuustaso
} from '@/types'
import { vaativuustasot, ArviointiasteikkoTyyppi } from '@/utils/constants'
import { dateBetween } from '@/utils/date'

@Component({
  components: {
    TyoskentelyjaksoForm,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    ElsaPopover,
    ElsaFormDatepicker,
    ElsaButton,
    ElsaVaativuustasoTooltipContent,
    ElsaArviointiasteikonTasoTooltipContent
  }
})
export default class SuoritemerkintaForm extends Mixins(TyoskentelyjaksoMixin) {
  $refs!: {
    suorituspaiva: ElsaFormDatepicker
  }

  validations() {
    return {
      form: {
        tyoskentelyjakso: {
          required
        },
        suorite: {
          required: requiredIf(() => {
            return this.value.id
          })
        }
      },
      uudetSuoritteet: {
        $each: {
          suorite: {
            required: requiredIf(() => {
              return !this.value.id
            })
          }
        }
      }
    }
  }

  @Prop({ required: false, default: () => [] })
  suoritteenKategoriat!: SuoritteenKategoria[]

  @Prop({ required: true })
  arviointiasteikko!: Arviointiasteikko

  @Prop({ required: false })
  arviointiasteikonTaso?: ArviointiasteikonTaso

  @Prop({ required: false, default: () => [] })
  kunnat!: Kunta[]

  @Prop({ required: false, default: () => [] })
  erikoisalat!: Erikoisala[]

  @Prop({
    required: false,
    default: () => ({
      tyoskentelyjakso: null,
      suorite: null,
      vaativuustaso: null,
      arviointiasteikonTaso: null,
      suorituspaiva: null,
      lisatiedot: null
    })
  })
  value!: Suoritemerkinta

  form: Partial<Suoritemerkinta> = {}
  vaativuustasot = vaativuustasot
  params = {
    saving: false,
    deleting: false
  }
  childDataReceived = false
  uudetSuoritteet: Partial<SuoritemerkinnanSuorite>[] = [{}]

  mounted() {
    const currentTyoskentelyjaksot = this.tyoskentelyjaksotFormatted.filter((jakso) =>
      dateBetween(new Date(), jakso.alkamispaiva, jakso.paattymispaiva)
    )
    this.form = {
      tyoskentelyjakso: this.value.tyoskentelyjakso
        ? this.value.tyoskentelyjakso
        : currentTyoskentelyjaksot.length === 1
        ? currentTyoskentelyjaksot[0]
        : undefined,
      suorite: this.value.suorite,
      vaativuustaso: vaativuustasot.find((taso) => taso.arvo === this.value.vaativuustaso),
      arviointiasteikonTaso: this.arviointiasteikonTaso,
      suorituspaiva: this.value.suorituspaiva,
      lisatiedot: this.value.lisatiedot
    }
    this.childDataReceived = true
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    this.$v.uudetSuoritteet.$touch()
    return !this.$v.$anyError
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateSuoriteState(index: number) {
    if (!this.$v.uudetSuoritteet?.$each) return
    const { $dirty, $error } = this.$v.uudetSuoritteet?.$each[index]?.suorite as Validation
    return $dirty ? ($error ? false : null) : null
  }

  get arviointiAsteikonNimi() {
    return this.arviointiasteikko?.nimi === ArviointiasteikkoTyyppi.EPA
      ? this.$t('luottamuksen-taso')
      : this.$t('etappi')
  }

  onSubmit() {
    const validations = [this.validateForm(), this.$refs.suorituspaiva.validateForm()]
    if (validations.includes(false)) {
      return
    }

    this.$emit(
      'submit',
      {
        tyoskentelyjaksoId: this.form?.tyoskentelyjakso?.id,
        suoriteId: this.form?.suorite?.id,
        vaativuustaso: (this.form?.vaativuustaso as Vaativuustaso)?.arvo,
        arviointiasteikonTaso: (this.form?.arviointiasteikonTaso as ArviointiasteikonTaso)?.taso,
        suorituspaiva: this.form?.suorituspaiva,
        lisatiedot: this.form?.lisatiedot
      },
      this.params,
      this.uudetSuoritteet
    )
  }

  onSuoritemerkintaDelete() {
    this.$emit('delete', this.params)
  }

  arviointiasteikonTasoLabel(value: ArviointiasteikonTaso) {
    return `${value.taso} ${value.nimi}`
  }

  vaativuustasoLabel(value: Vaativuustaso) {
    return `${value.arvo} ${value.nimi}`
  }

  onLisaaSuorite() {
    this.uudetSuoritteet.push({})
  }

  deleteSuorite(index: number) {
    this.uudetSuoritteet.splice(index, 1)
  }
}
