var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-skeleton',{staticClass:"mb-4",attrs:{"header":_vm.$t('yek.terveyskeskuskoulutusjaksot'),"loading":_vm.loading}},[(_vm.rows > 0)?_c('div',[_c('b-table',{attrs:{"fixed":"","items":_vm.vaiheet,"fields":_vm.fields,"stacked":"md"},scopedSlots:_vm._u([{key:"cell(pvm)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.pvm ? _vm.$date(data.item.pvm) : '')+" ")])]}},{key:"cell(tyyppi)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t('lomake-tyyppi-' + data.item.tyyppi))+" ")]}},{key:"cell(erikoistuvanNimi)",fn:function(data){return [_c('b-link',{staticClass:"task-type",attrs:{"to":{
            name: _vm.$isVirkailija()
              ? 'terveyskeskuskoulutusjakson-tarkistus'
              : 'terveyskeskuskoulutusjakson-hyvaksynta',
            params: { terveyskeskuskoulutusjaksoId: data.item.id }
          }}},[_vm._v(" "+_vm._s(data.item.erikoistuvanNimi)+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('elsa-button',{staticClass:"pt-1 pb-1",attrs:{"variant":"primary","to":{
            name: _vm.$isVirkailija()
              ? 'terveyskeskuskoulutusjakson-tarkistus'
              : 'terveyskeskuskoulutusjakson-hyvaksynta',
            params: { terveyskeskuskoulutusjaksoId: data.item.id }
          }}},[_vm._v(" "+_vm._s(_vm.$isVirkailija() ? _vm.$t('tarkista') : _vm.$t('hyvaksy'))+" ")])]}}],null,false,3406627319)})],1):_c('div',[_c('b-alert',{attrs:{"variant":"dark","show":""}},[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),_vm._v(" "+_vm._s(_vm.$t('ei-avoimia-terveyskoulutusjaksoja'))+" ")],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }