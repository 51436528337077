var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-skeleton',{staticClass:"mb-4",attrs:{"header":_vm.$t('yek.koulutuksen-edistyminen'),"loading":_vm.initializing}},[(!_vm.initializing && _vm.edistyminen)?_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"xl":"8"}},[_c('div',{staticClass:"border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0",attrs:{"to":{
                name: 'yektyoskentelyjaksot'
              },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('tyoskentelyjaksot'))+" ")])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"d-flex flex-column mb-2 w-50"},[_c('span',{staticClass:"text-size-lg"},[_vm._v(" "+_vm._s(_vm.$duration( _vm.edistyminen.tyoskentelyjaksoTilastot.arvioErikoistumiseenHyvaksyttavista ))+" ")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('yek.arvio-hyvaksyttavasta-koulutuksesta'))+" ")])]),_c('div',{staticClass:"d-flex flex-column mb-2 pl-4 w-50"},[_c('span',{staticClass:"text-size-lg"},[_vm._v(" "+_vm._s(_vm.$duration(_vm.edistyminen.tyoskentelyjaksoTilastot.arvioPuuttuvastaKoulutuksesta))+" ")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('arvio-puuttuvasta-koulutuksesta'))+" ")])])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-col',{staticClass:"p-0",attrs:{"md":"10"}},[_c('tyoskentelyjaksot-yek-bar-chart',{attrs:{"tilastot":_vm.edistyminen.tyoskentelyjaksoTilastot}})],1)],1),(_vm.edistyminen.laakarikoulutusSuoritettuSuomiTaiBelgia)?_c('div',{staticClass:"d-flex flex-row"},[_c('em',{staticClass:"align-middle"},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'info-circle']}})],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t('yek.aiempi-hyvaksiluettu-suoritus'))+" ")])]):_vm._e()],1)])]),_c('b-col',{attrs:{"xl":"4"}},[_c('div',{staticClass:"border rounded pt-3 pb-2 mb-2"},[_c('div',{staticClass:"container-fluid"},[_c('h3',[_vm._v(_vm._s(_vm.$t('opintooikeus')))]),_c('div',{staticClass:"d-flex align-items-center"},[(_vm.showOpintooikeusAlert(_vm.edistyminen.opintooikeudenPaattymispaiva))?_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fas', 'exclamation-circle']}}):_vm._e(),_c('span',{class:{
                  'text-danger ml-1': _vm.showOpintooikeusAlert(
                    _vm.edistyminen.opintooikeudenPaattymispaiva
                  )
                }},[_vm._v(" "+_vm._s(_vm.$date(_vm.edistyminen.opintooikeudenMyontamispaiva))+" - "+_vm._s(_vm.$date(_vm.edistyminen.opintooikeudenPaattymispaiva))+" ")])],1)])]),_c('div',{staticClass:"border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0",attrs:{"to":{ name: 'yekteoriakoulutukset' },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('teoriakoulutus'))+" ")])]),_c('div',[(
                  _vm.edistyminen.teoriakoulutuksetSuoritettu >= _vm.edistyminen.teoriakoulutuksetVaadittu
                )?_c('span',{staticClass:"text-success"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'check-circle']}}),_vm._v(" "+_vm._s(_vm.$t('suoritettu'))+" ")],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-suoritettu'))+" ")])])],1)])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }