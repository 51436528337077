var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('div',[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"label":_vm.$t('etunimi'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('etunimi')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.etunimi),callback:function ($$v) {_vm.$set(_vm.form, "etunimi", $$v)},expression:"form.etunimi"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,3331684268)}),_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pl-md-3",attrs:{"label":_vm.$t('sukunimi'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sukunimi')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.sukunimi),callback:function ($$v) {_vm.$set(_vm.form, "sukunimi", $$v)},expression:"form.sukunimi"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,19777484)})],1),_c('elsa-form-group',{attrs:{"label":_vm.$t('sahkopostiosoite'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sahkopostiosoite')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.sahkopostiosoite),callback:function ($$v) {_vm.$set(_vm.form, "sahkopostiosoite", $$v)},expression:"form.sahkopostiosoite"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,431054860)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('sahkopostiosoite-uudelleen'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sahkopostiosoiteUudelleen')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.sahkopostiosoiteUudelleen),callback:function ($$v) {_vm.$set(_vm.form, "sahkopostiosoiteUudelleen", $$v)},expression:"form.sahkopostiosoiteUudelleen"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,1138571244)}),_c('hr'),_c('h2',[_vm._v(_vm._s(_vm.$t('opintooikeus')))]),_c('div',{staticClass:"border rounded p-3 mb-4"},[(_vm.yliopistot.length > 1)?_c('elsa-form-group',{attrs:{"label":_vm.$t('yliopisto'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.yliopistot,"state":_vm.validateState('yliopisto'),"custom-label":_vm.yliopistoLabel,"track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.yliopisto),callback:function ($$v) {_vm.$set(_vm.form, "yliopisto", $$v)},expression:"form.yliopisto"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,872908378)}):(_vm.form.yliopisto)?_c('elsa-form-group',{attrs:{"label":_vm.$t('yliopisto')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.$t(("yliopisto-nimi." + (_vm.form.yliopisto.nimi)))))])]}}],null,false,231660173)}):_vm._e(),_c('elsa-form-group',{attrs:{"label":_vm.$t('erikoisala'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.erikoisalatSorted,"state":_vm.validateState('erikoisala'),"label":"nimi","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)},"select":_vm.onErikoisalaSelected},model:{value:(_vm.form.erikoisala),callback:function ($$v) {_vm.$set(_vm.form, "erikoisala", $$v)},expression:"form.erikoisala"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,2569356204)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('opiskelijatunnus')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.opiskelijatunnus),callback:function ($$v) {_vm.$set(_vm.form, "opiskelijatunnus", $$v)},expression:"form.opiskelijatunnus"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,373069087)}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pr-sm-3",attrs:{"label":_vm.$t('opintooikeuden-alkupvm'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-datepicker',{ref:"opiskeluoikeudenAlkamispaiva",attrs:{"id":uid,"value":_vm.form.opintooikeusAlkaa,"max":_vm.maxAlkamispaiva,"max-error-text":_vm.$t('alkamispaiva-ei-voi-olla-paattymispaivan-jalkeen')},on:{"update:value":function($event){return _vm.$set(_vm.form, "opintooikeusAlkaa", $event)},"input":_vm.onOpiskeluoikeudenAlkamispaivaChange}})]}}],null,false,574174917)}),_c('elsa-form-group',{staticClass:"col-xs-12 col-sm-6 pl-sm-3",attrs:{"label":_vm.$t('opintooikeuden-loppupvm'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-datepicker',{ref:"opiskeluoikeudenPaattymispaiva",staticClass:"datepicker-range",attrs:{"id":uid,"value":_vm.form.opintooikeusPaattyy,"min":_vm.minPaattymispaiva,"min-error-text":_vm.$t('paattymispaiva-ei-voi-olla-ennen-alkamispaivaa')},on:{"update:value":function($event){return _vm.$set(_vm.form, "opintooikeusPaattyy", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}})]}}],null,false,550653996)})],1),_c('elsa-form-group',{attrs:{"label":_vm.$t('asetus'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-radio-group',{attrs:{"id":uid,"options":_vm.asetuksetSorted,"value-field":"id","text-field":"nimi","stacked":"","state":_vm.validateState('asetusId')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.asetusId),callback:function ($$v) {_vm.$set(_vm.form, "asetusId", $$v)},expression:"form.asetusId"}})]}}],null,false,1561055708)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('kaytossa-oleva-opintoopas'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.opintooppaatFilteredAndSorted,"state":_vm.validateState('opintoopas'),"label":"nimi","value-field":"id","track-by":"id"},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.opintoopas),callback:function ($$v) {_vm.$set(_vm.form, "opintoopas", $$v)},expression:"form.opintoopas"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,2736468835)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('osaamisen-arvioinnin-oppaan-paivamaara'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-datepicker',{ref:"osaamisenArvioinninOppaanPvm",attrs:{"id":uid,"value":_vm.form.osaamisenArvioinninOppaanPvm},on:{"update:value":function($event){return _vm.$set(_vm.form, "osaamisenArvioinninOppaanPvm", $event)},"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}}})]}}],null,false,1873584587)})],1),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[_c('elsa-button',{staticClass:"ml-2 mb-2",attrs:{"loading":_vm.saving,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tallenna'))+" ")]),_c('elsa-button',{staticClass:"mb-2",attrs:{"variant":"back"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCancel($event)}}},[_vm._v(" "+_vm._s(_vm.$t('peruuta'))+" ")])],1),_c('div',{staticClass:"row"},[_c('elsa-form-error',{attrs:{"active":_vm.$v.$anyError}})],1)],1)],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }