





















































































































import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import TyoskentelyjaksoForm from '@/forms/tyoskentelyjakso-form.vue'
import TyoskentelyjaksoMixin from '@/mixins/tyoskentelyjakso'
import {
  ArvioitavanKokonaisuudenKategoria,
  Erikoisala,
  Koulutusjakso,
  KoulutusjaksoForm,
  Kunta,
  Tyoskentelyjakso
} from '@/types'
import { sortByAsc } from '@/utils/sort'
import { tyoskentelyjaksoLabel } from '@/utils/tyoskentelyjakso'

@Component({
  components: {
    ElsaFormGroup,
    ElsaFormError,
    ElsaButton,
    ElsaFormMultiselect,
    TyoskentelyjaksoForm
  },
  validations: {
    form: {
      nimi: {
        required
      }
    }
  }
})
export default class KoulutusjaksoFormClass extends Mixins(TyoskentelyjaksoMixin) {
  @Prop({ required: false, default: () => [] })
  arvioitavanKokonaisuudenKategoriat!: ArvioitavanKokonaisuudenKategoria[]

  @Prop({ required: false, default: () => [] })
  kunnat!: Kunta[]

  @Prop({ required: false, default: () => [] })
  erikoisalat!: Erikoisala[]

  @Prop({
    required: false,
    type: Object,
    default: () => ({
      id: null,
      nimi: null,
      muutOsaamistavoitteet: null,
      luotu: null,
      tallennettu: null,
      lukittu: false,
      tyoskentelyjaksot: [
        {
          alkamispaiva: null,
          paattymispaiva: null,
          minPaattymispaiva: null,
          osaaikaprosentti: 100,
          tyoskentelypaikka: {
            nimi: null,
            kunta: { abbreviation: null },
            tyyppi: null,
            muuTyyppi: null
          },
          kaytannonKoulutus: null,
          omaaErikoisalaaTukeva: null,
          hyvaksyttyAiempaanErikoisalaan: null
        }
      ],
      osaamistavoitteet: [],
      koulutussuunnitelma: null
    })
  })
  value!: Koulutusjakso
  form: KoulutusjaksoForm = {
    id: null,
    nimi: null,
    muutOsaamistavoitteet: null,
    luotu: null,
    tallennettu: null,
    lukittu: null,
    tyoskentelyjaksot: [],
    osaamistavoitteet: [],
    koulutussuunnitelma: null
  }
  params = {
    saving: false
  }

  mounted() {
    if (this.value.id) {
      this.form = {
        ...this.value,
        tyoskentelyjaksot: this.value.tyoskentelyjaksot
          .filter((tj) => !tj.hyvaksyttyAiempaanErikoisalaan)
          .map((tj) => ({
            ...tj,
            label: tyoskentelyjaksoLabel(this, tj)
          }))
      }
      if (this.form.tyoskentelyjaksot.length === 0) {
        this.form.tyoskentelyjaksot.push({})
      }
    } else {
      this.form = this.value
    }
  }

  validateState(name: string) {
    const validation = this.$v.form[name]
    return validation?.$dirty ? (validation.$error ? false : null) : null
  }

  onInput(value: Tyoskentelyjakso, index: number) {
    this.form.tyoskentelyjaksot[index] = value
  }

  addTyoskentelyjakso() {
    this.form.tyoskentelyjaksot.push({})
    this.$emit('skipRouteExitConfirm', false)
  }

  deleteTyoskentelyjakso(index: number) {
    const tyoskentelyjaksot = [...this.form.tyoskentelyjaksot]
    tyoskentelyjaksot.splice(index, 1)
    this.form = {
      ...this.form,
      tyoskentelyjaksot
    }
    this.$emit('skipRouteExitConfirm', false)
  }

  onSubmit() {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) {
      return
    }
    this.$emit(
      'submit',
      {
        ...this.form,
        tyoskentelyjaksot: this.form.tyoskentelyjaksot.filter(
          (tyoskentelyjakso) => tyoskentelyjakso && tyoskentelyjakso.id
        )
      },
      this.params
    )
  }

  onCancel() {
    this.$emit('cancel')
  }

  get tyoskentelyjaksotFiltered() {
    return this.tyoskentelyjaksotFormatted.filter(
      (tyoskentelyjakso) =>
        !this.form.tyoskentelyjaksot?.find((t) => t?.id === tyoskentelyjakso.id)
    )
  }

  get arvioitavanKokonaisuudenKategoriatSorted() {
    return (
      this.arvioitavanKokonaisuudenKategoriat.sort(
        (a: ArvioitavanKokonaisuudenKategoria, b: ArvioitavanKokonaisuudenKategoria) =>
          sortByAsc(a.jarjestysnumero, b.jarjestysnumero) || sortByAsc(a.nimi, b.nimi)
      ) ?? []
    )
  }
}
