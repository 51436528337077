












































































































































































































































































import { AxiosError } from 'axios'
import { Component, Mixins } from 'vue-property-decorator'
import { Validation } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'

import {
  getErikoistuvaLaakari,
  putErikoistuvaLaakariInvitation,
  patchErikoistuvaLaakari,
  getOpintooppaat
} from '@/api/kayttajahallinta'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import KayttajahallintaKayttajaMixin from '@/mixins/kayttajahallinta-kayttaja'
import { KayttajahallintaUpdateKayttaja, ElsaError, OpintoopasSimple } from '@/types/index'
import { confirmExit } from '@/utils/confirm'
import { isInPast } from '@/utils/date'
import { sortByAsc } from '@/utils/sort'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormDatepicker,
    ElsaFormError,
    ElsaFormGroup,
    ElsaFormMultiselect
  },
  validations: {
    form: {
      sahkoposti: {
        required,
        email
      },
      sahkopostiUudelleen: {
        required,
        email,
        sameAsSahkoposti: sameAs('sahkoposti')
      },
      opintooikeudet: {
        $each: {
          osaamisenArvioinninOppaanPvm: {
            required
          },
          opintoopas: {
            required
          }
        }
      }
    }
  }
})
export default class ErikoistuvaLaakariView extends Mixins(KayttajahallintaKayttajaMixin) {
  items = [
    {
      text: this.$t('kayttajahallinta'),
      to: { name: 'kayttajahallinta' }
    },
    {
      text: this.$t('kayttaja'),
      active: true
    }
  ]
  resending = false
  opintooppaat: OpintoopasSimple[] = []

  form: KayttajahallintaUpdateKayttaja = {
    sahkoposti: null,
    sahkopostiUudelleen: null,
    opintooikeudet: []
  }

  async mounted() {
    await this.fetchOpintooppaat()
    await this.fetchKayttaja()
    this.loading = false
  }

  async fetchKayttaja() {
    try {
      this.kayttajaWrapper = (await getErikoistuvaLaakari(this.$route?.params?.kayttajaId)).data
      this.initForm()
    } catch (err) {
      toastFail(this, this.$t('kayttajan-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'kayttajahallinta' })
    }
  }

  async fetchOpintooppaat() {
    try {
      this.opintooppaat = (await getOpintooppaat()).data
    } catch (err) {
      toastFail(this, this.$t('opintooppaiden-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'kayttajahallinta' })
    }
  }

  initForm() {
    const sahkoposti = this.sahkoposti
    this.form.sahkoposti = sahkoposti
    this.form.sahkopostiUudelleen = sahkoposti
    this.form.opintooikeudet = this.opintooikeudet.map((o) => ({
      id: o.id,
      opintoopas: this.opintooppaat.find((o2) => o2.id === o.opintoopasId),
      osaamisenArvioinninOppaanPvm: o.osaamisenArvioinninOppaanPvm
    }))
  }

  async onInvitationResend() {
    if (
      this.kayttajaWrapper?.erikoistuvaLaakari?.id &&
      (await this.$bvModal.msgBoxConfirm(this.$t('laheta-kutsu-viesti') as string, {
        title: this.$t('laheta-kutsu-uudelleen') as string,
        okVariant: 'primary',
        okTitle: this.$t('laheta-kutsu') as string,
        cancelTitle: this.$t('peruuta') as string,
        cancelVariant: 'back',
        hideHeaderClose: false,
        centered: true
      }))
    ) {
      this.resending = true
      try {
        await putErikoistuvaLaakariInvitation(this.kayttajaWrapper?.erikoistuvaLaakari?.id)
        toastSuccess(this, this.$t('kutsulinkki-lahetetty-uudestaan'))
      } catch (err) {
        toastFail(this, this.$t('kutsulinkin-lahettaminen-epaonnistui'))
      }
      this.resending = false
    }
  }

  async onCancel() {
    if (this.skipRouteExitConfirm || (await confirmExit(this))) {
      this.initForm()
      this.$v.form.$reset()
      this.skipRouteExitConfirm = true
      this.editing = false
      this.$emit('skipRouteExitConfirm', true)
    }
  }

  async onSave() {
    const pvmValidations = this.opintooikeudet.map((o) =>
      (
        this.$refs[`osaamisenArvioinninOppaanPvm${o.id}`] as ElsaFormDatepicker[]
      )[0].validateForm()
    )
    if (
      !this.validateForm() ||
      pvmValidations.includes(false) ||
      !this.kayttajaWrapper?.kayttaja
    ) {
      return
    }
    this.updatingKayttaja = true

    try {
      await patchErikoistuvaLaakari(this.kayttajaWrapper.kayttaja.userId, {
        sahkoposti: this.form.sahkoposti,
        opintooikeudet: this.form.opintooikeudet?.map((o) => ({
          id: o.id,
          opintoopas: (o.opintoopas as OpintoopasSimple)?.id,
          osaamisenArvioinninOppaanPvm: o.osaamisenArvioinninOppaanPvm
        }))
      })
      toastSuccess(this, this.$t('kayttajan-tiedot-paivitetty'))
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('tietojen-tallennus-epaonnistui')}: ${this.$t(message)}`
          : this.$t('tietojen-tallennus-epaonnistui')
      )
    }

    await this.fetchKayttaja()
    this.editing = false
    this.updatingKayttaja = false
    this.skipRouteExitConfirm = true
    this.$emit('skipRouteExitConfirm', true)
  }

  isInPast(date: string) {
    return isInPast(date)
  }

  get syntymaaika() {
    return this.kayttajaWrapper?.erikoistuvaLaakari?.syntymaaika
      ? this.$date(this.kayttajaWrapper?.erikoistuvaLaakari?.syntymaaika)
      : ''
  }

  get opintooikeudet() {
    return this.kayttajaWrapper?.erikoistuvaLaakari?.opintooikeudet ?? []
  }

  opintooppaatFilteredAndSorted(erikoisalaId: number) {
    return this.opintooppaat
      .filter((o) => o.erikoisalaId === erikoisalaId)
      .sort((a, b) => sortByAsc(a.nimi, b.nimi))
  }

  validateOpintoopas(index: number) {
    const { $dirty, $error } = this.$v.form?.opintooikeudet?.$each[index]
      ?.opintoopas as Validation
    return $dirty ? ($error ? false : null) : null
  }
}
