










import { Component, Vue } from 'vue-property-decorator'

import EtusivuErikoistuja from '@/views/etusivu/etusivu-erikoistuja.vue'
import EtusivuKouluttaja from '@/views/etusivu/etusivu-kouluttaja.vue'
import EtusivuVastuuhenkilo from '@/views/etusivu/etusivu-vastuuhenkilo.vue'
import EtusivuVirkailija from '@/views/etusivu/etusivu-virkailija.vue'
import EtusivuYekErikoistuja from '@/views/etusivu/etusivu-yek-erikoistuja.vue'

@Component({
  components: {
    EtusivuErikoistuja,
    EtusivuYekErikoistuja,
    EtusivuKouluttaja,
    EtusivuVastuuhenkilo,
    EtusivuVirkailija
  }
})
export default class Etusivu extends Vue {}
