
























import { Component, Vue } from 'vue-property-decorator'

import { getEtusivuVanhenevatKatseluoikeudet } from '@/api/kouluttaja'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import { Katseluoikeus } from '@/types'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton
  }
})
export default class ArviointipyynnotCard extends Vue {
  katseluoikeudet: Katseluoikeus[] | null = null
  loading = true

  async mounted() {
    try {
      this.katseluoikeudet = (await getEtusivuVanhenevatKatseluoikeudet()).data
    } catch (err) {
      toastFail(this, this.$t('katseluoikeuksien-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  get fields() {
    return [
      {
        key: 'erikoistujanNimi',
        label: this.$t('erikoistuja'),
        sortable: true
      },
      {
        key: 'katseluoikeusVanhenee',
        label: this.$t('katseluoikeus-vanhenee'),
        sortable: true
      }
    ]
  }

  get rows() {
    return this.katseluoikeudet?.length ?? 0
  }
}
