































import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {}
})
export default class NavbarNotLoggedIn extends Vue {
  get currentLocale() {
    return this.$i18n.locale
  }

  get locales() {
    return Object.keys(this.$i18n.messages)
  }

  changeLocale(lang: string) {
    this.$i18n.locale = lang
  }
}
