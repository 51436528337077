






































































import axios, { AxiosError } from 'axios'
import { Component, Vue } from 'vue-property-decorator'

import { getTerveyskeskuskoulutusjakso } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import TerveyskeskuskoulutusjaksoForm from '@/forms/terveyskeskuskoulutusjakso-form.vue'
import store from '@/store'
import {
  ElsaError,
  TerveyskeskuskoulutusjaksonHyvaksyminen,
  TerveyskeskuskoulutusjaksonHyvaksyntaForm
} from '@/types'
import { TerveyskeskuskoulutusjaksonTila } from '@/utils/constants'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    TerveyskeskuskoulutusjaksoForm
  }
})
export default class TerveyskeskuskoulutusjaksonHyvaksyntapyynto extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('tyoskentelyjaksot'),
      to: { name: 'tyoskentelyjaksot' }
    },
    {
      text: this.$t('terveyskeskuskoulutusjakson-hyvaksynta'),
      active: true
    }
  ]

  params = {
    saving: false
  }

  hyvaksynta: TerveyskeskuskoulutusjaksonHyvaksyminen | null = null
  reservedAsiakirjaNimetMutable: string[] | undefined = []

  async mounted() {
    try {
      this.hyvaksynta = (await getTerveyskeskuskoulutusjakso()).data
      const existingFileNamesInCurrentView = this.hyvaksynta
        ? this.hyvaksynta.tyoskentelyjaksot?.flatMap((item) =>
            item.asiakirjat?.map((asiakirja) => asiakirja.nimi)
          )
        : []
      this.reservedAsiakirjaNimetMutable = (
        await axios.get('erikoistuva-laakari/asiakirjat/nimet')
      ).data?.filter((nimi: string) => !existingFileNamesInCurrentView.includes(nimi))
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('terveyskeskuskoulutusjakson-tietojen-hakeminen-epaonnistui')}: ${this.$t(
              message
            )}`
          : this.$t('terveyskeskuskoulutusjakson-tietojen-hakeminen-epaonnistui')
      )
      this.$router.replace({ name: 'tyoskentelyjaksot' })
    }
  }

  get account() {
    return store.getters['auth/account']
  }

  get editable() {
    return (
      (this.hyvaksynta?.id == null || this.hyvaksynta?.virkailijanKuittausaika == null) &&
      !this.account.impersonated
    )
  }

  get showReturned() {
    return this.hyvaksynta?.tila === TerveyskeskuskoulutusjaksonTila.PALAUTETTU_KORJATTAVAKSI
  }

  get showSent() {
    return (
      this.hyvaksynta?.tila === TerveyskeskuskoulutusjaksonTila.ODOTTAA_VIRKAILIJAN_TARKISTUSTA ||
      this.hyvaksynta?.tila === TerveyskeskuskoulutusjaksonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
    )
  }

  get showAcceptedByEveryone() {
    return this.hyvaksynta?.tila === TerveyskeskuskoulutusjaksonTila.HYVAKSYTTY
  }

  get asiakirjaDataEndpointUrl() {
    return 'erikoistuva-laakari/asiakirjat/'
  }

  async onSubmit(
    submitData: {
      hyvaksynta: TerveyskeskuskoulutusjaksonHyvaksyminen
      form: TerveyskeskuskoulutusjaksonHyvaksyntaForm
    },
    params: { saving: boolean }
  ) {
    params.saving = true

    for (const asiakirjat of submitData.form.tyoskentelyjaksoAsiakirjat) {
      const formData = new FormData()
      asiakirjat.addedFiles.forEach((file: File) =>
        formData.append(`addedFiles`, file, file.name)
      )
      asiakirjat.deletedFiles.forEach((file: number) =>
        formData.append(`deletedFiles`, String(file))
      )

      try {
        await axios.put(
          `erikoistuva-laakari/tyoskentelyjaksot/${asiakirjat.id}/asiakirjat`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            timeout: 120000
          }
        )
      } catch (err) {
        const axiosError = err as AxiosError<ElsaError>
        const message = axiosError?.response?.data?.message
        toastFail(
          this,
          message
            ? `${this.$t('terveyskeskuskoulutusjakson-lahetys-epaonnistui')}: ${this.$t(message)}`
            : this.$t('terveyskeskuskoulutusjakson-lahetys-epaonnistui')
        )
        return
      }
    }

    try {
      const formData = new FormData()
      if (submitData.form.laillistamispaiva != null)
        formData.append('laillistamispaiva', submitData.form.laillistamispaiva)
      if (submitData.form.laillistamispaivanLiite != null)
        formData.append(
          'laillistamispaivanLiite',
          submitData.form.laillistamispaivanLiite,
          submitData.form.laillistamispaivanLiite?.name
        )
      if (this.hyvaksynta?.id === null) {
        await axios.post(
          'erikoistuva-laakari/tyoskentelyjaksot/terveyskeskuskoulutusjakson-hyvaksynta',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            timeout: 120000
          }
        )
      } else {
        await axios.put(
          'erikoistuva-laakari/tyoskentelyjaksot/terveyskeskuskoulutusjakson-hyvaksynta',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            timeout: 120000
          }
        )
      }
      toastSuccess(this, this.$t('terveyskeskuskoulutusjakson-lahetys-onnistui'))
      this.$emit('skipRouteExitConfirm', true)
      this.$router.push({ name: 'tyoskentelyjaksot' })
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('terveyskeskuskoulutusjakson-lahetys-epaonnistui')}: ${this.$t(message)}`
          : this.$t('terveyskeskuskoulutusjakson-lahetys-epaonnistui')
      )
    }
    this.params.saving = false
  }

  async onCancel() {
    this.$router.push({ name: 'tyoskentelyjaksot' })
  }
}
