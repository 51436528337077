
























































































































import axios from 'axios'
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import AsiakirjatUpload from '@/components/asiakirjat/asiakirjat-upload.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import { Asiakirja, Teoriakoulutus } from '@/types'
import { mapFiles } from '@/utils/fileMapper'

@Component({
  components: {
    ElsaFormError,
    ElsaFormGroup,
    ElsaFormDatepicker,
    ElsaButton,
    AsiakirjatContent,
    AsiakirjatUpload
  },
  validations: {
    form: {
      koulutuksenNimi: {
        required
      },
      koulutuksenPaikka: {
        required
      }
    }
  }
})
export default class TeoriakoulutusForm extends Mixins(validationMixin) {
  $refs!: {
    alkamispaiva: ElsaFormDatepicker
    paattymispaiva: ElsaFormDatepicker
  }

  @Prop({
    required: false,
    type: Object
  })
  value!: Teoriakoulutus

  form: Partial<Teoriakoulutus> = {
    koulutuksenNimi: null,
    koulutuksenPaikka: null,
    paattymispaiva: null,
    erikoistumiseenHyvaksyttavaTuntimaara: null,
    todistukset: []
  }
  params = {
    saving: false
  }

  addedFiles: File[] = []
  reservedAsiakirjaNimetMutable: string[] = []
  newAsiakirjatMapped: Asiakirja[] = []
  deletedAsiakirjat: Asiakirja[] = []
  childDataReceived = false

  async mounted() {
    if (this.value?.id) {
      this.form = {
        ...this.value
      }
    } else {
      this.form = {
        koulutuksenNimi: null,
        koulutuksenPaikka: null,
        paattymispaiva: null,
        erikoistumiseenHyvaksyttavaTuntimaara: null,
        todistukset: []
      }
    }
    this.childDataReceived = true

    this.reservedAsiakirjaNimetMutable = (
      await axios.get('erikoistuva-laakari/asiakirjat/nimet')
    ).data
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  onErikoistumiseenHyvaksyttavaTuntimaaraInput(value: string) {
    if (value === '') {
      this.form.erikoistumiseenHyvaksyttavaTuntimaara = null
    } else {
      this.form.erikoistumiseenHyvaksyttavaTuntimaara = parseFloat(value)
    }
    this.$emit('skipRouteExitConfirm', false)
  }

  onFilesAdded(files: File[]) {
    const addedFilesInDeletedArray = files.filter((added) =>
      this.deletedAsiakirjat.map((deleted) => deleted.nimi).includes(added.name)
    )
    const addedFilesNotInDeletedArray = files.filter(
      (added) => !addedFilesInDeletedArray.includes(added)
    )

    this.deletedAsiakirjat = this.deletedAsiakirjat?.filter(
      (deletedAsiakirja) =>
        !addedFilesInDeletedArray
          .map((addedFile) => addedFile.name)
          .includes(deletedAsiakirja.nimi)
    )
    this.addedFiles = [...this.addedFiles, ...addedFilesNotInDeletedArray]
    this.newAsiakirjatMapped = [
      ...mapFiles(addedFilesNotInDeletedArray),
      ...this.newAsiakirjatMapped
    ]
    this.$emit('skipRouteExitConfirm', false)
  }

  async onDeleteLiitetiedosto(asiakirja: Asiakirja) {
    if (asiakirja.id) {
      this.deletedAsiakirjat = [asiakirja, ...this.deletedAsiakirjat]
    } else {
      this.addedFiles = this.addedFiles?.filter((file) => file.name !== asiakirja.nimi)
      this.newAsiakirjatMapped = this.newAsiakirjatMapped?.filter(
        (a) => a.nimi !== asiakirja.nimi
      )
    }
    this.$emit('skipRouteExitConfirm', false)
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    const validations = [
      this.validateForm(),
      this.$refs.alkamispaiva.validateForm(),
      this.$refs.paattymispaiva.validateForm()
    ]

    if (validations.includes(false)) {
      return
    }

    this.$emit(
      'submit',
      {
        teoriakoulutus: {
          ...(this.form.id ? { id: this.form.id } : {}),
          koulutuksenNimi: this.form.koulutuksenNimi,
          koulutuksenPaikka: this.form.koulutuksenPaikka,
          alkamispaiva: this.form.alkamispaiva,
          paattymispaiva: this.form.paattymispaiva,
          erikoistumiseenHyvaksyttavaTuntimaara: this.form.erikoistumiseenHyvaksyttavaTuntimaara
        },
        addedFiles: this.addedFiles,
        deletedAsiakirjaIds: this.deletedAsiakirjat.map((asiakirja) => asiakirja.id)
      },
      this.params
    )
  }

  onCancel() {
    this.$emit('cancel')
  }

  get existingFileNamesInCurrentView() {
    return this.asiakirjatTableItems?.map((item) => item.nimi)
  }

  get existingFileNamesInOtherViews() {
    return this.reservedAsiakirjaNimetMutable?.filter(
      (nimi) => !this.existingFileNamesInCurrentView.includes(nimi)
    )
  }

  get asiakirjatTableItems() {
    return [...this.newAsiakirjatMapped, ...this.asiakirjatExcludingDeleted()]
  }

  private asiakirjatExcludingDeleted(): Asiakirja[] {
    return (this.value?.todistukset ?? []).filter(
      (asiakirja) =>
        !this.deletedAsiakirjat.map((deleted) => deleted.nimi).includes(asiakirja.nimi)
    )
  }
}
