var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.kategoriat.length > 0)?_c('div',_vm._l((_vm.kategoriat),function(kategoria,index){return _c('div',{key:index,staticClass:"mt-3"},[_c('elsa-button',{staticClass:"text-decoration-none shadow-none border-0 text-dark p-0 w-100",attrs:{"variant":"link"},on:{"click":function($event){kategoria.visible = !kategoria.visible}}},[_c('div',{staticClass:"kategoria-collapse text-left p-2 mt-2 font-weight-500 d-flex"},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":kategoria.visible ? 'caret-up' : 'caret-down',"fixed-width":"","size":"lg"}}),_vm._v(" "+_vm._s(((kategoria.nimi) + " (" + (_vm.suoritemerkinnatCountByKategoria(kategoria)) + " " + (_vm.$t( 'suoritetta' )) + ")"))+" ")],1)]),(kategoria.visible)?_c('div',_vm._l((kategoria.suoritteet),function(s,suoriteIndex){return _c('div',{key:suoriteIndex,class:{ 'mb-3': s.merkinnat.length === 0 }},[(index !== 0)?_c('hr',{staticClass:"mt-1 mb-2"}):_vm._e(),_c('div',{staticClass:"d-flex suorite-title mb-0 mt-2 p-2"},[_c('span',{staticClass:"font-weight-500"},[_vm._v(_vm._s(s.nimi))]),(s.merkinnat.length === 0)?_c('span',{staticClass:"mr-2 text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('suoritettu'))+" "+_vm._s(s.suoritettulkm)+" "),_c('span',{class:{ 'pr-1': s.vaadittulkm }},[_vm._v(" "+_vm._s(s.vaadittulkm ? ("/ " + (s.vaadittulkm)) : '')+" ")])]):_vm._e()]),(s.merkinnat.length > 0)?_c('div',[_c('b-table-simple',{staticClass:"mb-0",attrs:{"small":"","fixed":"","stacked":"md","responsive":""}},[_c('b-thead',[_c('b-tr',{staticClass:"text-size-sm"},[_c('b-th',{staticClass:"text-uppercase",staticStyle:{"width":"20%"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('pvm'))+" ")]),_c('b-th',{staticClass:"text-uppercase",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.arviointiAsteikonNimi(_vm.arviointiasteikko))+" ")]),_c('b-th')],1)],1),_c('b-tbody',_vm._l((s.visible
                    ? s.merkinnat
                    : s.merkinnat.slice(0, 1)),function(merkinta,merkintaIndex){return _c('b-tr',{key:("merkinta-" + merkintaIndex)},[_c('b-td',{attrs:{"stacked-heading":_vm.$t('pvm')}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('elsa-button',{staticClass:"shadow-none p-0",attrs:{"to":{
                          name: 'suoritemerkinta',
                          params: {
                            suoritemerkintaId: merkinta.id
                          }
                        },"variant":"link"}},[_vm._v(" "+_vm._s(merkinta.suorituspaiva ? _vm.$date(merkinta.suorituspaiva) : '')+" ")])],1)]),_c('b-td',{attrs:{"stacked-heading":_vm.arviointiAsteikonNimi(_vm.arviointiasteikko)}},[_c('div',{staticClass:"d-flex align-items-center"},[(merkinta.arviointiasteikonTaso)?_c('elsa-arviointiasteikon-taso',{attrs:{"value":merkinta.arviointiasteikonTaso,"tasot":_vm.arviointiasteikko.tasot}}):_vm._e()],1)]),_c('b-td',[(merkintaIndex === 0)?_c('div',{staticClass:"d-flex align-items-center suoritettu"},[(s.merkinnat.length > 1)?_c('elsa-button',{staticClass:"p-0",attrs:{"variant":"link"},on:{"click":function($event){s.visible = !s.visible}}},[_c('span',{class:{
                            success:
                              s.vaadittulkm && s.suoritettulkm && s.suoritettulkm >= s.vaadittulkm
                          }},[_vm._v(" "+_vm._s(_vm.$t('suoritettu'))+" "+_vm._s(s.suoritettulkm)+" ")]),_c('span',{class:{ 'pr-1': s.vaadittulkm }},[_vm._v(" "+_vm._s(s.vaadittulkm ? ("/ " + (s.vaadittulkm)) : '')+" ")]),_c('font-awesome-icon',{attrs:{"icon":s.visible ? 'chevron-up' : 'chevron-down',"fixed-width":"","size":"lg"}})],1):_c('div',[_c('span',{class:{
                            success:
                              s.vaadittulkm && s.suoritettulkm && s.suoritettulkm >= s.vaadittulkm
                          }},[_vm._v(" "+_vm._s(_vm.$t('suoritettu'))+" "+_vm._s(s.suoritettulkm)+" ")]),_c('span',{staticClass:"mr-2",class:{ 'pr-1': s.vaadittulkm }},[_vm._v(" "+_vm._s(s.vaadittulkm ? ("/ " + (s.vaadittulkm)) : '')+" ")])])],1):_vm._e()])],1)}),1)],1)],1):_vm._e()])}),0):_vm._e()],1)}),0):_c('b-alert',{attrs:{"variant":"dark","show":""}},[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-suoritemerkintoja'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }