var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('h5',[_vm._v(_vm._s(_vm.$t('lahikouluttajan-nimi')))]),_c('p',[_vm._v(_vm._s(_vm.kouluttaja.nimi))])])],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('lahikouluttajan-nimike'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('nimike')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.nimike),callback:function ($$v) {_vm.$set(_vm.form, "nimike", $$v)},expression:"form.nimike"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('toimipaikka'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('toimipaikka')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.toimipaikka),callback:function ($$v) {_vm.$set(_vm.form, "toimipaikka", $$v)},expression:"form.toimipaikka"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('lahiosoite'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('lahiosoite')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.lahiosoite),callback:function ($$v) {_vm.$set(_vm.form, "lahiosoite", $$v)},expression:"form.lahiosoite"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('postitoimipaikka'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('postitoimipaikka')},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.postitoimipaikka),callback:function ($$v) {_vm.$set(_vm.form, "postitoimipaikka", $$v)},expression:"form.postitoimipaikka"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('sahkopostiosoite'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('sahkoposti'),"value":_vm.account.email},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.sahkoposti),callback:function ($$v) {_vm.$set(_vm.form, "sahkoposti", $$v)},expression:"form.sahkoposti"}}),(_vm.$v.form.sahkoposti && !_vm.$v.form.sahkoposti.required)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")]):_vm._e(),(_vm.$v.form.sahkoposti && !_vm.$v.form.sahkoposti.email)?_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback"),"state":_vm.validateState('sahkoposti')}},[_vm._v(" "+_vm._s(_vm.$t('sahkopostiosoite-ei-kelvollinen'))+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('matkapuhelinnumero'),"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('b-form-input',{attrs:{"id":uid,"state":_vm.validateState('puhelin'),"value":_vm.account.phoneNumber},on:{"input":function($event){return _vm.$emit('skipRouteExitConfirm', false)}},model:{value:(_vm.form.puhelin),callback:function ($$v) {_vm.$set(_vm.form, "puhelin", $$v)},expression:"form.puhelin"}}),_c('small',{staticClass:"form-text text-muted"},[_vm._v(" "+_vm._s(_vm.$t('syota-puhelinnumero-muodossa'))+" ")]),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('tarkista-puhelinnumeron-muoto'))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }