
































































































































































































import { format } from 'date-fns'
import _get from 'lodash/get'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import KoulutuspaikanArvioijat from '@/components/koejakson-vaiheet/koulutuspaikan-arvioijat.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import store from '@/store'
import {
  LoppukeskusteluLomake,
  KoejaksonVaiheHyvaksyja,
  Koejakso,
  KoejaksonVaiheButtonStates,
  KoejaksonVaiheAllekirjoitus,
  Opintooikeus
} from '@/types'
import { LomakeTilat } from '@/utils/constants'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'
import { resolveOpintooikeusKaytossa } from '@/utils/opintooikeus'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaPopover,
    ElsaFormDatepicker,
    ErikoistuvaDetails,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    ElsaButton,
    ElsaConfirmationModal,
    KoejaksonVaiheAllekirjoitukset,
    KoulutuspaikanArvioijat
  }
})
export default class ErikoistuvaArviointilomakeLoppukeskustelu extends Mixins(validationMixin) {
  $refs!: {
    koulutuspaikanArvioijat: KoulutuspaikanArvioijat
    koejaksonPaattymispaiva: ElsaFormDatepicker
  }
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('koejakson-loppukeskustelu'),
      active: true
    }
  ]
  validations() {
    return {
      loppukeskusteluLomake: {
        lahikouluttaja: {
          nimi: {
            required
          }
        },
        lahiesimies: {
          nimi: {
            required
          }
        },
        koejaksonPaattymispaiva: required
      }
    }
  }

  loading = true

  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }

  koejaksonVaihe = 'väliarviointi'

  loppukeskusteluLomake: Partial<LoppukeskusteluLomake> = {
    erikoistuvaAllekirjoittanut: false,
    erikoistuvanErikoisala: this.account.erikoistuvaLaakari.erikoisalaNimi,
    erikoistuvanNimi: `${this.account.firstName} ${this.account.lastName}`,
    erikoistuvanOpiskelijatunnus: this.account.erikoistuvaLaakari.opiskelijatunnus,
    erikoistuvanYliopisto: this.account.erikoistuvaLaakari.yliopisto,
    esitetaanKoejaksonHyvaksymista: null,
    id: null,
    jatkotoimenpiteet: null,
    korjausehdotus: '',
    lahiesimies: {
      id: null,
      kayttajaUserId: null,
      kuittausaika: '',
      nimi: '',
      nimike: null,
      sopimusHyvaksytty: false
    },
    lahikouluttaja: {
      id: 0,
      kayttajaUserId: null,
      kuittausaika: '',
      nimi: '',
      nimike: null,
      sopimusHyvaksytty: false
    },
    muokkauspaiva: '',
    koejaksonPaattymispaiva: ''
  }

  validateState(value: string) {
    const form = this.$v.loppukeskusteluLomake
    const { $dirty, $error } = _get(form, value) as any
    return $dirty ? ($error ? false : null) : null
  }

  get account() {
    return store.getters['auth/account']
  }

  get editable() {
    if (this.account.impersonated) {
      return false
    }
    return this.koejaksoData.loppukeskustelunTila === LomakeTilat.UUSI
  }

  get deletable() {
    return this.koejaksoData.loppukeskustelunTila === LomakeTilat.ODOTTAA_HYVAKSYNTAA
  }

  get waitingForAcceptance() {
    return this.koejaksoData.loppukeskustelunTila === LomakeTilat.ODOTTAA_HYVAKSYNTAA
  }

  get acceptedByEveryone() {
    return this.koejaksoData.loppukeskustelunTila === LomakeTilat.HYVAKSYTTY
  }

  get kouluttajat() {
    return store.getters['erikoistuva/kouluttajatJaVastuuhenkilot'] || []
  }

  get koejaksoData(): Koejakso {
    return store.getters['erikoistuva/koejakso']
  }

  setKoejaksoData() {
    if (this.koejaksoData.loppukeskustelu) {
      this.loppukeskusteluLomake = this.koejaksoData.loppukeskustelu
    }
  }

  onLahikouluttajaSelect(lahikouluttaja: KoejaksonVaiheHyvaksyja) {
    this.loppukeskusteluLomake.lahikouluttaja = lahikouluttaja
  }

  onLahiesimiesSelect(lahiesimies: KoejaksonVaiheHyvaksyja) {
    this.loppukeskusteluLomake.lahiesimies = lahiesimies
  }

  get allekirjoitukset(): KoejaksonVaiheAllekirjoitus[] {
    const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
      this,
      this.loppukeskusteluLomake?.erikoistuvanNimi,
      this.loppukeskusteluLomake?.erikoistuvanKuittausaika
    )
    const allekirjoitusLahikouluttaja = allekirjoituksetHelper.mapAllekirjoitusLahikouluttaja(
      this,
      this.loppukeskusteluLomake?.lahikouluttaja
    )
    const allekirjoitusLahiesimies = allekirjoituksetHelper.mapAllekirjoitusLahiesimies(
      this,
      this.loppukeskusteluLomake?.lahiesimies
    )

    return [
      allekirjoitusLahikouluttaja,
      allekirjoitusLahiesimies,
      allekirjoitusErikoistuva
    ].filter((a): a is KoejaksonVaiheAllekirjoitus => a !== null)
  }

  optionDisplayName(option: any) {
    return option.nimike ? option.nimi + ', ' + option.nimike : option.nimi
  }

  hideModal(id: string) {
    return this.$bvModal.hide(id)
  }

  onValidateAndConfirm(modalId: string) {
    if (
      this.$refs.koulutuspaikanArvioijat &&
      !this.$refs.koulutuspaikanArvioijat.validateForm()
    ) {
      return
    }
    if (
      this.$refs.koejaksonPaattymispaiva &&
      !this.$refs.koejaksonPaattymispaiva.validateForm()
    ) {
      return
    }
    return this.$bvModal.show(modalId)
  }

  async onSend() {
    try {
      this.buttonStates.primaryButtonLoading = true
      await store.dispatch('erikoistuva/postLoppukeskustelu', this.loppukeskusteluLomake)
      this.buttonStates.primaryButtonLoading = false
      toastSuccess(this, this.$t('loppukeskustelu-lahetetty-onnistuneesti'))
      this.setKoejaksoData()
    } catch {
      toastFail(this, this.$t('loppukeskustelu-tallennus-epaonnistui'))
    }
  }

  async onFormDelete() {
    try {
      this.buttonStates.primaryButtonLoading = true
      await store.dispatch('erikoistuva/deleteLoppukeskustelu', this.loppukeskusteluLomake)
      this.buttonStates.primaryButtonLoading = false
      toastSuccess(this, this.$t('lomake-tyhjennetty-onnistuneesti'))
      this.loppukeskusteluLomake.id = null
    } catch {
      toastFail(this, this.$t('lomakkeen-tyhjennys-epaonnistui'))
    }
  }

  async mounted() {
    this.loading = true
    if (!this.koejaksoData) {
      await store.dispatch('erikoistuva/getKoejakso')
    }
    await store.dispatch('erikoistuva/getKouluttajatJaVastuuhenkilot')
    this.setKoejaksoData()
    this.loading = false
  }

  get opintooikeusKaytossa(): Opintooikeus | undefined {
    return resolveOpintooikeusKaytossa(this.account.erikoistuvaLaakari)
  }

  get minKoejaksonPaattymispaiva() {
    const dateFormat = 'yyyy-MM-dd'
    const koejaksonAlkamispaiva = this.koejaksoData.aloituskeskustelu.koejaksonAlkamispaiva
    if (!koejaksonAlkamispaiva) {
      return null
    }

    const koejaksonAlkamispaivaDate = new Date(koejaksonAlkamispaiva)
    // Koejakson kesto on vähintään 6kk.
    koejaksonAlkamispaivaDate.setMonth(koejaksonAlkamispaivaDate.getMonth() + 6)
    return format(koejaksonAlkamispaivaDate, dateFormat)
  }

  get maxKoejaksonPaattymispaiva() {
    const dateFormat = 'yyyy-MM-dd'
    const koejaksonAlkamispaiva = this.koejaksoData.aloituskeskustelu.koejaksonAlkamispaiva
    if (!this.opintooikeusKaytossa?.opintooikeudenPaattymispaiva || !koejaksonAlkamispaiva) {
      return null
    }

    const koejaksonAlkamispaivaMaxDate = new Date(
      this.koejaksoData.aloituskeskustelu.koejaksonAlkamispaiva
    )
    // Koejakson kesto on maksimissaan 2 vuotta.
    koejaksonAlkamispaivaMaxDate.setFullYear(koejaksonAlkamispaivaMaxDate.getFullYear() + 2)
    const opintooikeudenPaattymispaivaDate = new Date(
      this.opintooikeusKaytossa.opintooikeudenPaattymispaiva
    )
    // Mikäli maksimikesto 2 vuotta ylittää opinto-oikeuden päättymispäivän,
    // on maksimi päättymispäivä opinto-oikeuden päättymispäivä.
    if (koejaksonAlkamispaivaMaxDate > opintooikeudenPaattymispaivaDate) {
      return format(opintooikeudenPaattymispaivaDate, dateFormat)
    }

    return format(koejaksonAlkamispaivaMaxDate, dateFormat)
  }
}
