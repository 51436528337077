
















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { Kouluttaja } from '@/types'

@Component({})
export default class KoulutussopimusReadonly extends Vue {
  @Prop({ required: true, default: {} })
  kouluttaja!: Kouluttaja
}
