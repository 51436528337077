





































import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class KoejaksoTavoitteet extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('koejakso-tavoitteet'),
      active: true
    }
  ]
}
