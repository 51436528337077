































import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaTextEditor from '@/components/text-editor/text-editor.vue'
import { Ilmoitus } from '@/types'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaTextEditor
  },
  validations: {
    form: {
      teksti: {
        required
      }
    }
  }
})
export default class IlmoitusForm extends Mixins(validationMixin) {
  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({ required: false, type: Object })
  value?: Ilmoitus

  form: Ilmoitus = {
    teksti: null
  }

  params = {
    saving: false
  }

  async mounted() {
    if (this.value) {
      this.form = this.value
    }
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  onSubmit() {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) {
      return
    }
    this.$emit('submit', this.form, this.params)
  }

  onDelete() {
    this.$emit('delete', this.params)
  }
}
