
























































import { Component, Vue } from 'vue-property-decorator'

import { getOpintooppaat } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import { Opintoopas } from '@/types'
import { sortByDesc } from '@/utils/sort'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton
  }
})
export default class Opintooppaat extends Vue {
  oppaat: Opintoopas[] = []

  loading = true

  fields = [
    {
      key: 'nimi',
      label: this.$t('nimi'),
      class: 'nimi',
      sortable: true
    },
    {
      key: 'voimassaolo',
      label: this.$t('voimassaolo'),
      class: 'voimassaolo',
      sortable: true
    }
  ]

  async mounted() {
    await this.fetchOppaat()
    this.loading = false
  }

  async fetchOppaat() {
    try {
      this.oppaat = (await getOpintooppaat(this.$route?.params?.erikoisalaId)).data
    } catch (err) {
      toastFail(this, this.$t('opintooppaiden-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'opetussuunnitelmat' })
    }
  }

  get opintooppaatSorted() {
    return this.oppaat.sort((a, b) => sortByDesc(a.voimassaoloAlkaa, b.voimassaoloAlkaa))
  }

  get rows() {
    return this.oppaat?.length ?? 0
  }
}
