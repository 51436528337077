






























import { Component, Vue } from 'vue-property-decorator'

import BCardSkeleton from '@/components/card/card.vue'
import ArvioitavatKokonaisuudetCard from '@/components/etusivu-cards/arvioitavat-kokonaisuudet-card.vue'
import AvoimetAsiatCard from '@/components/etusivu-cards/avoimet-asiat-card.vue'
import ErikoistumisenEdistyminenCard from '@/components/etusivu-cards/erikoistumisen-edistyminen-card.vue'
import HenkilotiedotCard from '@/components/etusivu-cards/henkilotiedot-card.vue'
import KoulutussuunnitelmaCard from '@/components/etusivu-cards/koulutussuunnitelma-card.vue'
import PaivittaisetMerkinnatCard from '@/components/etusivu-cards/paivittaiset-merkinnat-card.vue'
import SeurantakeskustelutCard from '@/components/etusivu-cards/seurantakeskustelut-card.vue'
import store from '@/store'
import { ELSA_ROLE } from '@/utils/roles'

@Component({
  components: {
    BCardSkeleton,
    AvoimetAsiatCard,
    SeurantakeskustelutCard,
    ErikoistumisenEdistyminenCard,
    HenkilotiedotCard,
    KoulutussuunnitelmaCard,
    ArvioitavatKokonaisuudetCard,
    PaivittaisetMerkinnatCard
  }
})
export default class EtusivuErikoistuja extends Vue {
  get isImpersonatedVirkailija() {
    const account = store.getters['auth/account']
    return (
      account.impersonated &&
      account.originalUser.authorities.includes(ELSA_ROLE.OpintohallinnonVirkailija)
    )
  }
}
