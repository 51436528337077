var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{attrs:{"align-v":"center","lg":""}},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('elsa-search-input',{staticClass:"mb-3",attrs:{"hakutermi":_vm.hakutermi,"placeholder":_vm.$t('hae-paakayttajan-nimella')},on:{"update:hakutermi":function($event){_vm.hakutermi=$event}}})],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"drop-down-filter"},[_c('elsa-form-group',{staticClass:"mb-4",attrs:{"label":_vm.$t('jarjestys')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.sortFields,"label":"name","taggable":true},on:{"select":_vm.onSortBySelect},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [(option.name)?_c('div',[_vm._v(_vm._s(option.name))]):_vm._e()]}}],null,true),model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})]}}])})],1)])],1),(!_vm.loading)?_c('div',[(_vm.rows === 0)?_c('b-alert',{attrs:{"variant":"dark","show":""}},[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),(_vm.hakutermi.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-hakutuloksia'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-kayttajia'))+" ")])],1):_vm._e()],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1),(!_vm.loading && _vm.kayttajat && _vm.rows > 0)?_c('b-table',{staticClass:"kayttajat-table",attrs:{"items":_vm.kayttajat.content,"fields":_vm.fields,"stacked":"md","responsive":""},scopedSlots:_vm._u([{key:"cell(nimi)",fn:function(row){return [_c('elsa-button',{staticClass:"p-0 border-0 shadow-none",attrs:{"to":{
          name: 'paakayttaja',
          params: { kayttajaId: row.item.kayttajaId }
        },"variant":"link"}},[_c('span',[_vm._v(_vm._s(row.item.sukunimi)+" "+_vm._s(row.item.etunimi))])])]}},{key:"cell(tila)",fn:function(row){return [_c('span',{class:_vm.getTilaColor(row.item.kayttajatilinTila)},[_vm._v(" "+_vm._s(_vm.$t(("tilin-tila-" + (row.item.kayttajatilinTila))))+" ")])]}}],null,false,2498281687)}):_vm._e(),(!_vm.loading)?_c('elsa-pagination',{attrs:{"current-page":_vm.currentPage,"per-page":_vm.perPage,"rows":_vm.rows},on:{"update:currentPage":_vm.onPageInput}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }