























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class ElsaPagination extends Vue {
  @Prop({ required: true, default: null })
  currentPage!: any | null

  @Prop({ required: true, default: 0 })
  rows!: number

  @Prop({ required: true, default: 20 })
  perPage!: number

  get currentPageMutable() {
    return this.currentPage
  }

  set currentPageMutable(val) {
    this.$emit('update:currentPage', val)
  }
}
