















































































































































import axios, { AxiosError } from 'axios'
import { BModal } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'

import { postLahikouluttaja } from '@/api/erikoistuva'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import KouluttajaForm from '@/forms/kouluttaja-form.vue'
import store from '@/store'
import { Kayttaja, KouluttajaValtuutus, ElsaError } from '@/types'
import { toastSuccess, toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaFormMultiselect,
    KouluttajaForm,
    ElsaFormDatepicker
  }
})
export default class Kayttooikeus extends Vue {
  $refs!: {
    paattymispaiva: ElsaFormDatepicker
  }

  get kouluttajat(): Kayttaja[] {
    return store.getters['erikoistuva/kouluttajat'] || []
  }

  get filteredKouluttajat(): Kayttaja[] {
    return this.kouluttajat.filter(
      (k) =>
        !this.valtuutukset.some((v) => {
          return k.id == v.valtuutettu.id
        })
    )
  }

  get minPaattymispaiva(): Date {
    return new Date()
  }

  valittuKouluttaja: Kayttaja | null = null

  valittuValtuutus: Partial<KouluttajaValtuutus> | null = null

  valtuutukset: KouluttajaValtuutus[] = []

  isLoading = false

  async onKouluttajaSubmit(value: Kayttaja, params: any, modal: BModal) {
    params.saving = true
    try {
      const kouluttaja = (await postLahikouluttaja(value)).data

      if (!this.kouluttajat.some((kayttaja: Kayttaja) => kayttaja.id === kouluttaja.id)) {
        this.kouluttajat.push(kouluttaja)
      }

      this.valittuKouluttaja = kouluttaja
      modal.hide('confirm')
      toastSuccess(this, this.$t('uusi-kouluttaja-lisatty'))
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('uuden-kouluttajan-lisaaminen-epaonnistui')}: ${this.$t(message)}`
          : this.$t('uuden-kouluttajan-lisaaminen-epaonnistui')
      )
    }
    params.saving = false
  }

  optionDisplayName(option: any) {
    return option.nimike ? option.nimi + ', ' + option.nimike : option.nimi
  }

  async myonnaOikeus() {
    try {
      const valtuutus: KouluttajaValtuutus = (
        await axios.post('/erikoistuva-laakari/kouluttajavaltuutus', this.valittuKouluttaja)
      ).data
      this.valtuutukset.push(valtuutus)
      this.valittuKouluttaja = null
      toastSuccess(this, this.$t('katseluoikeus-myonnetty'))
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('katseluoikeuden-lisaaminen-epaonnistui')}: ${this.$t(message)}`
          : this.$t('katseluoikeuden-lisaaminen-epaonnistui')
      )
    }
  }

  async paivitaOikeus() {
    if (!this.$refs.paattymispaiva.validateForm()) {
      return
    }

    try {
      await axios.put(`/erikoistuva-laakari/kouluttajavaltuutus/${this.valittuValtuutus?.id}`, {
        paattymispaiva: this.valittuValtuutus?.paattymispaiva
      })
      const valtuutus = this.valtuutukset.find((v) => v.id === this.valittuValtuutus?.id)
      if (valtuutus) valtuutus.paattymispaiva = this.valittuValtuutus?.paattymispaiva || ''
      this.$bvModal.hide('muokkaaKatseluoikeuttaModal')
      toastSuccess(this, this.$t('katseluoikeus-paivitetty'))
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('katseluoikeuden-paivittaminen-epaonnistui')}: ${this.$t(message)}`
          : this.$t('katseluoikeuden-paivittaminen-epaonnistui')
      )
    }
  }

  muokkaaKatseluoikeutta(valtuutus: KouluttajaValtuutus) {
    this.valittuValtuutus = {
      id: valtuutus.id,
      paattymispaiva: valtuutus.paattymispaiva,
      valtuutettu: valtuutus.valtuutettu
    }
    this.$bvModal.show('muokkaaKatseluoikeuttaModal')
  }

  async mounted() {
    this.isLoading = true
    await store.dispatch('erikoistuva/getKouluttajat')
    this.valtuutukset = (await axios.get('/erikoistuva-laakari/kouluttajavaltuutukset')).data
    this.isLoading = false
  }
}
