


















































































import { Component, Vue } from 'vue-property-decorator'

import { getKurssikoodit } from '@/api/virkailija'
import ElsaButton from '@/components/button/button.vue'
import Pagination from '@/components/pagination/pagination.vue'
import SearchInput from '@/components/search-input/search-input.vue'
import { Kurssikoodi } from '@/types'
import { sortByAsc } from '@/utils/sort'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    Pagination,
    SearchInput
  }
})
export default class Kurssikoodit extends Vue {
  kurssikoodit: Kurssikoodi[] = []

  loading = true
  perPage = 20
  currentPage = 1
  hakutermi = ''

  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('kurssikoodien-yllapito'),
      active: true
    }
  ]

  fields = [
    {
      key: 'tunniste',
      label: this.$t('tunniste'),
      class: 'tunniste',
      sortable: true
    },
    {
      key: 'tyyppi',
      label: this.$t('tyyppi'),
      class: 'tyyppi',
      sortable: true
    }
  ]

  async mounted() {
    await this.fetchKurssikoodit()
    this.loading = false
  }

  async fetchKurssikoodit() {
    try {
      this.kurssikoodit = (await getKurssikoodit()).data
    } catch (err) {
      toastFail(this, this.$t('kurssikoodien-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'etusivu' })
    }
  }

  get kurssikooditSorted() {
    if (this.hakutermi) {
      this.currentPage = 1
      return this.sortKurssikoodit(
        this.kurssikoodit?.filter((item: Kurssikoodi) =>
          item.tunniste?.toLowerCase().includes(this.hakutermi.toLowerCase())
        )
      )
    }
    return this.sortKurssikoodit(this.kurssikoodit)
  }

  sortKurssikoodit(kurssikoodit: Kurssikoodi[]) {
    return kurssikoodit.sort((a, b) =>
      a.tyyppi?.nimi === b.tyyppi?.nimi
        ? sortByAsc(a.tunniste, b.tunniste)
        : sortByAsc(a.tyyppi?.nimi, b.tyyppi?.nimi)
    )
  }

  get rows() {
    return this.kurssikoodit?.length ?? 0
  }
}
