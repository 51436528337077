var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-skeleton',{staticClass:"h-100",attrs:{"header-route":'koulutussuunnitelma',"header":_vm.$t('koulutussuunnitelma'),"loading":_vm.loading}},[(!_vm.loading && _vm.koulutussuunnitelma)?_c('div',[_c('div',[(_vm.koulutussuunnitelma.muokkauspaiva)?_c('b-row',[_c('b-col',[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0",attrs:{"to":{
              name: 'koulutussuunnitelma'
            },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('henkilokohtainen-koulutussuunnitelma'))+" ")])]),_c('p',[_c('font-awesome-icon',{staticClass:"text-muted mr-0",attrs:{"icon":['fas', 'info-circle']}}),_vm._v(" "+_vm._s(_vm.$t('paivitetty-viimeksi'))+" "),_c('strong',[_vm._v(_vm._s(_vm.$date(_vm.koulutussuunnitelma.muokkauspaiva)))])],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0",attrs:{"to":{
              name: 'koulutussuunnitelma'
            },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('koulutusjaksot'))+" ")])]),_c('div',[_c('elsa-button',{staticClass:"mb-3 mt-1",attrs:{"to":{ name: 'uusi-koulutusjakso' },"variant":"outline-primary"}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-koulutusjakso'))+" ")])],1)],1)],1)],1),(_vm.koulutusjaksot.length > 0)?_c('div',{staticClass:"koulutusjaksot-list"},_vm._l((_vm.koulutusjaksot),function(koulutusjakso,index){return _c('b-row',{key:index,staticClass:"koulutusjakso",attrs:{"lg":""}},[_c('b-col',[_c('div',{staticClass:"line"}),_c('div',{staticClass:"indicator"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['far', 'hospital'],"fixed-width":"","size":"lg"}})],1),_c('div',{staticClass:"d-flex justify-content-center border rounded pt-1 pb-1 mb-4 ml-6"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0",attrs:{"to":{
                  name: 'koulutusjakso',
                  params: { koulutusjaksoId: koulutusjakso.id }
                },"variant":"link"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(koulutusjakso.nimi)+" ")]),_vm._l((koulutusjakso.tyoskentelyjaksot),function(tyoskentelyjakso){return _c('div',{key:tyoskentelyjakso.id},[_c('span',{staticClass:"date"},[_vm._v(" "+_vm._s(tyoskentelyjakso.alkamispaiva ? _vm.$date(tyoskentelyjakso.alkamispaiva) : '')+" - "),_c('span',{class:tyoskentelyjakso.paattymispaiva ? '' : 'text-lowercase'},[_vm._v(" "+_vm._s(tyoskentelyjakso.paattymispaiva ? _vm.$date(tyoskentelyjakso.paattymispaiva) : _vm.$t('kesken'))+" ")])]),_c('span',{staticClass:"tyoskentelypaikka"},[_vm._v(" "+_vm._s(tyoskentelyjakso.tyoskentelypaikka.nimi)+" ")])])})],2)],1)])])],1)}),1):_c('div',[_c('b-alert',{attrs:{"show":true,"variant":"dark"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('em',{staticClass:"align-middle"},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'info-circle']}})],1),_c('div',[_vm._v(_vm._s(_vm.$t('ei-koulutusjaksoja')))])])])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }