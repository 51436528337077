var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('koejakso')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('koejakso-kuvaus'))+" "),_c('b-link',{attrs:{"to":{ name: 'koejakso-yleiset-tavoitteet' }}},[_vm._v(" "+_vm._s(_vm.$t('koejakso-tavoitteet-linkki'))+" ")])],1)])],1),(!_vm.loading)?_c('div',[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-center border rounded pt-3 pb-3 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('h2',[_vm._v(_vm._s(_vm.$t('koejakson-suorituspaikka')))]),_c('p',[_vm._v(_vm._s(_vm.$t('koejakson-suorituspaikka-kuvaus')))]),_c('b-row',[_c('b-col',[(!_vm.account.impersonated)?_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"9"}},[_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelyjakso'),"add-new-enabled":true,"add-new-label":_vm.$t('lisaa-tyoskentelyjakso'),"required":false},on:{"submit":_vm.onTyoskentelyjaksoSubmit},scopedSlots:_vm._u([{key:"modal-content",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
return [_c('tyoskentelyjakso-form',{attrs:{"kunnat":_vm.kunnat,"erikoisalat":_vm.erikoisalat},on:{"submit":submit,"cancel":cancel}})]}},{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('elsa-form-multiselect',{attrs:{"id":uid,"options":_vm.tyoskentelyjaksotFormatted,"label":"label","track-by":"id"},on:{"select":_vm.onTyoskentelyjaksoSelect},model:{value:(_vm.form.tyoskentelyjakso),callback:function ($$v) {_vm.$set(_vm.form, "tyoskentelyjakso", $$v)},expression:"form.tyoskentelyjakso"}}),_c('b-form-invalid-feedback',{attrs:{"id":(uid + "-feedback")}},[_vm._v(" "+_vm._s(_vm.$t('pakollinen-tieto'))+" ")])]}}],null,false,3504370846)})],1),_c('b-col',{staticClass:"mt-md-3 mb-3 mb-md-0 pl-3 pl-md-0",attrs:{"align-self":"center","cols":"9","md":"3"}},[_c('elsa-button',{attrs:{"variant":"primary","disabled":!_vm.form.tyoskentelyjakso,"loading":_vm.tyoskentelyjaksoUpdating},on:{"click":_vm.onTyoskentelyjaksoAttached}},[_vm._v(" "+_vm._s(_vm.$t('liita-koejaksoon'))+" ")])],1)],1):_vm._e()],1),(_vm.tyoskentelyjaksotKoejakso.length > 0)?_c('b-table',{staticClass:"tyoskentelyjaksot-koejakso-table mt-1 mr-3 mr-md-4 ml-3",attrs:{"items":_vm.tyoskentelyjaksotKoejakso,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"stacked":"sm"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: ((field.width) + "%") })})})}},{key:"cell(formattedNimi)",fn:function(row){return [_c('elsa-button',{staticClass:"shadow-none p-0 text-left",attrs:{"to":{
                        name: 'tyoskentelyjakso',
                        params: { tyoskentelyjaksoId: row.item.id }
                      },"variant":"link"}},[_vm._v(" "+_vm._s(row.item.formattedNimi)+" ")])]}},{key:"cell(delete)",fn:function(row){return [(!_vm.account.impersonated)?_c('elsa-button',{staticClass:"border-0 p-0",attrs:{"variant":"outline-primary","loading":row.item.disableDelete},on:{"click":function($event){return _vm.onTyoskentelyjaksoDetached(row.item)}}},[_c('font-awesome-icon',{attrs:{"hidden":row.item.disableDelete,"icon":['far', 'trash-alt'],"fixed-width":"","size":"lg"}})],1):_vm._e()]}}],null,false,2301580548)}):_vm._e()],1)],1)])])],1),_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('elsa-koulutussopimus-card')],1)],1),_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('koejakson-arviointi')))]),_c('elsa-aloituskeskustelu-card'),_c('elsa-valiarviointi-card'),_c('elsa-kehittamistoimenpiteet-card'),_c('elsa-loppukeskustelu-card'),_c('elsa-vastuuhenkilon-arvio-card')],1)],1)],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }