



































































































import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { Validation, validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import { Kouluttaja, UusiKouluttaja } from '@/types'

@Component({
  components: {
    ElsaFormGroup,
    ElsaButton
  },
  validations: {
    form: {
      etunimi: {
        required
      },
      sukunimi: {
        required
      },
      sahkoposti: {
        required,
        email
      }
    }
  }
})
export default class KouluttajaForm extends Mixins(validationMixin) {
  form: Partial<UusiKouluttaja> = {}
  params = {
    saving: false
  }

  @Prop({ required: true })
  kouluttajat!: Kouluttaja[]

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as Validation
    return $dirty ? ($error ? false : null) : null
  }

  get samanNimiset() {
    return this.kouluttajat.filter(
      (k) =>
        k.nimi.toLowerCase() ===
          `${this.form.etunimi?.toLowerCase()} ${this.form.sukunimi?.toLowerCase()}` &&
        k.sahkoposti !== this.form.sahkoposti
    )
  }

  get samaNimi() {
    return this.samanNimiset.length > 0
  }

  get samaSahkoposti() {
    return this.kouluttajat.filter((k) => k.sahkoposti === this.form.sahkoposti).length > 0
  }

  onSubmit() {
    this.$v.form.$touch()
    if (this.$v.form.$anyError || this.samaSahkoposti) {
      return
    }
    this.$emit('submit', this.form, this.params)
  }

  onCancel() {
    this.$emit('cancel')
  }
}
