var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tyoskentelyjaksot"},[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('tyoskentelyjaksot')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('yek.tyoskentelyjaksot-kuvaus'))+" "),_c('a',{attrs:{"href":"https://www.laaketieteelliset.fi/ammatillinen-jatkokoulutus/opinto-oppaat/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.$t('yek.tyoskentelyjaksot-kuvaus-opinto-oppaasta'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('yek.tyoskentelyjaksot-kuvaus-yli-kymmenen-vuotta-vanhoja'))+" ")]),_c('elsa-vanha-asetus-varoitus'),(_vm.muokkausoikeudet)?_c('div',{staticClass:"d-flex flex-wrap mb-3 mb-lg-4"},[_c('elsa-button',{staticClass:"mb-2 mr-2",attrs:{"variant":"primary","to":{ name: 'uusi-yek-tyoskentelyjakso' }}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-tyoskentelyjakso'))+" ")]),_c('elsa-button',{staticClass:"mb-2",attrs:{"variant":"outline-primary","to":{ name: 'uusi-yek-poissaolo' }}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-poissaolo'))+" ")])],1):_vm._e(),(!_vm.loading && _vm.tyoskentelyjaksotTaulukko != null && _vm.tilastot != null)?_c('div',[(
              _vm.terveyskeskuskoulutusjaksoPalautettuKorjattavaksi ||
              _vm.terveyskeskuskoulutusjaksoLahetetty ||
              _vm.terveyskeskuskoulutusjaksoUusi
            )?_c('b-alert',{attrs:{"variant":"dark","show":""}},[_c('h5',[_vm._v(_vm._s(_vm.$t('terveyskeskuskoulutusjakson-hyvaksynta')))]),(_vm.terveyskeskuskoulutusjaksoPalautettuKorjattavaksi)?_c('div',[_c('div',{staticClass:"d-flex flex-row"},[_c('em',{staticClass:"align-middle"},[_c('font-awesome-icon',{staticClass:"mr-2 text-danger",attrs:{"icon":['fas', 'exclamation-circle']}})],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t('terveyskeskuskoulutusjakso-on-palautettu-muokattavaksi'))+" "),_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$t('syy'))+" "),_c('span',[_vm._v(" "+_vm._s(_vm.tyoskentelyjaksotTaulukko.terveyskeskuskoulutusjaksonKorjausehdotus)+" ")])])])]),_c('elsa-button',{staticClass:"mt-2",attrs:{"to":{
                  name: 'terveyskeskuskoulutusjakson-hyvaksyntapyynto-yek'
                },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('pyyda-hyvaksyntaa'))+" ")])],1):_vm._e(),(_vm.terveyskeskuskoulutusjaksoUusi)?_c('div',[_c('div',{staticClass:"d-flex flex-row"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('terveyskeskuskoulutusjakso-on-hyvaksyttavissa'))+" ")])]),_c('elsa-button',{staticClass:"mt-3",attrs:{"to":{
                  name: 'terveyskeskuskoulutusjakson-hyvaksyntapyynto-yek'
                },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('pyyda-hyvaksyntaa'))+" ")])],1):_vm._e(),(_vm.terveyskeskuskoulutusjaksoLahetetty)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('terveyskeskuskoulutusjakso-on-lahetetty-hyvaksyttavaksi'))+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelyaika')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var uid = ref.uid;
return [_c('div',{staticClass:"d-flex flex-wrap",attrs:{"id":uid}},[_c('div',{staticClass:"d-flex flex-column mb-2 duration-card"},[_c('span',{staticClass:"duration-text"},[_vm._v(" "+_vm._s(_vm.$duration(_vm.tilastot.tyoskentelyaikaYhteensa))+" ")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(_vm._s(_vm.$t('tyoskentelyaika-yhteensa')))])]),_c('div',{staticClass:"d-flex flex-column mb-2 duration-card"},[_c('span',{staticClass:"duration-text"},[_vm._v(" "+_vm._s(_vm.$duration(_vm.tilastot.arvioErikoistumiseenHyvaksyttavista))+" ")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('yek.arvio-hyvaksyttavasta-koulutuksesta'))+" ")])]),_c('div',{staticClass:"d-flex flex-column mb-2 duration-card"},[_c('span',{staticClass:"duration-text"},[_vm._v(" "+_vm._s(_vm.$duration(_vm.tilastot.arvioPuuttuvastaKoulutuksesta))+" ")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('arvio-puuttuvasta-koulutuksesta'))+" ")])])])]}}],null,false,1382092668)}),_c('b-row',[_c('elsa-form-group',{staticClass:"col-xl-12 mb-0",attrs:{"label":_vm.$t('koulutustyypit')}},[_c('tyoskentelyjaksot-yek-bar-chart',{attrs:{"tilastot":_vm.tilastot}})],1)],1),(_vm.tyoskentelyjaksotTaulukko.terveyskeskuskoulutusjaksonHyvaksymispvm != null)?_c('elsa-form-group',{attrs:{"label":_vm.$t('terveyskeskuskoulutusjakso')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var uid = ref.uid;
return [_c('div',{staticClass:"d-flex flex-wrap",attrs:{"id":uid}},[_c('em',{staticClass:"align-middle"},[_c('font-awesome-icon',{staticClass:"text-success mr-2",attrs:{"icon":['fas', 'check-circle']}})],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t('terveyskeskuskoulutusjakso-on-hyvaksytty-pvm', { pvm: _vm.$date( _vm.tyoskentelyjaksotTaulukko.terveyskeskuskoulutusjaksonHyvaksymispvm ) }))+" ")])])]}}],null,false,2790300294)}):_vm._e(),_c('b-row',{staticClass:"col-xl-12 mb-0 mt-2"},[_c('span',[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),_vm._v(" "+_vm._s(_vm.$t('yek.tyoskentelyjaksot-muut-koulutukset-ohje'))+" ")],1)]),(_vm.edistyminen && _vm.edistyminen.laakarikoulutusSuoritettuSuomiTaiBelgia)?_c('b-row',{staticClass:"col-xl-12 mb-0 mt-2"},[_c('span',[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'info-circle']}}),_vm._v(" "+_vm._s(_vm.$t('yek.aiempi-hyvaksiluettu-suoritus'))+" ")],1)]):_vm._e()],1)]),_c('div',{staticClass:"tyoskentelyjaksot-table"},[_c('b-table',{attrs:{"items":_vm.tyoskentelyjaksotFormatted,"fields":_vm.fields,"stacked":"md","responsive":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.width })})})}},{key:"cell(tyoskentelypaikkaLabel)",fn:function(row){return [_c('elsa-button',{staticClass:"shadow-none px-0",attrs:{"to":{
                    name: 'yektyoskentelyjakso',
                    params: { tyoskentelyjaksoId: row.item.id }
                  },"variant":"link"}},[_vm._v(" "+_vm._s(row.item.tyoskentelypaikka.nimi)+" ")])]}},{key:"cell(ajankohtaDate)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.ajankohta)+" ")]}},{key:"cell(keskeytyksetLength)",fn:function(row){return [(row.item.keskeytyksetLength > 0)?_c('elsa-button',{staticClass:"shadow-none text-nowrap px-0",attrs:{"variant":"link"},on:{"click":row.toggleDetails}},[_vm._v(" "+_vm._s(row.item.keskeytyksetLength)+" "),_c('span',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(row.item.keskeytyksetLength == 1 ? _vm.$t('poissaolo') : _vm.$t('poissaoloa'))+" ")]),_c('font-awesome-icon',{staticClass:"ml-2 text-dark",attrs:{"icon":row.detailsShowing ? 'chevron-up' : 'chevron-down',"fixed-width":"","size":"lg"}})],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-poissaoloja'))+" ")])]}},{key:"row-details",fn:function(row){return [_c('div',{staticClass:"px-md-3"},[_c('b-table-simple',{attrs:{"stacked":"md"}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticStyle:{"width":"60%"}},[_vm._v(" "+_vm._s(_vm.$t('poissaolon-syy'))+" "),_c('elsa-popover',{attrs:{"title":_vm.$t('poissaolon-syy')}},[_c('elsa-poissaolon-syyt')],1)],1),_c('b-th',{staticStyle:{"width":"25%"}},[_vm._v(_vm._s(_vm.$t('ajankohta')))]),_c('b-th',{staticStyle:{"width":"15%"}},[_vm._v(_vm._s(_vm.$t('poissaolo')))])],1)],1),_c('b-tbody',_vm._l((row.item.keskeytykset),function(keskeytysaika,index){return _c('b-tr',{key:index},[_c('b-td',{attrs:{"stacked-heading":_vm.$t('poissaolon-syy')}},[_c('elsa-button',{staticClass:"shadow-none px-0",attrs:{"to":{
                              name: 'yekpoissaolo',
                              params: { poissaoloId: keskeytysaika.id }
                            },"variant":"link"}},[_vm._v(" "+_vm._s(keskeytysaika.poissaolonSyy.nimi)+" ")])],1),_c('b-td',{attrs:{"stacked-heading":_vm.$t('ajankohta')}},[_vm._v(" "+_vm._s(keskeytysaika.alkamispaiva != keskeytysaika.paattymispaiva ? ((_vm.$date(keskeytysaika.alkamispaiva)) + "-" + (_vm.$date( keskeytysaika.paattymispaiva ))) : _vm.$date(keskeytysaika.alkamispaiva))+" ")]),_c('b-td',{attrs:{"stacked-heading":_vm.$t('tyoaika')}},[_c('span',[_vm._v(_vm._s(keskeytysaika.poissaoloprosentti)+" %")])])],1)}),1)],1)],1)]}}],null,false,1825477750)})],1)],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }