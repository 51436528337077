
















import { AxiosError } from 'axios'
import { Component, Vue } from 'vue-property-decorator'

import { postIlmoitus } from '@/api/tekninen-paakayttaja'
import IlmoitusForm from '@/forms/ilmoitus-form.vue'
import { ElsaError, Ilmoitus } from '@/types'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    IlmoitusForm
  }
})
export default class MuokkaaIlmoituksia extends Vue {
  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('julkiset-ilmoitukset'),
        active: true
      }
    ]
  }

  async onSubmit(
    value: Ilmoitus,
    params: {
      saving: boolean
    }
  ) {
    params.saving = true
    try {
      await postIlmoitus(value)
      toastSuccess(this, this.$t('ilmoitus-julkaistu-onnistuneesti'))
      this.$emit('skipRouteExitConfirm', true)
      this.$router.push({
        name: 'ilmoitukset'
      })
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('ilmoituksen-tallentaminen-epaonnistui')}: ${this.$t(message)}`
          : this.$t('ilmoituksen-tallentaminen-epaonnistui')
      )
    }
    params.saving = false
  }
}
