import { render, staticRenderFns } from "./erikoistuva-details.vue?vue&type=template&id=01fccb8b&scoped=true&"
import script from "./erikoistuva-details.vue?vue&type=script&lang=ts&"
export * from "./erikoistuva-details.vue?vue&type=script&lang=ts&"
import style0 from "./erikoistuva-details.vue?vue&type=style&index=0&id=01fccb8b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fccb8b",
  null
  
)

export default component.exports