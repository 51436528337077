var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tyoskentelyjakso"},[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('tyoskentelyjakso')))]),_c('hr'),(_vm.tyoskentelyjakso)?_c('div',[_c('elsa-form-group',{attrs:{"label":_vm.$t('tyyppi')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.tyyppiLabel))]),(_vm.tyoskentelyjakso.tyoskentelypaikka.muuTyyppi)?_c('span',[_vm._v(" : "+_vm._s(_vm.tyoskentelyjakso.tyoskentelypaikka.muuTyyppi)+" ")]):_vm._e()]}}],null,false,2738261703)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoskentelypaikka')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.tyoskentelyjakso.tyoskentelypaikka.nimi))])]}}],null,false,3466793869)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('kunta')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.tyoskentelyjakso.tyoskentelypaikka.kunta.abbreviation)+" ")])]}}],null,false,2179760561)}),_c('elsa-form-group',{attrs:{"label":_vm.$t('tyoaika-taydesta-tyopaivasta') + ' (50–100 %)'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.tyoskentelyjakso.osaaikaprosentti)+" %")])]}}],null,false,205143617)}),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"label":_vm.$t('alkamispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.$date(_vm.tyoskentelyjakso.alkamispaiva)))])]}}],null,false,294964898)}),(_vm.tyoskentelyjakso.paattymispaiva)?_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pl-md-3",attrs:{"label":_vm.$t('paattymispaiva')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{staticClass:"datepicker-range",attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.$date(_vm.tyoskentelyjakso.paattymispaiva))+" ")])]}}],null,false,1072334508)}):_vm._e()],1),(_vm.tyoskentelyjakso.hyvaksyttyAiempaanErikoisalaan)?_c('elsa-form-group',{attrs:{"label":_vm.$t('lisatiedot')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.$t('tyoskentelyjakso-on-aiemmin-hyvaksytty-toiselle-erikoisalalle'))+" ")])]}}],null,false,116828707)}):_vm._e(),_c('elsa-form-group',{attrs:{"label":_vm.$t('liitetiedostot')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('asiakirjat-content',{attrs:{"id":uid,"asiakirjat":_vm.tyoskentelyjakso.asiakirjat,"sorting-enabled":false,"pagination-enabled":false,"enable-search":false,"enable-delete":false,"no-results-info-text":_vm.$t('ei-liitetiedostoja'),"loading":_vm.loading}})]}}],null,false,1410486865)}),(!_vm.account.impersonated)?_c('elsa-button',{staticClass:"mt-3",attrs:{"variant":"outline-primary","to":{
              name: 'uusi-yek-poissaolo',
              params: { tyoskentelyjaksoId: _vm.tyoskentelyjakso.id }
            },"disabled":_vm.tyoskentelyjakso.liitettyTerveyskeskuskoulutusjaksoon}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-poissaolo'))+" ")]):_vm._e(),_c('hr'),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[(_vm.muokkausoikeudet)?_c('elsa-button',{staticClass:"ml-2 mb-3",attrs:{"to":{ name: 'muokkaa-yek-tyoskentelyjaksoa' },"variant":"primary","disabled":_vm.tyoskentelyjakso.liitettyTerveyskeskuskoulutusjaksoon}},[_vm._v(" "+_vm._s(_vm.$t('muokkaa-jaksoa'))+" ")]):_vm._e(),(_vm.muokkausoikeudet)?_c('elsa-button',{staticClass:"mb-3",attrs:{"loading":_vm.deleting,"variant":_vm.tyoskentelyjakso.tapahtumia ? 'outline-primary' : 'outline-danger',"disabled":_vm.tyoskentelyjakso.tapahtumia ||
                _vm.tyoskentelyjakso.liitettyTerveyskeskuskoulutusjaksoon},on:{"click":_vm.onTyoskentelyjaksoDelete}},[_vm._v(" "+_vm._s(_vm.$t('poista-jakso'))+" ")]):_vm._e(),_c('elsa-button',{staticClass:"mb-3 mr-auto font-weight-500 tyoskentelyjaksot-link",attrs:{"to":{ name: 'yektyoskentelyjaksot' },"variant":"link"}},[_vm._v(" "+_vm._s(_vm.$t('palaa-tyoskentelyjaksoihin'))+" ")])],1)],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)])],1),(
        _vm.tyoskentelyjakso &&
        (_vm.tyoskentelyjakso.tapahtumia || _vm.tyoskentelyjakso.liitettyTerveyskeskuskoulutusjaksoon) &&
        !_vm.account.impersonated
      )?_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex flex-row"},[_c('em',{staticClass:"align-middle"},[_c('font-awesome-icon',{staticClass:"text-muted mr-1",attrs:{"icon":"info-circle","fixed-width":""}})],1),_c('div',[_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.tyoskentelyjakso.liitettyTerveyskeskuskoulutusjaksoon ? _vm.$t('tyoskentelyjaksoa-ei-voi-muokata-tai-poistaa-tooltip') : _vm.$t('tyoskentelyjaksoa-ei-voi-poistaa-tooltip'))+" ")])])])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }