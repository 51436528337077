





















































































import { Component, Prop, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import { Asiakirja, AsiakirjatTableField } from '@/types'
import { saveBlob, fetchAndSaveBlob, openBlob, fetchAndOpenBlob } from '@/utils/blobs'
import { confirmDelete } from '@/utils/confirm'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaSearchInput,
    ElsaPagination
  }
})
export default class AsiakirjatContent extends Vue {
  hakutermi = ''
  currentPage = 1

  @Prop({ required: true, default: () => [] })
  asiakirjat!: Asiakirja[]

  @Prop({ required: false, type: String, default: 'erikoistuva-laakari/asiakirjat/' })
  asiakirjaDataEndpointUrl!: string

  @Prop({ required: false, type: String, default: 'lisattypvm' })
  sortBy!: string

  @Prop({ required: false, type: Boolean, default: true })
  sortingEnabled!: boolean

  @Prop({ required: false, type: Boolean, default: true })
  sortDesc!: boolean

  @Prop({ required: false, type: Boolean, default: true })
  paginationEnabled!: boolean

  @Prop({ required: false, type: Number, default: 20 })
  perPage!: number

  @Prop({ required: false, type: Boolean, default: true })
  enableSearch!: boolean

  @Prop({ required: false, type: Boolean, default: true })
  enableDelete!: boolean

  @Prop({ required: false, type: Boolean, default: true })
  enableLisatty!: boolean

  @Prop({ required: false, type: Boolean, default: true })
  showInfoIfEmpty!: boolean

  @Prop({ required: false, type: String })
  noResultsInfoText?: string

  @Prop({ required: false, type: String })
  noSearchResultsInfoText?: string

  @Prop({ required: false, type: Boolean, default: false })
  loading!: boolean

  @Prop({ required: false, type: String })
  confirmDeleteTitle?: string

  @Prop({ required: false, type: String })
  confirmDeleteTypeText?: string

  fields: AsiakirjatTableField[] = []

  mounted() {
    this.fields = [
      {
        key: 'nimi',
        label: this.$t('tiedoston-nimi'),
        class: 'file-name'
      },
      {
        key: 'lisattypvm',
        label: this.$t('lisatty'),
        class: 'created-date',
        sortable: this.sortingEnabled,
        width: 10,
        formatter: (val: string) => {
          return new Date(val).toLocaleDateString(this.$i18n.locale)
        },
        disabled: !this.enableLisatty
      },
      {
        key: 'download',
        label: '',
        width: 5,
        class: this.enableDelete ? 'download-btn float-left-xs' : 'download-btn'
      },
      {
        key: 'delete',
        label: '',
        width: 5,
        disabled: !this.enableDelete
      }
    ] as AsiakirjatTableField[]
  }

  async onDeleteAsiakirja(asiakirja: Asiakirja) {
    if (
      await confirmDelete(
        this,
        this.confirmDeleteTitle ?? (this.$t('poista-liitetiedosto') as string),
        this.confirmDeleteTypeText?.toLowerCase() ??
          (this.$t('liitetiedoston') as string).toLowerCase()
      )
    ) {
      this.$emit('deleteAsiakirja', asiakirja)
    }
  }

  async onViewAsiakirja(asiakirja: Asiakirja) {
    Vue.set(asiakirja, 'disablePreview', true)

    if (!asiakirja.isDirty) {
      const success = await fetchAndOpenBlob(this.asiakirjaDataEndpointUrl, asiakirja.id)
      if (!success) {
        toastFail(this, this.$t('asiakirjan-sisallon-hakeminen-epaonnistui'))
      }
    } else {
      asiakirja.data?.then((data) => openBlob(data, asiakirja.contentType ?? '')) ??
        toastFail(this, this.$t('asiakirjan-sisallon-hakeminen-epaonnistui'))
    }

    Vue.set(asiakirja, 'disablePreview', false)
  }

  async onDownloadAsiakirja(asiakirja: Asiakirja) {
    Vue.set(asiakirja, 'disableDownload', true)
    if (!asiakirja.isDirty) {
      const success = await fetchAndSaveBlob(
        this.asiakirjaDataEndpointUrl,
        asiakirja.nimi,
        asiakirja.id
      )
      if (!success) {
        toastFail(this, this.$t('asiakirjan-sisallon-hakeminen-epaonnistui'))
      }
    } else {
      asiakirja.data?.then((data) =>
        saveBlob(asiakirja.nimi, data, asiakirja.contentType ?? '')
      ) ?? toastFail(this, this.$t('asiakirjan-sisallon-hakeminen-epaonnistui'))
    }
    Vue.set(asiakirja, 'disableDownload', false)
  }

  get tulokset() {
    if (this.hakutermi) {
      this.currentPage = 1
      return this.asiakirjat?.filter((item) =>
        item.nimi.toLowerCase().includes(this.hakutermi.toLowerCase())
      )
    }

    return this.asiakirjat
  }

  get rows() {
    return this.tulokset?.length ?? 0
  }

  get searchVisible() {
    return this.enableSearch && this.asiakirjat?.length > 0
  }

  get computedFields() {
    return this.fields.filter((field) => !field.disabled)
  }
}
