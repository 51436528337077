


























































































































































































































































































































































































































































































































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { maxValue, minValue, required, requiredIf } from 'vuelidate/lib/validators'

import { getUusinOpas } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import { Opintoopas } from '@/types'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormDatepicker
  }
})
export default class YekOpintoopasForm extends Mixins(validationMixin) {
  $refs!: {
    voimassaoloAlkaa: ElsaFormDatepicker
    voimassaoloPaattyy: ElsaFormDatepicker
  }

  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({ required: true, type: Object })
  opas!: Opintoopas

  @Prop({ required: true })
  erikoisalaId!: number

  params = {
    saving: false
  }

  validations() {
    return {
      opas: {
        nimi: {
          required
        },
        voimassaoloAlkaa: {
          required
        },
        kaytannonKoulutuksenVahimmaispituusVuodet: {
          required: requiredIf(() => {
            return !this.opas.kaytannonKoulutuksenVahimmaispituusKuukaudet
          }),
          minValue: minValue(0)
        },
        kaytannonKoulutuksenVahimmaispituusKuukaudet: {
          required: requiredIf(() => {
            return !this.opas.kaytannonKoulutuksenVahimmaispituusVuodet
          }),
          minValue: minValue(0),
          maxValue: maxValue(12)
        },
        terveyskeskuskoulutusjaksonVahimmaispituusVuodet: {
          required: requiredIf(() => {
            return !this.opas.terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet
          }),
          minValue: minValue(0)
        },
        terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet: {
          required: requiredIf(() => {
            return !this.opas.terveyskeskuskoulutusjaksonVahimmaispituusVuodet
          }),
          minValue: minValue(0),
          maxValue: maxValue(12)
        },
        yliopistosairaalajaksonVahimmaispituusVuodet: {
          required: requiredIf(() => {
            return !this.opas.yliopistosairaalajaksonVahimmaispituusKuukaudet
          }),
          minValue: minValue(0)
        },
        yliopistosairaalajaksonVahimmaispituusKuukaudet: {
          required: requiredIf(() => {
            return !this.opas.yliopistosairaalajaksonVahimmaispituusVuodet
          }),
          minValue: minValue(0),
          maxValue: maxValue(12)
        },
        yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet: {
          required: requiredIf(() => {
            return !this.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet
          }),
          minValue: minValue(0)
        },
        yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet: {
          required: requiredIf(() => {
            return !this.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet
          }),
          minValue: minValue(0),
          maxValue: maxValue(12)
        },
        yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet: {
          minValue: minValue(0)
        },
        yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet: {
          minValue: minValue(0),
          maxValue: maxValue(12)
        },
        erikoisalanVaatimaTeoriakoulutustenVahimmaismaara: {
          required,
          minValue: minValue(0)
        }
      }
    }
  }

  get showMuunKoulutuksenMaksimipituus() {
    return (
      this.opas?.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet !=
        this.opas?.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet ||
      this.opas?.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet !=
        this.opas?.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet
    )
  }

  async tuoOppaanTiedot() {
    const uusinOpas = (await getUusinOpas(this.$route.params.erikoisalaId)).data
    this.opas.kaytannonKoulutuksenVahimmaispituusVuodet =
      uusinOpas.kaytannonKoulutuksenVahimmaispituusVuodet
    this.opas.kaytannonKoulutuksenVahimmaispituusKuukaudet =
      uusinOpas.kaytannonKoulutuksenVahimmaispituusKuukaudet
    this.opas.terveyskeskuskoulutusjaksonVahimmaispituusVuodet =
      uusinOpas.terveyskeskuskoulutusjaksonVahimmaispituusVuodet
    this.opas.terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet =
      uusinOpas.terveyskeskuskoulutusjaksonVahimmaispituusKuukaudet
    this.opas.yliopistosairaalajaksonVahimmaispituusVuodet =
      uusinOpas.yliopistosairaalajaksonVahimmaispituusVuodet
    this.opas.yliopistosairaalajaksonVahimmaispituusKuukaudet =
      uusinOpas.yliopistosairaalajaksonVahimmaispituusKuukaudet
    this.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet =
      uusinOpas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusVuodet
    this.opas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet =
      uusinOpas.yliopistosairaalanUlkopuolisenTyoskentelynVahimmaispituusKuukaudet
    this.opas.erikoisalanVaatimaTeoriakoulutustenVahimmaismaara =
      uusinOpas.erikoisalanVaatimaTeoriakoulutustenVahimmaismaara
    Vue.set(
      this.opas,
      'yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet',
      uusinOpas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusVuodet
    )
    Vue.set(
      this.opas,
      'yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet',
      uusinOpas.yliopistosairaalanUlkopuolisenTyoskentelynMaksimipituusKuukaudet
    )
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.opas[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.opas.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    const validations = [
      this.validateForm(),
      this.$refs.voimassaoloAlkaa.validateForm(),
      this.$refs.voimassaoloPaattyy.validateForm()
    ]

    if (validations.includes(false)) {
      return
    }
    this.$emit(
      'submit',
      {
        ...this.opas
      },
      this.params
    )
  }
}
