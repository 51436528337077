




























































































import { Component, Vue, Prop } from 'vue-property-decorator'

import AsiakirjaButton from '@/components/asiakirjat/asiakirja-button.vue'
import { Valmistumispyynto } from '@/types'

@Component({
  components: {
    AsiakirjaButton
  }
})
export default class ValmistumispyynnonTilaKoulutettava extends Vue {
  @Prop({ required: true })
  valmistumispyynto!: Valmistumispyynto

  get lahetetty() {
    return this.valmistumispyynto.erikoistujanKuittausaika
      ? this.$date(this.valmistumispyynto.erikoistujanKuittausaika)
      : null
  }

  get virkailijanKuittausaika() {
    return this.valmistumispyynto.virkailijanKuittausaika
      ? this.$date(this.valmistumispyynto.virkailijanKuittausaika)
      : null
  }

  get vastuuhenkiloHyvaksyjaKuittausaika() {
    return this.valmistumispyynto.vastuuhenkiloHyvaksyjaKuittausaika
      ? this.$date(this.valmistumispyynto.vastuuhenkiloHyvaksyjaKuittausaika)
      : null
  }

  get yhteenvetoAsiakirjaUrl() {
    return this.valmistumispyynto.yhteenvetoAsiakirjaId ? `yek-koulutettava/asiakirjat/` : null
  }

  get liitteetAsiakirjaUrl() {
    return this.valmistumispyynto.liitteetAsiakirjaId ? `yek-koulutettava/asiakirjat/` : null
  }

  tilaIcon(vaiheHyvaksytty: boolean) {
    return vaiheHyvaksytty ? ['far', 'check-circle'] : ['fas', 'info-circle']
  }

  tilaIconClass(vaiheHyvaksytty: boolean) {
    return vaiheHyvaksytty ? 'text-success' : 'text-muted'
  }
}
