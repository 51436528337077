var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"suoritteet mb-4"},[_c('b-row',{attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',{attrs:{"sm":"6","cols":"auto"}},[_c('elsa-button',{staticClass:"mb-2 mt-2 mr-3",attrs:{"to":{
          name: 'lisaa-suoritteen-kategoria'
        },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-kategoria'))+" ")]),_c('elsa-button',{staticClass:"mb-2 mt-2",attrs:{"to":{
          name: 'lisaa-suorite'
        },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('lisaa-suorite'))+" ")])],1),_c('b-col',{attrs:{"cols":"5","xl":"auto"}},[_c('b-row',{staticClass:"voimassaolo",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"text-uppercase text-size-sm"},[_vm._v(_vm._s(_vm.$t('voimassaolopvm'))+":")])]),_c('b-col',{attrs:{"cols":"auto","md":"5"}},[_c('elsa-form-datepicker',{attrs:{"id":"voimassaolo"},model:{value:(_vm.voimassaolo),callback:function ($$v) {_vm.voimassaolo=$$v},expression:"voimassaolo"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1):(_vm.rows === 0)?_c('div',[_c('b-alert',{attrs:{"variant":"dark","show":""}},[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),(_vm.voimassaolo != null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-hakutuloksia'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-suoritteita'))+" ")])],1)],1):_c('b-table',{staticClass:"suoritteet-table",attrs:{"items":_vm.kategoriatSorted,"fields":_vm.fields,"details-td-class":"row-details","stacked":"md","responsive":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(){return [_c('col',{attrs:{"span":"1","width":"10%"}}),_c('col',{attrs:{"span":"1","width":"45%"}}),_c('col',{attrs:{"span":"1","width":"27%"}})]},proxy:true},{key:"cell(jarjestys)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.jarjestysnumero)+" ")]}},{key:"cell(nimi)",fn:function(data){return [_c('b-link',{attrs:{"to":{
              name: 'suoritteen-kategoria',
              params: { kategoriaId: data.item.id }
            }}},[_vm._v(" "+_vm._s(data.item.nimi)+" ")])]}},{key:"row-details",fn:function(row){return [_c('b-table',{attrs:{"items":_vm.filteredSuoritteet(row.item.suoritteet),"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(){return [_c('col',{attrs:{"span":"1","width":"12%"}}),_c('col',{attrs:{"span":"1","width":"45%"}}),_c('col',{attrs:{"span":"1","width":"27%"}})]},proxy:true},{key:"cell(nimi)",fn:function(data){return [_c('b-link',{attrs:{"to":{
                  name: 'suorite',
                  params: { suoriteId: data.item.id }
                }}},[_vm._v(" "+_vm._s(data.item.nimi)+" ")])]}},{key:"cell(voimassaolo)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$date(data.item.voimassaolonAlkamispaiva))+" - "+_vm._s(data.item.voimassaolonPaattymispaiva != null ? _vm.$date(data.item.voimassaolonPaattymispaiva) : '')+" ")]}}],null,true)})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }