import { render, staticRenderFns } from "./yhdista-kayttajatileja.vue?vue&type=template&id=f529fa4c&scoped=true&"
import script from "./yhdista-kayttajatileja.vue?vue&type=script&lang=ts&"
export * from "./yhdista-kayttajatileja.vue?vue&type=script&lang=ts&"
import style0 from "./yhdista-kayttajatileja.vue?vue&type=style&index=0&id=f529fa4c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f529fa4c",
  null
  
)

export default component.exports