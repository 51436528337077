




















































import { Component, Vue } from 'vue-property-decorator'

import { deleteArvioitavaKokonaisuus, getArvioitavaKokonaisuus } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import ArvioitavaKokonaisuusForm from '@/forms/arvioitava-kokonaisuus-form.vue'
import { ArvioitavaKokonaisuusWithErikoisala } from '@/types'
import { confirmDelete } from '@/utils/confirm'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ArvioitavaKokonaisuusForm,
    ElsaButton
  }
})
export default class ArvioitavaKokonaisuusView extends Vue {
  kokonaisuus: ArvioitavaKokonaisuusWithErikoisala | null = null

  loading = true
  deleting = false

  get items() {
    return [
      {
        text: this.$t('etusivu'),
        to: { name: 'etusivu' }
      },
      {
        text: this.$t('opetussuunnitelmat'),
        to: { name: 'opetussuunnitelmat' }
      },
      {
        text: this.kokonaisuus?.kategoria.erikoisala.nimi,
        to: { name: 'erikoisala' }
      },
      {
        text: this.$t('arvioitava-kokonaisuus'),
        active: true
      }
    ]
  }

  async mounted() {
    await this.fetchKokonaisuus()
    this.loading = false
  }

  async fetchKokonaisuus() {
    try {
      this.kokonaisuus = (await getArvioitavaKokonaisuus(this.$route?.params?.kokonaisuusId)).data
    } catch (err) {
      toastFail(this, this.$t('arvioitavan-kokonaisuuden-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'opetussuunnitelmat', hash: '#arvioitavat-kokonaisuudet' })
    }
  }

  async onDelete() {
    if (
      await confirmDelete(
        this,
        this.$t('poista-arvioitava-kokonaisuus') as string,
        (this.$t('arvioitavan-kokonaisuuden') as string).toLowerCase()
      )
    ) {
      this.deleting = true
      try {
        await deleteArvioitavaKokonaisuus(this.$route?.params?.kokonaisuusId)
        toastSuccess(this, this.$t('arvioitavan-kokonaisuuden-poistaminen-onnistui'))
        this.$router.replace({ name: 'erikoisala', hash: '#arvioitavat-kokonaisuudet' })
      } catch (err) {
        toastFail(this, this.$t('arvioitavan-kokonaisuuden-poistaminen-epaonnistui'))
      }
      this.deleting = false
    }
  }
}
